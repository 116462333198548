import { useCallback } from "react";
import { useAppService } from "./useAppService";

export const useAttachments = () => {
  const service = useAppService();

  const attachFile = useCallback(
    async (file: File): Promise<string> => {
      // Get URL to upload a file
      const uploadLink = await service.getUploadLink({});

      let requestInit: RequestInit;
      if (uploadLink.uploadLink.includes("localhost")) {
        // For local testing
        const formData = new FormData();
        formData.append("file", file);

        requestInit = {
          method: "POST",
          body: formData,
          // Adding mode='no-cors' as fetch() fails when trying to test locally
          //  with client using localhost:3000 but the uploadlink is localhost:8448
          mode: "no-cors"
        };
      } else {
        requestInit = {
          method: "PUT",
          body: file,
          headers: {
            "Content-Length": `${file.size}`
          }
        };
      }
      await fetch(uploadLink.uploadLink, requestInit);

      return uploadLink.blobId;
    },
    [service]
  );

  return { attachFile };
};
