import { assertNever } from "@hx/util/types";
import { LoadingButton } from "@mui/lab";
import {
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { DbKey } from "@pmp/adl/common/db";
import {
  ApprovalDetails,
  CreatePromotionNoteAttachmentReq,
  PromotionApprovalSummary
} from "@pmp/adl/petstock/merchantportal/api";
import { Promotion } from "@pmp/adl/petstock/merchantportal/db";
import {
  ApprovalAction,
  ApprovalType,
  Feature
} from "@pmp/adl/petstock/merchantportal/types";
import FileUploadInput from "@pmp/common/inputs/file-upload-input/file-upload-input";
import SelectInput from "@pmp/common/inputs/select-input/select-input";
import TextareaInput from "@pmp/common/inputs/textarea-input/textarea-input";
import {
  formatDateMonthDayTime,
  formatDateMonthDayYear
} from "@pmp/utils/dates";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useState } from "react";
import { useAppService } from "../../../hooks/useAppService";
import { useFeatures } from "../../../hooks/useFeatures";
import { object, string } from "yup";
import { SubmitListener } from "../form/helpers";
import { useAttachments } from "src/hooks/useAttachments";
import { useAlert } from "src/hooks/useAlertContext";

interface ApprovalState {
  approvalType: ApprovalType;
  approvalAction: ApprovalAction;
  showApprovalDialog: boolean;
}

export interface PromotionApprovalsTableProps {
  approvalSummary: PromotionApprovalSummary;
  promotionId: DbKey<Promotion>;
  isPromotionSubmitted: boolean;
  refreshPromotion: () => void;
}

export const PromotionApprovalsTable: FC<PromotionApprovalsTableProps> = ({
  approvalSummary,
  promotionId,
  isPromotionSubmitted,
  refreshPromotion
}) => {
  const [approvalState, setApprovalState] = useState<ApprovalState | null>(
    null
  );

  const renderApprovalRows = () => {
    return Object.keys(approvalSummary).map(
      (key: keyof PromotionApprovalSummary) => {
        const approvalDetail: ApprovalDetails | null = approvalSummary[key];
        let stage: string;
        let approvalType: ApprovalType;

        switch (key) {
          case "coordinatorApproval":
            stage = "Category Coordinator Approval";
            approvalType = "coordinatorApproval";
            break;
          case "categoryManagerApproval":
            stage = "Category Manager Approval";
            approvalType = "managerApproval";
            break;
          case "planningApproval":
            stage = "Category Planner Approval";
            approvalType = "planningApproval";
            break;
          case "generalManagerApproval":
            stage = "General Manager Approval";
            approvalType = "gmApproval";
            break;
          case "totalBusinessApproval":
            stage = "Business Manager Approval";
            approvalType = "totalBusinessApproval";
            break;
          default:
            assertNever(key);
        }

        return (
          <TableRow key={key}>
            <TableCell>{stage}</TableCell>
            <ApprovalActionCell
              approvalAction={approvalDetail?.approvalAction ?? null}
              feature={key}
              isPromotionSubmitted={isPromotionSubmitted}
              onApprovalActionSelect={(approvalAction: ApprovalAction) => {
                setApprovalState({
                  approvalAction,
                  approvalType,
                  showApprovalDialog: true
                });
              }}
            />
            <TableCell>
              {approvalDetail !== null
                ? approvalDetail.approvedBy.fullname
                : "--"}
            </TableCell>
            <TableCell>
              {approvalDetail !== null
                ? formatDateMonthDayTime(approvalDetail.approvedAt)
                : "--"}
            </TableCell>
          </TableRow>
        );
      }
    );
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>STAGE</TableCell>
            <TableCell>APPROVAL ACTION</TableCell>
            <TableCell>USER</TableCell>
            <TableCell>DATE & TIME</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderApprovalRows()}</TableBody>
      </Table>
      {approvalState !== null && (
        <ApprovePromotionDialog
          approvalAction={approvalState.approvalAction}
          approvalType={approvalState.approvalType}
          promotionId={promotionId}
          open={approvalState.showApprovalDialog}
          onClose={() => {
            setApprovalState(null);
          }}
          refreshPromotion={refreshPromotion}
        />
      )}
    </TableContainer>
  );
};

interface ApprovalActionForm {
  approvalAction: string;
}
interface ApprovalActionCellProps {
  approvalAction: ApprovalAction | null;
  feature: Feature;
  isPromotionSubmitted: boolean;
  onApprovalActionSelect: (approvalAction: ApprovalAction) => void;
}
export const ApprovalActionCell: FC<ApprovalActionCellProps> = ({
  approvalAction,
  feature,
  isPromotionSubmitted,
  onApprovalActionSelect
}) => {
  const { hasEditorAccess } = useFeatures();

  const submitApprovalAction = (values: ApprovalActionForm) => {
    if (values.approvalAction) {
      onApprovalActionSelect(values.approvalAction as ApprovalAction);
    }
  };

  return (
    <TableCell>
      <Formik<ApprovalActionForm>
        initialValues={{
          approvalAction: approvalAction ?? ""
        }}
        onSubmit={submitApprovalAction}
      >
        <Form>
          <SelectInput
            name="approvalAction"
            disabled={!hasEditorAccess(feature) || !isPromotionSubmitted}
            placeholder="Pending Action"
            options={[
              {
                title: "Approve",
                value: "approve"
              },
              {
                title: "Re-negotiation",
                value: "renegotiate"
              },
              {
                title: "Reject",
                value: "reject"
              }
            ]}
          />
          <SubmitListener debounceWait={0} />
        </Form>
      </Formik>
    </TableCell>
  );
};

interface ApprovePromotionForm {
  approvalAction: ApprovalAction;
  approvalType: ApprovalType;
  note: string;
  attachments: File[];
}

interface ApprovePromotionDialog {
  approvalType: ApprovalType;
  approvalAction: ApprovalAction;
  promotionId: DbKey<Promotion>;
  open: boolean;
  onClose: () => void;
  refreshPromotion: () => void;
}
const ApprovePromotionDialog: FC<ApprovePromotionDialog> = ({
  approvalType,
  approvalAction,
  promotionId,
  open,
  onClose,
  refreshPromotion
}) => {
  const service = useAppService();
  const { attachFile } = useAttachments();
  const [showAlert] = useAlert();
  const { getUserProfile } = useFeatures();

  let title: string;
  let subtext: string;
  let primaryButtonLabel: string;

  switch (approvalAction) {
    case "approve":
      title = "Promotion Approval";
      subtext =
        "This promotion will be approved by you & won’t be scheduled to run until everyone approve it.";
      primaryButtonLabel = "Approve";
      break;
    case "renegotiate":
      title = "Promotion Re-negotiation";
      subtext =
        "This promotion will be sent back to Supplier for amendment. Promotion Owner of this Promotion block will be notified.";
      primaryButtonLabel = "Request Negotiation";
      break;
    case "reject":
      title = "Reject Promotion";
      subtext = "This promotion will be rejected";
      primaryButtonLabel = "Reject";
      break;
    default:
      assertNever(approvalAction);
  }

  const submitApproval = useCallback(
    async (values: ApprovePromotionForm) => {
      const { approvalAction, approvalType, note, attachments } = values;
      const attachmentReq: CreatePromotionNoteAttachmentReq[] = [];
      if (approvalAction === "renegotiate" && attachments.length > 0) {
        for (const file of values.attachments) {
          // Upload each attachment
          const blobId = await attachFile(file);

          const fileReq: CreatePromotionNoteAttachmentReq = {
            fileName: file.name,
            uploadId: blobId
          };
          attachmentReq.push(fileReq);
        }
      }

      const resp = await service.approvePromotion({
        promotionId,
        approvalAction,
        approvalType,
        note,
        promotionNoteAttachments: attachmentReq
      });

      let title: string;
      let body = "";
      let type: AlertColor = "error";
      let shouldRefreshPromotion = false;
      switch (resp) {
        case "success":
          type = "success";
          shouldRefreshPromotion = true;
          switch (approvalAction) {
            case "approve":
              title = "This promotion has been succesfully approved by you.";
              break;
            case "renegotiate":
              title =
                "This promotion is under renegotiation and awaiting for Supplier to submit Amendment.";
              break;
            case "reject":
              title = "This promotion has been rejected";

              const role = getUserProfile().role?.name;
              if (role) {
                title = `${title} by ${getUserProfile().fullname}`;
              }
              title = `${title} on ${formatDateMonthDayYear(Date.now())}`;
              break;
            default:
              assertNever(approvalAction);
          }
          break;
        case "failNoteMustBeProvided":
          title = `Failed to ${approvalAction} promotion.`;
          body = "A note or reason must be provided.";
          break;
        case "failHeaderMustBeProvided":
          title = `Failed to ${approvalAction} promotion.`;
          body = "The promotion header must be provided.";
          break;
        case "failPromotionAlreadyApproved":
          title = `Failed to ${approvalAction} promotion.`;
          body = "Promotion has already been approved";
          break;
        case "failPromotionNotSubmitted":
          title = `Failed to ${approvalAction} promotion.`;
          body = "The promotion must be submitted to be approved.";
          break;
        default:
          title = `Failed to ${approvalAction} promotion.`;
          body = "An error was encountered, please try again.";
          break;
      }
      showAlert({
        title,
        body,
        type
      });
      if (shouldRefreshPromotion) {
        refreshPromotion();
      }
    },
    [
      attachFile,
      getUserProfile,
      promotionId,
      refreshPromotion,
      service,
      showAlert
    ]
  );

  const validationSchema = object().shape({
    approvalAction: string()
      .oneOf(["approve", "reject", "renegotiate"])
      .required(),
    note: string().when("approvalAction", {
      is: "approve",
      then: string(),
      otherwise: string().required()
    })
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <Formik<ApprovePromotionForm>
        initialValues={{
          approvalAction,
          approvalType,
          note: "",
          attachments: []
        }}
        validationSchema={validationSchema}
        onSubmit={submitApproval}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <DialogContentText>{subtext}</DialogContentText>
                <TextareaInput
                  name="note"
                  label={
                    approvalAction === "reject"
                      ? "Reason for Rejection"
                      : "Note"
                  }
                  placeholder="Enter your note"
                  required={approvalAction !== "approve"}
                />
                {approvalAction === "renegotiate" && (
                  <FileUploadInput
                    name="attachments"
                    label="Attachment"
                    multiple={true}
                  />
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
              >
                {primaryButtonLabel}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
