import { assertNever } from "@hx/util/types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ChangePasswordReq,
  ChangePasswordResp,
  ForgotPasswordReq,
  LoginReq,
  SupplierWorkspaceSummary,
  UserProfile,
  VerifyUserReq,
  VerifyUserResp
} from "@pmp/adl/petstock/merchantportal/api";
import { ApiServices } from "../service/api-services";

import { AppRoutes } from "./app";

export type LoginRespType =
  | { kind: "logged-in" }
  | { kind: "login-failed"; error: string };

export interface IdentityState {
  /** The authenticated users details */
  profile: UserProfile;
  /** The http APIs authenticated for the current user  */
  apis: ApiServices;
}

export interface LoginState {
  user: IdentityState | undefined;
  loginError: string | undefined;
  verifyAccountError: string | undefined;
  forgotPasswordConfirmation: string | undefined;
  changePasswordError: string | undefined;

  onLogin(req: LoginReq): Promise<LoginRespType>;

  onLogout(): void;

  onVerifyAccount(req: VerifyUserReq): Promise<VerifyUserResp>;

  onForgotPassword(req: ForgotPasswordReq): Promise<void>;

  onChangePassword(req: ChangePasswordReq): Promise<ChangePasswordResp>;

  /** A boolean state tracking whether the user profile has loaded. */
  userProfileIsLoading: boolean;
  hasUnreadNotifications: boolean;
  userSupplierWorkspaces: UserSupplierWorkspaces;

  setGlobalWorkspace(supplierWorkspaceId: string): void;

  selectedSupplierWorkspaceId: string | null;
}

export interface LoginStateProps {
  createApiServices(token?: string): ApiServices;
}

export type UserSupplierWorkspaces = Array<
  Pick<SupplierWorkspaceSummary, "id" | "code" | "name">
>;

/**
 * Manages login state with react hooks
 */
export function useLoginState(props: LoginStateProps): LoginState {
  const [loginError, setLoginError] = React.useState<string | undefined>(
    undefined
  );
  const [verifyAccountError, setVerifyAccountError] = React.useState<
    string | undefined
  >(undefined);
  const [
    forgotPasswordConfirmation,
    setForgotPasswordConfirmation
  ] = React.useState<string | undefined>(undefined);
  const [changePasswordError, setChangePasswordError] = React.useState<
    string | undefined
  >(undefined);

  const [user, setUser] = React.useState<IdentityState | undefined>(undefined);
  const [userProfileIsLoading, setUserProfileIsLoading] = useState(true);
  const [userSupplierWorkspaces, setUserSupplierWorkspaces] = useState<
    UserSupplierWorkspaces
  >([]);
  const [
    selectedSupplierWorkspaceId,
    setSelectedSupplierWorkspaceId
  ] = useState<string | null>(null);

  useEffect(() => {
    const supplierWorkspaceId1 = getSupplierWorkspaceId();
    setSelectedSupplierWorkspaceId(supplierWorkspaceId1 ?? null);
  }, []);

  const [hasUnreadNotifications, setHasUnreadNotifications] = React.useState(
    false
  );

  const history = useHistory();
  const location = useLocation();

  async function loadUserState(authToken: string) {
    const apis = props.createApiServices(authToken);
    const profile = await apis.app.whoAmI();
    setUser({ apis, profile });

    const unreadNotifications = await apis.app.queryNotifications({
      group: null,
      unRead: true
    });
    setHasUnreadNotifications(unreadNotifications.length > 0);

    const userWorkspaces = await apis.app.queryWorkspaces({
      supplierWorkspaceId: null,
      workspaceNameLike: null
    });

    setUserSupplierWorkspaces([...userWorkspaces]);

    if (userWorkspaces.length === 1) {
      const workspaceId = userWorkspaces[0].id;
      storeSupplierWorkspaceId(workspaceId);
      setSelectedSupplierWorkspaceId(workspaceId);
    }
  }

  async function onLogin(req: LoginReq): Promise<LoginRespType> {
    const apis0 = props.createApiServices();
    const r = await apis0.app.login(req);

    if (r.kind === "invalidCredentials") {
      const errorStr =
        "Email Address and/or Password is incorrect. Please try again.";
      setLoginError(errorStr);
      return { kind: "login-failed", error: errorStr };
    } else if (r.kind === "userNotVerified") {
      const errorStr =
        "Account is pending verification. Please check your email and verify your account.";
      setLoginError(errorStr);
      return { kind: "login-failed", error: errorStr };
    } else if (r.kind === "userInactive") {
      const errorStr =
        "Account has been archived. Please contact your administrator.";
      setLoginError(errorStr);
      return { kind: "login-failed", error: errorStr };
    } else if (r.kind === "accessToken") {
      setLoginError(undefined);
      await loadUserStateAndStoreToken(r.value);
      return { kind: "logged-in" };
    } else {
      assertNever(r);
    }
  }

  async function onVerifyAccount(req: VerifyUserReq): Promise<VerifyUserResp> {
    const apis0 = props.createApiServices();
    const r = await apis0.app.verifyUser(req);

    if (r.kind === "failUserNotVerified") {
      setVerifyAccountError("Unable to verify account.");
      return r;
    } else if (r.kind === "accessToken") {
      setVerifyAccountError(undefined);
      await loadUserStateAndStoreToken(r.value);
      return r;
    } else {
      assertNever(r);
    }
  }

  async function onForgotPassword(req: ForgotPasswordReq): Promise<void> {
    const apis0 = props.createApiServices();
    await apis0.app.forgotPassword(req);
    const confirmationStr =
      "Your change password request has been submitted successfully.";

    setForgotPasswordConfirmation(confirmationStr);
  }

  async function onChangePassword(
    req: ChangePasswordReq
  ): Promise<ChangePasswordResp> {
    const apis0 = props.createApiServices();
    const r = await apis0.app.changePassword(req);

    if (r.kind === "failPasswordNotChanged") {
      setChangePasswordError("Unable to change your password.");
      return r;
    } else if (r.kind === "accessToken") {
      setChangePasswordError(undefined);
      await loadUserStateAndStoreToken(r.value);
      return r;
    } else {
      assertNever(r);
    }
  }

  async function loadUserStateAndStoreToken(authToken: string) {
    await loadUserState(authToken);
    storeLocalToken(authToken);
  }

  function onLogout(): void {
    setUser(undefined);
    history.push(AppRoutes.Login);
    clearLocalTokens();
  }

  const setGlobalWorkspace = (supplierWorkspaceId: string) => {
    setSelectedSupplierWorkspaceId(supplierWorkspaceId);
    storeSupplierWorkspaceId(supplierWorkspaceId);
    const currentPath = location.pathname;
    /* TODO: update this once routes are fixed */
    //  add redirects per route
    if (currentPath.startsWith(AppRoutes.Workspaces)) {
      if (supplierWorkspaceId === "") {
        history.push(AppRoutes.Workspaces);
      } else {
        history.push(`${AppRoutes.Workspaces}/${supplierWorkspaceId}`);
      }
    }
  };

  // On initial mount, load the logged in user profile.
  useEffect(() => {
    const token = getLocalToken();
    // If the user is logged out, then stop loading and don't attempt to load the user profile.
    if (token === null || token.length < 0) {
      setUserProfileIsLoading(false);
    } else {
      void loadUserState(token);
    }
    // Dependency array deliberately kept empty so effect only runs once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // When the logged in user profile changes, check if it is defined and set
  // update the user profile loading state tracker accordingly.
  useEffect(() => {
    if (user) {
      setUserProfileIsLoading(false);
    }
  }, [user]);

  return {
    user,
    loginError,
    verifyAccountError,
    onLogin,
    onLogout,
    userProfileIsLoading,
    onVerifyAccount,
    forgotPasswordConfirmation,
    onForgotPassword,
    changePasswordError,
    onChangePassword,
    hasUnreadNotifications,
    setGlobalWorkspace,
    userSupplierWorkspaces,
    selectedSupplierWorkspaceId
  };
}

/* TODO: refactor localstorage helpers */
export function storeLocalToken(authToken: string) {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
}

export function getLocalToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function clearLocalTokens() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(SELECTED_SUPPLIER_WORKSPACE);
}

export function storeSupplierWorkspaceId(supplierWorkspaceId: string) {
  localStorage.setItem(SELECTED_SUPPLIER_WORKSPACE, supplierWorkspaceId);
}

export function getSupplierWorkspaceId() {
  return localStorage.getItem(SELECTED_SUPPLIER_WORKSPACE);
}

const AUTH_TOKEN_KEY = "merchantportal-access-token";
const SELECTED_SUPPLIER_WORKSPACE = "merchantportal-supplier-workspace";
