import { Box, Stack, Typography, useTheme } from "@mui/material";
import { valuesPromotionStatus } from "@pmp/adl/petstock/merchantportal/types";
import { promotionStatusToUILabelColor, UILabelColor } from "@pmp/utils/adl";
import React from "react";
import { FC, useMemo } from "react";

interface PromotionStatusLegendProps {
  hideDivider?: boolean;
}
const PromotionStatusLegend: FC<PromotionStatusLegendProps> = ({
  hideDivider = false
}) => {
  const theme = useTheme();
  const promoStatusUI = useMemo(() => {
    return valuesPromotionStatus.map(status =>
      promotionStatusToUILabelColor(status, theme)
    );
  }, [theme]);

  return (
    <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
      {promoStatusUI.map(statusUI => {
        return (
          <PromotionStatusLegendItem
            key={statusUI.label}
            {...statusUI}
            hideDivider={hideDivider}
          />
        );
      })}
    </Stack>
  );
};

interface PromotionStatusLegendItemProps extends UILabelColor {
  hideDivider: boolean;
}
const PromotionStatusLegendItem: FC<PromotionStatusLegendItemProps> = ({
  color,
  label,
  hideDivider
}) => {
  return (
    <Stack
      direction={"row"}
      ml={"10px"}
      sx={{
        "&:not(:first-child):before": !hideDivider
          ? {
              content: '""',
              mr: "11px",
              height: 16,
              borderRight: "1px solid",
              borderColor: theme => theme.palette.gray.lighter
            }
          : ""
      }}
    >
      <Box
        borderRadius={"2px"}
        width={16}
        height={16}
        mr={1}
        sx={{
          backgroundColor: color
        }}
      />
      <Typography variant={"h5"}>{label}</Typography>
    </Stack>
  );
};

export default PromotionStatusLegend;
