import AvatarName from "@pmp/components/AvatarName";
import { QueryActivityLogResp } from "../../../adl-gen/petstock/merchantportal/api";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";
import { isLoaded, LoadingValue } from "../../../utils/UtilityTypes";
import { Loader } from "../common/loader/loader";
import { format } from "date-fns";
import { activityLogToText } from "@pmp/utils/adl";

interface ActivityLogTableProps {
  loadingActivities: LoadingValue<QueryActivityLogResp>;
}

export const ActivityLogTable = (props: ActivityLogTableProps) => {
  return (
    <Loader loadingStates={[props.loadingActivities]}>
      {isLoaded(props.loadingActivities) && (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: "1px solid"
                  }}
                >
                  USER
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid",
                    borderColor: theme => theme.palette.gray.lighter
                  }}
                >
                  ACTIVITY
                </TableCell>
                <TableCell>DATE & TIME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.loadingActivities.value.map(activity => (
                <TableRow key={activity.id}>
                  <TableCell
                    sx={{
                      borderRight: "1px solid"
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <AvatarName
                        name={`${
                          activity.userMap[activity.performedBy].firstName
                        } ${activity.userMap[activity.performedBy].lastName}`}
                        size={"small"}
                      />
                      {`${activity.userMap[activity.performedBy].firstName} ${
                        activity.userMap[activity.performedBy].lastName
                      }`}
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid",
                      borderColor: theme => theme.palette.gray.lighter
                    }}
                  >
                    {activityLogToText(activity)}
                  </TableCell>
                  <TableCell>
                    {format(activity.occurredAt, "MMM dd hh:mm a")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Loader>
  );
};
