import { DbKey } from "@adltools/adl-gen/common/db";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  FindProductsReq,
  FindProductsResp,
  ProductDetails
} from "@pmp/adl/petstock/merchantportal/api";
import { Product } from "@pmp/adl/petstock/merchantportal/db";
import { PromotionItemType } from "@pmp/adl/petstock/merchantportal/types";
import { formatPlural } from "@pmp/utils/strings";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism.css";
import React, { FC, useCallback, useMemo, useState } from "react";
import Editor from "react-simple-code-editor";
import { UploadIcon } from "../../common/icon/icons";

interface BarcodeUploaderProps {
  findProducts: (req: FindProductsReq) => Promise<FindProductsResp>;
  productType: PromotionItemType | null;
  handleCancelUpload: () => void;
  handleAddProducts: (
    products: DbKey<Product>[],
    productType: PromotionItemType | null
  ) => void;
  handleSwitchToSelectItems: () => void;
  supplierWorkspaceId: string;
  qualifiedProducts: DbKey<Product>[]; // qualified products should not be allowed to be added as award products
  awardProducts: DbKey<Product>[]; // award products should not be allowed to be added as qualified products
  productsAlreadyInPromotion: DbKey<Product>[]; // products already exist in the promotion and should not be allowed to be added again
}

const BarcodeUploader: FC<BarcodeUploaderProps> = ({
  findProducts,
  handleCancelUpload,
  productType,
  handleSwitchToSelectItems,
  handleAddProducts,
  supplierWorkspaceId,
  qualifiedProducts,
  awardProducts,
  productsAlreadyInPromotion
}) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<"barcodes" | "promoItems">(
    "barcodes"
  );
  const [barcodes, setBarcodes] = useState<Array<string>>([]);
  const [foundProducts, setFoundProducts] = useState<Array<ProductDetails>>([]);
  const [unrecognisedBarcodes, setUnrecognisedBarcodes] = useState<
    Array<string>
  >([]);
  const [duplicatedBarcodes, setDuplicatedBarcodes] = useState<
    Array<ProductDetails>
  >([]);
  const [
    duplicatedBarcodesWithSameProductType,
    setDuplicatedBarcodesWithSameProductType
  ] = useState<Array<ProductDetails>>([]);
  const [selectedProductsIds, setSelectedProductsIds] = useState<
    DbKey<Product>[]
  >([]);
  /**
   * Cleans the value typed in the Editor
   *
   * lines: alphanumeric until a separator is found
   * separators: any non-alphanumeric character
   *
   * @param value latest value typed in the Editor
   */
  const handleBarcodeUploadChange = (value: string) => {
    // lines by sets of alphanumeric
    const lines = value.split(/[^a-zA-Z0-9]/);
    // removes all empty lines
    const cleanLines = lines.filter(
      (line, index) => line !== "" || index === lines.length - 1
    );
    // remove duplicated barcodes
    const uniqueLines = Array.from(new Set(cleanLines));
    // add linebreaks
    const updatedValue = uniqueLines.join("\n");
    setValue(updatedValue);
    // removes any empty lines(last one)
    const barcodes = uniqueLines.filter(line => line !== "");
    setBarcodes(barcodes);
  };

  const highlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split("\n")
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join("\n");

  const isItemInList = (
    item: string,
    productList: DbKey<Product>[]
  ): boolean => {
    return productList.length > 0 && productList.indexOf(item) >= 0;
  };

  const _handleBarcodeUpload = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await findProducts({
        barcodes,
        supplierWorkspaceId
      });
      const _foundProducts: Array<ProductDetails> = [];
      const _unrecognisedBarcodes: string[] = [];
      const _duplicatedBarcodes: Array<ProductDetails> = [];
      const _duplicatedBarcodesWithSameProductType: Array<ProductDetails> = [];
      Object.entries(resp.barcodeMap).map(([_key, value]) => {
        if (value) {
          let isDuplicatedProduct;
          let isDuplicatedProductWithSameType;
          if (productType === null) {
            isDuplicatedProduct = isItemInList(
              value.id,
              productsAlreadyInPromotion
            );
          } else if (productType === "award") {
            isDuplicatedProductWithSameType = isItemInList(
              value.id,
              awardProducts
            );
            isDuplicatedProduct = isItemInList(value.id, qualifiedProducts);
          } else if (productType === "qualifying") {
            isDuplicatedProductWithSameType = isItemInList(
              value.id,
              qualifiedProducts
            );
            isDuplicatedProduct = isItemInList(value.id, awardProducts);
          }

          if (isDuplicatedProduct) {
            _duplicatedBarcodes.push(value);
          } else if (isDuplicatedProductWithSameType) {
            _duplicatedBarcodesWithSameProductType.push(value);
          } else {
            _foundProducts.push(value);
          }
        } else {
          _unrecognisedBarcodes.push(_key);
        }
      });

      setDuplicatedBarcodes(_duplicatedBarcodes);
      setDuplicatedBarcodesWithSameProductType(
        _duplicatedBarcodesWithSameProductType
      );
      setFoundProducts(_foundProducts);
      setUnrecognisedBarcodes(_unrecognisedBarcodes);
    } catch (e) {}
    setLoading(false);
    setCurrentStep("promoItems");
  }, [
    awardProducts,
    barcodes,
    findProducts,
    productType,
    qualifiedProducts,
    productsAlreadyInPromotion,
    supplierWorkspaceId
  ]);

  const isSelected = useCallback(
    (id: DbKey<Product>) => {
      return selectedProductsIds.indexOf(id) !== -1;
    },
    [selectedProductsIds]
  );

  const selectedItems = useMemo(() => {
    const count = selectedProductsIds.length;
    return `Selected ${count} ${formatPlural("Item", count)}`;
  }, [selectedProductsIds.length]);

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        setSelectedProductsIds([]);
        return;
      }

      setSelectedProductsIds([...foundProducts.map(p => p.id)]);
    },
    [foundProducts]
  );

  const handleSelectProduct = useCallback(
    (id: DbKey<Product>) => {
      const selectedProductIndex = selectedProductsIds.indexOf(id);
      if (selectedProductIndex === -1) {
        setSelectedProductsIds(products => [...products, id]);
      } else {
        setSelectedProductsIds(products => products.filter(p => p !== id));
      }
    },
    [selectedProductsIds]
  );

  const renderImportAlert = useMemo(() => {
    const allFound = barcodes.length === foundProducts.length;
    if (allFound) {
      return (
        <Alert
          severity={"success"}
          title={`Imported Successfully. ${barcodes.length} Barcodes were recognized.`}
        >
          Imported Successfully.&nbsp;{barcodes.length}&nbsp;Barcodes were
          recognized.
        </Alert>
      );
    }

    const foundProductsCount = foundProducts.length;
    const unrecognisedCount = unrecognisedBarcodes.length;
    const duplicatedCount = duplicatedBarcodes.length; // recognised barcodes but duplicates with the qualified/award products
    const duplicatedWithSameTypeCount =
      duplicatedBarcodesWithSameProductType.length; // recognised barcodes but duplicates with the existing products

    let alertMessage = "";
    if (
      foundProducts.length === 0 &&
      duplicatedCount === 0 &&
      duplicatedWithSameTypeCount === 0
    ) {
      alertMessage += `No Barcodes were recognized`;
    } else {
      if (foundProductsCount > 0) {
        if (alertMessage.length === 0) {
          alertMessage = `Imported Successfully. `;
        }

        if (foundProductsCount === 1) {
          alertMessage += `${foundProductsCount} Product Barcode was recognized`;
        } else {
          alertMessage += `${foundProductsCount} Product Barcodes were recognized`;
        }
      }

      if (unrecognisedCount > 0) {
        if (alertMessage.length === 0) {
          alertMessage = `Imported Successfully. `;
        } else {
          alertMessage += `, `;
        }

        if (unrecognisedCount === 1) {
          alertMessage += `${unrecognisedCount} Barcode was not recognized`;
        } else {
          alertMessage += `${unrecognisedCount} Barcodes were not recognized`;
        }
      }

      if (duplicatedCount > 0) {
        let duplicationType;
        if (productType === null) {
          duplicationType = "existing products";
        } else {
          duplicationType =
            productType === "award" ? "Qualified Products" : "Award Products";
        }
        if (alertMessage.length === 0) {
          alertMessage = `Imported Successfully. `;
        } else {
          alertMessage += `, `;
        }

        alertMessage += `${duplicatedCount} found duplicated with ${duplicationType} and won't be included`;
      }

      if (duplicatedWithSameTypeCount > 0) {
        let duplicationType;
        if (productType === null) {
          duplicationType = "existing products";
        } else {
          duplicationType =
            productType === "award" ? "Award Products" : "Qualified Products";
        }
        if (alertMessage.length === 0) {
          alertMessage = `Imported Successfully. `;
        } else {
          alertMessage += `, `;
        }

        alertMessage += `${duplicatedWithSameTypeCount} found duplicated with ${duplicationType} and won't be included`;
      }
    }
    alertMessage = alertMessage + `.`;

    return (
      <Alert severity={"warning"} title={alertMessage}>
        {alertMessage}
      </Alert>
    );
  }, [
    barcodes.length,
    foundProducts.length,
    unrecognisedBarcodes.length,
    duplicatedBarcodes.length,
    duplicatedBarcodesWithSameProductType.length,
    productType
  ]);

  const renderCurrentStep = useMemo(() => {
    switch (currentStep) {
      case "barcodes":
        return (
          <>
            <Typography variant={"subtitle2"} color={"gray.darker"}>
              Type or Paste the Barcodes of the Product you want to include in
              the text box below.
            </Typography>
            <Stack gap={1}>
              <Typography variant={"h4Bold"}>Barcode</Typography>
              <Box height={256} overflow={"auto"}>
                <StyledEditor
                  value={value}
                  onValueChange={handleBarcodeUploadChange}
                  highlight={code =>
                    highlightWithLineNumbers(code, languages.text)
                  }
                  textareaId="codeArea"
                  className="editor"
                  style={{
                    fontFamily: "monospace",
                    fontSize: 14,
                    outline: 0,
                    minHeight: 256
                  }}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
              <Button
                variant={"outlined"}
                onClick={handleCancelUpload}
                disabled={loading}
              >
                cancel
              </Button>
              <LoadingButton
                startIcon={<UploadIcon />}
                disabled={barcodes.length === 0}
                onClick={_handleBarcodeUpload}
                loading={loading}
              >
                upload
              </LoadingButton>
            </Stack>
          </>
        );
      case "promoItems":
        return (
          <>
            {renderImportAlert}
            <TableContainer>
              <Table stickyHeader aria-label="barcode product matches">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedProductsIds.length === foundProducts.length
                        }
                        indeterminate={
                          selectedProductsIds.length > 0 &&
                          selectedProductsIds.length < foundProducts.length
                        }
                        onChange={handleSelectAll}
                        inputProps={{
                          "aria-label": "select all products"
                        }}
                      />
                    </TableCell>
                    <TableCell>barcode</TableCell>
                    <TableCell>matched product item</TableCell>
                    <TableCell>brand</TableCell>
                    <TableCell>class</TableCell>
                    <TableCell>group</TableCell>
                    <TableCell>inventory</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unrecognisedBarcodes.length > 0 &&
                    unrecognisedBarcodes.map(barcode => {
                      return (
                        <TableRow key={barcode}>
                          <TableCell padding="checkbox">
                            <Checkbox disabled />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {barcode}
                          </TableCell>
                          <TableCell>--</TableCell>
                          <TableCell>--</TableCell>
                          <TableCell>--</TableCell>
                          <TableCell>--</TableCell>
                          <TableCell>--</TableCell>
                        </TableRow>
                      );
                    })}
                  {foundProducts.length > 0 &&
                    foundProducts.map(product => {
                      return (
                        <TableRow
                          key={product.id}
                          onClick={() => handleSelectProduct(product.id)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isSelected(product.id)} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {product.upc}
                          </TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>{product.brand}</TableCell>
                          <TableCell>{product.class}</TableCell>
                          <TableCell>{product.group}</TableCell>
                          <TableCell>{product.inventory}</TableCell>
                        </TableRow>
                      );
                    })}
                  {(duplicatedBarcodes.length > 0 ||
                    duplicatedBarcodesWithSameProductType.length > 0) &&
                    [
                      ...duplicatedBarcodes,
                      ...duplicatedBarcodesWithSameProductType
                    ].map(product => {
                      return (
                        <TableRow
                          key={product.id}
                          onClick={() => handleSelectProduct(product.id)}
                          aria-readonly={true}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox disabled />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {product.upc}
                          </TableCell>
                          <TableCell aria-readonly={true}>
                            {product.name}
                          </TableCell>
                          <TableCell>{product.brand}</TableCell>
                          <TableCell>{product.class}</TableCell>
                          <TableCell>{product.group}</TableCell>
                          <TableCell>{product.inventory}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Button
                  variant={"outlined"}
                  onClick={() => setCurrentStep("barcodes")}
                  disabled={loading}
                >
                  back
                </Button>
                <Typography>{selectedItems}</Typography>
              </Stack>
              <Stack direction={"row"} gap={2}>
                <Button
                  variant={"outlined"}
                  onClick={handleCancelUpload}
                  disabled={loading}
                >
                  cancel
                </Button>
                <LoadingButton
                  startIcon={<UploadIcon />}
                  disabled={barcodes.length === 0}
                  onClick={() =>
                    handleAddProducts(selectedProductsIds, productType)
                  }
                  loading={loading}
                >
                  add to promotion
                </LoadingButton>
              </Stack>
            </Stack>
          </>
        );
    }
  }, [
    _handleBarcodeUpload,
    barcodes.length,
    currentStep,
    duplicatedBarcodes,
    duplicatedBarcodesWithSameProductType,
    foundProducts,
    handleAddProducts,
    handleCancelUpload,
    handleSelectAll,
    handleSelectProduct,
    isSelected,
    loading,
    productType,
    renderImportAlert,
    selectedItems,
    selectedProductsIds,
    unrecognisedBarcodes,
    value
  ]);

  return (
    <Stack gap={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant={"h2Bold"}>Barcode Upload</Typography>
        <Button onClick={handleSwitchToSelectItems}>select items</Button>
      </Stack>
      {renderCurrentStep}
    </Stack>
  );
};

const StyledEditor = styled(Editor)(({ theme }) => ({
  counterReset: "line",
  border: "1px solid",
  borderColor: theme.palette.gray.lighter,
  borderRadius: theme.shape.borderRadius,

  "&:before": {
    content: '""',
    position: "absolute",
    t: 0,
    l: 0,
    width: 50,
    height: "100%",
    backgroundColor: theme.palette.gray.lightest
  },
  "#codeArea": {
    outline: "none",
    paddingLeft: "60px !important"
  },

  pre: {
    paddingLeft: "60px !important"
  },

  ".editorLineNumber": {
    position: "absolute",
    left: 0,
    backgroundColor: theme.palette.gray.lightest,
    width: 50,
    textAlign: "center"
  }
}));

export default BarcodeUploader;
