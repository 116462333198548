/**
 * Sydney is the current default timezone for use in promotion events.
 */
export const PROMO_EVENTS_TZ = "Australia/Sydney";

export const editablePromoStatusBySuperAdmin = [
  "draft",
  "renegotiation",
  "submitted",
  "underReview",
  "scheduled"
];
