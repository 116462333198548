import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import {
  QueryActivityLogResp,
  SupplierWorkspaceSummary
} from "../../../adl-gen/petstock/merchantportal/api";
import { ActivityLogTable } from "../../widgets/activity-log-table/activity-log-table";
import { LoadingValue } from "../../../utils/UtilityTypes";
import { CrossIcon } from "src/ui/common/icon/icons";

export interface WorkspaceActivityLogPageProps {
  open: boolean;
  onClose: () => void;
  workspace: SupplierWorkspaceSummary | undefined;
  loadingActivities: LoadingValue<QueryActivityLogResp>;
}

export const WorkspaceActivityLogPageView = (
  props: WorkspaceActivityLogPageProps
) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton onClick={props.onClose}>
            <CrossIcon />
          </IconButton>
        </Box>
        <Typography variant="h2Bold">Activity Log</Typography>
        <Typography variant="h5">
          See what users in{" "}
          {props.workspace ? props.workspace.name : " workspace "} have been up
          to.
        </Typography>
        <ActivityLogTable loadingActivities={props.loadingActivities} />
      </DialogContent>
    </Dialog>
  );
};
