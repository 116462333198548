import { assertNotUndefined } from "@hx/util/types";
import React, { useCallback, useContext } from "react";

import { DbKey } from "../../../adl-gen/common/db";
import { AppService } from "../../../adl-gen/app-service";
import { UpdateWorkspaceReq } from "../../../adl-gen/petstock/merchantportal/api";
import {
  AppUser,
  SupplierWorkspace
} from "../../../adl-gen/petstock/merchantportal/db";
import { AppRoutes, LoggedInContext } from "../../../app/app";
import { useAlert } from "../../../hooks/useAlertContext";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { isLoaded } from "../../../utils/UtilityTypes";
import { Loader } from "../../widgets/common/loader/loader";
import { ManageWorkspacePageView } from "./manage-workspace-page-view";
import { useHistory, useParams } from "react-router-dom";
import {
  InviteExistingUserDialog,
  InviteExistingUserDialogProps
} from "../../widgets/invite-existing-user-dialog/invite-existing-user-dialog";

export const ManageWorkspacePage = () => {
  const service: AppService = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );
  const { workspaceId } = useParams<{
    workspaceId: DbKey<SupplierWorkspace>;
  }>();
  const [showAlert] = useAlert();
  const history = useHistory();

  const loadWorkspace = useCallback(async () => {
    return await service.queryWorkspaces({
      workspaceNameLike: null,
      supplierWorkspaceId: workspaceId
    });
  }, [service, workspaceId]);
  const [loadingWorkspace] = useLoadingDataState(loadWorkspace);

  const loadWorkspaceUsers = useCallback(async () => {
    const allUsers = await service.queryUsers({
      supplierWorkspaceId: workspaceId,
      userId: null,
      searchQuery: null
    });

    // Only show workspace users who are 'active'
    return allUsers.filter(user => user.inactiveAt === null);
  }, [service, workspaceId]);

  const loadAdminUsers = useCallback(async () => {
    const allUsers = await service.queryUsers({
      supplierWorkspaceId: null,
      userId: null,
      searchQuery: null
    });

    // Only show users who are 'admin' and are not already in the workspace
    return allUsers.filter(
      user =>
        user.userType === "admin" &&
        user.workspaces.findIndex(w => w.id === workspaceId) < 0
    );
  }, [service, workspaceId]);
  const [loadingAdminUsers] = useLoadingDataState(loadAdminUsers);

  const loadManufacturers = useCallback(async () => {
    return await service.queryManufacturers({});
  }, [service]);
  const [loadingManufacturers] = useLoadingDataState(loadManufacturers);

  const handleUpdateWorkspace = useCallback(
    async (req: UpdateWorkspaceReq) => {
      const resp = await service.updateWorkspace({
        id: workspaceId,
        value: req
      });

      switch (resp) {
        case "failNameExists":
          showAlert({
            title: "Error Updating Workspace",
            body: "A supplier workspace with that name already exists"
          });
          return;
        case "failCodeExists":
          showAlert({
            title: "Error Updating Workspace",
            body: "A supplier workspace with that supplier code already exists"
          });
          return;
        case "success":
          history.push(`${AppRoutes.Workspaces}/${workspaceId}`);
          return;
      }
    },
    [service, history, showAlert, workspaceId]
  );

  const handleInviteExistingUser = useCallback(
    async (invitedUser: DbKey<AppUser>) => {
      return await service.inviteExistingUser({
        userId: invitedUser,
        workspaceId: workspaceId
      });
    },
    [service, workspaceId]
  );

  const InviteExistingUserModal: React.FC<Omit<
    InviteExistingUserDialogProps & { refreshUsers: () => void },
    "onSendInvite"
  >> = useCallback(
    ({ open, onClose, workspaceName, users, refreshUsers }) => {
      return (
        <InviteExistingUserDialog
          users={users}
          open={open}
          workspaceName={workspaceName}
          onClose={onClose}
          onSendInvite={async (invitedUser: DbKey<AppUser>) => {
            const resp = await handleInviteExistingUser(invitedUser);

            if (resp === "success") {
              onClose();
              refreshUsers();
            } else {
              await showAlert({
                title: "Error inviting user",
                body: "Error inviting user"
              });
            }

            return resp;
          }}
        />
      );
    },
    [handleInviteExistingUser, showAlert]
  );

  return (
    <Loader
      loadingStates={[
        loadingWorkspace,
        loadingAdminUsers,
        loadingManufacturers
      ]}
    >
      {isLoaded(loadingWorkspace) &&
        isLoaded(loadingAdminUsers) &&
        isLoaded(loadingManufacturers) && (
          <ManageWorkspacePageView
            supplierWorkspace={loadingWorkspace.value[0]}
            adminUsers={loadingAdminUsers.value}
            manufacturers={loadingManufacturers.value}
            loadUsers={loadWorkspaceUsers}
            onUpdateWorkspace={handleUpdateWorkspace}
            InviteExistingUserModal={InviteExistingUserModal}
          />
        )}
    </Loader>
  );
};
