import { DbKey } from "@adltools/adl-gen/common/db";
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Container,
  DialogActions
} from "@mui/material";
import SearchInput from "@pmp/common/inputs/search-input/search-input";
import { Formik, Form } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import {
  QueryProductsFilter,
  QueryProductsResp
} from "@pmp/adl/petstock/merchantportal/api";
import { Product } from "@pmp/adl/petstock/merchantportal/db";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { Loader } from "../common/loader/loader";
import { SubmitListener } from "../form/helpers";
import { useLoadingDataState } from "src/hooks/useLoadingData";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";
import { UploadIcon } from "src/ui/common/icon/icons";
import { PromotionItemType } from "@pmp/adl/petstock/merchantportal/types";

export interface AddProductsDialogProps {
  /**
   * Controls opening or closing the dialog
   */
  open: boolean;

  /**
   * Pre-populates the selectedProducts if provided
   */
  initialProducts: DbKey<Product>[];

  productType: PromotionItemType | null;

  /**
   * Function that provides the list of products filtered by the provided options.
   */
  loadProducts: (
    filterOptions: QueryProductsFilter | null
  ) => Promise<QueryProductsResp>;

  brands: string[];
  classes: string[];
  manufacturers: string[];
  groups: string[];

  /**
   * Callback to run when 'Add Products' is clicked. Provides a list of product ids.
   */
  onAddProducts: (
    products: DbKey<Product>[],
    productType: PromotionItemType | null
  ) => void;

  /**
   * Callback to run when dialog is closed
   */
  onClose: () => void;

  handleSwitchToBarcodeUpload: () => void;
}
export const AddProductsDialog = (props: AddProductsDialogProps) => {
  const {
    open,
    initialProducts,
    productType,
    loadProducts,
    brands,
    classes,
    manufacturers,
    groups,
    onAddProducts,
    onClose,
    handleSwitchToBarcodeUpload
  } = props;
  const [
    productFilter,
    setProductFilter
  ] = useState<QueryProductsFilter | null>(null);
  const queryProducts = useCallback(async () => {
    return await loadProducts(productFilter);
  }, [productFilter, loadProducts]);

  const [loadingProducts] = useLoadingDataState(queryProducts);

  const [selectedProducts, setSelectedProducts] = useState<DbKey<Product>[]>(
    initialProducts
  );

  useEffect(() => {
    if (!_.isEqual(selectedProducts, initialProducts)) {
      setSelectedProducts(initialProducts);
    }
    // we only want to update this when initialProducts change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProducts]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setSelectedProducts([]);
      return;
    }
    const products = isLoaded(loadingProducts)
      ? loadingProducts.value.map(p => p.id)
      : [];
    setSelectedProducts(products);
  };

  const brandOptions = brands.map(b => ({
    value: b,
    title: b
  }));

  const classOptions = classes.map(b => ({
    value: b,
    title: b
  }));

  const manufacturerOptions = manufacturers.map(b => ({
    value: b,
    title: b
  }));

  const groupOptions = groups.map(b => ({
    value: b,
    title: b
  }));

  const handleSelectProduct = (id: DbKey<Product>) => {
    const selectedProductIndex = selectedProducts.indexOf(id);

    if (selectedProductIndex === -1) {
      setSelectedProducts(products => [...products, id]);
    } else {
      setSelectedProducts(products => products.filter(p => p !== id));
    }
  };

  const handleProductSearch = useCallback((values: ProductTableFilter) => {
    setProductFilter({
      nameOrBarcodeLike: values.nameOrBarcode,
      filterByBrands: values.filterByBrands,
      filterByClasses: values.filterByClasses,
      filterByGroup: values.filterByGroups,
      filterByManufactuer: values.filterByManufacturer,
      excludeIds: null
    });
  }, []);

  const handleAddProducts = () => {
    onAddProducts(selectedProducts, productType);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        sx: {
          padding: 6
        }
      }}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        variant="h2Bold"
      >
        Add Products
        <Button
          onClick={handleSwitchToBarcodeUpload}
          startIcon={<UploadIcon />}
        >
          Barcode Upload
        </Button>
      </DialogTitle>
      <Container maxWidth="xl" sx={{ marginBottom: 1 }}>
        <Formik<ProductTableFilter>
          initialValues={{
            nameOrBarcode: "",
            filterByBrands: [],
            filterByClasses: [],
            filterByGroups: [],
            filterByManufacturer: []
          }}
          onSubmit={handleProductSearch}
        >
          <>
            <SubmitListener />
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <SearchInput
                    name="nameOrBarcode"
                    placeholder="Find product by Name or Barcode"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <AutocompleteSelectInput
                    name="filterByBrands"
                    options={brandOptions}
                    limitTags={1}
                    multiple
                    placeholder="Show by Brand"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <AutocompleteSelectInput
                    name="filterByClasses"
                    options={classOptions}
                    limitTags={1}
                    multiple
                    placeholder="Show by Class"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <AutocompleteSelectInput
                    name="filterByGroups"
                    options={groupOptions}
                    limitTags={1}
                    multiple
                    placeholder="Show by Group"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <AutocompleteSelectInput
                    name="filterByManufacturer"
                    options={manufacturerOptions}
                    limitTags={1}
                    multiple
                    placeholder="Show by Manufacturer"
                  />
                </Grid>
              </Grid>
            </Form>
          </>
        </Formik>
      </Container>
      <DialogContent>
        <Loader loadingStates={[loadingProducts]}>
          {isLoaded(loadingProducts) && (
            <ProductTable
              products={loadingProducts.value}
              selectedProducts={selectedProducts}
              onSelectAllProducts={handleSelectAll}
              onSelectProduct={handleSelectProduct}
            />
          )}
        </Loader>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        {selectedProducts.length > 0 ? (
          <Typography textAlign="center">
            {selectedProducts.length} Items Selected
          </Typography>
        ) : (
          <Box />
        )}
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleAddProducts}
            disabled={selectedProducts.length === 0}
          >
            Add Products
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

interface ProductTableFilter {
  nameOrBarcode: string;
  filterByBrands: string[];
  filterByClasses: string[];
  filterByGroups: string[];
  filterByManufacturer: string[];
}

interface ProductTableProps {
  products: QueryProductsResp;
  selectedProducts: DbKey<Product>[];
  onSelectProduct: (id: DbKey<Product>) => void;
  onSelectAllProducts: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ProductTable = ({
  products,
  selectedProducts,
  onSelectProduct,
  onSelectAllProducts
}: ProductTableProps) => {
  const isSelected = (id: DbKey<Product>) =>
    selectedProducts.indexOf(id) !== -1;

  return (
    <Stack spacing={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedProducts.length === products.length}
                  indeterminate={
                    selectedProducts.length > 0 &&
                    selectedProducts.length < products.length
                  }
                  onChange={onSelectAllProducts}
                  inputProps={{
                    "aria-label": "select all products"
                  }}
                />
              </TableCell>
              <TableCell>PRODUCT</TableCell>
              <TableCell>RETAIL PRICE</TableCell>
              <TableCell>BRAND</TableCell>
              <TableCell>CLASS</TableCell>
              <TableCell>GROUP</TableCell>
              <TableCell>MANUFACTURER</TableCell>
              <TableCell>INVENTORY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(product => {
              return (
                <TableRow
                  key={product.id}
                  onClick={() => onSelectProduct(product.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected(product.id)} />
                  </TableCell>
                  <TableCell>
                    <Typography>{product.name}</Typography>
                    <Typography variant="subtitle2">{product.upc}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>${product.rrp}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{product.brand}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{product.class}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{product.group}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{product.manufacturer}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{product.inventory}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {products.length === 0 && (
        <Stack alignItems="center">
          <Typography>
            There are no more products to add as all products have been added to
            this promotion.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
