/** All the side effect css need to be imported first (disabled ordered imports) */
/* tslint:disable:no-import-side-effect no-submodule-imports ordered-imports*/
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { BrowserRouter as Router } from "react-router-dom";

import { BrowserHttp } from "@hx/hx/service/browser-http";
import { HttpWithRetries } from "@hx/hx/service/retrying-http";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Rollbar from "rollbar";

import { HttpServiceError } from "@adltools/service/http-service-error";
import { CssBaseline, ThemeProvider } from "@mui/material";

import {
  texprUiConfig,
  UiConfig
} from "./adl-gen/petstock/merchantportal/uiconfig";
import { RESOLVER } from "./adl-gen/resolver";
import { createJsonBinding, Json } from "./adl-gen/runtime/json";
import { App } from "./app/app";
import { AppService } from "./adl-gen/app-service";
import { clearLocalTokens } from "./app/identity-state";
import { ApiServices } from "./service/api-services";
import { AppTheme } from "./ui/common/theme";
import { AlertManager } from "./app/alert-manager";
import { GlobalAlert } from "./ui/widgets/common/global-alert/global-alert";

if (module.hot) {
  module.hot.accept();
}

declare const window: Window & {
  /** UI config JSON injected via index.html */
  UI_CONFIG: Json;
};

// Fetch and parse the typed UiConfig record from the window
// This has been populated via a script tag in index.html
function loadUiConfig(): UiConfig {
  if (!window.UI_CONFIG) {
    throw new Error("UI_CONFIG not populated");
  }
  try {
    return createJsonBinding(RESOLVER, texprUiConfig()).fromJson(
      window.UI_CONFIG
    );
  } catch (e) {
    throw new Error("Could not parse UI_CONFIG: " + e);
  }
}

const UI_CONFIG: UiConfig = loadUiConfig();

// tslint:disable:no-console
console.log(`Environment: ${UI_CONFIG.environment}`);
console.log(`Release name: ${UI_CONFIG.releaseName}`);
// tslint:enable:no-console

// Load Rollbar
// NOTE: We are following the NodeJS style configuration as opposed to the
// browser JS style because we do not want to load it via index.html and also
// have a handle on the created Rollbar object.
// See: https://docs.rollbar.com/docs/nodejs
const rollbar = (function(): Rollbar | undefined {
  if (UI_CONFIG.rollbar) {
    // tslint:disable-next-line:no-console
    console.log("Loading Rollbar");
    return new Rollbar({
      accessToken: UI_CONFIG.rollbar.accessToken,
      payload: {
        environment: UI_CONFIG.environment
      },

      // We register our own error handlers and send to Rollbar manually
      captureUncaught: false,
      captureUnhandledRejections: false
    });
  } else {
    return undefined;
  }
})();

// Global error handler, includes errors that occur in the handler
window.addEventListener("error", (event: ErrorEvent) =>
  handleError("global error", event.error, event)
);

// Global unhandled rejection handler
window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) =>
  handleError("unhandled rejection", event.reason, event)
);

/**
 * Last resort error handler that alerts the user.
 */
function handleError(
  eventType: "global error" | "unhandled rejection",
  error: { publicMessage?: string },
  event: Event
) {
  // tslint:disable-next-line:no-console
  console.error(
    `${eventType}: ${String(error)}, error:`,
    error,
    "event:",
    event
  );
  if (rollbar) {
    rollbar.error(
      "" + String(error), // message
      error, // exception object
      { eventType, event } // custom payload
    );
  }
  if (error.publicMessage) {
    window.alert(error.publicMessage);
  } else {
    window.alert(
      "Sorry, an unhandled error has occurred. Please try refreshing and performing the action again."
    );
  }
}

// Configure http to retry up to 8 times, with an initial delay of 100ms, with
// exponential increaes.  The precise delays are randomised, but this will
// result in a typical max delay before failure of 25 seconds
const http = new HttpWithRetries(new BrowserHttp(), 8, 100);

function handleHttpServiceError(error: HttpServiceError) {
  // Check if it's an authorization error
  if (error.status === 401) {
    const publicMessageFragment = error.publicMessage
      ? `: ${error.publicMessage}`
      : "";
    window.alert(
      `Sorry, an authorization error occurred ${publicMessageFragment}. You will be logged out.`
    );
    clearLocalTokens();
    return;
  }
}

function makeApiServices(authToken?: string): ApiServices {
  const app = new AppService(
    http,
    "/_a",
    RESOLVER,
    authToken,
    handleHttpServiceError
  );

  return {
    app
  };
}

//Create an unauthenticated servier for logins, and other public endpoints.
const service = makeApiServices();

function createApiServices(authToken?: string) {
  if (authToken === undefined) {
    return service;
  } else {
    return makeApiServices(authToken);
  }
}

ReactDOM.render(
  <AlertManager>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <Router>
          <GlobalAlert />
          <App createApiServices={createApiServices} uiconfig={UI_CONFIG} />
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  </AlertManager>,
  document.getElementById("root")
);
