import { assertNotUndefined } from "@hx/util/types";
import React, { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useLoadingDataState } from "src/hooks/useLoadingData";

import { AppService } from "../../../adl-gen/app-service";
import { DbKey } from "../../../adl-gen/common/db";
import {
  AppUser,
  SupplierWorkspace
} from "../../../adl-gen/petstock/merchantportal/db";
import { LoggedInContext } from "../../../app/app";
import { useAlert } from "../../../hooks/useAlertContext";
import { isLoaded } from "../../../utils/UtilityTypes";
import { Loader } from "../../widgets/common/loader/loader";
import {
  InviteExistingUserDialog,
  InviteExistingUserDialogProps
} from "../../widgets/invite-existing-user-dialog/invite-existing-user-dialog";
import { WorkspacePageView } from "./workspace-page-view";

export const WorkspacePage = () => {
  const service: AppService = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );
  const { workspaceId } = useParams<{
    workspaceId: DbKey<SupplierWorkspace>;
  }>();

  const loadWorkspace = useCallback(async () => {
    return await service.queryWorkspaces({
      workspaceNameLike: null,
      supplierWorkspaceId: workspaceId
    });
  }, [service, workspaceId]);
  const [loadingWorkspace] = useLoadingDataState(loadWorkspace);

  const loadWorkspaceUsers = useCallback(async () => {
    return await service.queryUsers({
      supplierWorkspaceId: workspaceId,
      userId: null,
      searchQuery: null
    });
  }, [service, workspaceId]);

  const loadAdminUsers = useCallback(async () => {
    const allUsers = await service.queryUsers({
      supplierWorkspaceId: null,
      userId: null,
      searchQuery: null
    });

    // Only show users who are 'admin' and are not already in the workspace
    return allUsers.filter(
      user =>
        user.userType === "admin" &&
        user.workspaces.findIndex(w => w.id === workspaceId) < 0
    );
  }, [service, workspaceId]);
  const [loadingAdminUsers, refreshAdminUsers] = useLoadingDataState(
    loadAdminUsers
  );

  const loadAllWorkspaces = useCallback(async () => {
    return await service.queryWorkspaces({
      workspaceNameLike: null,
      supplierWorkspaceId: null
    });
  }, [service]);
  const [loadingAllWorkspaces] = useLoadingDataState(loadAllWorkspaces);

  const [showAlert] = useAlert();
  const handleInviteExistingUser = useCallback(
    async (invitedUser: DbKey<AppUser>) => {
      return await service.inviteExistingUser({
        userId: invitedUser,
        workspaceId: workspaceId
      });
    },
    [service, workspaceId]
  );

  const InviteExistingUserModal: React.FC<Omit<
    InviteExistingUserDialogProps & {
      refreshUsers: () => void;
    },
    "onSendInvite"
  >> = useCallback(
    ({ open, onClose, workspaceName, users, refreshUsers }) => {
      return (
        <InviteExistingUserDialog
          open={open}
          users={users}
          workspaceName={workspaceName}
          onClose={onClose}
          onSendInvite={async (invitedUser: DbKey<AppUser>) => {
            const resp = await handleInviteExistingUser(invitedUser);
            if (resp === "success") {
              onClose();
              refreshUsers();
              refreshAdminUsers();
            } else {
              await showAlert({
                title: "Error inviting user",
                body: "Error inviting user"
              });
            }

            return resp;
          }}
        />
      );
    },
    [handleInviteExistingUser, showAlert, refreshAdminUsers]
  );

  return (
    <Loader
      loadingStates={[
        loadingWorkspace,
        loadingAllWorkspaces,
        loadingAdminUsers
      ]}
    >
      {isLoaded(loadingWorkspace) &&
        isLoaded(loadingAllWorkspaces) &&
        isLoaded(loadingAdminUsers) && (
          <WorkspacePageView
            adminUsers={loadingAdminUsers.value}
            supplierWorkspace={loadingWorkspace.value[0]}
            loadUsers={loadWorkspaceUsers}
            InviteExistingUserModal={InviteExistingUserModal}
          />
        )}
    </Loader>
  );
};
