import { FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { CalendarIcon } from "src/ui/common/icon/icons";

interface Props
  extends Omit<
    DatePickerProps<Date, Date>,
    "value" | "onChange" | "renderInput"
  > {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The input label.
   */
  label?: string;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
  /**
   * Edge case when need a label inside the input.
   */
  startDescription?: string;
  /**
   * Determines the required ON/OFF state.
   */
  required?: boolean;
}

/**
 * Component used as basic date picker input.
 * @param props: Props
 */
const DatePickerInput: FC<Props> = ({
  name,
  label,
  required,
  helperText,
  startDescription,
  ...rest
}) => {
  const [field, meta, helper] = useField(name);
  const { setValue, setTouched } = helper;
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);
  // mui Label + DatePicker does not hook with htmlFor from label, so we handle the open/close with this state
  const [open, setOpen] = useState(false);

  const { value } = field;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    }
  }, [value]);

  const _onChange = (date: Date) => {
    if (date) {
      setSelectedDate(date);
      setValue(date.getTime());
    }
    setTouched(true);
  };

  return (
    <Stack spacing={1}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
          onClick={() => setOpen(true)}
        />
      )}
      <DatePicker
        {...field}
        {...rest}
        inputFormat="dd/MM/yyyy"
        toolbarPlaceholder={startDescription}
        value={selectedDate}
        onChange={_onChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={params => (
          <TextField
            {...params}
            error={hasError}
            onClick={() => setOpen(true)}
            InputProps={{
              startAdornment: startDescription && (
                <Typography sx={{ marginRight: 1 }} title={startDescription}>
                  {startDescription}:
                </Typography>
              ),
              endAdornment: <CalendarIcon onClick={() => setOpen(true)} />
            }}
            sx={{
              "& .MuiInputBase-input": {
                width: "auto",
                marginRight: "auto",
                paddingRight: 1,
                "&::placeholder": {
                  textTransform: "uppercase"
                }
              }
            }}
          />
        )}
      />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </Stack>
  );
};

export default DatePickerInput;
