import { Stack } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";

interface PublicPageLayoutProps {
  children: React.ReactNode;
}

const MAX_WIDTH_PUBLIC_LAYOUT = 1140;
export const PublicPageLayout = ({ children }: PublicPageLayoutProps) => {
  return (
    <Stack
      position={"relative"}
      sx={{
        minHeight: "calc(100vh - 80px)",
        maxWidth: MAX_WIDTH_PUBLIC_LAYOUT,
        margin: "40px auto"
      }}
    >
      <Logo />
      <Stack justifyContent={"center"} height={"100%"} flexGrow={1}>
        {children}
      </Stack>
    </Stack>
  );
};
