import { assertNotUndefined } from "@hx/util/types";
import { UserProfile } from "@pmp/adl/petstock/merchantportal/api";
import { useContext } from "react";
import { Feature } from "src/adl-gen/petstock/merchantportal/types";
import { LoggedInContext } from "src/app/app";

export const useFeatures = () => {
  const userProfile = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.profile
  );
  const features = userProfile.role?.features;
  const userType = userProfile.userType;
  const roleName = userProfile.role?.name;

  const getFeatureAccessLevel = (feature: Feature) => {
    return userProfile.role?.features.find(f => f.feature === feature);
  };

  const getUserProfile = (): UserProfile => {
    return userProfile;
  };

  const hasEditorAccess = (feature: Feature) => {
    if (userType === "superAdmin") {
      return true;
    }

    const featureAccessLevel = getFeatureAccessLevel(feature);
    return featureAccessLevel && featureAccessLevel.accessLevel === "editor";
  };

  const hasReadAccess = (feature: Feature) => {
    if (userType === "superAdmin") {
      return true;
    }

    const featureAccessLevel = getFeatureAccessLevel(feature);
    return featureAccessLevel && featureAccessLevel.accessLevel !== "noAccess";
  };

  const isApprover = (): boolean => {
    if (isSuperAdmin()) {
      return true;
    }

    const approverFeatures: Feature[] = [
      "coordinatorApproval",
      "categoryManagerApproval",
      "planningApproval",
      "generalManagerApproval",
      "totalBusinessApproval"
    ];
    return (
      features?.some(
        f => approverFeatures.includes(f.feature) && f.accessLevel === "editor"
      ) ?? false
    );
  };

  const isSuperAdmin = () => {
    return userType === "superAdmin";
  };

  const isAdmin = () => {
    return userType === "admin";
  };

  const isPetspirationUser = () => {
    return userType === "admin";
  };

  const isSupplier = () => {
    return userType === "supplierUser";
  };

  const isSupplierUser = () => {
    return roleName === "Supplier User";
  };

  return {
    features,
    userType,
    getUserProfile,
    hasEditorAccess,
    hasReadAccess,
    isApprover,
    isSuperAdmin,
    isAdmin,
    isPetspirationUser,
    isSupplier,
    isSupplierUser
  };
};
