import { LoadingValue } from "@pmp/utils/UtilityTypes";
import {
  PromotionDetailsSummary,
  QueryActivityLogResp
} from "@pmp/adl/petstock/merchantportal/api";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import { ActivityLogTable } from "../../../widgets/activity-log-table/activity-log-table";
import { CrossIcon } from "src/ui/common/icon/icons";

interface PromotionOverviewViewActivityLogProps {
  open: boolean;
  onClose: () => void;
  promotion: PromotionDetailsSummary | undefined;
  loadingActivities: LoadingValue<QueryActivityLogResp>;
}

export const PromotionActivityLogPageView = (
  props: PromotionOverviewViewActivityLogProps
) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton onClick={props.onClose}>
            <CrossIcon />
          </IconButton>
        </Box>
        <Typography sx={{ fontSize: 24, fontWeight: 600, marginBottom: 1 }}>
          Activity Log
        </Typography>
        <Typography>
          Latest updates in{" "}
          {props.promotion ? '"' + props.promotion.name + '"' : " "}
        </Typography>
        <ActivityLogTable loadingActivities={props.loadingActivities} />
      </DialogContent>
    </Dialog>
  );
};
