import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { useMemo, useState } from "react";
import { HiddenIcon, ViewIcon } from "src/ui/common/icon/icons";

interface Props extends BaseTextFieldProps {
  /**
   * Name to link to form submission.
   */
  name: string;
}

export const PasswordInput = (props: Props) => {
  const { helperText, name, label, required, ...rest } = props;
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);

  return (
    <Stack spacing={1}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
        />
      )}
      <TextField
        type={showPassword ? "text" : "password"}
        id={name}
        hiddenLabel
        error={hasError}
        helperText={hasError ? meta.error : null}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <HiddenIcon /> : <ViewIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{}}
        {...field}
        {...rest}
      />
    </Stack>
  );
};

export default PasswordInput;
