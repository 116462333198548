import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";

import {
  PromotionSummary,
  QueryUsersResp,
  SupplierWorkspaceSummary
} from "@pmp/adl/petstock/merchantportal/api";
import { PromotionSummaryToPromotionStatus } from "@pmp/utils/adl";
import React, { useMemo, useState } from "react";
import { AppRoutes } from "../../../app/app";
import { useFeatures } from "../../../hooks/useFeatures";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  PlusIcon,
  SettingIcon,
  StarIcon,
  UserIcon
} from "../../common/icon/icons";
import PromotionStatusLineChart, {
  PromotionStatusLineChartProps
} from "../../components/PromotionStatus/PromotionStatusLineChart";
import TabLabel from "../../components/TabLabel";
import Link from "../../components/utils/Link";
import { InviteExistingUserDialogProps } from "../../widgets/invite-existing-user-dialog/invite-existing-user-dialog";
import { ManageUsersTable } from "../../widgets/manage-users-table/manage-users-table";

export interface WorkspacePageViewProps {
  adminUsers: QueryUsersResp;
  supplierWorkspace: SupplierWorkspaceSummary;
  loadUsers: () => Promise<QueryUsersResp>;
  InviteExistingUserModal: React.FC<
    Omit<
      InviteExistingUserDialogProps & {
        refreshUsers: () => void;
      },
      "onSendInvite"
    >
  >;
}

export const WorkspacePageView = ({
  adminUsers,
  supplierWorkspace,
  loadUsers,
  InviteExistingUserModal
}: WorkspacePageViewProps) => {
  const [loadingUsers, refreshUsers] = useLoadingDataState(loadUsers);
  const { hasEditorAccess, isSuperAdmin } = useFeatures();
  const canEditWorkspaceInformation = hasEditorAccess("workspaceInformation");

  const [
    openInviteWorkspaceUsersModal,
    setOpenInviteWorkspaceUsersModal
  ] = useState(false);

  const promotionStatusFromPromotionSummary = useMemo(() => {
    const groupPromoStatus: PromotionStatusLineChartProps = {
      statuses: [],
      tooltipPrefix: "Promotions in"
    };
    const itemPromoStatus: PromotionStatusLineChartProps = {
      statuses: [],
      tooltipPrefix: "Promotion Items in"
    };
    Object.entries(supplierWorkspace.promotionSummary).map(entry => {
      const key = entry[0] as keyof PromotionSummary;
      groupPromoStatus.statuses.push({
        count: entry[1].promotionGroupsCount,
        status: PromotionSummaryToPromotionStatus(key)
      });
      itemPromoStatus.statuses.push({
        count: entry[1].promotionItemsCount,
        status: PromotionSummaryToPromotionStatus(key)
      });
    });
    return {
      groupPromoStatus,
      itemPromoStatus
    };
  }, [supplierWorkspace.promotionSummary]);

  return (
    <Stack gap={4}>
      <Stack direction={"row"} gap={2}>
        <Typography variant="h1Bold">{supplierWorkspace.name}</Typography>
        {canEditWorkspaceInformation && (
          <Link href={`${AppRoutes.Workspaces}/${supplierWorkspace.id}/edit`}>
            <IconButton>
              <SettingIcon />
            </IconButton>
          </Link>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card>
            <CardHeader title={"general information"} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography variant={"h5Bold"}>Supplier Code</Typography>
                </Grid>
                <Grid item xs={7} pl={2}>
                  <Typography
                    noWrap
                    textAlign={"left"}
                    title={supplierWorkspace.code}
                  >
                    {supplierWorkspace.code}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={"h5Bold"}>Description</Typography>
                </Grid>
                <Grid item xs={7} pl={2}>
                  <Typography
                    noWrap
                    textAlign={"left"}
                    title={supplierWorkspace.description ?? "--"}
                  >
                    {supplierWorkspace.description ?? "--"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <CardHeader title={"promo summary"} />
            <CardContent>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={1}>
                  <Typography variant={"h5Bold"}>Groups</Typography>
                </Grid>
                <Grid item xs={11} pl={2}>
                  <PromotionStatusLineChart
                    {...promotionStatusFromPromotionSummary.groupPromoStatus}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant={"h5Bold"}>Items</Typography>
                </Grid>
                <Grid item xs={11} pl={2}>
                  <PromotionStatusLineChart
                    {...promotionStatusFromPromotionSummary.itemPromoStatus}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h3Bold">Workspace Users </Typography>
        {isSuperAdmin() && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() => setOpenInviteWorkspaceUsersModal(true)}
          >
            Add New User
          </Button>
        )}
      </Stack>
      <ManageUsersTable
        tabs={[
          {
            label: <TabLabel label={"All Active Users"} icon={<UserIcon />} />,
            filterUsers: (users: QueryUsersResp) =>
              users.filter(u => u.inactiveAt === null)
          },
          {
            label: <TabLabel label={"All Admins"} icon={<StarIcon />} />,
            filterUsers: (users: QueryUsersResp) =>
              users.filter(u => u.userType === "admin" && u.inactiveAt === null)
          }
        ]}
        loadingUsers={loadingUsers}
        refreshUsers={refreshUsers}
        allowArchiving={false}
      />
      <InviteExistingUserModal
        users={adminUsers}
        open={openInviteWorkspaceUsersModal}
        workspaceName={supplierWorkspace.name}
        onClose={() => setOpenInviteWorkspaceUsersModal(false)}
        refreshUsers={refreshUsers}
      />
    </Stack>
  );
};
