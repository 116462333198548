import { DbKey } from "@adltools/adl-gen/common/db";
import { Dialog, DialogContent } from "@mui/material";

import {
  FindProductsReq,
  FindProductsResp
} from "@pmp/adl/petstock/merchantportal/api";
import { Product } from "@pmp/adl/petstock/merchantportal/db";
import { PromotionItemType } from "@pmp/adl/petstock/merchantportal/types";
import React from "react";
import BarcodeUploader from "../../components/BarcodeUpload/BarcodeUploader";

export interface BarcodeUploadDialogProps {
  /**
   * Controls opening or closing the dialog
   */
  open: boolean;

  findProducts: (req: FindProductsReq) => Promise<FindProductsResp>;

  productType: PromotionItemType | null;

  /**
   * Callback to run when 'Add Products' is clicked. Provides a list of product ids.
   */
  onAddProducts: (
    products: DbKey<Product>[],
    productType: PromotionItemType | null
  ) => void;

  /**
   * Callback to run when dialog is closed
   */
  onClose: () => void;
  handleSwitchToSelectItems: () => void;
  supplierWorkspaceId: string;
  qualifiedProducts: DbKey<Product>[];
  awardProducts: DbKey<Product>[];
  productsAlreadyInPromotion: DbKey<Product>[];
}
export const BarcodeUploadDialog = ({
  findProducts,
  handleSwitchToSelectItems,
  productType,
  onClose,
  open,
  supplierWorkspaceId,
  onAddProducts,
  qualifiedProducts,
  awardProducts,
  productsAlreadyInPromotion
}: BarcodeUploadDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        sx: {
          padding: 6
        }
      }}
    >
      <DialogContent>
        <BarcodeUploader
          findProducts={findProducts}
          productType={productType}
          handleCancelUpload={onClose}
          handleSwitchToSelectItems={handleSwitchToSelectItems}
          supplierWorkspaceId={supplierWorkspaceId}
          handleAddProducts={onAddProducts}
          qualifiedProducts={qualifiedProducts}
          awardProducts={awardProducts}
          productsAlreadyInPromotion={productsAlreadyInPromotion}
        />
      </DialogContent>
    </Dialog>
  );
};
