import {
  Divider,
  FormHelperText,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  Stack
} from "@mui/material";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { FC, ReactNode, useMemo, useState } from "react";
import { ChevronDownIcon } from "src/ui/common/icon/icons";

export type SelectOption = {
  /**
   * The value passed to the form.
   */
  value: string | number;
  /**
   * The item rendered in list.
   */
  item?: ReactNode;
  /**
   * The shown when option is selected.
   */
  title: string;
  /**
   * Set as true if the option should be shown as a subheading instead.
   */
  subheading?: boolean;
};

export type SelectOptions = Array<SelectOption>;

type Props = SelectProps & {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The list of options.
   */
  options: SelectOptions;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Set as true if placeholder should be hidden
   */
  hidePlaceholder?: boolean;
  /**
   * Optional helper to create an action.
   */
  createOption?: {
    /**
     * The item shown as the last option with click action.
     */
    item: ReactNode;
    /**
     * The callback when creating a new item
     */
    onCreateOption(): void;
  };
};

/**
 * Component used as basic select input
 * @param props: Props
 */
export const SelectInput: FC<Props> = props => {
  const {
    helperText,
    name,
    label,
    required,
    options,
    placeholder,
    hidePlaceholder,
    createOption,
    ...rest
  } = props;
  const [field, meta] = useField(name);
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);
  // mui Label + Select do not hook with htmlFor from label, so we handle the open/close with this state
  const [open, setOpen] = useState(false);

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
          onClick={() => setOpen(true)}
        />
      )}
      <Select
        id={name}
        labelId={name}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        displayEmpty
        renderValue={selected => {
          const selectedOption = options.find(
            option => option.value === selected
          );
          if (selectedOption) {
            return selectedOption.item || selectedOption.title;
          }
          if (!hidePlaceholder) {
            return placeholder || "--";
          }
        }}
        error={hasError}
        IconComponent={ChevronDownIcon}
        {...field}
        {...rest}
      >
        {!hidePlaceholder && (
          <MenuItem
            value=""
            sx={{ backgroundColor: theme => theme.palette.common.white }}
          >
            <em>{placeholder || "--"}</em>
          </MenuItem>
        )}
        {options.map(option =>
          option.subheading ? (
            <ListSubheader>{option.title}</ListSubheader>
          ) : (
            <MenuItem key={option.value} value={option.value}>
              {option.item || option.title}
            </MenuItem>
          )
        )}
        {createOption && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                createOption.onCreateOption();
              }}
            >
              {createOption.item}
            </MenuItem>
          </>
        )}
      </Select>
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </Stack>
  );
};

export default SelectInput;
