import { assertNotUndefined } from "@hx/util/types";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { DbKey } from "@pmp/adl/common/db";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";
import SelectInput, {
  SelectOption,
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { array, object, string } from "yup";
import {
  QueryRolesResp,
  SupplierWorkspaceSummary,
  UserDetails
} from "../../../adl-gen/petstock/merchantportal/api";
import { RolePreset } from "../../../adl-gen/petstock/merchantportal/db";
import { UserType } from "../../../adl-gen/petstock/merchantportal/types";
import { useFeatures } from "../../../hooks/useFeatures";

interface EditUserFormValues {
  selectedUserType: UserType | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: DbKey<RolePreset> | null;
  organisation: string;
  title: string;
  selectedWorkspaces: string[];
}

export const EDIT_USER_FORM_ID = "edit-user-form-id";

const validationSchema = object().shape({
  selectedUserType: string()
    .oneOf(["superAdmin", "admin", "supplierUser"])
    .required("User type is required"),
  firstName: string()
    .trim()
    .required("First name is required"),
  lastName: string()
    .trim()
    .required("Last name is required"),
  email: string()
    .email("Must provide a valid email address")
    .required("Email address is required"),
  phoneNumber: string()
    .matches(/^\d+$/, { message: "Phone number must contain only digits" })
    .required("Phone number is required")
    .min(7, "Phone number must contain at least 7 digits"),
  role: string().when("selectedUserType", {
    is: (type: UserType) => type !== "superAdmin",
    then: string()
      .trim()
      .nullable()
      .required("Role is required"),
    otherwise: string().nullable()
  }),
  organisation: string()
    .trim()
    .required("Organisation is required"),
  title: string()
    .trim()
    .required("Title is required"),
  selectedWorkspaces: array()
    .when("selectedUserType", {
      is: "admin",
      then: array()
        .min(1)
        .required("At least one workspace is required")
    })
    .when("selectedUserType", {
      is: "supplierUser",
      then: array()
        .max(1)
        .min(1)
        .required("One workspace is required")
    })
    .when("selectedUserType", {
      is: "superAdmin",
      then: array().of(object())
    })
});

export interface EditUserPageViewProps {
  user: UserDetails;
  cancelFn: () => void;
  roles: QueryRolesResp;
  workspaces: SupplierWorkspaceSummary[];
  applyChangesFn: (req: UserDetails) => Promise<void>;
  archiveUserFn: () => Promise<void>;
  unarchiveUserFn: () => Promise<void>;
}

export const EditUserPageView = ({
  user,
  cancelFn,
  roles,
  workspaces,
  applyChangesFn,
  archiveUserFn,
  unarchiveUserFn
}: EditUserPageViewProps) => {
  const { hasEditorAccess } = useFeatures();
  const canEditUserInformation = hasEditorAccess("userInformation");
  const canEditUserPermission = hasEditorAccess("userPermission");
  const canEditUserWorkspace = hasEditorAccess("workspaceUsers");

  const onSubmitForm = useCallback(
    (updatedUser: EditUserFormValues) => {
      const userWorkspaces = workspaces.filter(
        ws => updatedUser.selectedWorkspaces.findIndex(uw => uw === ws.id) >= 0
      );

      // transform from SupplierWorkspaceSummary to SupplierWorkspace

      const userWorkspaceSummaries = userWorkspaces.map(ws => {
        return {
          id: ws.id,
          value: {
            name: ws.name,
            code: ws.code,
            description: ws.description
          }
        };
      });

      const selectedRole = roles.find(role => role.id === updatedUser.role);

      const newUserData: UserDetails = {
        id: user.id,
        name: {
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName
        },
        email: updatedUser.email,
        phoneNumber: updatedUser.phoneNumber,
        organisation: updatedUser.organisation,
        title: updatedUser.title,
        userType: assertNotUndefined(updatedUser.selectedUserType),
        role: selectedRole ?? null,
        workspaces: userWorkspaceSummaries,
        invitedBy: user.invitedBy,
        invitedAt: user.invitedAt,
        verifiedAt: user.verifiedAt,
        inactiveAt: user.inactiveAt
      };

      applyChangesFn(newUserData);
    },
    [
      roles,
      user.id,
      user.invitedBy,
      user.invitedAt,
      user.verifiedAt,
      user.inactiveAt,
      applyChangesFn,
      workspaces
    ]
  );

  const initialWorkspaces: string[] = user.workspaces.map(ws => ws.id);

  // Roles are split up below as there is no easy way to indentify which roles are for suppliers and which are for admins
  const supplierRoles = roles.filter(
    role =>
      role.value.name === "Supplier Admin" ||
      role.value.name === "Supplier User"
  );
  const supplierRolePresetOptions: SelectOption[] = supplierRoles.map(role => ({
    value: role.id,
    title: role.value.name
  }));

  const adminRolePresetOptions: SelectOption[] = roles
    .filter(r => !supplierRoles.some(sr => sr.id === r.id))
    .map(role => ({
      value: role.id,
      title: role.value.name
    }));

  const isActiveUser = useMemo(() => !Boolean(user.inactiveAt), [user]);
  const isUserInfoDisabled = !isActiveUser || !canEditUserInformation;
  const isUserPermissionDisabled = !isActiveUser || !canEditUserPermission;

  const options: SelectOptions = [
    {
      title: "Super Admin",
      value: "superAdmin"
    },
    {
      title: "Admin",
      value: "admin"
    },
    {
      title: "Supplier",
      value: "supplierUser"
    }
  ];

  const workspaceOptions: SelectOptions = workspaces.map(item => ({
    value: item.id,
    title: item.name
  }));

  /** Render the page */
  return (
    <Stack spacing={2}>
      {!isActiveUser && (
        <Alert severity="info">
          This user has been archived but will be maintained in audit logs. You
          cannot make any edits until this user is unarchived.
        </Alert>
      )}
      <Typography variant="h1Bold">Edit Single User</Typography>
      <Box display="flex" paddingTop={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={246}
          height={292}
          border={1}
          mr={8}
        >
          <Box borderRadius={100} height={96} width={96} mb={3} />
          <Typography variant="h6">
            {user.name.firstName} {user.name.lastName}
          </Typography>
          <Typography variant="body1">{user.title}</Typography>
        </Box>
        <Stack flex={1} spacing={3}>
          <Typography variant="h5">General Information</Typography>
          <Formik<EditUserFormValues>
            initialValues={{
              firstName: user.name.firstName,
              lastName: user.name.lastName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              organisation: user.organisation,
              title: user.title,
              selectedUserType: user.userType,
              role: user.role?.id ?? null,
              selectedWorkspaces: initialWorkspaces
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
            validateOnChange={true}
          >
            {({ values, isValid, isSubmitting }) => (
              <Form id={EDIT_USER_FORM_ID}>
                <Stack flex={1} spacing={3}>
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextInput
                          name="firstName"
                          label="First Name"
                          required
                          placeholder="First Name"
                          disabled={isUserInfoDisabled}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          name="lastName"
                          required
                          label="Last Name"
                          placeholder="Last Name"
                          disabled={isUserInfoDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextInput
                          name="email"
                          label="Email Address"
                          required
                          placeholder="Email Address"
                          type="email"
                          disabled={isUserInfoDisabled}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          name="phoneNumber"
                          label="Phone Number"
                          required
                          placeholder="Phone Number"
                          type="tel"
                          disabled={isUserInfoDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextInput
                          name="organisation"
                          label="Organisation"
                          required
                          placeholder="Organisation"
                          disabled={isUserInfoDisabled}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          name="title"
                          label="Title"
                          required
                          placeholder="Title"
                          disabled={isUserInfoDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Typography variant="h5">Permission</Typography>
                  <>
                    <Stack>
                      <SelectInput
                        name="selectedUserType"
                        options={options}
                        label="User Type"
                        required
                        disabled={isUserPermissionDisabled}
                      />
                    </Stack>

                    {values.selectedUserType !== "superAdmin" ? (
                      <Stack>
                        <SelectInput
                          name="role"
                          options={
                            values.selectedUserType === "supplierUser"
                              ? supplierRolePresetOptions
                              : adminRolePresetOptions
                          }
                          label="User Role Preset"
                          required
                          disabled={isUserPermissionDisabled}
                        />
                      </Stack>
                    ) : (
                      <>
                        {(values.role = null)}
                        {(values.selectedWorkspaces = [])}
                      </>
                    )}
                  </>

                  {values.selectedUserType !== "superAdmin" && (
                    <>
                      <Typography variant="h5">Supplier Workspaces</Typography>
                      <Stack>
                        <Stack>
                          <AutocompleteSelectInput
                            name="selectedWorkspaces"
                            options={workspaceOptions}
                            multiple={values.selectedUserType === "admin"}
                            label={`Assign Workspace${
                              values.selectedUserType === "admin" ? "(s)" : ""
                            }`}
                            required
                            placeholder="Workspaces"
                            disabled={!isActiveUser || !canEditUserWorkspace}
                          />
                        </Stack>
                      </Stack>
                    </>
                  )}
                  {!isActiveUser ? (
                    hasEditorAccess("archiveUser") && (
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <LoadingButton
                          disabled={user === undefined}
                          sx={{ width: "200px" }}
                          variant="contained"
                          onClick={unarchiveUserFn}
                        >
                          Unarchive User
                        </LoadingButton>
                      </Stack>
                    )
                  ) : (
                    <>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        {hasEditorAccess("archiveUser") && (
                          <LoadingButton
                            color="error"
                            disabled={user === undefined}
                            sx={{ width: "200px" }}
                            variant="contained"
                            onClick={archiveUserFn}
                          >
                            Archive User
                          </LoadingButton>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="outlined"
                          onClick={cancelFn}
                          sx={{ width: "200px" }}
                        >
                          Cancel
                        </Button>
                        {(canEditUserInformation || canEditUserPermission) && (
                          <LoadingButton
                            disabled={!isValid}
                            loading={isSubmitting}
                            variant="contained"
                            type="submit"
                            sx={{ width: "200px" }}
                          >
                            Apply Change
                          </LoadingButton>
                        )}
                      </Stack>
                    </>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Box>
    </Stack>
  );
};
