import { Tooltip, TooltipProps } from "@mui/material";
import React, { FC } from "react";
import { HelpIcon } from "src/ui/common/icon/icons";

/**
 * Component to use as an Input tooltip, do not outside of inputs folder.
 */
const InputTooltip: FC<Omit<TooltipProps, "children">> = props => {
  return (
    <Tooltip {...props} placement={"top"} arrow>
      <HelpIcon />
    </Tooltip>
  );
};

export default InputTooltip;
