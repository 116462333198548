import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { useMemo, useRef } from "react";

interface Props extends BaseTextFieldProps {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The max number of characters allowed.
   */
  max?: number;
}

/**
 * Component used as basic textarea input
 * @param props: Props
 */
export const TextareaInput = (props: Props) => {
  const { helperText, name, label, required, max, ...rest } = props;
  const [field, meta] = useField(name);
  const textareaRef = useRef<HTMLInputElement>(null);
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);

  return (
    <Stack spacing={1}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
        />
      )}
      <TextField
        type="text"
        id={name}
        hiddenLabel
        multiline
        inputRef={textareaRef}
        inputProps={{ maxLength: max }}
        rows={4}
        InputProps={{
          endAdornment: max ? (
            <InputAdornment position="end">
              <Typography>
                {textareaRef?.current?.value.length}/{max}
              </Typography>
            </InputAdornment>
          ) : null,
          sx: { alignItems: "end" }
        }}
        error={hasError}
        helperText={hasError ? meta.error : null}
        sx={{}}
        {...field}
        {...rest}
      />
    </Stack>
  );
};

export default TextareaInput;
