import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { FC, ReactNode, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

interface PMPTabProps {
  id: string;
  label: ReactNode;
  content: ReactNode;
  disabled?: boolean;
}

interface PMPTabsProps {
  ariaLabel: string;
  tabs: Array<PMPTabProps>;
}

const PMPTabs: FC<PMPTabsProps> = ({ ariaLabel, tabs }) => {
  const location = useLocation();

  const defaultTab = useMemo(() => {
    if (location.hash) {
      const hashValue = location.hash.substring(1);
      const found = tabs.find(tab => tab.id === hashValue);
      if (found) {
        return found.id;
      }
    }

    return tabs.find(tab => !tab.disabled)?.id ?? "";
  }, [location.hash, tabs]);
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const handleTabChange = (_, tab: string) => {
    setSelectedTab(tab);
    if (window.location.hash !== tab) {
      window.location.hash = tab;
    }
  };

  const a11yProps = (tab: string) => {
    return {
      id: `${ariaLabel}-${tab}`,
      "aria-controls": `${ariaLabel}-${tab}`
    };
  };

  return (
    <Box width={"100%"}>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange} aria-label={ariaLabel}>
          {tabs.map(tab => (
            <Tab
              key={tab.id}
              label={tab.label}
              {...a11yProps(tab.id)}
              value={tab.id}
              disabled={tab.disabled}
            />
          ))}
        </TabList>
        {tabs.map(tab => (
          <TabPanel value={tab.id} key={tab.id} id={tab.id}>
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default PMPTabs;
