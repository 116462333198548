import { Components, Theme } from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Paper(theme: Theme): Components {
  return {
    MuiPaper: {
      defaultProps: {
        variant: "outlined"
      },
      styleOverrides: {
        outlined: {
          color: theme.palette.gray.main
        }
      }
    }
  };
}
