import { Theme } from "@mui/material/styles";
import AppBar from "./AppBar";
import Avatar from "./Avatar";
import Button from "./Button";
import Card from "./Card";
import Chip from "./Chip";
import InputLabel from "./InputLabel";
import Link from "./Link";
import List from "./List";
import Paper from "./Paper";
import Table from "./Table";
import MuiLab from "./MuiLab";
import Tabs from "./Tabs";
import TextField from "./TextField";

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    AppBar(theme),
    Avatar(theme),
    Button(theme),
    Card(theme),
    Chip(theme),
    InputLabel(theme),
    List(theme),
    Link(theme),
    Table(theme),
    Tabs(theme),
    TextField(theme),
    MuiLab(theme),
    Paper(theme)
  );
}
