import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Instant } from "@pmp/adl/common";
import { DbKey, WithDbId } from "@pmp/adl/common/db";
import {
  LocationSummary,
  QueryPromotionEventsResp
} from "@pmp/adl/petstock/merchantportal/api";
import { PromotionEvent } from "@pmp/adl/petstock/merchantportal/db";
import DatePickerInput from "@pmp/common/inputs/date-picker-input/date-picker-input";
import { Loader } from "@pmp/common/loader/loader";
import { promotionEventDurationTypeToText } from "@pmp/utils/adl";
import { formatDateWithTZ, getTimeZonedTime } from "@pmp/utils/dates";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { PlusIcon, TrashIcon } from "src/ui/common/icon/icons";

import { useAlert } from "../../../hooks/useAlertContext";
import { useAppService } from "../../../hooks/useAppService";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { LoadingBackdrop } from "../../components/LoadingBackdrop";
import { SubmitListener } from "../../widgets/form/helpers";
import PromotionEventsForm, {
  PROMOTION_EVENT_FORM_ID
} from "../../widgets/forms/promotion-events-form";

export interface PromotionEventListPageViewProps {
  loadPromotionEvents: (
    queryByMonth: Instant | null
  ) => Promise<QueryPromotionEventsResp>;
  locations: LocationSummary[];
}

export const PromotionEventListPageView = (
  props: PromotionEventListPageViewProps
) => {
  const { loadPromotionEvents, locations } = props;
  const [openTimeSlotDialog, setOpenTimeSlotDialog] = useState(false);
  const [queryByMonth, setQueryByMonth] = useState<Instant>(
    new Date().getTime()
  );

  const handlePromotionSearch = useCallback(
    (values: PromotionEventTableFilter) => {
      setQueryByMonth(values.yearMonth);
    },
    []
  );

  const queryPromotionEvents = useCallback(async () => {
    return await loadPromotionEvents(queryByMonth);
  }, [loadPromotionEvents, queryByMonth]);

  const [loadingPromotionEvents, refreshPromotionEvents] = useLoadingDataState(
    queryPromotionEvents
  );

  return (
    <>
      <AddTimeSlotDialog
        open={openTimeSlotDialog}
        onClose={() => setOpenTimeSlotDialog(false)}
        refreshPromotionEvents={refreshPromotionEvents}
      />
      <Stack spacing={4}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h1Bold">Promotion Time Slot</Typography>
          <Formik<PromotionEventTableFilter>
            initialValues={{
              yearMonth: new Date().getTime()
            }}
            onSubmit={handlePromotionSearch}
          >
            <>
              <SubmitListener />
              <Form>
                <Stack spacing={2} direction={"row"}>
                  <DatePickerInput name="yearMonth" views={["year", "month"]} />
                  <Button
                    startIcon={<PlusIcon />}
                    onClick={() => setOpenTimeSlotDialog(true)}
                  >
                    Add Time Slot
                  </Button>
                </Stack>
              </Form>
            </>
          </Formik>
        </Stack>

        <Loader loadingStates={[loadingPromotionEvents]}>
          {isLoaded(loadingPromotionEvents) && (
            <PromotionEventTable
              promotionEvents={loadingPromotionEvents.value}
              locations={locations}
              refreshPromotionEvents={refreshPromotionEvents}
            />
          )}
        </Loader>
      </Stack>
    </>
  );
};

interface PromotionEventTableFilter {
  yearMonth: Instant;
}

interface PromotionEventTableProps {
  promotionEvents: QueryPromotionEventsResp;
  locations: LocationSummary[];
  refreshPromotionEvents: () => void;
}

const PromotionEventTable = ({
  promotionEvents,
  locations,
  refreshPromotionEvents
}: PromotionEventTableProps) => {
  const service = useAppService();
  const [
    openDeletePromotionEventDialog,
    setOpenDeletePromotionEventDialog
  ] = useState(false);
  const [
    opneEditPromotinoEventDialog,
    setOpenEditPromotionEventDialog
  ] = useState(false);
  const [selectedPromotionEvent, setSelectedPromotionEvent] = useState<
    WithDbId<PromotionEvent>
  >();
  const [showAlert] = useAlert();

  const handleDeletePromotionEvent = useCallback(
    async (promotionEventId: DbKey<PromotionEvent>) => {
      try {
        await service.deletePromotionEvent(promotionEventId);
        showAlert({
          title: "Successfully deleted time slot",
          body: "",
          type: "success"
        });
        refreshPromotionEvents();
      } catch (e) {
        showAlert({
          title: "Error deleting time slot",
          body:
            "An error occurred while deleting the time slot, please try again."
        });
      }
    },
    [refreshPromotionEvents, service, showAlert]
  );

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>PROMOTION DURATION</TableCell>
            <TableCell>START DATE</TableCell>
            <TableCell>END DATE</TableCell>
            <TableCell>LOCATION</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {promotionEvents.map(promotionEvent => {
            const totalLocationCount = promotionEvent.value.locations.length;

            // Only show the first 4 locations
            const locationsToRender = promotionEvent.value.locations
              .slice(0, 4)
              .map(locationId => {
                const location = locations.find(
                  l => l.locationId === locationId
                );

                if (!location) {
                  return null;
                }

                return location.name;
              });
            return (
              <TableRow key={promotionEvent.id}>
                <TableCell>
                  {promotionEventDurationTypeToText(
                    promotionEvent.value.durationType,
                    promotionEvent.value.name
                  )}
                </TableCell>
                <TableCell>
                  {formatDateWithTZ(promotionEvent.value.startDate)}
                </TableCell>
                <TableCell>
                  {formatDateWithTZ(promotionEvent.value.endDate)}
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>{locationsToRender.join(", ")}</Typography>
                    {totalLocationCount > 4 && (
                      <Chip label={`+${totalLocationCount - 4}`} />
                    )}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenEditPromotionEventDialog(true);
                        setSelectedPromotionEvent(promotionEvent);
                      }}
                    >
                      Edit
                    </Button>
                    <IconButton
                      color={"error"}
                      onClick={() => {
                        setOpenDeletePromotionEventDialog(true);
                        setSelectedPromotionEvent(promotionEvent);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <DeletePromotionEventDialog
        promotionEventId={selectedPromotionEvent?.id ?? ""}
        open={openDeletePromotionEventDialog}
        onClose={() => setOpenDeletePromotionEventDialog(false)}
        onDeletePromotionEvent={handleDeletePromotionEvent}
      />
      <EditPromotionEventDialog
        promotionEvent={selectedPromotionEvent}
        open={opneEditPromotinoEventDialog}
        onClose={() => setOpenEditPromotionEventDialog(false)}
        refreshPromotionEvents={refreshPromotionEvents}
      />
    </TableContainer>
  );
};

interface DeletePromotionEventDialogProps {
  promotionEventId: DbKey<PromotionEvent>;
  open: boolean;
  onClose: () => void;
  onDeletePromotionEvent: (promotionEventId: DbKey<PromotionEvent>) => void;
}

interface AddTimeSlotDialogProps {
  open: boolean;
  onClose: () => void;
  refreshPromotionEvents: () => void;
}

const AddTimeSlotDialog = ({
  open,
  onClose,
  refreshPromotionEvents
}: AddTimeSlotDialogProps) => {
  const [
    createPromotionEventsSubmitting,
    setCreatePromotionEventsSubmitting
  ] = useState(false);
  const [showAlert] = useAlert();
  const [selectedTab, setSelectedTab] = useState("0");

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, tabIndex: string) => {
      setSelectedTab(tabIndex);
    },
    [setSelectedTab]
  );

  const handleSuccess = useCallback(
    async (eventCount: number) => {
      showAlert({
        title: eventCount === 1 ? "Time slot created" : "Time slots created",
        body:
          eventCount === 1
            ? "Successfully created 1 time slot"
            : `Successfully created ${eventCount} time slots`,
        type: "success"
      });
      refreshPromotionEvents();
      onClose();
    },
    [onClose, refreshPromotionEvents, showAlert]
  );

  const handleClose = useCallback(() => {
    if (!createPromotionEventsSubmitting) {
      setSelectedTab("0");
      onClose();
    }
  }, [createPromotionEventsSubmitting, onClose]);

  useEffect(() => {
    return () => {
      setCreatePromotionEventsSubmitting(false);
    };
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Add promotion time slot</DialogTitle>
      <DialogContent>
        <Typography>Select the time slot you desire below.</Typography>
      </DialogContent>
      <DialogContent>
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange}>
            <Tab label="Preset" value="0" />
            <Tab label="Custom" value="1" />
          </TabList>
          <TabPanel value="0" sx={{ padding: 0, paddingTop: 2 }}>
            <PromotionEventsForm
              promotionEventType="preset"
              setFormSubmitting={setCreatePromotionEventsSubmitting}
              onSuccess={handleSuccess}
            />
          </TabPanel>
          <TabPanel value="1" sx={{ padding: 0, paddingTop: 2 }}>
            <PromotionEventsForm
              promotionEventType="custom"
              setFormSubmitting={setCreatePromotionEventsSubmitting}
              onSuccess={handleSuccess}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={createPromotionEventsSubmitting}
          variant={"contained"}
          type={"submit"}
          form={PROMOTION_EVENT_FORM_ID}
        >
          Add time slot
        </LoadingButton>
      </DialogActions>
      <LoadingBackdrop open={createPromotionEventsSubmitting} />
    </Dialog>
  );
};

export const DeletePromotionEventDialog = ({
  promotionEventId,
  open,
  onClose,
  onDeletePromotionEvent
}: DeletePromotionEventDialogProps) => {
  const [
    deletePromotionEventSubmitting,
    setDeletePromotionEventSubmitting
  ] = useState(false);

  useEffect(() => {
    return () => {
      setDeletePromotionEventSubmitting(false);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!deletePromotionEventSubmitting) {
          onClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>Delete a Time Slot</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove this time slot? Promotions that
          haven’t been approved in this timeslot will be auto-rejected, while
          approved promotions will be auto-archived.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          loading={deletePromotionEventSubmitting}
          variant={"contained"}
          onClick={() => {
            onDeletePromotionEvent(promotionEventId);
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
      <LoadingBackdrop open={deletePromotionEventSubmitting} />
    </Dialog>
  );
};

interface EditPromotionEventDialogProps {
  promotionEvent?: WithDbId<PromotionEvent>;
  open: boolean;
  onClose: () => void;
  refreshPromotionEvents: () => void;
}

const EditPromotionEventDialog = ({
  promotionEvent,
  open,
  onClose,
  refreshPromotionEvents
}: EditPromotionEventDialogProps) => {
  const [
    updatePromotionEventsSubmitting,
    setUpdatePromotionEventsSubmitting
  ] = useState(false);
  const [showAlert] = useAlert();

  const handleSuccess = useCallback(
    (eventCount: number) => {
      showAlert({
        title: eventCount === 1 ? "Time slot updated" : "Time slots updated",
        body:
          eventCount === 1
            ? "Successfully updated 1 time slot"
            : `Successfully updated ${eventCount} time slots`,
        type: "success"
      });
      refreshPromotionEvents();
    },
    [refreshPromotionEvents, showAlert]
  );

  const handleDeletePromotionEvent = useCallback(() => {
    showAlert({
      title: "Successfully deleted time slot",
      body: "",
      type: "success"
    });
    refreshPromotionEvents();
  }, [refreshPromotionEvents, showAlert]);

  useEffect(() => {
    return () => {
      setUpdatePromotionEventsSubmitting(false);
    };
  }, []);

  if (!promotionEvent) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Edit promotion time slot</DialogTitle>
      <DialogContent>
        <Typography>
          Adjust the Promotion time slot as you desire below.
        </Typography>
      </DialogContent>
      <DialogContent>
        <PromotionEventsForm
          existingPromotionEvents={[
            {
              id: promotionEvent.id,
              durationType: promotionEvent.value.durationType,
              startDate: getTimeZonedTime(promotionEvent.value.startDate),
              locationIds: promotionEvent.value.locations,
              endDate: getTimeZonedTime(promotionEvent.value.endDate),
              name: promotionEvent.value.name
            }
          ]}
          promotionEventType={
            promotionEvent.value.durationType === "Custom" ? "custom" : "preset"
          }
          setFormSubmitting={setUpdatePromotionEventsSubmitting}
          onSuccess={handleSuccess}
          onDeleteSuccess={handleDeletePromotionEvent}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={updatePromotionEventsSubmitting}
          variant={"contained"}
          type={"submit"}
          form={PROMOTION_EVENT_FORM_ID}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
      <LoadingBackdrop open={updatePromotionEventsSubmitting} />
    </Dialog>
  );
};
