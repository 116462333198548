import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography
} from "@mui/material";
import { LoginReq } from "@pmp/adl/petstock/merchantportal/api";

import PasswordInput from "@pmp/common/inputs/password-input/password-input";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { object, string } from "yup";
import { AppRoutes } from "../../../app/app";
import { LoginRespType } from "../../../app/identity-state";
import GetHelp from "../../components/GetHelp";

export interface LoginPageProps {
  /** Error message to show upon login failure */
  loginError?: string;

  /** Callback to login */
  onLogin(req: LoginReq): Promise<LoginRespType>;
}

interface LoginFormValues {
  email: string;
  password: string;
}

const validationSchema = object().shape({
  email: string()
    .min(2)
    .email("Invalid email")
    .required("Email is required"),
  password: string().required("Password is required")
});

/**
 * Landing page of the app.
 */
export const LoginPage = (props: LoginPageProps) => {
  const history = useHistory();
  const location = useLocation();

  /** Callback to log in */
  const onLogin = async (values: LoginFormValues) => {
    const data = values;
    if (data) {
      const resp = await props.onLogin(data);
      if (resp.kind === "logged-in") {
        const params = new URLSearchParams(location.search);
        // If the login was from a redirect
        if (params.has("referrer")) {
          location.pathname = params.get("referrer") || "";
          params.delete("referrer");
          location.search = params.toString();
        } else {
          location.pathname = AppRoutes.Index;
        }
        history.push(location);
      }
    }
  };

  /** Render the page */

  return (
    <Grid container>
      <Grid item xs={5}>
        <Stack spacing={4}>
          <Typography variant="h1Bold">Sign In</Typography>
          {props.loginError && (
            <Alert severity="error">{props.loginError}</Alert>
          )}
          <Formik<LoginFormValues>
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={onLogin}
          >
            {({ isValid }) => (
              <Form>
                <Stack spacing={2}>
                  <TextInput
                    name="email"
                    label="Email Address"
                    placeholder="Your Email"
                    type="email"
                  />
                  <PasswordInput
                    name="password"
                    placeholder="Your Password"
                    label="Password"
                  />

                  <Link align="center" href={AppRoutes.ForgotPassword}>
                    Forgot my password
                  </Link>
                  <Button fullWidth type="submit" disabled={!isValid}>
                    Sign In
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Grid>

      <Box position={"absolute"} left={0} bottom={0}>
        <GetHelp />
      </Box>
      <Box
        position={"fixed"}
        top={20}
        bottom={20}
        right={20}
        borderRadius={4}
        sx={{
          width: "50vw",
          backgroundImage: "url('/assets/background.webp')",
          background:
            "linear-gradient(180deg, rgba(0, 40, 85, 0) 0%, #002855 100%), url('/assets/background.webp')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Typography
          variant={"h2Bold"}
          color={"common.white"}
          display={"inline-block"}
          width={"60%"}
          position={"absolute"}
          left={32}
          bottom={32}
        >
          “We have entrepreneurial energy that drives us to find and develop the
          very best products for your pet”
        </Typography>
      </Box>
    </Grid>
  );
};
