import { Components, Theme } from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function TextField(theme: Theme): Components {
  return {
    MuiInputBase: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          "&:not(.MuiInputBase-multiline)": {
            height: 44
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 4
        }
      }
    }
  };
}
