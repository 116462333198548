import { assertNotUndefined } from "@hx/util/types";
import { AppService } from "@pmp/adl/app-service";
import { Loader } from "@pmp/common/loader/loader";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { LoggedInContext } from "../../../app/app";
import { useFeatures } from "../../../hooks/useFeatures";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  AlertIcon,
  FileTextIcon,
  LayersIcon,
  PackageIcon
} from "../../common/icon/icons";
import DashboardView, { DashboardStat } from "./DashboardView";

interface DashboardPageProps {
  workspaceId: string | null;
}

const DashboardPage: FC<DashboardPageProps> = ({ workspaceId }) => {
  const { isSupplier } = useFeatures();

  const service: AppService = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );

  const loadDashboardStats = useCallback(async () => {
    return await service.queryDashboardSummary({
      workspaceId
    });
  }, [service, workspaceId]);
  const [loadingDashboardStats] = useLoadingDataState(loadDashboardStats);

  const loadDashboardActions = useCallback(async () => {
    return await service.queryDashboardOutstandingActions({
      workspaceId
    });
  }, [service, workspaceId]);
  const [loadingDashboardActions] = useLoadingDataState(loadDashboardActions);

  const loadDashboardPromotions = useCallback(async () => {
    return await service.queryDashboardScheduledPromotions({
      workspaceId
    });
  }, [service, workspaceId]);
  const [loadingDashboardPromotions] = useLoadingDataState(
    loadDashboardPromotions
  );

  const loadDashboardPromotionsStatusStatistics = useCallback(async () => {
    return await service.queryPromotionStatusStatistics({
      workspaceId
    });
  }, [service, workspaceId]);
  const [loadingDashboardPromotionsStatusStatistics] = useLoadingDataState(
    loadDashboardPromotionsStatusStatistics
  );

  const supplierDashboardStats = useMemo(() => {
    if (loadingDashboardStats.state === "success") {
      const stats = loadingDashboardStats.value;

      return [
        {
          stat: stats.totalPromotions,
          Icon: LayersIcon,
          label: "total promotions"
        },
        {
          stat: stats.totalCurrentPromotionItems,
          Icon: PackageIcon,
          label: "total current promotions items"
        },
        {
          stat: stats.totalPlannedPromotionItems,
          Icon: FileTextIcon,
          label: "total planned promotion items"
        },
        {
          stat: stats.totalPromotionsWithPendingActions,
          Icon: AlertIcon,
          label: "total pending actions",
          color: "warning"
        }
      ] as Array<DashboardStat>;
    }
    return [];
  }, [loadingDashboardStats]);

  const adminDashboardStats = useMemo(() => {
    if (loadingDashboardStats.state === "success") {
      const stats = loadingDashboardStats.value;

      return [
        {
          stat: stats.totalRunningPromotions,
          Icon: LayersIcon,
          label: "total running promotions"
        },
        {
          stat: stats.totalPlannedPromotions,
          Icon: FileTextIcon,
          label: "total planned promotions"
        },
        {
          stat: stats.totalPromotionsWithPendingActions,
          label: "total pending actions",
          Icon: AlertIcon,
          color: "warning"
        },
        {
          stat: stats.totalSuppliersRunningPromotion,
          Icon: PackageIcon,
          label: "total suppliers running promotion"
        }
      ] as Array<DashboardStat>;
    }
    return [];
  }, [loadingDashboardStats]);

  const actionsData = useMemo(() => {
    if (loadingDashboardActions.state === "success") {
      const {
        promotions,
        suppliersCount,
        usersCount
      } = loadingDashboardActions.value;
      return {
        actions: promotions,
        groupedCounts: {
          users: usersCount,
          suppliers: suppliersCount
        }
      };
    }

    return {
      actions: []
    };
  }, [loadingDashboardActions]);

  const promotions = useMemo(() => {
    if (loadingDashboardPromotions.state === "success") {
      const { current, upcoming } = loadingDashboardPromotions.value;
      return {
        current,
        upcoming
      };
    }

    return {
      current: [],
      upcoming: []
    };
  }, [loadingDashboardPromotions]);

  const promotionByStatus = useMemo(() => {
    if (loadingDashboardPromotionsStatusStatistics.state === "success") {
      const promoByStatus = loadingDashboardPromotionsStatusStatistics.value;

      return promoByStatus.map(entry => {
        return {
          status: entry.key,
          count: entry.value
        };
      });
    }
    return [];
  }, [loadingDashboardPromotionsStatusStatistics]);

  const itemsStats = useMemo(() => {
    if (loadingDashboardStats.state === "success") {
      const stats = loadingDashboardStats.value;

      return {
        planned: stats.totalPlannedPromotionItems,
        current: stats.totalCurrentPromotionItems
      };
    }
    return undefined;
  }, [loadingDashboardStats]);

  return (
    <Loader
      loadingStates={[
        loadingDashboardStats,
        loadingDashboardActions,
        loadingDashboardPromotions,
        loadingDashboardPromotionsStatusStatistics
      ]}
    >
      {isLoaded(loadingDashboardStats) &&
        isLoaded(loadingDashboardActions) &&
        isLoaded(loadingDashboardPromotions) &&
        isLoaded(loadingDashboardPromotionsStatusStatistics) && (
          <DashboardView
            dashboardStats={
              isSupplier() ? supplierDashboardStats : adminDashboardStats
            }
            promotions={promotions}
            selectedWorkspaceId={workspaceId}
            promotionByStatus={promotionByStatus}
            actionsData={actionsData}
            itemsStats={itemsStats}
          />
        )}
    </Loader>
  );
};

export default DashboardPage;
