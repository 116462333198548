import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
  Zoom
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { PromotionDetailsSummary } from "@pmp/adl/petstock/merchantportal/api";
import DateRange from "@pmp/components/DateRange";
import PromoStatusChip from "@pmp/components/PromotionStatus/PromoStatusChip";
import {
  promotionEventDurationTypeToText,
  promotionStatusToUIColor,
  promotionTypeToText
} from "@pmp/utils/adl";
import { formatLeadingZero } from "@pmp/utils/numbers";
import { Instance } from "@popperjs/core";
import { addMonths, subDays } from "date-fns";
import React, { FC, useMemo, useRef, useState } from "react";
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";

import { AppRoutes } from "../../../app/app";
import { CaretDownIcon, CaretRightIcon } from "../../common/icon/icons";

export interface TimelineGroupBase {
  id: string;
  title: string;
}

export interface TimelineGroupParent extends TimelineGroupBase {
  kind: "parent";
}

export interface TimelineGroupHeader extends TimelineGroupBase {
  kind: "header";
}

export interface TimelineGroupItem extends TimelineGroupBase {
  kind: "item";
  parentId?: string;
  pmpData: PromotionDetailsSummary;
}

export type TimelineGroup =
  | TimelineGroupParent
  | TimelineGroupHeader
  | TimelineGroupItem;

export interface TimelineItem {
  id: string;
  group: string;
  title: string;
  start_time: Date;
  end_time: Date;
  pmpData: PromotionDetailsSummary;
}

export interface PromotionsTimelineProps {
  groups: Array<TimelineGroup>;
  items: Array<TimelineItem>;
}

const ONE_DAY_MILLISECONDS = 60 * 60 * 24 * 1000;
const ONE_WEEK_MILLISECONDS = 7 * ONE_DAY_MILLISECONDS;
const ONE_MONTH_MILLISECONDS = 30 * ONE_DAY_MILLISECONDS;
const ROW_HEIGHT = 56;
const PromotionsTimeline: FC<PromotionsTimelineProps> = ({ items, groups }) => {
  const theme = useTheme();
  const [openGroups, setOpenGroups] = useState({});

  const toggleOpenGroups = (id: string) => {
    setOpenGroups({
      ...openGroups,
      [id]: !openGroups[id]
    });
  };

  const ItemRenderer = ({ item, itemContext, getItemProps }) => {
    const positionRef = useRef<{ x: number; y: number }>({
      x: 0,
      y: 0
    });
    const popperRef = useRef<Instance>(null);
    const areaRef = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMouseMove = (event: any) => {
      positionRef.current = { x: event.clientX, y: event.clientY };

      if (popperRef.current != null) {
        popperRef.current.update();
      }
    };

    const backgroundColor = promotionStatusToUIColor(
      item.pmpData.status,
      theme
    );

    return (
      <Box
        {...getItemProps({
          style: {
            background: backgroundColor,
            color: theme.palette.getContrastText(backgroundColor),
            border: "none",
            borderRadius: 4
          }
        })}
      >
        <TimelineItemTooltip
          title={<TimelineItemTooltipContent {...item.pmpData} />}
          open={itemContext.selected}
          TransitionComponent={Zoom}
          enterDelay={500}
          leaveDelay={200}
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  positionRef.current.x,
                  areaRef.current?.getBoundingClientRect().y,
                  0,
                  0
                );
              }
            }
          }}
        >
          <Box
            ref={areaRef}
            onMouseMove={handleMouseMove}
            sx={{
              padding: "6px 12px"
            }}
          >
            <Typography variant={"h6"} noWrap>
              {itemContext.title}
            </Typography>
          </Box>
        </TimelineItemTooltip>
      </Box>
    );
  };

  const groupRenderer = ({ group }) => {
    const typedGroup = group as TimelineGroup;
    if (typedGroup.kind === "header") {
      const { title } = typedGroup;

      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          height={ROW_HEIGHT}
          px={2}
          sx={{
            backgroundColor: theme => theme.palette.gray.lighter
          }}
        >
          <Typography
            variant={"h5Bold"}
            noWrap
            textTransform={"uppercase"}
            color={"primary.main"}
          >
            {title}
          </Typography>
        </Stack>
      );
    }
    if (typedGroup.kind === "parent") {
      const { title, id } = typedGroup;

      return (
        <Button
          variant={"text"}
          onClick={() => toggleOpenGroups(id)}
          fullWidth
          startIcon={openGroups[id] ? <CaretDownIcon /> : <CaretRightIcon />}
          sx={{
            height: "100%",
            backgroundColor: theme => theme.palette.gray.lightest,
            justifyContent: "flex-start"
          }}
          disableRipple
        >
          <Typography variant={"h5"} noWrap textAlign={"left"}>
            {title}
          </Typography>
        </Button>
      );
    }
    if (typedGroup.kind === "item") {
      const { pmpData, title } = typedGroup;

      const { status } = pmpData;
      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          height={ROW_HEIGHT}
          mx={2}
        >
          <PromoStatusChip status={status} size={"small"} uppercased />
          <Typography variant={"h5"} noWrap>
            {title}
          </Typography>
        </Stack>
      );
    }

    return null;
  };

  const activeGroups = useMemo(() => {
    return groups.filter(
      g =>
        g.kind !== "item" ||
        g.parentId === undefined ||
        (g.parentId && openGroups[g.parentId])
    );
  }, [groups, openGroups]);
  return (
    <Paper
      sx={{
        overflow: "hidden",
        height: "100%",
        minHeight: 0
      }}
    >
      <StyledTimeline
        groups={activeGroups}
        items={items}
        defaultTimeStart={subDays(Date.now(), 1)}
        defaultTimeEnd={addMonths(Date.now(), 1)}
        canMove={false}
        itemRenderer={({ item, itemContext, getItemProps }) => (
          <ItemRenderer
            getItemProps={getItemProps}
            item={item}
            itemContext={itemContext}
          />
        )}
        groupRenderer={groupRenderer}
        lineHeight={ROW_HEIGHT}
        minZoom={ONE_WEEK_MILLISECONDS}
        maxZoom={ONE_MONTH_MILLISECONDS}
        sidebarWidth={450}
      >
        <TimelineHeaders className="sticky">
          <SidebarHeader>
            {({ getRootProps }) => {
              return (
                <Stack
                  {...getRootProps()}
                  px={2}
                  py={1}
                  direction={"column-reverse"}
                >
                  <Typography
                    variant={"h5Bold"}
                    textTransform={"uppercase"}
                    color={"primary.main"}
                  >
                    promotions
                  </Typography>
                </Stack>
              );
            }}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker />
        </TimelineMarkers>
      </StyledTimeline>
    </Paper>
  );
};

const StyledTimeline = styled(Timeline)(({ theme }) => ({
  /* BACKGROUNDS */
  "&.react-calendar-timeline .rct-header-root, &.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd": {
    background: theme.palette.common.white
  },
  "&.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd, &.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, &.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,&.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0,&.react-calendar-timeline  .rct-vertical-lines .rct-vl": {
    background: theme.palette.gray.lightest
  },
  "&.react-calendar-timeline .rct-dateHeader, &.react-calendar-timeline .rct-dateHeader-primary": {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    ...theme.typography.h5Bold
  },
  /* BORDERS */
  "&.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd, &.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, &.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,&.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0,&.react-calendar-timeline  .rct-vertical-lines .rct-vl, &.react-calendar-timeline .rct-dateHeader ,&.react-calendar-timeline .rct-calendar-header": {
    border: "none"
  },
  "&.react-calendar-timeline .rct-calendar-header": {
    borderLeft: "1px solid"
  },
  "&.react-calendar-timeline .rct-calendar-header, &.react-calendar-timeline .rct-sidebar, &.react-calendar-timeline .rct-sidebar .rct-sidebar-row, &.react-calendar-timeline .rct-header-root": {
    borderColor: theme.palette.gray.lighter
  },
  /* HEADER */
  "&.react-calendar-timeline .sticky": {
    position: "sticky",
    top: 0,
    zIndex: 100
  },

  /* LAYOUT */
  "&.react-calendar-timeline .rct-sidebar .rct-sidebar-row": {
    padding: 0
  }
}));

const TimelineItemTooltipContent: FC<PromotionDetailsSummary> = ({
  id,
  name,
  itemCount,
  promotionEventSummary,
  categoryCount,
  createdByUser,
  promotionType,
  brands
}) => {
  const {
    startDate,
    endDate,
    durationType,
    name: promoEventName
  } = promotionEventSummary;
  return (
    <Paper variant={"elevation"} elevation={5}>
      <Box width={300} height={310}>
        <Stack gap={1}>
          <Box
            px={3}
            py={1}
            sx={{
              background: theme => theme.palette.gray.lightest
            }}
          >
            <Typography variant={"h5Bold"} noWrap display={"block"}>
              {name}
            </Typography>
          </Box>
          <Box px={3} py={1}>
            <DateRange startDate={startDate} endDate={endDate} />
          </Box>
          <Grid container spacing={0.5} px={3}>
            <Grid item xs={6}>
              <Typography variant={"h6Bold"}>Promotion Items</Typography>
            </Grid>
            <Grid item xs={6} pl={2}>
              <Typography
                noWrap
                variant={"h6"}
                color={"gray.main"}
                textAlign={"right"}
              >
                {formatLeadingZero(itemCount)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6Bold"}>Category Count</Typography>
            </Grid>
            <Grid item xs={6} pl={2}>
              <Typography
                noWrap
                variant={"h6"}
                color={"gray.main"}
                textAlign={"right"}
              >
                {formatLeadingZero(categoryCount)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6Bold"}>Brands</Typography>
            </Grid>
            <Grid item xs={6} pl={2}>
              <Typography
                noWrap
                variant={"h6"}
                color={"gray.main"}
                textAlign={"right"}
              >
                {brands.length === 0
                  ? "--"
                  : brands.length === 1
                  ? brands[0]
                  : `${brands[0]} +${formatLeadingZero(brands.length - 1)}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6Bold"}>Promotion Type</Typography>
            </Grid>
            <Grid item xs={6} pl={2}>
              <Typography
                noWrap
                variant={"h6"}
                color={"gray.main"}
                textAlign={"right"}
              >
                {promotionTypeToText(promotionType)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6Bold"}>Duration</Typography>
            </Grid>
            <Grid item xs={6} pl={2}>
              <Typography
                noWrap
                variant={"h6"}
                color={"gray.main"}
                textAlign={"right"}
              >
                {promotionEventDurationTypeToText(durationType, promoEventName)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6Bold"}>Prepared By</Typography>
            </Grid>
            <Grid item xs={6} pl={2}>
              <Typography
                noWrap
                variant={"h6"}
                color={"gray.main"}
                textAlign={"right"}
              >
                {createdByUser}
              </Typography>
            </Grid>
          </Grid>
          <Box flexGrow={1} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            px={3}
            py={1}
            sx={{
              background: theme => theme.palette.gray.lightest
            }}
          >
            <Button
              variant={"outlined"}
              size={"small"}
              fullWidth
              href={`${AppRoutes.Promotions}/${id}`}
            >
              view promo
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

const TimelineItemTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0
  }
}));

export default PromotionsTimeline;
