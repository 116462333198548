import { assertNotUndefined } from "@hx/util/types";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Stack, Tab, Typography } from "@mui/material";
import { WithDbId } from "@pmp/adl/common/db";
import {
  QueryUsersResp,
  SupplierWorkspaceSummary,
  UpdateWorkspaceReq
} from "@pmp/adl/petstock/merchantportal/api";
import { Manufacturer } from "@pmp/adl/petstock/merchantportal/db";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";

import TextInput from "@pmp/common/inputs/text-input/text-input";
import TextAreaInput from "@pmp/common/inputs/textarea-input/textarea-input";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useFeatures } from "src/hooks/useFeatures";
import { AppRoutes } from "../../../app/app";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  ArrowLeftIcon,
  PlusIcon,
  SettingIcon,
  StarIcon,
  UserIcon
} from "../../common/icon/icons";
import ActionsDrawer from "../../components/ActionsDrawer";
import TabLabel from "../../components/TabLabel";
import Link from "../../components/utils/Link";
import { InviteExistingUserDialogProps } from "../../widgets/invite-existing-user-dialog/invite-existing-user-dialog";
import { ManageUsersTable } from "../../widgets/manage-users-table/manage-users-table";
import { validationSchemaCreateWorkspace } from "../workspace-overview/create-new-workspace";

export interface ManageWorkspacePageViewProps {
  supplierWorkspace: SupplierWorkspaceSummary;
  adminUsers: QueryUsersResp;
  manufacturers: Array<WithDbId<Manufacturer>>;
  loadUsers: () => Promise<QueryUsersResp>;
  onUpdateWorkspace: (req: UpdateWorkspaceReq) => Promise<void>;
  InviteExistingUserModal: React.FC<
    Omit<
      InviteExistingUserDialogProps & { refreshUsers: () => void },
      "onSendInvite"
    >
  >;
}

export const ManageWorkspacePageView = ({
  supplierWorkspace,
  adminUsers,
  manufacturers,
  loadUsers,
  onUpdateWorkspace,
  InviteExistingUserModal
}: ManageWorkspacePageViewProps) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, tabIndex: string) => {
      setSelectedTab(tabIndex);
    },
    [setSelectedTab]
  );
  const [loadingUsers, refreshUsers] = useLoadingDataState(loadUsers);
  const { isSuperAdmin } = useFeatures();

  const [openInviteMoreUsersModal, setOpenInviteMoreUsersModal] = useState(
    false
  );

  const workspaceUsersTabLabel =
    loadingUsers.state === "success" ? `(${loadingUsers.value.length})` : "";

  return (
    <Stack gap={4}>
      <Stack>
        <Link href={`${AppRoutes.Workspaces}/${supplierWorkspace.id}`}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <ArrowLeftIcon />
            <Typography variant={"h5Bold"}>Back to workspace</Typography>
          </Stack>
        </Link>
        <Typography variant={"h1Bold"}>
          Manage {supplierWorkspace.name}
        </Typography>
      </Stack>
      <Box>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab
                label={
                  <TabLabel
                    label={"workspace setting"}
                    icon={<SettingIcon />}
                  />
                }
                value="1"
              />
              <Tab
                label={
                  <TabLabel
                    label={`Workspace Users ${workspaceUsersTabLabel}`}
                    icon={<UserIcon />}
                  />
                }
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <WorkspaceSetting
              supplierWorkspace={supplierWorkspace}
              manufacturers={manufacturers}
              onUpdateWorkspace={onUpdateWorkspace}
            />
          </TabPanel>
          <TabPanel value="2">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {isSuperAdmin() && (
                <Button
                  startIcon={<PlusIcon />}
                  onClick={() => setOpenInviteMoreUsersModal(true)}
                >
                  Add New User
                </Button>
              )}
            </Stack>
            <ManageUsersTable
              tabs={[
                {
                  label: (
                    <TabLabel label={"All Active Users"} icon={<UserIcon />} />
                  ),
                  filterUsers: (users: QueryUsersResp) => users.filter(u => u)
                },
                {
                  label: <TabLabel label={"All Admins"} icon={<StarIcon />} />,
                  filterUsers: (users: QueryUsersResp) =>
                    users.filter(u => u.userType === "admin")
                }
              ]}
              loadingUsers={loadingUsers}
              refreshUsers={refreshUsers}
              allowArchiving={false}
            />
          </TabPanel>
        </TabContext>
      </Box>
      <InviteExistingUserModal
        users={adminUsers}
        open={openInviteMoreUsersModal}
        workspaceName={supplierWorkspace.name}
        onClose={() => setOpenInviteMoreUsersModal(false)}
        refreshUsers={refreshUsers}
      />
    </Stack>
  );
};

interface WorkspaceSettingForm {
  workspaceName: string;
  supplierCode: string;
  description: string;
  manufacturerIds: string[];
}

interface WorkspaceSettingProps {
  supplierWorkspace: SupplierWorkspaceSummary;
  manufacturers: Array<WithDbId<Manufacturer>>;
  onUpdateWorkspace: (req: UpdateWorkspaceReq) => Promise<void>;
}

const WorkspaceSetting = ({
  supplierWorkspace,
  manufacturers,
  onUpdateWorkspace
}: WorkspaceSettingProps) => {
  const onSubmitForm = useCallback(
    (values: WorkspaceSettingForm) => {
      const req: UpdateWorkspaceReq = {
        name: assertNotUndefined(values.workspaceName),
        code: assertNotUndefined(values.supplierCode),
        description: values.description,
        manufacturerIds: values.manufacturerIds
      };
      return onUpdateWorkspace(req);
    },
    [onUpdateWorkspace]
  );

  const manufacturerOptions = useMemo(() => {
    return manufacturers.map(m => {
      return {
        title: m.value.name,
        value: m.id
      };
    });
  }, [manufacturers]);

  const { isSuperAdmin } = useFeatures();

  return (
    <Stack flex={1} spacing={3}>
      <Formik<WorkspaceSettingForm>
        initialValues={{
          workspaceName: supplierWorkspace.name,
          supplierCode: supplierWorkspace.code,
          description: supplierWorkspace.description ?? "",
          manufacturerIds: supplierWorkspace.manufacturers.map(m => m.id)
        }}
        validationSchema={validationSchemaCreateWorkspace}
        onSubmit={onSubmitForm}
      >
        {({ isValid, initialValues, values, resetForm }) => (
          <Form>
            <Stack spacing={2}>
              <Typography variant="h3Bold">General Information</Typography>
              <TextInput
                name="workspaceName"
                label="Supplier Name"
                required
                placeholder="Supplier Name"
              />
              <TextInput
                name="supplierCode"
                label="Supplier Code"
                required
                placeholder="Supplier Code"
              />
              <TextAreaInput
                name="description"
                label="Supplier Description"
                placeholder="Supplier Description"
              />
              <AutocompleteSelectInput
                options={manufacturerOptions}
                name={"manufacturerIds"}
                label={"Assigned Manufacturers"}
                required
                multiple
                disabled={!isSuperAdmin()}
              />
              <ActionsDrawer
                isOpen={initialValues !== values}
                primaryAction={
                  <Button type={"submit"} disabled={!isValid}>
                    apply changes
                  </Button>
                }
                secondaryAction={
                  <Button
                    variant={"outlined"}
                    onClick={() =>
                      resetForm({
                        values: initialValues
                      })
                    }
                  >
                    discard change
                  </Button>
                }
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
