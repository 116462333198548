import { AlertColor } from "@mui/material";
import React, { useCallback } from "react";
import {
  AlertContext,
  OpenAlertProps,
  DEFAULT_ALERT_MANAGER_VALUES
} from "../hooks/useAlertContext";

export interface AlertManagerProps {
  isShown: boolean;
  title: string;
  body: string;
  type?: AlertColor;
}

export const AlertManager = ({ children }) => {
  const [alert, setAlert] = React.useState<AlertManagerProps>(
    DEFAULT_ALERT_MANAGER_VALUES
  );

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
  }>();

  const openAlert = useCallback(
    async (props: OpenAlertProps) => {
      setAlert({ isShown: true, ...props });
      return new Promise<void>(resolve => {
        awaitingPromiseRef.current = { resolve };
      });
    },
    [setAlert]
  );

  const closeAlert = useCallback(async () => {
    setAlert({ ...DEFAULT_ALERT_MANAGER_VALUES });
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
  }, [setAlert]);

  return (
    <AlertContext.Provider value={{ ...alert, openAlert, closeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
