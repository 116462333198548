import { DbKey } from "@pmp/adl/common/db";
import {
  PromotionsGroupBy,
  QueryPromotionsFilter
} from "@pmp/adl/petstock/merchantportal/api";
import {
  Promotion,
  SupplierWorkspace
} from "@pmp/adl/petstock/merchantportal/db";
import React, { FC, useCallback } from "react";
import { useLoadingDataState } from "src/hooks/useLoadingData";
import { useAppService } from "../../../../hooks/useAppService";
import { PromotionOverviewView } from "./promotion-overview-view";

interface PromotionOverviewPageProps {
  selectedWorkspaceId: string | null;
}

export const PromotionOverviewPage: FC<PromotionOverviewPageProps> = ({
  selectedWorkspaceId
}) => {
  const service = useAppService();

  const loadPromotions = useCallback(
    async (promotionFilter: QueryPromotionsFilter) => {
      return await service.queryPromotions({
        promotionId: null,
        promotionFilter: {
          supplierWorkspaceIds: selectedWorkspaceId
            ? [selectedWorkspaceId]
            : null,
          promotionNameLike: promotionFilter.promotionNameLike,
          filterByStatus:
            promotionFilter.filterByStatus === undefined ||
            promotionFilter.filterByStatus?.length === 0
              ? null
              : promotionFilter.filterByStatus,
          endDateAfter: promotionFilter.endDateAfter,
          filterByBrands: promotionFilter.filterByBrands,
          filterByClasses: promotionFilter.filterByClasses,
          filterByManufacturer: promotionFilter.filterByManufacturer
        }
      });
    },
    [service, selectedWorkspaceId]
  );

  const loadPromotionsByGroup = useCallback(
    async (
      promotionFilter: QueryPromotionsFilter,
      groupBy: PromotionsGroupBy
    ) => {
      return await service.queryPromotionsByGroup({
        promotionFilter: {
          supplierWorkspaceIds: selectedWorkspaceId
            ? [selectedWorkspaceId]
            : null,
          promotionNameLike: promotionFilter.promotionNameLike,
          filterByStatus:
            promotionFilter.filterByStatus === undefined ||
            promotionFilter.filterByStatus?.length === 0
              ? null
              : promotionFilter.filterByStatus,
          endDateAfter: promotionFilter.endDateAfter,
          filterByBrands: promotionFilter.filterByBrands,
          filterByClasses: promotionFilter.filterByClasses,
          filterByManufacturer: promotionFilter.filterByManufacturer
        },
        groupBy
      });
    },
    [service, selectedWorkspaceId]
  );

  const loadCommonProductDetails = useCallback(async () => {
    return await service.findCommonProductDetailsByWorkspace({
      supplierWorkspaceId: selectedWorkspaceId ? selectedWorkspaceId : null
    });
  }, [service, selectedWorkspaceId]);

  const [loadingCommonProductDetails] = useLoadingDataState(
    loadCommonProductDetails
  );

  const loadPromotionActivities = useCallback(
    async (promotionId: DbKey<Promotion>) => {
      return await service.queryActivityLogs({
        supplierWorkspaceId: null,
        promotionId: promotionId
      });
    },
    [service]
  );

  const duplicatePromotion = useCallback(
    async (
      promotionId: DbKey<Promotion>,
      promotionWorkspaceId: DbKey<SupplierWorkspace>
    ) => {
      return await service.duplicatePromotion({
        promotionId: promotionId,
        supplierWorkspaceId: promotionWorkspaceId
      });
    },
    [service]
  );

  return (
    <PromotionOverviewView
      loadPromotions={loadPromotions}
      loadPromotionsByGroup={loadPromotionsByGroup}
      brands={
        loadingCommonProductDetails.state === "success"
          ? loadingCommonProductDetails.value.brands
          : []
      }
      classes={
        loadingCommonProductDetails.state === "success"
          ? loadingCommonProductDetails.value.classes
          : []
      }
      manufacturers={
        loadingCommonProductDetails.state === "success"
          ? loadingCommonProductDetails.value.manufacturers
          : []
      }
      duplicatePromotion={duplicatePromotion}
      loadActivityLogs={loadPromotionActivities}
      selectedWorkspaceId={selectedWorkspaceId}
    />
  );
};
