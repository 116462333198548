import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from "@mui/material";
import { Form, Formik } from "formik";
import TextareaInput from "@pmp/common/inputs/textarea-input/textarea-input";
import FileUploadInput from "@pmp/common/inputs/file-upload-input/file-upload-input";
import SelectInput, {
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import { valuesPromotionNoteTag } from "@pmp/adl/petstock/merchantportal/types";
import {
  CreatePromotionNoteAttachmentReq,
  CreatePromotionNoteReq
} from "@pmp/adl/petstock/merchantportal/api";
import { object, string } from "yup";
import LoadingButton from "@mui/lab/LoadingButton";

export interface AddNewNoteFormValues {
  note: string;
  attachments: File[];
  tag: string;
}

export const PROMOTION_NOTE_FORM_ID = "promotion-note-form-id";

const tagOptions: SelectOptions = valuesPromotionNoteTag
  .sort()
  .filter(t => t.valueOf() !== "approval") // 'Approval' tag is not part of the options for adding notes
  .map(tag => {
    // doing this extra work to add a "-" to renegotiation
    if (tag === "renegotiation") {
      return {
        title: "Re-negotiation",
        value: tag.valueOf()
      };
    } else {
      return {
        title: `${tag[0].toUpperCase()}${tag.slice(1).toLowerCase()}`,
        value: tag.valueOf()
      };
    }
  });

interface AddNoteFormProps {
  handleAddNote: (
    createNoteReq: Omit<CreatePromotionNoteReq, "promotionId">
  ) => Promise<void>;
  showAttachmentsField: boolean;
  showTagsSelect: boolean;
  handleAttachFile: (file: File) => Promise<string>;
  isOpen: boolean;
  onClose(): void;
}

const validationSchema = object().shape({
  note: string()
    .trim()
    .min(1)
    .required("Note is required"),
  tag: string()
    .trim()
    .required("Tag is required")
});

const AddNoteDialog: FC<AddNoteFormProps> = ({
  handleAddNote,
  showAttachmentsField, // to be used in promotion approval
  showTagsSelect, // to be used in promotion approval
  handleAttachFile,
  isOpen,
  onClose
}) => {
  const handleSubmitNote = async (values: AddNewNoteFormValues) => {
    const attachmentReq: CreatePromotionNoteAttachmentReq[] = [];
    for (const file of values.attachments) {
      // Upload each attachment
      const blobId = await handleAttachFile(file);

      const fileReq: CreatePromotionNoteAttachmentReq = {
        fileName: file.name,
        uploadId: blobId
      };
      attachmentReq.push(fileReq);
    }

    const selectedTag = valuesPromotionNoteTag.find(
      t => t.valueOf() === values.tag
    );

    await handleAddNote({
      note: values.note,
      tag: selectedTag ?? "approval", // In approvals page, tag will be implicitly set to 'approval'
      promotionNoteAttachments: attachmentReq
    });
  };

  return (
    <Formik<AddNewNoteFormValues>
      initialValues={{
        note: "",
        attachments: [],
        tag: ""
      }}
      validationSchema={validationSchema}
      onSubmit={values => handleSubmitNote(values)}
    >
      {({ values, isSubmitting }) => (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
          <DialogTitle>Add new note</DialogTitle>
          <DialogContent>
            <Form id={PROMOTION_NOTE_FORM_ID}>
              <Stack spacing={2}>
                <TextareaInput
                  name={"note"}
                  label="Note"
                  max={160}
                  required
                  value={values.note}
                />
                {showAttachmentsField && (
                  <FileUploadInput
                    name={"attachments"}
                    label={"Attachment (optional)"}
                    multiple={true}
                  />
                )}
                <SelectInput
                  options={tagOptions}
                  name={"tag"}
                  label="Tag"
                  required={showTagsSelect}
                  value={values.tag}
                  placeholder={"Select Tag"}
                />
              </Stack>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button variant={"outlined"} onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant={"contained"}
              type={"submit"}
              form={PROMOTION_NOTE_FORM_ID}
              loading={isSubmitting}
            >
              Add note
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddNoteDialog;
