import { Link, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { HelpIcon } from "../common/icon/icons";

const GetHelp = () => {
  const theme = useTheme();
  return (
    <Link color={"gray.main"}>
      <Stack direction={"row"} alignItems={"center"} gap={theme.spacing(1)}>
        <HelpIcon />
        <Typography variant={"h5"}>Get Help?</Typography>
      </Stack>
    </Link>
  );
};

export default GetHelp;
