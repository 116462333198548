import { Stack, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface TabLabelProps {
  icon?: ReactNode;
  label: string;
}

const TabLabel: FC<TabLabelProps> = ({ label, icon }) => {
  return (
    <Stack direction={"row"} gap={1} alignItems={"center"}>
      {icon}
      <Typography variant={"h5"}>{label}</Typography>
    </Stack>
  );
};

export default TabLabel;
