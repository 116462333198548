import {
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { DbKey, WithDbId } from "@pmp/adl/common/db";
import { Manufacturer } from "@pmp/adl/petstock/merchantportal/db";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";
import React, { useMemo } from "react";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import { Form, Formik } from "formik";
import { array, object, string } from "yup";
import { CreateWorkspaceReq } from "@pmp/adl/petstock/merchantportal/api";
import { CrossIcon } from "src/ui/common/icon/icons";

export interface CreateNewWorkspaceProps {
  open: boolean;
  onClose: () => void;
  onCreateWorkspace: (createWorkspaceReq: CreateWorkspaceReq) => Promise<void>;
  manufacturers: Array<WithDbId<Manufacturer>>;
}

export const validationSchemaCreateWorkspace = object().shape({
  workspaceName: string()
    .min(2)
    .required("Supplier Name is required"),
  supplierCode: string()
    .min(2)
    .required("Supplier Code is required"),
  description: string()
    .trim()
    .max(100, "Supplier Description cannot exceed 100 characters"),
  manufacturerIds: array()
    .min(1, "Manufacturers must have at least 1 item")
    .required("Manufacturers are required")
});

export const CreateNewWorkspace = ({
  open,
  onClose,
  onCreateWorkspace,
  manufacturers
}: CreateNewWorkspaceProps) => {
  interface CreateWorkspaceFormValues {
    workspaceName: string;
    supplierCode: string;
    manufacturerIds: Array<DbKey<Manufacturer>>;
  }
  const createWorkspace = async (values: CreateWorkspaceFormValues) => {
    const { workspaceName, supplierCode, manufacturerIds } = values;
    await onCreateWorkspace({
      name: workspaceName,
      code: supplierCode,
      manufacturerIds
    });
  };

  const manufacturerOptions = useMemo(() => {
    return manufacturers.map(m => {
      return {
        title: m.value.name,
        value: m.id
      };
    });
  }, [manufacturers]);

  return (
    <Modal open={open} onClose={onClose} sx={{ padding: "30px" }}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: theme => theme.shadows[5],
          padding: "30px"
        }}
      >
        <Stack direction="row-reverse">
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h2Bold">
            Create New Supplier Workspace
          </Typography>
          <Typography variant={"subtitle2"}>
            Workspace users will be notified about this space and all updates.
          </Typography>
          <Formik<CreateWorkspaceFormValues>
            initialValues={{
              workspaceName: "",
              supplierCode: "",
              manufacturerIds: []
            }}
            validationSchema={validationSchemaCreateWorkspace}
            onSubmit={createWorkspace}
          >
            {({ isValid }) => (
              <Form>
                <Stack spacing={2}>
                  <TextInput
                    name="workspaceName"
                    label="Supplier Name"
                    required
                    placeholder="Supplier Name"
                  />
                  <TextInput
                    name="supplierCode"
                    label="Supplier Code"
                    required
                    placeholder="Supplier Code"
                  />
                  <AutocompleteSelectInput
                    options={manufacturerOptions}
                    name={"manufacturerIds"}
                    label={"manufacturer"}
                    required
                    multiple
                  />
                  <Button type="submit" disabled={!isValid}>
                    Create Workspace
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Paper>
    </Modal>
  );
};
