import {
  Button,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { DbKey, WithDbId } from "@pmp/adl/common/db";

import {
  PromotionSummary,
  QueryActivityLogResp,
  QueryWorkspacesResp,
  SupplierWorkspaceSummary,
  SupplierWorkspaceUser
} from "@pmp/adl/petstock/merchantportal/api";
import {
  Manufacturer,
  SupplierWorkspace
} from "@pmp/adl/petstock/merchantportal/db";
import SearchInput from "@pmp/common/inputs/search-input/search-input";
import { Loader } from "@pmp/common/loader/loader";
import { PromotionSummaryToPromotionStatus } from "@pmp/utils/adl";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { AppRoutes } from "../../../app/app";
import { useFeatures } from "../../../hooks/useFeatures";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  ExternalLinkIcon,
  ListIcon,
  MoreVerticalIcon,
  PlusIcon,
  SettingIcon
} from "../../common/icon/icons";
import AvatarName from "../../components/AvatarName";
import PromotionStatusLegend from "../../components/PromotionStatus/PromotionStatusLegend";
import PromotionStatusLineChart, {
  PromotionStatusLineChartProps
} from "../../components/PromotionStatus/PromotionStatusLineChart";
import { SubmitListener } from "../../widgets/form/helpers";
import { CreateNewWorkspaceProps } from "./create-new-workspace";
import { WorkspaceActivityLogPageView } from "./workspace-activity-log-page-view";

export interface WorkspaceOverviewPageViewProps {
  loadWorkspaces: (searchTerm: string | null) => Promise<QueryWorkspacesResp>;
  loadWorkspaceActivities: (
    workspaceId: DbKey<SupplierWorkspace>
  ) => Promise<QueryActivityLogResp>;
  CreateWorkspaceModal: React.FC<
    Omit<CreateNewWorkspaceProps, "onCreateWorkspace">
  >;
  manufacturers: Array<WithDbId<Manufacturer>>;
}

interface WorkspaceTableFilter {
  workspaceNameLike: string;
}
export const WorkspaceOverviewPageView = ({
  loadWorkspaces,
  loadWorkspaceActivities,
  CreateWorkspaceModal,
  manufacturers
}: WorkspaceOverviewPageViewProps) => {
  const { isSuperAdmin } = useFeatures();
  const [openCreateWorkspaceModal, setOpenCreateWorkspaceModal] = useState(
    false
  );
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const searchWorkspace = useCallback((values: WorkspaceTableFilter) => {
    const { workspaceNameLike } = values;
    setSearchTerm(workspaceNameLike);
  }, []);

  const queryWorkspaces = useCallback(async () => {
    return await loadWorkspaces(searchTerm);
  }, [searchTerm, loadWorkspaces]);

  const [loadingWorkspaces] = useLoadingDataState(queryWorkspaces);

  const [workspace, setWorkspace] = useState<
    SupplierWorkspaceSummary | undefined
  >(undefined);

  const handleViewActivityLog = (
    selectedWorkspace: SupplierWorkspaceSummary
  ) => {
    setWorkspace(selectedWorkspace);
  };

  const queryWorkspaceActivities = useCallback(async () => {
    if (workspace) {
      return await loadWorkspaceActivities(workspace.id);
    }
    return [];
  }, [loadWorkspaceActivities, workspace]);
  const [loadingActivities] = useLoadingDataState(queryWorkspaceActivities);

  return (
    <Stack spacing={4}>
      <Stack direction={"row"}>
        <Typography variant="h1Bold" marginRight={"auto"}>
          Supplier Workspace Overview
        </Typography>
        {isSuperAdmin() && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() => setOpenCreateWorkspaceModal(true)}
          >
            New Supplier
          </Button>
        )}
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Formik<WorkspaceTableFilter>
          initialValues={{
            workspaceNameLike: ""
          }}
          onSubmit={searchWorkspace}
        >
          <Form>
            <SubmitListener />
            <SearchInput
              name={"workspaceNameLike"}
              placeholder={"search workspace"}
            />
          </Form>
        </Formik>
        <PromotionStatusLegend />
      </Stack>
      <Loader loadingStates={[loadingWorkspaces]}>
        {isLoaded(loadingWorkspaces) && (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: "1px solid"
                    }}
                  >
                    supplier workspace
                  </TableCell>
                  <TableCell>promotion status</TableCell>
                  <TableCell>action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingWorkspaces.value.map(workspace => (
                  <TableRow key={workspace.id}>
                    <WorkspaceDetailsCell
                      name={workspace.name}
                      users={workspace.users}
                    />
                    <PromotionStatusCell
                      promotionSummary={workspace.promotionSummary}
                    />
                    <WorkspaceActionsCell
                      supplierWorkspaceId={workspace.id}
                      supplierWorkspaceSummary={workspace}
                      handleViewActivityLog={handleViewActivityLog}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Loader>
      <CreateWorkspaceModal
        open={openCreateWorkspaceModal}
        onClose={() => setOpenCreateWorkspaceModal(false)}
        manufacturers={manufacturers}
      />
      <WorkspaceActivityLogPageView
        open={workspace !== undefined}
        onClose={() => setWorkspace(undefined)}
        workspace={workspace}
        loadingActivities={loadingActivities}
      />
    </Stack>
  );
};

interface WorkspaceDetailsCellProps {
  name: string;
  users: SupplierWorkspaceUser[];
}

const WorkspaceDetailsCell = ({ name, users }: WorkspaceDetailsCellProps) => {
  return (
    <TableCell
      width={300}
      sx={{
        borderRight: "1px solid"
      }}
    >
      <Stack direction={"row"} alignItems="center">
        <AvatarName name={name} />
        <Stack ml={2} alignItems={"flex-start"}>
          <Typography variant={"h5Bold"}>{name}</Typography>
          <Typography variant={"h5"}>{users.length} Assigned Users</Typography>
        </Stack>
      </Stack>
    </TableCell>
  );
};

interface PromotionStatusCellProps {
  promotionSummary: PromotionSummary;
}

const PromotionStatusCell = ({
  promotionSummary
}: PromotionStatusCellProps) => {
  const promotionStatusFromPromotionSummary = useMemo(() => {
    const groupPromoStatus: PromotionStatusLineChartProps = {
      statuses: [],
      tooltipPrefix: "Promotions in"
    };
    const itemPromoStatus: PromotionStatusLineChartProps = {
      statuses: [],
      tooltipPrefix: "Promotion Items in"
    };
    Object.entries(promotionSummary).map(entry => {
      const key = entry[0] as keyof PromotionSummary;
      groupPromoStatus.statuses.push({
        count: entry[1].promotionGroupsCount,
        status: PromotionSummaryToPromotionStatus(key)
      });
      itemPromoStatus.statuses.push({
        count: entry[1].promotionItemsCount,
        status: PromotionSummaryToPromotionStatus(key)
      });
    });
    return {
      groupPromoStatus,
      itemPromoStatus
    };
  }, [promotionSummary]);

  return (
    <TableCell>
      <Grid container>
        <Grid item xs={1}>
          <Typography>Blocks</Typography>
        </Grid>
        <Grid item xs={11}>
          <PromotionStatusLineChart
            {...promotionStatusFromPromotionSummary.groupPromoStatus}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography>Items</Typography>
        </Grid>
        <Grid item xs={11}>
          <PromotionStatusLineChart
            {...promotionStatusFromPromotionSummary.itemPromoStatus}
          />
        </Grid>
      </Grid>
    </TableCell>
  );
};

interface WorkspaceActionsCellProps {
  supplierWorkspaceId: DbKey<SupplierWorkspace>;
  supplierWorkspaceSummary: SupplierWorkspaceSummary;
  handleViewActivityLog: (
    supplierWorkspaceSummary: SupplierWorkspaceSummary
  ) => void;
}

const WorkspaceActionsCell = ({
  supplierWorkspaceId,
  supplierWorkspaceSummary,
  handleViewActivityLog
}: WorkspaceActionsCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell width={240}>
      <Button
        startIcon={<ExternalLinkIcon />}
        variant={"outlined"}
        href={`${AppRoutes.Workspaces}/${supplierWorkspaceId}`}
      >
        View Supplier
      </Button>
      <IconButton
        aria-label="more"
        id="actions"
        aria-controls={open ? "more-actions" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        id={"more-actions"}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          "aria-labelledby": "actions"
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleViewActivityLog(supplierWorkspaceSummary);
          }}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant={"h5"}>activity log</Typography>{" "}
          </ListItemText>
        </MenuItem>
        <Link
          color={"inherit"}
          underline={"none"}
          href={`${AppRoutes.Workspaces}/${supplierWorkspaceId}/edit`}
        >
          <MenuItem>
            <ListItemIcon>
              <SettingIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant={"h5"}>manage workspace</Typography>{" "}
            </ListItemText>
          </MenuItem>
        </Link>
      </Menu>
    </TableCell>
  );
};
