import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import { Form, Formik } from "formik";
import React from "react";
import { AppRoutes } from "src/app/app";
import { object, string } from "yup";
import { ForgotPasswordReq } from "@pmp/adl/petstock/merchantportal/api";
import GetHelp from "../../components/GetHelp";

export interface ForgotPasswordPageProps {
  /** Error message to show upon request password reset failure */
  forgotPasswordConfirmation?: string;
  /** Callback to request password reset */
  onForgotPassword(req: ForgotPasswordReq): Promise<void>;
}

interface ForgotPasswordFormValues {
  email: string;
}

const validationSchema = object().shape({
  email: string()
    .min(2)
    .required("Email is required")
    .email("Invalid email")
});

/**
 * Landing page of the app.
 */
export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  /** Callback to request forgot password */
  const onForgotPassword = async (values: ForgotPasswordFormValues) => {
    const { email } = values;
    if (email) {
      await props.onForgotPassword({ email });
    }
  };

  /** Render the page */
  return (
    <Container maxWidth={"sm"}>
      <Paper elevation={4}>
        <Stack spacing={4} py={7} px={10}>
          <Box>
            <Typography variant="h1Bold">Forgot your password?</Typography>
            <Typography>
              Please enter the email you use to sign in into Merchant Portal.
            </Typography>
          </Box>
          <Formik<ForgotPasswordFormValues>
            initialValues={{
              email: ""
            }}
            validationSchema={validationSchema}
            onSubmit={onForgotPassword}
          >
            {({ isValid }) => (
              <Form>
                <Stack spacing={4}>
                  {props.forgotPasswordConfirmation && (
                    <Alert severity="success">
                      {props.forgotPasswordConfirmation}
                    </Alert>
                  )}
                  <TextInput
                    name="email"
                    label="Your Email"
                    placeholder="Your Email"
                    type="email"
                    required
                  />
                  <Button fullWidth type="submit" disabled={!isValid}>
                    Request Password Reset
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
          <Button variant={"text"} href={AppRoutes.Login}>
            Back to sign in
          </Button>
        </Stack>
      </Paper>
      <Box position={"absolute"} left={"50%"} bottom={0}>
        <GetHelp />
      </Box>
    </Container>
  );
};
