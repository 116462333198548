import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
  Checkbox,
  Chip,
  FormHelperText,
  Stack,
  TextField
} from "@mui/material";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import {
  SelectOption,
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import { useField } from "formik";
import React, { FC, ReactNode, useMemo, useState } from "react";
import {
  CheckmarkIcon,
  CheckmarkOutlineIcon,
  CrossIcon
} from "src/ui/common/icon/icons";

type Props = {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The list of options.
   */
  options: SelectOptions;
  /**
   * The input label.
   */
  label?: string;
  /**
   * Determines the required ON/OFF state.
   */
  required?: boolean;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * The maximum number of tags that will be visible when not focused.
   * Set `-1` to disable the limit.
   * @default 2 most common case in app.
   */
  limitTags?: number;

  disabled?: boolean;

  multiple?: boolean;

  noOptionsText?: string;
};

const icon = <CheckmarkOutlineIcon />;
const checkedIcon = <CheckmarkIcon />;

export const AutocompleteSelectInput: FC<Props> = props => {
  const {
    helperText,
    name,
    label,
    required,
    options,
    placeholder,
    limitTags,
    disabled,
    multiple,
    noOptionsText
  } = props;
  const [field, meta, helper] = useField(name);
  const { value } = field;

  // used for autocomplete value as it needs the whole option not just the value
  const internalValue = useMemo(() => {
    return options.filter(option => value.some(val => option.value === val));
  }, [options, value]);
  const { setValue, setTouched } = helper;
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);
  // mui Label + Select do not hook with htmlFor from label, so we handle the open/close with this state
  const [open, setOpen] = useState(false);

  const _onChange = (
    _event: React.SyntheticEvent,
    value: AutocompleteValue<SelectOptions, undefined, undefined, undefined>,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<SelectOption> | undefined
  ) => {
    if (value) {
      if (multiple) {
        setValue(value.map(val => val.value));
      } else {
        if (reason === "removeOption") {
          setValue([]);
        } else {
          setValue([details?.option.value]);
        }
      }
    }
  };

  const _onBlur = () => {
    setTouched(true);
  };

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
          onClick={() => setOpen(true)}
          disabled={disabled}
        />
      )}
      <Autocomplete
        multiple
        fullWidth
        disableCloseOnSelect
        clearOnEscape
        limitTags={limitTags || 2}
        id={name}
        options={options}
        noOptionsText={noOptionsText}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={_onChange}
        onBlur={_onBlur}
        value={internalValue}
        getOptionLabel={(option: SelectOption) => option.title}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option: SelectOption, { selected }) => (
          <li {...props} key={option.value}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.item || option.title}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off" // disable autocomplete and autofill
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: null
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            // key provided by getTabProps
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={option.title}
              {...getTagProps({ index })}
              deleteIcon={<CrossIcon />}
              sx={{
                borderRadius: 1,
                fontWeight: "bold"
              }}
            />
          ))
        }
        getLimitTagsText={more => (
          <Chip
            label={`+ ${more}`}
            deleteIcon={<CrossIcon />}
            sx={{
              borderRadius: 1,
              fontWeight: "bold"
            }}
          />
        )}
      />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </Stack>
  );
};

export default AutocompleteSelectInput;
