import { assertNotUndefined } from "@hx/util/types";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { DbKey } from "@pmp/adl/common/db";
import {
  RolePreset,
  SupplierWorkspace
} from "@pmp/adl/petstock/merchantportal/db";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";
import SelectInput, {
  SelectOption,
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { CrossIcon } from "src/ui/common/icon/icons";
import { array, object, string } from "yup";
import {
  InviteWorkspaceUserReq,
  InviteWorkspaceUserResp,
  QueryRolesResp,
  SupplierWorkspaceSummary,
  UserReq
} from "../../../adl-gen/petstock/merchantportal/api";
import { UserType } from "../../../adl-gen/petstock/merchantportal/types";

interface InviteNewUserFormValues {
  selectedUserType: UserType | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: DbKey<RolePreset> | null;
  organisation: string;
  title: string;
  selectedWorkspaces: string[];
}

export const INVITE_NEW_USER_FORM_ID = "invite-new-user-form-id";

const validationSchema = object().shape({
  selectedUserType: string()
    .oneOf(["superAdmin", "admin", "supplierUser"])
    .required("User type is required"),
  firstName: string()
    .trim()
    .required("First name is required"),
  lastName: string()
    .trim()
    .required("Last name is required"),
  email: string()
    .email("Must provide a valid email address")
    .required("Email address is required"),
  phoneNumber: string()
    .matches(/^\d+$/, { message: "Phone number must contain only digits" })
    .required("Phone number is required")
    .min(7, "Phone number must contain at least 7 digits"),
  role: string().when("selectedUserType", {
    is: (type: UserType) => type !== "superAdmin",
    then: string()
      .trim()
      .nullable()
      .required("Role is required"),
    otherwise: string().nullable()
  }),
  organisation: string()
    .trim()
    .required("Organisation is required"),
  title: string()
    .trim()
    .required("Title is required"),
  selectedWorkspaces: array()
    .when("selectedUserType", {
      is: "admin",
      then: array()
        .min(1)
        .required("At least one workspace is required")
    })
    .when("selectedUserType", {
      is: "supplierUser",
      then: array()
        .max(1)
        .min(1)
        .required("One workspace is required")
    })
    .when("selectedUserType", {
      is: "superAdmin",
      then: array().of(object())
    })
});

export interface WorkspaceInviteUserPageProps {
  open: boolean;
  onClose: () => void;
  roles: QueryRolesResp;
  workspaces: SupplierWorkspaceSummary[];
  onInvite(
    inviteWorkspaceUserReq: InviteWorkspaceUserReq
  ): Promise<InviteWorkspaceUserResp>;
}

export const WorkspaceInviteNewUserPageView = (
  props: WorkspaceInviteUserPageProps
) => {
  const onSubmitForm = useCallback(
    (values: InviteNewUserFormValues) => {
      const selectedWorkspaceIdList: Array<DbKey<SupplierWorkspace>> = [];
      const rolePresetId =
        values.selectedUserType !== "superAdmin" ? values.role : null;

      if (values.selectedUserType !== "superAdmin") {
        values.selectedWorkspaces.map(wsSummary => {
          selectedWorkspaceIdList.push(wsSummary);
        });
      }

      const newUserData: UserReq = {
        firstName: assertNotUndefined(values.firstName),
        lastName: assertNotUndefined(values.lastName),
        email: assertNotUndefined(values.email),
        phoneNumber: assertNotUndefined(values.phoneNumber),
        password: "",
        userType: assertNotUndefined(values.selectedUserType),
        rolePresetId,
        organisation: assertNotUndefined(values.organisation),
        title: assertNotUndefined(values.title),
        verifiedAt: null
      };
      return props.onInvite({
        userReq: newUserData,
        workspaces: selectedWorkspaceIdList
      });
    },
    [props]
  );

  const options: SelectOptions = [
    {
      title: "Super Admin",
      value: "superAdmin"
    },
    {
      title: "Admin",
      value: "admin"
    },
    {
      title: "Supplier",
      value: "supplierUser"
    }
  ];

  // Roles are split up below as there is no easy way to indentify which roles are for suppliers and which are for admins
  const supplierRoles = props.roles.filter(
    role =>
      role.value.name === "Supplier Admin" ||
      role.value.name === "Supplier User"
  );
  const supplierRolePresetOptions: SelectOption[] = supplierRoles.map(role => ({
    value: role.id,
    title: role.value.name
  }));

  const adminRolePresetOptions: SelectOption[] = props.roles
    .filter(r => !supplierRoles.some(sr => sr.id === r.id))
    .map(role => ({
      value: role.id,
      title: role.value.name
    }));

  const workspaces: SelectOptions = props.workspaces.map(item => ({
    value: item.id,
    title: item.name
  }));

  /** Render the page */
  return (
    <Modal open={props.open} onClose={props.onClose} sx={{ padding: "30px" }}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 956,
          boxShadow: theme => theme.shadows[5],
          padding: "30px"
        }}
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton onClick={props.onClose}>
            <CrossIcon />
          </IconButton>
        </Box>
        <Stack px="131px">
          <Stack
            alignItems="stretch"
            flexDirection="column"
            justifyContent="center"
            spacing={4}
          >
            <Stack>
              <Typography variant="h2Bold">Invite New User</Typography>
              <Typography variant="h5">
                Invite new users to collaborate in Merchant Portal.{" "}
              </Typography>
            </Stack>
            <Formik<InviteNewUserFormValues>
              initialValues={{
                selectedUserType: "superAdmin",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                role: "",
                organisation: "",
                title: "",
                selectedWorkspaces: []
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmitForm}
              validateOnChange={true}
            >
              {({ values, isValid, isSubmitting }) => (
                <Form id={INVITE_NEW_USER_FORM_ID}>
                  <Stack spacing={4}>
                    <Stack>
                      <SelectInput
                        name="selectedUserType"
                        options={options}
                        label="User Type"
                        required
                      ></SelectInput>
                    </Stack>

                    {values.selectedUserType !== "superAdmin" ? (
                      <Stack>
                        <SelectInput
                          name="role"
                          options={
                            values.selectedUserType === "supplierUser"
                              ? supplierRolePresetOptions
                              : adminRolePresetOptions
                          }
                          label="User Role Preset"
                          required
                        />
                      </Stack>
                    ) : (
                      <>
                        {(values.role = null)}
                        {(values.selectedWorkspaces = [])}
                      </>
                    )}

                    <Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextInput
                            name="firstName"
                            label="First Name"
                            required
                            placeholder="First Name"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            name="lastName"
                            required
                            label="Last Name"
                            placeholder="Last Name"
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextInput
                            name="email"
                            label="Email Address"
                            required
                            placeholder="Email Address"
                            type="email"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            name="phoneNumber"
                            label="Phone Number"
                            required
                            placeholder="Phone Number"
                            type="tel"
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextInput
                            name="organisation"
                            label="Organisation"
                            required
                            placeholder="Organisation"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            name="title"
                            label="Title"
                            required
                            placeholder="Title"
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    {values.selectedUserType !== "superAdmin" && (
                      <Stack>
                        <AutocompleteSelectInput
                          name="selectedWorkspaces"
                          options={workspaces}
                          multiple={values.selectedUserType === "admin"}
                          label={`Assign Workspace${
                            values.selectedUserType === "admin" ? "(s)" : ""
                          }`}
                          required
                          placeholder="Workspaces"
                        />
                      </Stack>
                    )}
                    <LoadingButton
                      fullWidth
                      type="submit"
                      loading={isSubmitting}
                      disabled={!isValid}
                      variant="contained"
                    >
                      Send Invite
                    </LoadingButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
};
