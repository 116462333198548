import {
  MarkNotificationsReq,
  MarkNotificationsResp,
  QueryNotificationsResp
} from "@pmp/adl/petstock/merchantportal/api";
import React, { useCallback } from "react";
import {
  Badge,
  Button,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import { format } from "date-fns";
import { AppRoutes } from "../../../app/app";
import { useHistory } from "react-router-dom";
import { NotificationType } from "@pmp/adl/petstock/merchantportal/db";

export interface NotificationTableProps {
  notifications: QueryNotificationsResp;
  markNotifications: (
    req: MarkNotificationsReq
  ) => Promise<MarkNotificationsResp>;
  isNormalTableView: boolean;
}

const notificationTypeWithReviewNowButton: NotificationType[] = [
  "promotionSubmitted",
  "promotionApprovalRequired",
  "promotionApprovalRequiredImmediate"
];

export const NotificationTable = ({
  notifications,
  markNotifications,
  isNormalTableView
}: NotificationTableProps) => {
  const history = useHistory();

  const handlePromotionReview = useCallback(
    async (
      userNotificationId: string,
      workspaceId: string | undefined,
      promotionId: string | null,
      readAt: number | null
    ) => {
      if (!readAt) {
        await markNotifications({
          userNotificationIds: Array.of(userNotificationId),
          notificationGroup: null
        });
      }

      location.pathname = `${AppRoutes.Promotions}/${promotionId}`;
      history.push(location);
    },
    [history, markNotifications]
  );

  return (
    <Table>
      {notifications.map(notification => (
        <TableRow key={notification.userNotificationId}>
          {!isNormalTableView && !notification.readAt && (
            <TableCell align={"right"}>
              <Badge
                color="error"
                variant="dot"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
              ></Badge>
            </TableCell>
          )}
          <TableCell>
            <Stack>
              <Typography variant="body1">
                {notification.message.title}
              </Typography>
              {notification.message.detail && (
                <Typography variant="body1" style={{ color: "orange" }}>
                  {notification.message.detail &&
                    `${notification.message.detail}`}
                </Typography>
              )}
              {!isNormalTableView &&
                notificationTypeWithReviewNowButton.indexOf(
                  notification.notificationType
                ) !== -1 &&
                notification.workspace &&
                notification.promotionId && (
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handlePromotionReview(
                        notification.userNotificationId,
                        notification.workspace?.id,
                        notification.promotionId,
                        notification.readAt
                      )
                    }
                    style={{ width: "150px" }}
                  >
                    Review Now
                  </Button>
                )}
              <Typography>
                {format(new Date(notification.generatedAt), "MMM dd hh:mm a")}{" "}
                {notification.workspace
                  ? `in ${notification.workspace.name}`
                  : ``}
              </Typography>
            </Stack>
          </TableCell>
          {isNormalTableView && (
            <>
              <TableCell sx={{ width: "25%" }} align={"right"}>
                {notificationTypeWithReviewNowButton.indexOf(
                  notification.notificationType
                ) !== -1 &&
                  notification.workspace &&
                  notification.promotionId && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handlePromotionReview(
                          notification.userNotificationId,
                          notification.workspace?.id,
                          notification.promotionId,
                          notification.readAt
                        )
                      }
                      style={{ width: "150px" }}
                    >
                      Review Now
                    </Button>
                  )}
              </TableCell>
              <TableCell align={"left"}>
                <Badge
                  color="error"
                  variant="dot"
                  invisible={notification.readAt !== null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                ></Badge>
              </TableCell>
            </>
          )}
        </TableRow>
      ))}
    </Table>
  );
};
