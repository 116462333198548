import { Components, Theme } from "@mui/material/styles";

export default function InputLabel(theme: Theme): Components {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          ...theme.typography.h5,
          fontWeight: "bold",
          textTransform: "capitalize"
        },
        asterisk: {
          color: theme.palette.error.main
        }
      }
    }
  };
}
