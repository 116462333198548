import { Components, Theme } from "@mui/material/styles";

export default function Card(theme: Theme): Components {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
          width: "100%",
          "&.autoHeight": {
            height: "auto"
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.gray.lightest
        },
        title: {
          textTransform: "uppercase",
          fontWeight: "bold"
        }
      }
    }
  };
}
