import { assertNotUndefined } from "@hx/util/types";
import { useContext } from "react";

import { LoggedInContext } from "../app/app";

export const useAppService = () => {
  return assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );
};
