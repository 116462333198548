import React, { useContext } from "react";
import { Button, Alert, AlertTitle } from "@mui/material";
import { AlertContext, useAlert } from "../../../../hooks/useAlertContext";

/* TODO: update UI */

export const GlobalAlert = () => {
  const { isShown, title, body, type } = useContext(AlertContext);

  const [, closeAlert] = useAlert();

  return (
    <>
      {isShown && (
        <Alert
          severity={type ?? "error"}
          action={
            <Button color="inherit" size="small" onClick={() => closeAlert()}>
              OK
            </Button>
          }
          sx={{
            position: "fixed",
            zIndex: "tooltip",
            minWidth: "25%",
            top: "5%",
            left: "50%",
            transform: "translateX(-50%)",
            "& .MuiAlert-action": {
              alignItems: "center",
              paddingTop: 0
            }
          }}
        >
          <AlertTitle>{title}</AlertTitle>
          {body}
        </Alert>
      )}
    </>
  );
};
