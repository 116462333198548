import { assertNotUndefined } from "@hx/util/types";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  IconProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Portal,
  Skeleton,
  Stack,
  Theme,
  Toolbar,
  Typography
} from "@mui/material";
import { SxProps } from "@mui/system";
import { AppService } from "@pmp/adl/app-service";

import {
  CreateWorkspaceReq,
  UserProfile
} from "@pmp/adl/petstock/merchantportal/api";
import { UserType } from "@pmp/adl/petstock/merchantportal/types";
import SelectInput, {
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import { Form, Formik } from "formik";
import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFeatures } from "src/hooks/useFeatures";
import { AppRoutes, LoggedInContext } from "../../../app/app";
import { UserSupplierWorkspaces } from "../../../app/identity-state";
import { useAlert } from "../../../hooks/useAlertContext";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DashboardIcon,
  HelpIcon,
  NotificationIcon,
  PromotionIcon,
  SettingIcon,
  UsersIcon,
  WorkspaceIcon
} from "../../common/icon/icons";
import LinkBehavior from "../../common/theme/helpers/LinkBehaviour";
import AvatarName from "../../components/AvatarName";
import { CreateNewWorkspace } from "../../page/workspace-overview/create-new-workspace";
import { SubmitListener } from "../../widgets/form/helpers";

interface NavigationSideBarProps {
  userProfile: UserProfile;
  availableWorkspaces: UserSupplierWorkspaces;
  selectedSupplierWorkspaceId: string | null;
  handleSelectGlobalWorkspace: (workspaceId: string) => void;
}

export const NAVIGATION_SIDE_BAR_WIDTH = 240;

interface SubNavMenuItem {
  route: AppRoutes | "#";
  title: string;
  disabled?: boolean;
  accessList?: Array<UserType>;
}

interface NavMenuItem {
  // predefined routes and # for disabled/unavailable routes
  route: AppRoutes | "#";
  // nav title to display in the navigation
  title: string;
  // determines if the active style should be applied
  isActive: boolean;
  // determines if the nav should be disabled
  disabled?: boolean;
  // Icon to show in the nav
  Icon: React.FC<IconProps>;
  // Determines which user has access to the nav item, undefined for all UserType
  accessList?: Array<UserType>;
  // The sub navs of this item if any
  subNavs?: Array<SubNavMenuItem>;
}

type WorkspaceFormValues = { workspace: string };
export const NavigationSideBar = ({
  userProfile,
  handleSelectGlobalWorkspace,
  availableWorkspaces,
  selectedSupplierWorkspaceId
}: NavigationSideBarProps) => {
  const location = useLocation();
  const history = useHistory();
  const { isSupplier } = useFeatures();

  const [openNavs, setOpenNavs] = useState<Array<string>>([]);
  const [openCreateWorkspaceDialog, setOpenCreateWorkspaceDialog] = useState(
    false
  );

  const service: AppService = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );
  const [showAlert] = useAlert();

  const navMenuItems = useMemo(() => {
    const allNavMenuItems: Array<NavMenuItem> = [
      {
        route: AppRoutes.Index,
        title: "Dashboard",
        isActive: location.pathname === AppRoutes.Index,
        Icon: DashboardIcon
      },
      {
        route: AppRoutes.Promotions,
        title: "Promotion",
        isActive: location.pathname.startsWith(AppRoutes.Promotions),
        Icon: PromotionIcon,
        subNavs: [
          {
            route: AppRoutes.Promotions,
            title: "List View"
          },
          {
            route: AppRoutes.PromotionsTimeline,
            title: "Timeline"
          }
        ]
      },
      {
        route: AppRoutes.TimeSlots,
        title: "Time Slot",
        accessList: ["superAdmin"],
        isActive: location.pathname.startsWith(AppRoutes.TimeSlots),
        Icon: CalendarIcon
      },
      {
        route: AppRoutes.Workspaces,
        title: "Workspace",
        isActive: location.pathname.startsWith(AppRoutes.Workspaces),
        Icon: WorkspaceIcon
      },
      {
        route: AppRoutes.Users,
        title: "Users & roles",
        isActive: location.pathname.startsWith(AppRoutes.Users),
        Icon: UsersIcon,
        accessList: ["superAdmin"]
      },
      {
        route: AppRoutes.Notification,
        title: "Notification",
        isActive: location.pathname.startsWith(AppRoutes.Notification),
        Icon: NotificationIcon,
        subNavs: [
          {
            route: AppRoutes.NotificationHashPromo,
            title: "Promotion"
          },
          {
            route: AppRoutes.NotificationHashGeneral,
            title: "General"
          }
        ]
      },
      {
        route: "#",
        title: "account settings",
        isActive: false,
        Icon: SettingIcon,
        disabled: true
      },
      {
        route: "#",
        title: "help & support",
        isActive: false,
        Icon: HelpIcon,
        disabled: true
      }
    ];

    allNavMenuItems.forEach(
      nav =>
        (nav.subNavs = nav.subNavs?.filter(
          subNav =>
            subNav.accessList === undefined ||
            subNav.accessList.includes(userProfile.userType)
        ))
    );

    return allNavMenuItems.filter(
      nav =>
        nav.accessList === undefined ||
        nav.accessList.includes(userProfile.userType)
    );
  }, [location.pathname, userProfile.userType]);

  const toggleOpenNav = (key: string) => {
    const index = openNavs.indexOf(key);

    if (index === -1) {
      setOpenNavs([...openNavs, key]);
    } else {
      setOpenNavs(openNavs.filter(nav => nav !== key));
    }
  };

  const _handleSelectGlobalWorkspace = useCallback(
    (values: WorkspaceFormValues) => {
      const { workspace } = values;
      handleSelectGlobalWorkspace(workspace);
    },
    [handleSelectGlobalWorkspace]
  );

  /* TODO: Refactor this to Create workspace dialog */
  const handleCreateWorkspace = useCallback(
    async (createWorkspaceReq: CreateWorkspaceReq) => {
      const resp = await service.createWorkspace(createWorkspaceReq);

      if (resp.kind === "success") {
        setOpenCreateWorkspaceDialog(false);
        handleSelectGlobalWorkspace(resp.value);
        history.push(`${AppRoutes.Workspaces}/${resp.value}`);
      } else if (resp.kind === "failNameExists") {
        showAlert({
          title: "Error Creating Workspace",
          body: "A supplier workspace with that name already exists"
        });
      } else if (resp.kind === "failCodeExists") {
        showAlert({
          title: "Error Creating Workspace",
          body: "A supplier workspace with that supplier code already exists"
        });
      }
    },
    [service, handleSelectGlobalWorkspace, history, showAlert]
  );
  const loadManufacturers = useCallback(async () => {
    return await service.queryManufacturers({});
  }, [service]);
  const [loadingManufacturers] = useLoadingDataState(loadManufacturers);

  const workspaceOptions = useMemo(() => {
    const options: SelectOptions = availableWorkspaces.map(w => {
      return {
        title: w.name,
        item: <WorkspaceItem code={w.code} name={w.name} />,
        value: w.id
      };
    });
    return options;
  }, [availableWorkspaces]);

  const isNavOpen = (title: string) => {
    return openNavs.includes(title);
  };

  const hasSubNavs = (navs: Array<SubNavMenuItem> | undefined): boolean => {
    return navs === undefined ? false : navs.length > 0;
  };

  const renderNavMenuItems = () => {
    return navMenuItems.map(value => {
      const { route, title, isActive, Icon, subNavs, disabled } = value;
      return (
        <Fragment key={title}>
          <ListItem>
            <ListItemButton
              // LinkComponent used underneath needs to be defined here instead of `theme/overrides/List.ts`
              // See: https://github.com/mui/material-ui/issues/29030
              component={LinkBehavior}
              selected={isActive}
              href={route}
              disabled={disabled}
              title={`Navigation to ${title}`}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>
                <Typography variant={"h4"}>{title}</Typography>
              </ListItemText>
              {hasSubNavs(subNavs) && (
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleOpenNav(title);
                  }}
                  title={`Open sub items for ${title}`}
                >
                  {isNavOpen(title) || isActive ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )}
                </IconButton>
              )}
            </ListItemButton>
          </ListItem>
          {hasSubNavs(subNavs) && (
            <Collapse
              in={isNavOpen(title) || isActive}
              timeout="auto"
              unmountOnExit
            >
              <List component={"nav"}>
                {subNavs?.map(nav => {
                  return (
                    <ListItem key={nav.title}>
                      <ListItemButton
                        component={LinkBehavior}
                        sx={{ pl: 9 }}
                        href={nav.route}
                        disabled={nav.disabled}
                      >
                        <ListItemText>
                          <Typography variant={"h5"}>{nav.title}</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          )}
        </Fragment>
      );
    });
  };

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      sx={{
        // inline-styles: specific to this nav needing 100% eight/width
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: NAVIGATION_SIDE_BAR_WIDTH,
          backgroundColor: theme => theme.palette.gray.lightest,
          borderRightColor: theme => theme.palette.gray.lighter,
          borderRight: "1px solid"
        }
      }}
    >
      <Toolbar>
        <img
          src={`/assets/logo.png`}
          alt="Merchant Portal Logo"
          loading="lazy"
          width={"132px"}
        />
      </Toolbar>
      <List component={"nav"}>
        <List>{renderNavMenuItems()}</List>
      </List>
      <Box p={1} justifySelf={"flex-end"} mt={"auto"}>
        <Divider variant={"middle"} />
        {availableWorkspaces.length === 0 ? (
          <Skeleton variant="circular" width={32} height={32} />
        ) : isSupplier() ? (
          <WorkspaceItem
            sx={{
              px: 2,
              py: 1
            }}
            code={availableWorkspaces[0].code}
            name={availableWorkspaces[0].name}
          />
        ) : (
          <Formik<WorkspaceFormValues>
            initialValues={{
              workspace: selectedSupplierWorkspaceId ?? ""
            }}
            onSubmit={_handleSelectGlobalWorkspace}
            enableReinitialize
          >
            <Form>
              <SubmitListener />
              <SelectInput
                name={"workspace"}
                placeholder={"All workspaces"}
                options={workspaceOptions}
                createOption={
                  userProfile.userType === "superAdmin"
                    ? {
                        item: <Typography>+ Create Workspace</Typography>,
                        onCreateOption: () => setOpenCreateWorkspaceDialog(true)
                      }
                    : undefined
                }
                sx={{
                  // inline styles remove border
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 }
                }}
              />
            </Form>
          </Formik>
        )}
      </Box>
      <Portal>
        <CreateNewWorkspace
          open={openCreateWorkspaceDialog}
          onClose={() => setOpenCreateWorkspaceDialog(false)}
          onCreateWorkspace={handleCreateWorkspace}
          manufacturers={
            loadingManufacturers.state === "success"
              ? loadingManufacturers.value
              : []
          }
        />
      </Portal>
    </Drawer>
  );
};

const WorkspaceItem: FC<{
  name: string;
  code: string;
  sx?: SxProps<Theme>;
}> = ({ name, code, sx }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} width={"100%"} sx={sx}>
      <AvatarName name={name} size={"small"} />
      <Stack
        ml={1}
        width={"100%"}
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        <Typography variant={"h5"} color={"primary.dark"} noWrap>
          {name}
        </Typography>
        <Typography variant={"h6"} color={"gray.main"}>
          {code}
        </Typography>
      </Stack>
    </Stack>
  );
};
