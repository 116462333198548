import { Theme } from "@mui/material";
import {
  ActivityLogDetails,
  PromotionsGroupBy,
  PromotionSummary
} from "@pmp/adl/petstock/merchantportal/api";
import {
  FundingType,
  PromotionPriority
} from "@pmp/adl/petstock/merchantportal/db";
import {
  ApprovalAction,
  AppUserName,
  FixedPriceDiscountOpts,
  PromotionDurationType,
  PromotionStatus,
  PromotionType,
  PromotionTypeOpts,
  SalePriceDiscountOpts,
  UserType,
  valuesPromotionDurationType
} from "@pmp/adl/petstock/merchantportal/types";
import { SelectOption } from "@pmp/common/inputs/select-input/select-input";

export const promotionTypeToText = (v: PromotionType): string => {
  let s1 = "";

  switch (v.kind) {
    case "salePriceDiscount":
      s1 = s1.concat("Sale Price Discount");
      switch (v.value.kind) {
        case "basic":
          s1 = s1.concat(` / Basic`);
          switch (v.value.value.kind) {
            case "amountOff":
              s1 = s1.concat(" / Amount Off");
              return s1;
            case "percentOff":
              s1 = s1.concat(" / Percent Off");
              return s1;
          }
        case "buybreak":
          s1 = s1.concat(" / Buybreak ($/%)");
          break;
        case "multiBuy":
          s1 = s1.concat(" / Multibuy ($/%)");
          break;
        case "buybreakAward":
          s1 = s1.concat(" / Buybreak ($/%) Award");
          break;
        case "buybreakAwardQty":
          s1 = s1.concat(" / Buybreak ($/%) Award Qty");
          break;
      }
      break;
    case "fixedPriceDiscount":
      s1 = s1.concat("Fixed Price Discount");
      switch (v.value.kind) {
        case "basic":
          s1 = s1.concat(` / Basic`);
          break;
        case "multiBuy":
          s1 = s1.concat(" / Multibuy");
          break;
        case "multiBuyAward":
          s1 = s1.concat(" / Multibuy Award");
          break;
        case "multiBuySet":
          s1 = s1.concat(" / Multibuy Set");
          break;
      }
      break;
  }

  return s1;
};

export type PromotionTypeOptDetails = {
  name: string;
  description: string;
  type: string;
};

export const promotionTypeOptToText = (pt: keyof PromotionTypeOpts): string => {
  let s1 = "";

  switch (pt) {
    case "salePriceDiscount":
      s1 = "Sale Price Discount";
      break;
    case "fixedPriceDiscount":
      s1 = "Fixed Price Discount";
      break;
  }

  return s1;
};

export const promotionTypeOptDescriptionToText = (
  pt: keyof PromotionTypeOpts,
  ptk: keyof SalePriceDiscountOpts | keyof FixedPriceDiscountOpts
): PromotionTypeOptDetails => {
  let s1 = "";
  let s2 = "";
  let s3 = "";

  switch (pt) {
    case "salePriceDiscount":
      s3 = "Sale Price Discount";
      switch (ptk) {
        case "basic":
          s1 = "Basic";
          s2 = "Buy a number of x for % or $ off";
          break;
        case "buybreak":
          s1 = "Buybreak ($/%)";
          s2 = "Buy 1 of x, get a % discount on 1 x";
          break;
        case "multiBuy":
          s1 = "Multibuy ($/%)";
          s2 = "Buy a >number of x for a set discount $/%";
          break;
        case "buybreakAward":
          s1 = "Buybreak ($/%) Award";
          s2 = "Buy a number of x, get a number of x for a set $";
          break;
        case "buybreakAwardQty":
          s1 = "Buybreak ($/%) Award Qty";
          s2 = "Buy a number of x, get a % discount on all x";
          break;
      }
      break;
    case "fixedPriceDiscount":
      s3 = "Fixed Price Discount";
      switch (ptk) {
        case "basic":
          s1 = "Basic";
          s2 = "Buy a number of X at a set $";
          break;
        case "multiBuy":
          s1 = "MultiBuy";
          s2 = "Buy a number of X, get a number of X for a set $";
          break;
        case "multiBuyAward":
          s1 = "Multibuy Award";
          s2 = "Buy a >number of x, get number+ for a $ discount";
          break;
        case "multiBuySet":
          s1 = "Multibuy Set";
          s2 = "Buy a number of x, get a number of x for a set $";
          break;
      }
      break;
  }

  return { name: s1, description: s2, type: s3 };
};

export const fundingTypeToText = (v: FundingType): string => {
  switch (v) {
    case "percentageFormat":
      return "Percentage (%)";
    case "dollarFormat":
      return "Dollar ($)";
  }
};

export const fundingFormatTypeToText = (v: FundingType): string => {
  switch (v) {
    case "percentageFormat":
      return "Scan %";
    case "dollarFormat":
      return "Scan $";
  }
};

export const promotionEventDurationTypeToText = (
  durationType: PromotionDurationType,
  name: string
): string => {
  switch (durationType) {
    case "OnlineTwoDayTactical":
      return "Online (2-day) Tactical";
    case "FourDayTactical":
      return "4 Day Tactical";
    case "SevenDayTactical":
      return "7 Day Tactical";
    case "ElevenDayTactical":
      return "11 Day Promotion";
    case "Month":
      return "Month Long Promotion";
    case "Custom":
      return name;
  }
};

export const promotionPriorityToText = (
  priority: PromotionPriority | null
): string => {
  switch (priority) {
    case "low":
      return "Low Priority";
    case "medium":
      return "Medium Priority";
    case "high":
      return "High Priority";
  }

  return "";
};

const getUserNameFromMap = (
  userMap: Record<string, AppUserName>,
  userId: string
) => {
  const user = userMap[userId];
  return `${user.firstName} ${user.lastName}`;
};

export const activityLogToText = (
  activityDetails: ActivityLogDetails
): string => {
  const userMap = activityDetails.userMap;

  switch (activityDetails.activity.kind) {
    // Existing user is invited to collaborate in the workspace
    case "inviteUser":
      const invitedUserId = activityDetails.activity.value.userId;
      return `Invited ${getUserNameFromMap(
        userMap,
        invitedUserId
      )} into workspace`;
    case "updateWorkspaceUser":
      const updatedUserId = activityDetails.activity.value.userId;
      return `Updated information for ${getUserNameFromMap(
        userMap,
        updatedUserId
      )}`;
    case "archiveUser":
      const archivedUserId = activityDetails.activity.value.userId;
      return `Archived ${getUserNameFromMap(userMap, archivedUserId)}`;
    case "unarchiveUser":
      const unArchivedUserId = activityDetails.activity.value.userId;
      return `Unarchived ${getUserNameFromMap(userMap, unArchivedUserId)}`;
    case "createWorkspace":
      return `Created workspace`;
    case "updateWorkspace":
      return `Edited workspace information`;

    // New user is invited into Merchant Portal and added into a workspace
    case "addWorkspaceUser":
      const addedUserId = activityDetails.activity.value.userId;
      return `Added ${getUserNameFromMap(userMap, addedUserId)} into workspace`;
    case "deleteWorkspaceUser":
      const deletedUserId = activityDetails.activity.value.userId;
      return `Removed ${getUserNameFromMap(
        userMap,
        deletedUserId
      )} from workspace`;

    // Promotion activity logs - these are displayed within a promotion context so there's no need to show promotion name
    case "createPromotion":
      return `Created promotion`;
    case "updatePromotion":
      return `Updated promotion information`;
    case "updatePromotionOwner":
      return `Updated promotion owner`;
    case "updatePromotionEvent":
      return `Updated promotion information`;
    case "submitPromotion":
      return `Submitted promotion`;
    case "unsubmitPromotion":
      return `Unsubmitted promotion`;
    case "approvePromotion":
      return approvalActionToText(activityDetails.activity.value.action);
    case "duplicatePromotion":
      return `Duplicated from ${activityDetails.activity.value.duplicatedPromotionId}`;
    case "addPromotionItems":
      return `Added ${activityDetails.activity.value.productIds.length} promotion items`;
    case "deletePromotionItems":
      return `Deleted ${activityDetails.activity.value.productIds.length} promotion items`;
    case "bulkUpdatePromotionItems":
      return `Bulk updated promotion items`;
    case "addPromotionNote":
      return `Added a note`;
    case "archivePromotionNote":
      return `Archived a note`;
    case "unArchivePromotionNote":
      return `Unarchived a note`;
  }
};

export const approvalActionToText = (
  approvalAction: ApprovalAction
): string => {
  switch (approvalAction) {
    case "approve":
      return `Approved promotion`;
    case "reject":
      return `Rejected promotion`;
    case "renegotiate":
      return `Renegotiated promotion`;
  }

  return "";
};

/**
 * Creates an array of select options for all duration types excluding the options provided
 *
 * @param optionsToExclude A list of durations to exclude
 */
export const promotionEventDurationTypeToOptions = (
  optionsToExclude: PromotionDurationType[]
): Array<SelectOption> => {
  return valuesPromotionDurationType
    .filter(option => !optionsToExclude.includes(option))
    .map(option => {
      return {
        value: option,
        title: promotionEventDurationTypeToText(option, "")
      };
    });
};

export const formatAppUserName = (name: AppUserName): string => {
  return `${name.firstName} ${name.lastName}`;
};

export type UILabelColor = {
  label: string;
  color: string;
};

export const promotionStatusToUILabelColor = (
  promotionStatus: PromotionStatus,
  theme: Theme
): UILabelColor => {
  switch (promotionStatus) {
    case "draft":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "submitted":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "underReview":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "completed":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "rejected":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "renegotiation":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "scheduled":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
    case "archived":
      return {
        label: promotionStatusToText(promotionStatus),
        color: promotionStatusToUIColor(promotionStatus, theme)
      };
  }
};

export const promotionStatusToUIColor = (
  promotionStatus: PromotionStatus,
  theme: Theme
): string => {
  switch (promotionStatus) {
    case "draft":
      return theme.palette.gray.light;
    case "submitted":
      return theme.palette.system.purple;
    case "underReview":
      return theme.palette.info.main;
    case "completed":
      return theme.palette.success.main;
    case "rejected":
      return theme.palette.error.main;
    case "renegotiation":
      return theme.palette.warning.main;
    case "scheduled":
      return theme.palette.error.dark;
    case "archived":
      return theme.palette.primary.dark;
  }
};
export const promotionStatusToText = (
  promotionStatus: PromotionStatus
): string => {
  switch (promotionStatus) {
    case "draft":
      return "Draft";
    case "submitted":
      return "Submitted";
    case "underReview":
      return "Review";
    case "completed":
      return "Accepted";
    case "rejected":
      return "Rejected";
    case "renegotiation":
      return "Amendment";
    case "scheduled":
      return "Scheduled";
    case "archived":
      return "Archived";
  }
};

type PromotionSummaryStatus = keyof PromotionSummary;
export const PromotionSummaryToPromotionStatus = (
  pss: PromotionSummaryStatus
): PromotionStatus => {
  switch (pss) {
    case "draft":
      return "draft";
    case "submitted":
      return "submitted";
    case "inReview":
      return "underReview";
    case "renegotiation":
      return "renegotiation";
    case "rejected":
    case "claims": // unknown PS
      return "rejected";
    case "approved":
      return "completed";
    case "archived":
      return "archived";
  }
};

export const promotionDiscountToText = (pd: string): string => {
  switch (pd) {
    case "amountOff":
      return "$ Discount";
    case "percentOff":
      return "% Discount";
  }

  return "";
};

export const userTypeToText = (ut: UserType): string => {
  switch (ut) {
    case "superAdmin":
      return "Super Admin";
    case "admin":
      return "Admin";
    case "supplierUser":
      return "Supplier User";
  }
};

export const promotionsGroupByToText = (pgBy: PromotionsGroupBy): string => {
  switch (pgBy) {
    case "durationType":
      return "Duration Type";
    case "workspace":
      return "Workspace";
  }
};
