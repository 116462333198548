import { assertNever } from "@hx/util/types";
import { DbKey } from "@pmp/adl/common/db";
import {
  CreatePromotionReq,
  FindProductsReq
} from "@pmp/adl/petstock/merchantportal/api";
import { AppUser } from "@pmp/adl/petstock/merchantportal/db";
import { Loader } from "@pmp/common/loader/loader";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import React, { FC, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../app/app";
import { useAlert } from "../../../../hooks/useAlertContext";
import { useAppService } from "../../../../hooks/useAppService";
import { useLoadingDataState } from "../../../../hooks/useLoadingData";
import { CreateNewPromotionView } from "./create-new-promotion-view";

interface CreateNewPromotionPageProps {
  selectedWorkspaceId: string;
}

export const CreateNewPromotionPage: FC<CreateNewPromotionPageProps> = ({
  selectedWorkspaceId
}) => {
  const service = useAppService();
  const history = useHistory();
  const [showAlert] = useAlert();

  useEffect(() => {
    // navigating without a WS_ID is not possible in the app
    // adding this in case users type url in browser
    const notifyNoWorkspaceSelected = async () => {
      await showAlert({
        title: "Please select a workspace from the navigation selector",
        body: "",
        type: "warning"
      });
    };
    if (!selectedWorkspaceId) {
      notifyNoWorkspaceSelected().then();
      history.push(AppRoutes.Promotions);
    }
  }, [history, selectedWorkspaceId, showAlert]);

  const loadPromotionEvents = useCallback(async () => {
    return await service.queryPromotionEvents({
      promotionEventId: null,
      queryAllAfter: Date.now(),
      queryByMonth: null
    });
  }, [service]);
  const [loadingPromotionEvents] = useLoadingDataState(loadPromotionEvents);

  const loadCommonProductDetails = useCallback(async () => {
    return await service.findCommonProductDetailsByWorkspace({
      supplierWorkspaceId: selectedWorkspaceId
    });
  }, [service, selectedWorkspaceId]);

  const [loadingCommonProductDetails] = useLoadingDataState(
    loadCommonProductDetails
  );

  const handleCreatePromotion = useCallback(
    async (
      req: Omit<CreatePromotionReq, "supplierWorkspaceId">,
      owner: DbKey<AppUser> | undefined,
      saveAndSubmit: boolean
    ) => {
      const resp = await service.createPromotion({
        ...req,
        supplierWorkspaceId: selectedWorkspaceId
      });

      switch (resp.kind) {
        case "success":
          if (saveAndSubmit) {
            if (owner !== undefined) {
              const resp2 = await service.updatePromotionOwner({
                promotionId: resp.value,
                userId: owner
              });
              switch (resp2) {
                case "failPromotionDoesNotExist":
                  await showAlert({
                    title: "Error Assigning Owner to Promotion",
                    body: "Promotion no longer exists"
                  });
                case "failUserDoesNotExist":
                  await showAlert({
                    title: "Error Assigning Owner to Promotion",
                    body: "User no longer exists"
                  });
                case "failInvalidUser":
                  await showAlert({
                    title: "Error Assigning Owner to Promotion",
                    body: "Chosen user is not a valid owner"
                  });
              }
            }
            const resp3 = await service.submitPromotion({
              promotionId: resp.value
            });
            switch (resp3) {
              case "failPromotionDoesNotExist":
                await showAlert({
                  title: "Error Submitting Promotion",
                  body: "Promotion no longer exists"
                });
              case "failAlreadySubmitted":
                await showAlert({
                  title: "Error Submitting Promotion",
                  body: "Promotion already submitted"
                });
              case "failInvalidOwner":
                await showAlert({
                  title: "Error Submitting Promotion",
                  body: "Invalid owner"
                });
              case "failNoPromotionItems":
                await showAlert({
                  title: "Error Submitting Promotion",
                  body: "No promotion items added"
                });
              case "failPromotionInvalid":
                await showAlert({
                  title: "Error Submitting Promotion",
                  body: "Invalid promotion"
                });
            }
          }
          history.push(`./${resp.value}`);
          break;
        case "failInvalidPromotionEvent":
          await showAlert({
            title: "Error Creating Promotion",
            body:
              "Unable to create a promotion for the provided promotion event"
          });
          break;
        case "failInvalidSupplierWorkspace":
          await showAlert({
            title: "Error Creating Promotion",
            body:
              "Unable to create a promotion for the provided supplier workspace"
          });
          break;
        case "failInvalidPromotionItemType":
          await showAlert({
            title: "Error Creating Promotion",
            body:
              "Unable to create promotion because the promotion items are invalid"
          });
          break;
        default:
          assertNever(resp);
      }
    },
    [service, selectedWorkspaceId, history, showAlert]
  );

  const loadWorkspaceUsers = useCallback(async () => {
    return await service.queryUsers({
      supplierWorkspaceId: selectedWorkspaceId,
      userId: null,
      searchQuery: null
    });
  }, [selectedWorkspaceId, service]);

  const [loadingWorkspaceUsers] = useLoadingDataState(loadWorkspaceUsers);

  const findProducts = useCallback(
    async (req: FindProductsReq) => {
      return await service.findProducts(req);
    },
    [service]
  );

  return (
    <Loader loadingStates={[loadingPromotionEvents, loadingWorkspaceUsers]}>
      {isLoaded(loadingPromotionEvents) && isLoaded(loadingWorkspaceUsers) && (
        <CreateNewPromotionView
          promotionEvents={loadingPromotionEvents.value}
          onCreatePromotion={handleCreatePromotion}
          onFindProducts={findProducts}
          workspaceUsers={loadingWorkspaceUsers.value}
          workspaceId={selectedWorkspaceId}
          brands={
            loadingCommonProductDetails.state === "success"
              ? loadingCommonProductDetails.value.brands
              : []
          }
          classes={
            loadingCommonProductDetails.state === "success"
              ? loadingCommonProductDetails.value.classes
              : []
          }
          manufacturers={
            loadingCommonProductDetails.state === "success"
              ? loadingCommonProductDetails.value.manufacturers
              : []
          }
          groups={
            loadingCommonProductDetails.state === "success"
              ? loadingCommonProductDetails.value.groups
              : []
          }
        />
      )}
    </Loader>
  );
};
