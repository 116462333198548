import { Stack, Typography } from "@mui/material";

import { UiConfig } from "@pmp/adl/petstock/merchantportal/uiconfig";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPage from "../ui/page/dashboard/DashboardPage";
import { PromotionEventListPage } from "../ui/page/promotion-event/promotion-event-list-page";
import { UserManagementPage } from "src/ui/page/user-management/user-management-page";
import { ApiServices } from "../service/api-services";
import { LoginRedirect } from "../ui/common/login-redirect";
import { LoggedInPageLayout } from "../ui/layouts/logged-in-page-layout/logged-in-page-layout";
import { PublicPageLayout } from "../ui/layouts/public-page-layout/public-page-layout";
import { ChangePasswordPage } from "../ui/page/forgot-password/change-your-password-page";
import { ForgotPasswordPage } from "../ui/page/forgot-password/forgot-your-password-page";
import { LoginPage } from "../ui/page/login-page/login-page";
import { ManageWorkspacePage } from "../ui/page/manage-workspace/manage-workspace-page";
import { NotificationPage } from "../ui/page/notification/notification-page";
import { CreateNewPromotionPage } from "../ui/page/promotion/create-new-promotion/create-new-promotion-page";
import { PromotionOverviewPage } from "../ui/page/promotion/promotion-overview/promotion-overview-page";
import { PromotionPage } from "../ui/page/promotion/promotion-page";
import { EditUserPage } from "../ui/page/user-management/edit-user-page";
import { VerifyAccountPage } from "../ui/page/verify-account-page/verify-account-page";
import { WorkspaceOverviewPage } from "../ui/page/workspace-overview/workspace-overview-page";
import { WorkspacePage } from "../ui/page/workspace/workspace-page";
import { LoginState, useLoginState } from "./identity-state";
import { LicenseInfo } from "@mui/x-license-pro";

export interface LoggedInContextProps {
  loginState?: LoginState;
}

export const LoggedInContext = React.createContext<LoggedInContextProps>({});

LicenseInfo.setLicenseKey(
  "830c7fe3bea13e0a8d2f8ec827fca778Tz01MzU1MixFPTE2OTg5ODUzODg5MzMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

interface AppProps {
  /** UI configuration */
  uiconfig: UiConfig;
  createApiServices(token?: string): ApiServices;
}

/** All routes in the app */
export enum AppRoutes {
  Admin = "/admin",
  ChangePassword = "/change-password",
  ForgotPassword = "/forgot-password",
  Index = "/",
  Login = "/login",
  Notification = "/notification",
  NotificationHashGeneral = "/notification#general",
  NotificationHashPromo = "/notification#promotion",
  Promotions = "/promotions",
  PromotionsTimeline = "/promotions#timeline",
  PromotionsCreate = "/promotions/create",
  TimeSlots = "/time-slots",
  Users = "/users",
  Verify = "/verify",
  Workspaces = "/workspaces"
}

export const App = (props: AppProps) => {
  const loginState = useLoginState({
    createApiServices: props.createApiServices
  });

  return (
    <>
      {loginState.userProfileIsLoading ? (
        <div>Loading..</div>
      ) : (
        <LoggedInContext.Provider
          value={{
            loginState
          }}
        >
          {loginState.user === undefined ? (
            <PublicPageLayout>
              <Switch>
                <Route path={AppRoutes.Login}>
                  <LoginPage
                    loginError={loginState.loginError}
                    onLogin={loginState.onLogin}
                  />
                </Route>
                <Route path={AppRoutes.Verify}>
                  <VerifyAccountPage
                    verifyAccountError={loginState.verifyAccountError}
                    onVerify={loginState.onVerifyAccount}
                  />
                </Route>
                <Route path={AppRoutes.ForgotPassword}>
                  <ForgotPasswordPage
                    forgotPasswordConfirmation={
                      loginState.forgotPasswordConfirmation
                    }
                    onForgotPassword={loginState.onForgotPassword}
                  />
                </Route>
                <Route path={AppRoutes.ChangePassword}>
                  <ChangePasswordPage
                    changePasswordError={loginState.changePasswordError}
                    onChangePassword={loginState.onChangePassword}
                  />
                </Route>
                <Route>
                  <LoginRedirect />
                </Route>
              </Switch>
            </PublicPageLayout>
          ) : (
            <LoggedInPageLayout
              userProfile={loginState.user?.profile}
              onLogout={loginState.onLogout}
              hasUnreadNotifications={loginState.hasUnreadNotifications}
              handleSelectGlobalWorkspace={loginState.setGlobalWorkspace}
              userWorkspaces={loginState.userSupplierWorkspaces}
              selectedSupplierWorkspaceId={
                loginState.selectedSupplierWorkspaceId ?? ""
              }
            >
              <Switch>
                <Route path={AppRoutes.PromotionsCreate}>
                  <CreateNewPromotionPage
                    selectedWorkspaceId={
                      loginState.selectedSupplierWorkspaceId ?? ""
                    }
                  />
                </Route>
                <Route path={`${AppRoutes.Promotions}/:promotionId`}>
                  <PromotionPage
                    selectedWorkspaceId={
                      loginState.selectedSupplierWorkspaceId ?? ""
                    }
                    handleSelectGlobalWorkspace={loginState.setGlobalWorkspace}
                  />
                </Route>
                <Route path={AppRoutes.Promotions}>
                  <PromotionOverviewPage
                    selectedWorkspaceId={loginState.selectedSupplierWorkspaceId}
                  />
                </Route>
                <Route path={`${AppRoutes.TimeSlots}`}>
                  <PromotionEventListPage />
                </Route>
                <Route path={`${AppRoutes.Workspaces}/:workspaceId/edit`}>
                  <ManageWorkspacePage />
                </Route>
                <Route path={`${AppRoutes.Workspaces}/:workspaceId`}>
                  <WorkspacePage />
                </Route>
                <Route path={AppRoutes.Workspaces}>
                  {loginState.selectedSupplierWorkspaceId && (
                    <Redirect
                      path={`${AppRoutes.Workspaces}/:workspaceId`}
                      to={`${AppRoutes.Workspaces}/${loginState.selectedSupplierWorkspaceId}`}
                    />
                  )}
                  <WorkspaceOverviewPage />
                </Route>
                <Route path={`${AppRoutes.Users}/:userId/edit`}>
                  <EditUserPage />
                </Route>
                <Route path={AppRoutes.Users}>
                  <UserManagementPage />
                </Route>
                <Route path={`${AppRoutes.Notification}`}>
                  <NotificationPage />
                </Route>
                <Route exact path={AppRoutes.Index}>
                  <DashboardPage
                    workspaceId={loginState.selectedSupplierWorkspaceId ?? null}
                  />
                </Route>
                <Route>
                  <Stack>
                    <Typography variant="h2">Page Not Found</Typography>
                  </Stack>
                </Route>
              </Switch>
            </LoggedInPageLayout>
          )}
        </LoggedInContext.Provider>
      )}
    </>
  );
};
