import { LoadingValue } from "../utils/UtilityTypes";
import { useCallback, useEffect, useState } from "react";

type LoadingValuePromise<T> = () => Promise<T>;

export const useLoadingDataState = <T>(
  fn: LoadingValuePromise<T>
): [LoadingValue<T>, () => void] => {
  const [loadingValue, setLoadingValue] = useState<LoadingValue<T>>({
    state: "loading"
  });

  const fetchFn = useCallback(() => {
    setLoadingValue({ state: "loading" });
    fn()
      .then(value => {
        setLoadingValue({ state: "success", value });
      })
      .catch(err => {
        setLoadingValue({ state: "error", error: err });
      });
  }, [fn]);

  useEffect(() => {
    fetchFn();
  }, [fetchFn]);

  return [loadingValue, fetchFn];
};
