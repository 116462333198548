import { DbKey } from "@pmp/adl/common/db";
import {
  ArchivePromotionNoteReq,
  CreatePromotionNoteReq,
  GetDownloadLinkReq,
  PromotionDetailsSummary,
  PromotionNoteSummary,
  QueryPromotionNotesReq,
  UpdateAdminOnlyFieldsReq,
  UpdatePromotionOwnerReq,
  UpdatePromotionReq
} from "@pmp/adl/petstock/merchantportal/api";
import { Promotion } from "@pmp/adl/petstock/merchantportal/db";
import { Loader } from "@pmp/common/loader/loader";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import React, { FC, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "src/hooks/useAlertContext";
import { useLoadingDataState } from "src/hooks/useLoadingData";
import { useAppService } from "../../../hooks/useAppService";
import { useAttachments } from "../../../hooks/useAttachments";
import { PromotionPageView } from "./promotion-page-view";

interface PromotionPageProps {
  selectedWorkspaceId: string | null;
  handleSelectGlobalWorkspace: (workspaceId: string) => void;
}

export const PromotionPage: FC<PromotionPageProps> = ({
  selectedWorkspaceId,
  handleSelectGlobalWorkspace
}) => {
  const service = useAppService();
  const { attachFile } = useAttachments();
  const [showAlert] = useAlert();

  const { promotionId } = useParams<{
    promotionId: DbKey<Promotion>;
  }>();

  const loadPromotion = useCallback(async () => {
    return await service.queryPromotions({
      promotionId,
      promotionFilter: null
    });
  }, [service, promotionId]);
  const [loadingPromotion, refreshPromotion] = useLoadingDataState(
    loadPromotion
  );

  useEffect(() => {
    if (!selectedWorkspaceId) {
      if (isLoaded(loadingPromotion)) {
        handleSelectGlobalWorkspace(
          loadingPromotion.value[0].supplierWorkspaceId
        );
      }
    }
  }, [handleSelectGlobalWorkspace, loadingPromotion, selectedWorkspaceId]);

  const loadActivityLogs = useCallback(
    async (promotionId: DbKey<PromotionDetailsSummary>) => {
      return await service.queryActivityLogs({
        supplierWorkspaceId: selectedWorkspaceId,
        promotionId: promotionId
      });
    },
    [service, selectedWorkspaceId]
  );

  const loadPromotionEvents = useCallback(async () => {
    return await service.queryPromotionEvents({
      promotionEventId: null,
      queryAllAfter: new Date().getTime(),
      queryByMonth: null
    });
  }, [service]);
  const [loadingPromotionEvents] = useLoadingDataState(loadPromotionEvents);

  const loadCommonProductDetails = useCallback(async () => {
    return await service.findCommonProductDetailsByWorkspace({
      supplierWorkspaceId: selectedWorkspaceId
    });
  }, [service, selectedWorkspaceId]);

  const [loadingCommonProductDetails] = useLoadingDataState(
    loadCommonProductDetails
  );

  const archiveNote = useCallback(
    (note: PromotionNoteSummary) => {
      const req: ArchivePromotionNoteReq = {
        promotionNoteId: note.id,
        promotionId: note.promotionId,
        archiveNote: !note.isArchived
      };

      return service.archivePromotionNote(req);
    },
    [service]
  );

  const queryPromotionNotes = useCallback(
    async (req: QueryPromotionNotesReq) => {
      return await service.queryPromotionNotes({
        promotionId: req.promotionId,
        tags: req.tags,
        showArchivedNotes: req.showArchivedNotes
      });
    },
    [service]
  );

  const createNote = useCallback(
    (req: CreatePromotionNoteReq) => {
      return service.createPromotionNote(req);
    },
    [service]
  );

  const updatePromotion = useCallback(
    async (req: UpdatePromotionReq) => {
      const resp = await service.updatePromotion(req);

      switch (resp) {
        case "success":
          showAlert({
            title: "Promotion Updated",
            body: "Successfully updated promotion details",
            type: "success"
          });
          refreshPromotion();
          break;
        case "failPromotionNotDraft":
          showAlert({
            title: "Unable to update promotion",
            body: "Promotion cannot be updated when not in draft"
          });
          break;
        default:
          showAlert({
            title: "Unable to update promotion",
            body:
              "An error occurred while updating the promotion, please try again"
          });
          break;
      }
    },
    [service, refreshPromotion, showAlert]
  );

  const updatePromotionOwner = useCallback(
    async (req: UpdatePromotionOwnerReq) => {
      const resp = await service.updatePromotionOwner(req);

      switch (resp) {
        case "success":
          showAlert({
            title: "Promotion Owner Updated",
            body: "Successfully updated promotion owner",
            type: "success"
          });
          refreshPromotion();
          break;
        case "failInvalidUser":
        case "failUserDoesNotExist":
          showAlert({
            title: "Unable to update promotion owner",
            body: "The selected user is invalid"
          });
          break;
        default:
          showAlert({
            title: "Unable to update promotion owner",
            body:
              "An error occurred while updating the promotion owner, please try again"
          });
          break;
      }
    },
    [refreshPromotion, service, showAlert]
  );

  const updateAdminOnlyFields = useCallback(
    async (req: UpdateAdminOnlyFieldsReq) => {
      const resp = await service.updateAdminOnlyFields(req);

      switch (resp) {
        case "success":
          showAlert({
            title: "Promotion Updated",
            body: "Successfully updated promotion details",
            type: "success"
          });
          refreshPromotion();
          break;
        case "failInvalidPromotionStatus":
          showAlert({
            title: "Unable to update promotion",
            body: "Promotion cannot be updated for the current promotion status"
          });
          break;
        default:
          showAlert({
            title: "Unable to update promotion",
            body:
              "An error occurred while updating the promotion, please try again"
          });
          break;
      }
    },
    [service, refreshPromotion, showAlert]
  );

  const submitPromotion = useCallback(async () => {
    const resp = await service.submitPromotion({ promotionId });

    switch (resp) {
      case "success":
        showAlert({
          title:
            "This promotion has been submitted. You can unsubmit & make changes before Petspiration staff start reviewing it.",
          body: "",
          type: "success"
        });
        refreshPromotion();
        break;
      case "failAlreadySubmitted":
        showAlert({
          title: "Unable to submit promotion",
          body: "Promotion is already submitted"
        });
        refreshPromotion();
        break;
      case "failInvalidOwner":
        showAlert({
          title: "Unable to submit promotion",
          body: "The owner field is empty or the current owner is invalid"
        });
        break;
      case "failNoPromotionItems":
        showAlert({
          title: "Unable to submit promotion",
          body: "No promotion items have been added to the promotion"
        });
        break;
      case "failPromotionInvalid":
        showAlert({
          title: "Unable to submit promotion",
          body:
            "Promotion details are invalid. Please update the promotion details and try again."
        });
        break;
      default:
        showAlert({
          title: "Unable to submit promotion",
          body:
            "An error occurred while submitting the promotion, please try again"
        });
        break;
    }
  }, [promotionId, refreshPromotion, service, showAlert]);

  const unsubmitPromotion = useCallback(async () => {
    const resp = await service.unsubmitPromotion({ promotionId });

    switch (resp) {
      case "success":
        showAlert({
          title:
            "This promotion is now in Draft. You can continue making edits.",
          body: "",
          type: "success"
        });
        refreshPromotion();
        break;
      case "failPromotionUnderReview":
        showAlert({
          title: "Unable to unsubmit promotion",
          body: "Promotion cannot be unsubmitted when under review"
        });
        break;
      default:
        showAlert({
          title: "Unable to unsubmit promotion",
          body:
            "An error occurred while unsubmitting the promotion, please try again"
        });
        break;
    }
  }, [promotionId, refreshPromotion, service, showAlert]);

  const downloadFile = useCallback(
    async (req: GetDownloadLinkReq): Promise<string | null> => {
      // Get URL to upload a file
      const resp = await service.getDownloadLink(req);
      if (resp.kind === "success") {
        return resp.value;
      } else {
        await showAlert({
          title: "Unable to download file",
          body:
            "An error occurred while downloading the note attachment, please try again."
        });
        return null;
      }
    },
    [service, showAlert]
  );

  return (
    <Loader loadingStates={[loadingPromotion, loadingPromotionEvents]}>
      {selectedWorkspaceId &&
        isLoaded(loadingPromotion) &&
        isLoaded(loadingPromotionEvents) && (
          <PromotionPageView
            promotion={loadingPromotion.value[0]}
            loadActivityLogs={loadActivityLogs}
            queryPromotionNotes={queryPromotionNotes}
            archiveNote={archiveNote}
            createNote={createNote}
            promotionEvents={loadingPromotionEvents.value}
            updatePromotion={updatePromotion}
            updatePromotionOwner={updatePromotionOwner}
            updateAdminOnlyFields={updateAdminOnlyFields}
            submitPromotion={submitPromotion}
            unsubmitPromotion={unsubmitPromotion}
            attachFile={attachFile}
            downloadFile={downloadFile}
            refreshPromotion={refreshPromotion}
            brands={
              loadingCommonProductDetails.state === "success"
                ? loadingCommonProductDetails.value.brands
                : []
            }
            classes={
              loadingCommonProductDetails.state === "success"
                ? loadingCommonProductDetails.value.classes
                : []
            }
            manufacturers={
              loadingCommonProductDetails.state === "success"
                ? loadingCommonProductDetails.value.manufacturers
                : []
            }
            groups={
              loadingCommonProductDetails.state === "success"
                ? loadingCommonProductDetails.value.groups
                : []
            }
            workspaceId={selectedWorkspaceId}
          />
        )}
    </Loader>
  );
};
