import { Components, Theme } from "@mui/material/styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Avatar(theme: Theme): Components {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: "bold",
          backgroundColor: "inherit"
        }
      }
    }
  };
}
