import { assertNotUndefined } from "@hx/util/types";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import { DbKey, WithDbId } from "@pmp/adl/common/db";
import {
  CreatePromotionReq,
  FindProductsReq,
  FindProductsResp,
  PromotionProductDetails,
  QueryProductsFilter,
  QueryUsersResp,
  UserDetails
} from "@pmp/adl/petstock/merchantportal/api";
import {
  AppUser,
  CostUsed,
  FundingType,
  Product,
  PromotionEvent,
  valuesCostUsed,
  valuesFundingType
} from "@pmp/adl/petstock/merchantportal/db";
import {
  FixedPriceDiscountOpts,
  makeMultibuy,
  makePromotionDiscount,
  PromotionDiscount,
  PromotionDiscountOpts,
  PromotionItemType,
  PromotionType,
  PromotionTypeOpts,
  SalePriceDiscountOpts
} from "@pmp/adl/petstock/merchantportal/types";
import NumberInput from "@pmp/common/inputs/number-input/number-input";
import SelectInput, {
  SelectOption
} from "@pmp/common/inputs/select-input/select-input";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import TextareaInput from "@pmp/common/inputs/textarea-input/textarea-input";
import NumberWithSelectInput from "@pmp/common/inputs/number-with-select-input/number-with-select-input";
import {
  fundingFormatTypeToText,
  promotionDiscountToText,
  promotionEventDurationTypeToText,
  promotionTypeOptDescriptionToText,
  promotionTypeOptToText
} from "@pmp/utils/adl";
import { formatPlural } from "@pmp/utils/strings";
import { format } from "date-fns";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useMemo, useState, useRef, FC } from "react";
import { UploadIcon } from "src/ui/common/icon/icons";
import { AddProductsDialog } from "src/ui/widgets/add-products-dialog/add-products-dialog";
import { array, mixed, number, object, string } from "yup";
import { useFeatures } from "../../../../hooks/useFeatures";
import { EditIcon } from "../../../common/icon/icons";
import ActionsDrawer from "../../../components/ActionsDrawer";
import { BarcodeUploadDialog } from "../../../widgets/barcode-upload-dialog/barcode-upload-dialog";
import { BigDecimal } from "@pmp/adl/common";
import { useAppService } from "src/hooks/useAppService";
import {
  formatDateMonthDayYearWithTZ,
  formatDateMonthYearShortWithTZ
} from "@pmp/utils/dates";
import _ from "lodash";

const MONTH_FORMAT = "MMM yyyy";

type PromotionEventData = WithDbId<
  Pick<PromotionEvent, "durationType" | "startDate" | "endDate" | "name">
>;

export interface CreateNewPromotionViewProps {
  promotionEvents: PromotionEventData[];
  onCreatePromotion: (
    req: Omit<CreatePromotionReq, "supplierWorkspaceId">,
    owner: DbKey<AppUser> | undefined,
    saveAndSubmit: boolean
  ) => Promise<void>;
  onFindProducts: (req: FindProductsReq) => Promise<FindProductsResp>;
  workspaceUsers: QueryUsersResp;
  workspaceId: string;
  brands: string[];
  classes: string[];
  manufacturers: string[];
  groups: string[];
  // TODO (eric): Add create and submit promotion
}

interface CreateNewPromotionForm {
  name: string;
  promotionEventId: DbKey<PromotionEvent> | null;
  description: string;
  header: string;
  month: string | undefined;
  psfDono: number | undefined;
  qualifyAmount: number | undefined;
  promotionAmount: number | undefined;
  promotionPrice: number | undefined;
  cost: string | undefined;
  scanValue: number | undefined;
  scanFormat: string | undefined;
  durationType: string | undefined;
  promotionType: string | undefined;
  promotionDiscount: keyof PromotionDiscountOpts | undefined;
  promotionDiscountValue: number | undefined;
  items: DbKey<Product>[];
  qualifiedItems: DbKey<Product>[];
  awardItems: DbKey<Product>[];
}

type PromotionTypeOption = [
  keyof PromotionTypeOpts,
  keyof SalePriceDiscountOpts | keyof FixedPriceDiscountOpts
];

export type ItemSelectionSource = "itemSearch" | "barcodeScan";

export const CreateNewPromotionView = ({
  promotionEvents,
  onCreatePromotion,
  onFindProducts,
  workspaceUsers,
  workspaceId,
  brands,
  classes,
  manufacturers,
  groups
}: CreateNewPromotionViewProps) => {
  const { isSuperAdmin, isSupplier } = useFeatures();

  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [openBarcodeUploadDialog, setOpenBarcodeUploadDialog] = useState(false);
  const [openAddOwnerDialog, setOpenAddOwnerDialog] = useState(false);
  const owner = useRef<DbKey<AppUser> | undefined>(undefined);
  const saveAndSubmitPromo = useRef(false);

  const [selectedProducts, setSelectedProducts] = useState<DbKey<Product>[]>(
    []
  );
  const [selectedQualifiedProducts, setSelectedQualifiedProducts] = useState<
    DbKey<Product>[]
  >([]);
  const [selectedAwardProducts, setSelectedAwardProducts] = useState<
    DbKey<Product>[]
  >([]);
  const [currentProductType, setCurrentProductType] = useState<
    "items" | "awardItems" | "qualifiedItems"
  >("items");

  const activeSupplierUsers = workspaceUsers.filter(
    user => user.inactiveAt === null && user.userType === "supplierUser"
  );

  const validationSchema = object().shape({
    name: string()
      .trim()
      .max(150, "Promotion Name cannot exceed 150 characters")
      .required("Promotion Name is required"),
    description: string()
      .trim()
      .required("Promotion Description is required"),
    header: string()
      .trim()
      .max(30, "Promotion header cannot exceed 30 characters"),
    month: string().required("Promotion Header is required"),
    psfDono: number()
      .min(0, "PSF Dono must be greater than or equal to 0")
      .nullable(),
    cost: string()
      .oneOf(valuesCostUsed)
      .required("Cost Used is required"),
    scanValue: mixed()
      .when("scanFormat", {
        is: "dollarFormat",
        then: number().min(0, "Scan Value must be greater than or equal to 0"),
        otherwise: number()
          .min(0, "Scan Value must be greater than or equal to 0")
          .max(100, "Scan Value must be less than or equal to 100")
      })
      .required("Scan Value is required"),
    scanFormat: string()
      .oneOf(valuesFundingType)
      .required(),
    durationType: string().required("Promotion Duration is required"),
    promotionEventId: string().required("Promotion Slot is required"),
    promotionType: array()
      .of(string())
      .min(2)
      .required("Promotion Type is required"),
    promotionDiscount: string().oneOf(["amountOff", "percentOff"]),
    promotionDiscountValue: mixed()
      .when(["promotionDiscount", "promotionType"], {
        is: (promotionDiscount, promotionType) =>
          promotionType &&
          promotionType.includes("salePriceDiscount") &&
          promotionDiscount === "amountOff",
        then: number()
          .moreThan(0)
          .required("Promotion Discount is required")
      })
      .when(["promotionDiscount", "promotionType"], {
        is: (promotionDiscount, promotionType) =>
          promotionType &&
          promotionType.includes("salePriceDiscount") &&
          promotionDiscount === "percentOff",
        then: number()
          .moreThan(0)
          .max(100)
          .required("Promotion Discount is required")
      }),
    qualifyAmount: number().when("promotionType", {
      is: promotionType => promotionType && !promotionType.includes("basic"),
      then: number()
        .moreThan(0)
        .integer()
        .required("Qualify Quantity is required")
    }),
    promotionAmount: number().when("promotionType", {
      is: promotionType =>
        promotionType && promotionType.includes("buybreakAwardQty"),
      then: number()
        .moreThan(0)
        .integer()
        .required("Promotion Quantity is required")
    }),
    promotionPrice: number().when("promotionType", {
      is: promotionType =>
        promotionType && promotionType.includes("fixedPriceDiscount"),
      then: number()
        .moreThan(0)
        .required("Promotion Price is required")
    })
  });

  const handleSaveAndSubmit = (values: CreateNewPromotionForm) => {
    if (isSupplier()) {
      onSaveAndSubmit(values);
    } else {
      setOpenAddOwnerDialog(true);
    }
  };

  const onSaveAndSubmit = (values: CreateNewPromotionForm) => {
    saveAndSubmitPromo.current = true;
    onSubmitForm(values);
  };

  const onSubmitForm = useCallback(
    async (values: CreateNewPromotionForm) => {
      const promotionTypeOption = JSON.parse(
        assertNotUndefined(values.promotionType)
      ) as PromotionTypeOption;

      let promotionType: PromotionType = {
        kind: "salePriceDiscount",
        value: {
          kind: "basic",
          value: {
            kind: "percentOff",
            value: 1
          }
        }
      };

      let productDetails: PromotionProductDetails[] = [
        {
          promotionItemType: null,
          promotionItems: []
        }
      ];

      switch (promotionTypeOption[0]) {
        case "salePriceDiscount": {
          const promotionDiscount = assertNotUndefined(
            values.promotionDiscount
          );
          const promotionDiscountValue = assertNotUndefined(
            values.promotionDiscountValue
          );
          const salePriceDiscount = makePromotionDiscount(
            promotionDiscount,
            promotionDiscount === "percentOff"
              ? promotionDiscountValue
              : promotionDiscountValue.toString()
          ) as PromotionDiscount;

          switch (promotionTypeOption[1]) {
            case "basic": {
              promotionType = {
                kind: "salePriceDiscount",
                value: {
                  kind: "basic",
                  value: salePriceDiscount
                }
              };

              productDetails = [
                {
                  promotionItems: values.items,
                  promotionItemType: null
                }
              ];

              break;
            }
            case "multiBuy": {
              const multiBuy = makeMultibuy<PromotionDiscount>({
                qualifyAmount: assertNotUndefined(values.qualifyAmount),
                pricing: salePriceDiscount
              });
              promotionType = {
                kind: "salePriceDiscount",
                value: {
                  kind: "multiBuy",
                  value: multiBuy
                }
              };
              productDetails = [
                {
                  promotionItems: values.items,
                  promotionItemType: null
                }
              ];
              break;
            }
            case "buybreak": {
              promotionType = {
                kind: "salePriceDiscount",
                value: {
                  kind: "buybreak",
                  value: {
                    qualifyAmount: assertNotUndefined(values.qualifyAmount),
                    discount: salePriceDiscount
                  }
                }
              };
              productDetails = [
                {
                  promotionItems: values.items,
                  promotionItemType: null
                }
              ];
              break;
            }
            case "buybreakAward": {
              promotionType = {
                kind: "salePriceDiscount",
                value: {
                  kind: "buybreakAward",
                  value: {
                    qualifyAmount: assertNotUndefined(values.qualifyAmount),
                    discount: salePriceDiscount
                  }
                }
              };
              productDetails = [
                {
                  promotionItems: values.awardItems,
                  promotionItemType: "award"
                },
                {
                  promotionItems: values.qualifiedItems,
                  promotionItemType: "qualifying"
                }
              ];
              break;
            }
            case "buybreakAwardQty": {
              promotionType = {
                kind: "salePriceDiscount",
                value: {
                  kind: "buybreakAwardQty",
                  value: {
                    qualifyAmount: assertNotUndefined(values.qualifyAmount),
                    discount: salePriceDiscount,
                    promoAmount: assertNotUndefined(values.promotionAmount)
                  }
                }
              };
              productDetails = [
                {
                  promotionItems: values.awardItems,
                  promotionItemType: "award"
                },
                {
                  promotionItems: values.qualifiedItems,
                  promotionItemType: "qualifying"
                }
              ];
              break;
            }
          }
          break;
        }
        case "fixedPriceDiscount": {
          const promotionPrice = assertNotUndefined(
            values.promotionPrice
          ).toString();

          switch (promotionTypeOption[1]) {
            case "basic": {
              promotionType = {
                kind: "fixedPriceDiscount",
                value: {
                  kind: "basic",
                  value: promotionPrice
                }
              };
              productDetails = [
                {
                  promotionItems: values.items,
                  promotionItemType: null
                }
              ];
              break;
            }
            case "multiBuy": {
              const multiBuy = makeMultibuy<BigDecimal>({
                qualifyAmount: assertNotUndefined(values.qualifyAmount),
                pricing: promotionPrice
              });
              promotionType = {
                kind: "fixedPriceDiscount",
                value: {
                  kind: "multiBuy",
                  value: multiBuy
                }
              };
              productDetails = [
                {
                  promotionItems: values.items,
                  promotionItemType: null
                }
              ];
              break;
            }
            case "multiBuyAward": {
              const multiBuy = makeMultibuy<BigDecimal>({
                qualifyAmount: assertNotUndefined(values.qualifyAmount),
                pricing: promotionPrice
              });
              promotionType = {
                kind: "fixedPriceDiscount",
                value: {
                  kind: "multiBuyAward",
                  value: multiBuy
                }
              };
              productDetails = [
                {
                  promotionItems: values.awardItems,
                  promotionItemType: "award"
                },
                {
                  promotionItems: values.qualifiedItems,
                  promotionItemType: "qualifying"
                }
              ];
              break;
            }
            case "multiBuySet": {
              const multiBuy = makeMultibuy<BigDecimal>({
                qualifyAmount: assertNotUndefined(values.qualifyAmount),
                pricing: promotionPrice
              });
              promotionType = {
                kind: "fixedPriceDiscount",
                value: {
                  kind: "multiBuySet",
                  value: multiBuy
                }
              };
              productDetails = [
                {
                  promotionItems: values.items,
                  promotionItemType: null
                }
              ];
              break;
            }
          }
          break;
        }
      }

      const costUsed = assertNotUndefined(values.cost) as CostUsed;
      const fundingType = assertNotUndefined(values.scanFormat) as FundingType;

      const req: Omit<CreatePromotionReq, "supplierWorkspaceId"> = {
        name: assertNotUndefined(values.name),
        promotionEventId: values.promotionEventId ?? null,
        description: assertNotUndefined(values.description),
        header: assertNotUndefined(values.header),
        promotionType,
        productDetails,
        priority: null,
        funding: {
          costUsed: costUsed,
          fundingType: fundingType,
          value: assertNotUndefined(values.scanValue).toString()
        },
        psaDono:
          values.psfDono && values.psfDono > 0
            ? values.psfDono?.toString()
            : null
      };
      await onCreatePromotion(req, owner.current, saveAndSubmitPromo.current);
      saveAndSubmitPromo.current = false;
    },
    [onCreatePromotion, owner, saveAndSubmitPromo]
  );

  const monthOptions: SelectOption[] = useMemo(() => {
    const uniqueMonths = Array.from(
      new Set(
        promotionEvents.map(pe =>
          formatDateMonthYearShortWithTZ(pe.value.startDate)
        )
      )
    );
    return uniqueMonths.map(m => ({
      title: format(new Date(m), MONTH_FORMAT),
      value: format(new Date(m), MONTH_FORMAT)
    }));
  }, [promotionEvents]);

  const DurationTypeInput = () => {
    const { values, touched, setFieldValue, handleChange } = useFormikContext<
      CreateNewPromotionForm
    >();

    const activityOptions = useRef<SelectOption[]>([]);

    React.useEffect(() => {
      // set the values of promotion duration, based on month
      if (values.month !== null && touched.month) {
        activityOptions.current = [];

        const presetActivities = _.uniqWith(
          promotionEvents
            .filter(pe => {
              const promotionMonth = formatDateMonthYearShortWithTZ(
                pe.value.startDate
              );
              return (
                promotionMonth === values.month &&
                pe.value.durationType !== "Custom"
              );
            })
            .map(pe => ({
              title: promotionEventDurationTypeToText(
                pe.value.durationType,
                pe.value.name
              ),
              value: pe.value.durationType.toString()
            })),
          _.isEqual
        );

        if (presetActivities.length > 0) {
          activityOptions.current.push(
            {
              title: "PRESET DURATION",
              value: "PRESET DURATION",
              subheading: true
            },
            ...presetActivities
          );
        }

        const customActivities = _.uniqWith(
          promotionEvents
            .filter(pe => {
              const promotionMonth = formatDateMonthYearShortWithTZ(
                pe.value.startDate
              );
              return (
                promotionMonth === values.month &&
                pe.value.durationType === "Custom"
              );
            })
            .map(pe => {
              return {
                title: pe.value.name,
                value: pe.value.name
              };
            }),
          _.isEqual
        );

        if (customActivities.length > 0) {
          activityOptions.current.push(
            {
              title: "CUSTOM EVENTS",
              value: "CUSTOM EVENTS",
              subheading: true
            },
            ...customActivities
          );
        }
      } else {
        activityOptions.current = [];
      }
    }, [touched, setFieldValue, values]);

    return (
      <SelectInput
        name="durationType"
        options={activityOptions.current}
        placeholder="Select Promotion Duration"
        label="Promotion Duration"
        required
        onChange={e => {
          handleChange(e);
          setFieldValue("promotionEventId", undefined);
        }}
      />
    );
  };

  const ItemDialogs = () => {
    const { setFieldValue } = useFormikContext<CreateNewPromotionForm>();
    const service = useAppService();

    let initialProducts: string[];
    let productType: PromotionItemType | null;
    if (currentProductType === "awardItems") {
      initialProducts = selectedAwardProducts;
      productType = "award";
    } else if (currentProductType === "qualifiedItems") {
      initialProducts = selectedQualifiedProducts;
      productType = "qualifying";
    } else {
      initialProducts = selectedProducts;
      productType = null;
    }

    const handleAddProducts = useCallback(
      async (productIds: DbKey<Product>[], source: ItemSelectionSource) => {
        let itemsAdded;

        if (source === "barcodeScan") {
          // If source is barcode uploader, add the selected products to existing list
          itemsAdded = Array.from(new Set([...initialProducts, ...productIds]));
        } else {
          // If source is product search, replace the existing item list with the new selection
          itemsAdded = Array.from(new Set([...productIds]));
        }

        if (currentProductType === "awardItems") {
          setSelectedAwardProducts(itemsAdded);
          setFieldValue("awardItems", itemsAdded);
        } else if (currentProductType === "qualifiedItems") {
          setSelectedQualifiedProducts(itemsAdded);
          setFieldValue("qualifiedItems", itemsAdded);
        } else {
          setSelectedProducts(itemsAdded);
          setFieldValue("items", itemsAdded);
        }
      },
      [initialProducts, setFieldValue]
    );

    const loadProducts = useCallback(
      async (filterOptions: QueryProductsFilter) => {
        let excludedItems: string[] = [];
        if (currentProductType === "awardItems") {
          excludedItems = selectedQualifiedProducts;
        } else if (currentProductType === "qualifiedItems") {
          excludedItems = selectedAwardProducts;
        }
        return await service.queryProducts({
          supplierWorkspaceId: workspaceId,
          productFilter:
            filterOptions !== null
              ? {
                  ...filterOptions,
                  excludeIds: excludedItems
                }
              : {
                  nameOrBarcodeLike: null,
                  filterByBrands: null,
                  filterByClasses: null,
                  filterByGroup: null,
                  filterByManufactuer: null,
                  excludeIds: excludedItems
                }
        });
      },
      [service]
    );

    return (
      <>
        <AddProductsDialog
          open={openProductDialog}
          onClose={() => setOpenProductDialog(false)}
          onAddProducts={productIds => {
            handleAddProducts(productIds, "itemSearch");
            setOpenProductDialog(false);
          }}
          loadProducts={loadProducts}
          productType={productType}
          brands={brands}
          classes={classes}
          manufacturers={manufacturers}
          groups={groups}
          handleSwitchToBarcodeUpload={() => {
            setOpenProductDialog(false);
            setOpenBarcodeUploadDialog(true);
          }}
          initialProducts={initialProducts}
        />
        <BarcodeUploadDialog
          open={openBarcodeUploadDialog}
          productType={productType}
          findProducts={onFindProducts}
          onAddProducts={productIds => {
            handleAddProducts(productIds, "barcodeScan");
            setOpenBarcodeUploadDialog(false);
          }}
          onClose={() => setOpenBarcodeUploadDialog(false)}
          handleSwitchToSelectItems={() => {
            setOpenBarcodeUploadDialog(false);
            setOpenProductDialog(true);
          }}
          supplierWorkspaceId={workspaceId}
          qualifiedProducts={selectedQualifiedProducts}
          awardProducts={selectedAwardProducts}
          productsAlreadyInPromotion={selectedProducts}
        />
      </>
    );
  };

  const AddOwnerDialog = () => {
    const { values } = useFormikContext<CreateNewPromotionForm>();

    return (
      <Dialog
        open={openAddOwnerDialog}
        onClose={() => setOpenAddOwnerDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h2Bold">
            {" "}
            Submission Error: Owner missing
          </Typography>
          <Typography variant="h5">
            Please assign an Owner to this promotion
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <InputLabel required>Email Address</InputLabel>
            <Autocomplete
              fullWidth
              options={activeSupplierUsers}
              noOptionsText="Supplier user does not exist or cannot be set as owner. Please try again or contact your Super Admin."
              sx={{
                width: "100%"
              }}
              onChange={(_event, value: UserDetails) => {
                owner.current = value.id;
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Email Address"
                  sx={{ width: "100%" }}
                />
              )}
              getOptionLabel={(option: UserDetails) =>
                option.name.firstName +
                " " +
                option.name.lastName +
                " - " +
                option.email
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            onClick={() => setOpenAddOwnerDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              onSaveAndSubmit(values);
            }}
            disabled={owner === undefined}
          >
            Confirm & Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const PromotionSlotInput = () => {
    const { values, touched } = useFormikContext<CreateNewPromotionForm>();

    const promotionSlots = useRef<SelectOption[]>([]);

    React.useEffect(() => {
      // set the values of promotion slot, based on promotion duration
      if (values.durationType !== null && touched.durationType) {
        promotionSlots.current = promotionEvents
          .filter(pe => {
            const promotionMonth = formatDateMonthYearShortWithTZ(
              pe.value.startDate
            );

            return (
              promotionMonth === values.month &&
              (pe.value.durationType === values.durationType ||
                pe.value.name === values.durationType)
            );
          })
          .map(pe => ({
            title: `${formatDateMonthDayYearWithTZ(
              pe.value.startDate
            )} - ${formatDateMonthDayYearWithTZ(pe.value.endDate)}`,
            value: pe.id
          }));
      } else {
        promotionSlots.current = [];
      }
    }, [touched, values]);

    return (
      <SelectInput
        name="promotionEventId"
        options={promotionSlots.current}
        placeholder="Select Promotion Slot"
        label="Promotion Slot"
        required
      />
    );
  };

  const costUsedOptions: SelectOption[] = valuesCostUsed.map(cu => ({
    title: cu.toUpperCase(),
    value: cu
  }));

  const scanValueOptions: SelectOption[] = valuesFundingType.map(ft => {
    return {
      title: fundingFormatTypeToText(ft),
      value: ft
    };
  });

  const promotionDiscountOptions: SelectOption[] = [
    {
      title: promotionDiscountToText("amountOff"),
      value: "amountOff"
    },
    {
      title: promotionDiscountToText("percentOff"),
      value: "percentOff"
    }
  ];

  const promotionTypes: Map<
    keyof PromotionTypeOpts,
    Array<keyof SalePriceDiscountOpts | keyof FixedPriceDiscountOpts>
  > = new Map();

  promotionTypes.set("fixedPriceDiscount", [
    "basic",
    "multiBuy",
    "multiBuyAward",
    "multiBuySet"
  ]);
  promotionTypes.set("salePriceDiscount", [
    "basic",
    "buybreak",
    "buybreakAward",
    "buybreakAwardQty",
    "multiBuy"
  ]);

  const promotionTypeOptions: SelectOption[] = [];

  promotionTypes.forEach((value, key) => {
    promotionTypeOptions.push({
      title: promotionTypeOptToText(key),
      value: key,
      subheading: true
    });
    for (const groupOption of value) {
      const promotionTypeDetails = promotionTypeOptDescriptionToText(
        key,
        groupOption
      );
      promotionTypeOptions.push({
        title: groupOption,
        value: JSON.stringify([key, groupOption]),
        item: (
          <Stack>
            <Typography variant="h5" color={"primary.dark"}>
              {promotionTypeDetails.type} / {promotionTypeDetails.name}
            </Typography>
            <Typography variant="body1">
              {promotionTypeDetails.description}
            </Typography>
          </Stack>
        )
      });
    }
  });

  const AddProductsButtons: FC<{
    itemsName: "items" | "awardItems" | "qualifiedItems";
  }> = props => {
    const { values } = useFormikContext<CreateNewPromotionForm>();

    let items: string[];
    if (props.itemsName === "awardItems") {
      items = values.awardItems;
    } else if (props.itemsName === "qualifiedItems") {
      items = values.qualifiedItems;
    } else {
      items = values.items;
    }

    return (
      <Stack direction="row" alignItems="center" mt={2} spacing={1}>
        {items.length === 0 ? (
          <>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setOpenProductDialog(true);
                setCurrentProductType(props.itemsName);
              }}
            >
              + Add Items
            </Button>
            <Typography fontSize={10}>OR</Typography>
            <Button
              startIcon={<UploadIcon />}
              size="small"
              variant="outlined"
              onClick={() => {
                setOpenBarcodeUploadDialog(true);
                setCurrentProductType(props.itemsName);
              }}
            >
              Barcode Upload
            </Button>
          </>
        ) : (
          <Button
            size={"small"}
            startIcon={<EditIcon />}
            variant={"outlined"}
            onClick={() => {
              setOpenProductDialog(true);
              setCurrentProductType(props.itemsName);
            }}
          >
            {`${items.length} ${formatPlural(
              "product",
              items.length
            )} selected`}
          </Button>
        )}
      </Stack>
    );
  };

  const PricingStrategyInput = () => {
    const { values } = useFormikContext<CreateNewPromotionForm>();
    if (!values.promotionType) {
      return <></>;
    }

    const promotionType = JSON.parse(
      values.promotionType
    ) as PromotionTypeOption;

    let inputs: JSX.Element = <></>;
    switch (true) {
      case promotionType[0] === "salePriceDiscount" &&
        promotionType[1] === "basic": {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <NumberWithSelectInput
                textfieldName={"promotionDiscountValue"}
                selectName={"promotionDiscount"}
                options={promotionDiscountOptions}
                label={"Promo % or $ off cost ex GST"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"items"} />
          </>
        );
        break;
      }
      case (promotionType[0] === "salePriceDiscount" &&
        promotionType[1] === "multiBuy") ||
        (promotionType[0] === "salePriceDiscount" &&
          promotionType[1] === "buybreak"): {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <NumberInput
                name={"qualifyAmount"}
                label={"Qualify Qty"}
                required
              />
              <NumberWithSelectInput
                textfieldName={"promotionDiscountValue"}
                selectName={"promotionDiscount"}
                options={promotionDiscountOptions}
                label={"Promo % or $ off cost ex GST"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"items"} />
          </>
        );
        break;
      }
      case promotionType[0] === "salePriceDiscount" &&
        promotionType[1] === "buybreakAward": {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <Typography variant={"h4Bold"}>1. Qualified Products</Typography>
              <NumberInput
                name={"qualifyAmount"}
                label={"Qualify Qty"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"qualifiedItems"} />
            <Stack spacing={2}>
              <Typography variant={"h4Bold"}>2. Award Products</Typography>
              <NumberWithSelectInput
                textfieldName={"promotionDiscountValue"}
                selectName={"promotionDiscount"}
                options={promotionDiscountOptions}
                label={"Promo % or $ off cost ex GST"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"awardItems"} />
          </>
        );
        break;
      }
      case promotionType[0] === "salePriceDiscount" &&
        promotionType[1] === "buybreakAwardQty": {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <Typography variant={"h4Bold"}>1. Qualified Products</Typography>
              <NumberInput
                name={"qualifyAmount"}
                label={"Qualify Qty"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"qualifiedItems"} />
            <Stack spacing={2}>
              <Typography variant={"h4Bold"}>2. Award Products</Typography>
              <Stack direction={"row"} mt={2} spacing={1}>
                <NumberInput
                  name={"promotionAmount"}
                  label={"Promotion Qty"}
                  required
                />
                <NumberWithSelectInput
                  textfieldName={"promotionDiscountValue"}
                  selectName={"promotionDiscount"}
                  options={promotionDiscountOptions}
                  label={"Promo % or $ off cost ex GST"}
                  required
                />
              </Stack>
            </Stack>
            <AddProductsButtons itemsName={"awardItems"} />
          </>
        );
        break;
      }
      case promotionType[0] === "fixedPriceDiscount" &&
        promotionType[1] === "basic": {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <NumberInput
                name={"promotionPrice"}
                label={"Promotion Price"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"items"} />
          </>
        );
        break;
      }
      case (promotionType[0] === "fixedPriceDiscount" &&
        promotionType[1] === "multiBuy") ||
        (promotionType[0] === "fixedPriceDiscount" &&
          promotionType[1] === "multiBuySet"): {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <NumberInput
                name={"qualifyAmount"}
                label={"Qualify Qty"}
                required
              />
              <NumberInput
                name={"promotionPrice"}
                label={
                  promotionType[1] === "multiBuy"
                    ? "Promotion Price"
                    : "Promotion Price per Set"
                }
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"items"} />
          </>
        );
        break;
      }
      case promotionType[0] === "fixedPriceDiscount" &&
        promotionType[1] === "multiBuyAward": {
        inputs = (
          <>
            <Divider />
            <Stack spacing={2}>
              <Typography variant={"h4Bold"}>1. Qualified Products</Typography>
              <NumberInput
                name={"qualifyAmount"}
                label={"Qualify Qty"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"qualifiedItems"} />

            <Stack spacing={2}>
              <Typography variant={"h4Bold"}>2. Award Products</Typography>
              <NumberInput
                name={"promotionPrice"}
                label={"Promotion Price"}
                required
              />
            </Stack>
            <AddProductsButtons itemsName={"awardItems"} />
          </>
        );
        break;
      }
    }

    return <Stack spacing={2}>{inputs}</Stack>;
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h1Bold">Create New Promotion</Typography>
      <Stack>
        <Formik<CreateNewPromotionForm>
          initialValues={{
            name: "",
            promotionEventId: null,
            description: "",
            header: "",
            month: undefined,
            psfDono: undefined,
            qualifyAmount: 1,
            promotionAmount: 1,
            promotionPrice: undefined,
            cost: undefined,
            scanValue: undefined,
            scanFormat: "percentageFormat",
            durationType: undefined,
            promotionType: undefined,
            promotionDiscount: "percentOff",
            promotionDiscountValue: undefined,
            items: [],
            qualifiedItems: [],
            awardItems: []
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          isInitialValid={false}
          onSubmit={onSubmitForm}
        >
          {({ handleChange, setFieldValue, values, isValid, isSubmitting }) => (
            <Form id={"create-promotion-form-id"}>
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                mt={2}
                marginBottom={20}
              >
                <Grid item xs={12}>
                  <TextInput
                    name="name"
                    required
                    placeholder="Promotion Block Name"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectInput
                    name="month"
                    options={monthOptions}
                    placeholder="Select Promotion Month"
                    label="Promotion Month"
                    required
                    onChange={e => {
                      handleChange(e);
                      setFieldValue("promotionEventId", undefined);
                      setFieldValue("durationType", undefined);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DurationTypeInput />
                </Grid>
                <Grid item xs={6}>
                  <PromotionSlotInput />
                </Grid>
                <Grid item xs={6}>
                  {isSuperAdmin() && (
                    <TextareaInput
                      name="header"
                      label="Promotion Header"
                      placeholder="Enter Promotion Header"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextareaInput
                    name="description"
                    label="Promotion Description"
                    placeholder="Enter Promotion Description"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberInput
                    name="psfDono"
                    label="PSF Dono"
                    placeholder="Enter PSF Dono"
                    min={0}
                    max={100}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectInput
                    name="cost"
                    options={costUsedOptions}
                    placeholder="Select Cost Used"
                    label="Cost Used"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    name="promotionType"
                    options={promotionTypeOptions}
                    placeholder="Select Promotion Type"
                    label="Promotion Type"
                    onChange={e => {
                      handleChange(e);
                      setFieldValue("items", []);
                      setFieldValue("awardItems", []);
                      setFieldValue("qualifiedItems", []);
                      setSelectedProducts([]);
                      setSelectedAwardProducts([]);
                      setSelectedQualifiedProducts([]);
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberWithSelectInput
                    textfieldName={"scanValue"}
                    selectName={"scanFormat"}
                    options={scanValueOptions}
                    label={"Scan % or $ value"}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <PricingStrategyInput />
                </Grid>
              </Grid>
              <ActionsDrawer
                isOpen={true}
                primaryAction={
                  <LoadingButton
                    variant="outlined"
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                  >
                    Save as Draft
                  </LoadingButton>
                }
                secondaryAction={
                  <Button
                    onClick={() => {
                      handleSaveAndSubmit(values);
                    }}
                    disabled={
                      !(
                        isValid &&
                        (values.items.length > 0 ||
                          (values.awardItems.length > 0 &&
                            values.qualifiedItems.length > 0))
                      )
                    }
                  >
                    Save & Submit Promotion
                  </Button>
                }
              />
              <ItemDialogs />
              <AddOwnerDialog />
            </Form>
          )}
        </Formik>
      </Stack>
    </Stack>
  );
};
