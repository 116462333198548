import { Components, Theme } from "@mui/material/styles";

// https://mui.com/material-ui/about-the-lab/#typescript
import type {} from "@mui/lab/themeAugmentation";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function MuiLab(theme: Theme): Components {
  return {
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained"
      }
    },
    //  Uncomment for debugging
    // MuiStack: {
    //   defaultProps: {
    //     sx: {
    //       border: "2px dashed tomato"
    //     }
    //   }
    // }
  };
}
