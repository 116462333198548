import { Drawer, Stack } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { NAVIGATION_SIDE_BAR_WIDTH } from "../layouts/navigation-side-bar/navigation-side-bar";

interface ActionsDrawerProps {
  isOpen: boolean;
  primaryAction: ReactNode;
  secondaryAction?: ReactNode;
}

const ActionsDrawer: FC<ActionsDrawerProps> = ({
  isOpen,
  primaryAction,
  secondaryAction
}) => {
  return (
    <Drawer
      anchor={"bottom"}
      open={isOpen}
      variant={"persistent"}
      sx={{
        "& .MuiDrawer-paper": {
          marginLeft: `${NAVIGATION_SIDE_BAR_WIDTH}px`
        }
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={2} px={4} py={2}>
        {primaryAction}
        {secondaryAction}
      </Stack>
    </Drawer>
  );
};

export default ActionsDrawer;
