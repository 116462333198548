import {
  Button,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  MarkNotificationsReq,
  valuesNotificationGroup
} from "@pmp/adl/petstock/merchantportal/api";
import { NotificationTable } from "./notification-table";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { Loader } from "@pmp/common/loader/loader";
import { useAppService } from "../../../hooks/useAppService";
import { AppRoutes } from "../../../app/app";
import { useHistory } from "react-router-dom";
import { SelectOptions } from "@pmp/common/inputs/select-input/select-input";
import { useAlert } from "../../../hooks/useAlertContext";
import { CheckmarkOutlineIcon } from "src/ui/common/icon/icons";

const notificationFilterOptions: SelectOptions = [
  {
    title: "All",
    value: "all"
  },
  {
    title: "Promotion",
    value: "promotion"
  },
  {
    title: "General",
    value: "general"
  }
];

export const NotificationPopOver = () => {
  const [showAlert] = useAlert();
  const service = useAppService();

  const [selectedFilter, setSelectedFilter] = useState("all");
  const loadNotifications = useCallback(async () => {
    const notificationGroupValue = valuesNotificationGroup.find(
      val => val.toString().toLowerCase() === selectedFilter
    );

    return await service.queryNotifications({
      group: notificationGroupValue ? notificationGroupValue : null,
      unRead: true
    });
  }, [selectedFilter, service]);
  const [loadingNotifications, refreshNotifications] = useLoadingDataState(
    loadNotifications
  );

  const markNotifications = useCallback(
    async (req: MarkNotificationsReq) => {
      return await service.markNotifications(req);
    },
    [service]
  );

  const handleFilterNotifications = useCallback(
    async (event: SelectChangeEvent) => {
      setSelectedFilter(event.target.value);
      refreshNotifications();
    },
    [refreshNotifications]
  );

  const handleMarkNotificationsAsRead = useCallback(async () => {
    const notificationGroupValue = valuesNotificationGroup.find(
      val => val.toString().toLowerCase() === selectedFilter
    );
    const resp = await service.markNotifications({
      // Leave as empty to mark all user's unread notification by the specified group
      userNotificationIds: [],
      notificationGroup: notificationGroupValue ? notificationGroupValue : null
    });

    if (resp === "success") {
      refreshNotifications();
    } else {
      await showAlert({
        title: "Error updating notification(s)",
        body:
          "An error occurred while updating the notification(s), please try again."
      });
    }
  }, [refreshNotifications, selectedFilter, service, showAlert]);

  const history = useHistory();
  const handleViewAll = useCallback(async () => {
    location.pathname = `${AppRoutes.Notification}`;
    history.push(location);
  }, [history]);

  return (
    <Stack flex={1} spacing={3} sx={{ padding: 2, paddingTop: 2 }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Typography variant="h6">Notifications</Typography>
          {/** TODO: Use input select */}
          <Select
            variant="standard"
            id="filterNotifications"
            labelId="filterNotifications"
            defaultValue="all"
            onChange={handleFilterNotifications}
            sx={{ m: 1, minWidth: 50 }}
            size="small"
          >
            {notificationFilterOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Button
          variant="text"
          endIcon={<CheckmarkOutlineIcon />}
          onClick={handleMarkNotificationsAsRead}
        >
          Mark all as read
        </Button>
      </Stack>
      <Loader loadingStates={[loadingNotifications]}>
        {isLoaded(loadingNotifications) && (
          <NotificationTable
            notifications={loadingNotifications.value}
            markNotifications={markNotifications}
            isNormalTableView={false}
          />
        )}
      </Loader>
      <Link align={"center"} onClick={() => handleViewAll()}>
        View All
      </Link>
    </Stack>
  );
};
