import { Box, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import React, { FC } from "react";

interface NivoData {
  id: string;
  label: string;
  color: string;
  value: number;
}

interface PieChartProps {
  data: Array<NivoData>;
  tooltipLabelValueJoiner?: string;
}

const PieChart: FC<PieChartProps> = ({ data, tooltipLabelValueJoiner }) => {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      innerRadius={0.75}
      padAngle={0.7}
      cornerRadius={1}
      activeOuterRadiusOffset={8}
      colors={{ datum: "data.color" }}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]]
      }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      tooltip={props => {
        return (
          <Box
            p={1}
            borderRadius={1}
            border={"1px solid"}
            borderColor={"gray.lighter"}
            sx={{
              background: theme => theme.palette.common.white
            }}
          >
            <Typography variant={"h5"}>
              {`${props.datum.value}${
                tooltipLabelValueJoiner ? ` ${tooltipLabelValueJoiner} ` : " "
              }${props.datum.label}`}
            </Typography>
          </Box>
        );
      }}
    />
  );
};

export default PieChart;
