import {
  AppBar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Toolbar
} from "@mui/material";
import { UserProfile } from "@pmp/adl/petstock/merchantportal/api";
import React, { useState } from "react";
import { NotificationIcon } from "../../common/icon/icons";
import AvatarName from "../../components/AvatarName";
import { NotificationPopOver } from "../../page/notification/notification-pop-over";

interface HeaderProps {
  onLogout: () => void;
  userProfile: UserProfile | undefined;
  hasUnreadNotifications: boolean;
}

export const Header = ({
  onLogout,
  userProfile,
  hasUnreadNotifications
}: HeaderProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    anchorElNotification,
    setAnchorElNotification
  ] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openNotifications, setOpenNotifications] = useState(false);
  const handleClickNotification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNotification(event.currentTarget);
    setOpenNotifications(true);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotification(null);
    setOpenNotifications(false);
  };

  return (
    <AppBar>
      <Toolbar>
        <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
          <IconButton
            aria-describedby={"notificationPopover"}
            onClick={handleClickNotification}
            disableRipple
          >
            <Badge
              overlap={"circular"}
              color="error"
              variant="dot"
              invisible={!hasUnreadNotifications}
            >
              <NotificationIcon />
            </Badge>
          </IconButton>
          <Popover
            id={"notificationPopover"}
            open={openNotifications}
            anchorEl={anchorElNotification}
            onClose={handleCloseNotifications}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            PaperProps={{
              style: { width: "50%" }
            }}
          >
            <NotificationPopOver />
          </Popover>
          <IconButton
            onClick={handleClick}
            disableRipple
            title={"Open user menu"}
          >
            <AvatarName name={userProfile?.fullname || ""} />
          </IconButton>
          <Menu
            id="user-profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem key="user-name" disabled>
              {userProfile?.fullname}
            </MenuItem>
            <MenuItem key="log-out" onClick={() => onLogout()}>
              Log out
            </MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
