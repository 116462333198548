import numeral from "numeral";

export const formatCurrency = (value: number): string => {
  return numeral(value).format("$0,0.00");
};

export const formatPercent = (value: number): string => {
  return numeral(value / 100).format("0%");
};

export const formatLeadingZero = (value: number): string => {
  return numeral(value).format("00");
};
