import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { PlusIcon, SearchIcon } from "src/ui/common/icon/icons";

import {
  QueryRolesResp,
  QueryUsersResp,
  SupplierWorkspaceSummary
} from "../../../adl-gen/petstock/merchantportal/api";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { ManageUsersTable } from "../../widgets/manage-users-table/manage-users-table";
import { WorkspaceInviteUserPageProps } from "../workspace-invite-new-user/workspace-invite-new-user-page-view";

export interface UserManagementPageViewProps {
  workspaces: SupplierWorkspaceSummary[];
  loadUsers: (searchTerm: string | null) => Promise<QueryUsersResp>;
  roles: QueryRolesResp;
  InviteWorkspaceUsersModal: React.FC<
    Omit<
      WorkspaceInviteUserPageProps & { refreshUsers: () => void },
      "onInvite"
    >
  >;
}

export const UserManagementPageView = ({
  workspaces,
  roles,
  loadUsers,
  InviteWorkspaceUsersModal
}: UserManagementPageViewProps) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [
    openInviteWorkspaceUsersModal,
    setOpenInviteWorkspaceUsersModal
  ] = useState(false);

  const queryUsers = useCallback(async () => {
    return await loadUsers(searchTerm);
  }, [searchTerm, loadUsers]);

  const searchUsers = _.debounce(async (term: string) => {
    setSearchTerm(term);
  }, 500);

  const [loadingUsers, refreshUsers] = useLoadingDataState(queryUsers);

  return (
    <Stack>
      <Box display="flex" alignItems="center">
        <Typography variant="h1Bold">Manage All Users</Typography>
        <Box flexGrow={1} />
        <Button
          startIcon={<PlusIcon />}
          onClick={() => setOpenInviteWorkspaceUsersModal(true)}
        >
          Invite New User
        </Button>
      </Box>

      <Box display="flex" alignItems="center" mt={4} mb={2}>
        <TextField
          placeholder="Search Users"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={e => searchUsers(e.target.value)}
        />
      </Box>
      <ManageUsersTable
        tabs={[
          {
            label: "All Active Users",
            filterUsers: (users: QueryUsersResp) =>
              users.filter(u => u.inactiveAt === null)
          },
          {
            label: "All Admins",
            filterUsers: (users: QueryUsersResp) =>
              users.filter(u => u.userType === "admin" && u.inactiveAt === null)
          },
          {
            label: "Super Admins",
            filterUsers: (users: QueryUsersResp) =>
              users.filter(
                u => u.userType === "superAdmin" && u.inactiveAt === null
              )
          },
          {
            label: "Supplier Users",
            filterUsers: (users: QueryUsersResp) =>
              users.filter(
                u => u.userType === "supplierUser" && u.inactiveAt === null
              )
          },
          {
            label: "Archived Users",
            filterUsers: (users: QueryUsersResp) =>
              users.filter(u => u.inactiveAt !== null)
          }
        ]}
        loadingUsers={loadingUsers}
        refreshUsers={refreshUsers}
        allowArchiving={true}
      />
      <InviteWorkspaceUsersModal
        open={openInviteWorkspaceUsersModal}
        workspaces={workspaces}
        roles={roles}
        onClose={() => setOpenInviteWorkspaceUsersModal(false)}
        refreshUsers={refreshUsers}
      />
    </Stack>
  );
};
