/**
 * ✍️PetStock Merchant Portal Theme Typography
 * Figma: https://www.figma.com/file/hoiWN138B6hOCvFNGase6p/Merchant-Portal?node-id=1322%3A15636
 * Docs: https://mui.com/material-ui/customization/typography/
 */

declare module "@mui/material/Typography" {
  // remove this options from Typography
  interface TypographyPropsVariantOverrides {
    body2: false;
    caption: false;
    overline: false;
    h1Bold: true;
    h2Bold: true;
    h3Bold: true;
    h4Bold: true;
    h5Bold: true;
    h6Bold: true;
  }
}

const typography = {
  fontWeightRegular: 400,
  fontWeightBold: 600,
  h1: {
    fontWeight: 400,
    fontSize: 32
  },
  h2: {
    fontWeight: 400,
    fontSize: 24
  },
  h3: {
    fontWeight: 400,
    fontSize: 20
  },
  h4: {
    fontWeight: 400,
    fontSize: 16
  },
  h5: {
    fontWeight: 400,
    fontSize: 14
  },
  h6: {
    fontWeight: 400,
    fontSize: 12
  },
  h1Bold: {
    fontWeight: "bold",
    fontSize: 32
  },
  h2Bold: {
    fontWeight: "bold",
    fontSize: 24
  },
  h3Bold: {
    fontWeight: "bold",
    fontSize: 20
  },
  h4Bold: {
    fontWeight: "bold",
    fontSize: 16
  },
  h5Bold: {
    fontWeight: "bold",
    fontSize: 14
  },
  h6Bold: {
    fontWeight: "bold",
    fontSize: 12
  },
  subtitle1: {
    // Body Large
    fontWeight: 400,
    fontSize: 16
  },
  subtitle2: {
    // Body Medium
    fontWeight: 400,
    fontSize: 14
  },
  body1: {
    fontSize: 12
  },
  button: {
    fontWeight: 600,
    fontSize: 14,
    textTransform: "capitalize"
  }
} as const;

export default typography;
