import React from "react";

const Logo = () => {
  return (
    <img
      src={`/assets/logo.png`}
      alt="Merchant Portal Logo"
      loading="lazy"
      width={"132px"}
    />
  );
};

export default Logo;
