import { Components, Theme } from "@mui/material/styles";

export default function Table(theme: Theme): Components {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.gray.lightest
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.gray.lightest,
            "&:hover": {
              backgroundColor: theme.palette.action.hover
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:first-of-type": {
            borderLeft: "1px solid",
            paddingLeft: theme.spacing(3),
            borderColor: theme.palette.gray.lighter
          },
          "&:last-of-type": {
            borderRight: "1px solid",
            paddingRight: theme.spacing(3),
            borderColor: theme.palette.gray.lighter
          },
          "&.PmpCellNoWrap": {
            maxWidth: "20ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }
        },
        head: {
          color: theme.palette.primary.main,
          textTransform: "uppercase",
          fontWeight: 600,
          borderTop: "1px solid",
          borderColor: theme.palette.gray.lighter,
          "&:first-of-type": {
            borderTopLeftRadius: theme.shape.borderRadius
          },
          "&:last-of-type": {
            borderTopRightRadius: theme.shape.borderRadius
          }
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper
        },
        body: {
          "&:first-of-type": {
            paddingLeft: theme.spacing(3)
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(3)
          }
        }
      }
    }
  };
}
