import { InputLabel, InputLabelProps, Stack } from "@mui/material";
import InputTooltip from "@pmp/common/inputs/helpers/input-tooltip";
import React, { FC, ReactNode } from "react";

interface Props extends Omit<InputLabelProps, "children"> {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * Determines the error ON/OFF state.
   */
  error: boolean;
  /**
   * The input label.
   */
  label: ReactNode;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
}

/**
 * Component to use as `label` for inputs
 * @param props: {@link Props}
 */
const LabelInput: FC<Props> = props => {
  const { label, helperText, name, error, ...rest } = props;
  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      <InputLabel htmlFor={name} error={error} {...rest} sx={{}}>
        {label}
      </InputLabel>
      {helperText && <InputTooltip title={helperText} />}
    </Stack>
  );
};

export default LabelInput;
