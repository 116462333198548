import { Instant } from "@adltools/adl-gen/common";
import { ButtonBase, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { formatDate } from "@pmp/utils/dates";
import { ArrowRightIcon, CalendarIcon } from "../common/icon/icons";

interface Props {
  startDate: Instant;
  endDate: Instant;
  disabled?: boolean;
  onClick?: () => void;
}

/* TODO: INPUTS UI => add inputs height here */
const DateRange: FC<Props> = ({ startDate, endDate, disabled, onClick }) => {
  const content = (
    <Stack
      direction={"row"}
      alignItems={"center"}
      p={1}
      spacing={1}
      sx={{
        border: `1px solid`,
        color: disabled ? "gray.lighter" : "primary.main",
        borderColor: "currentColor",
        borderRadius: theme => `${theme.shape.borderRadius}px`
      }}
    >
      <CalendarIcon />
      <Typography variant={"h6Bold"}>{formatDate(startDate)}</Typography>
      <ArrowRightIcon />
      <Typography variant={"h6Bold"}>{formatDate(endDate)}</Typography>
    </Stack>
  );

  if (onClick) {
    return (
      <ButtonBase onClick={onClick} disabled={disabled}>
        {content}
      </ButtonBase>
    );
  }

  return content;
};

export default DateRange;
