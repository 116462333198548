import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import { PromotionStatus } from "@pmp/adl/petstock/merchantportal/types";
import { promotionStatusToUILabelColor, UILabelColor } from "@pmp/utils/adl";
import React, { FC, useMemo } from "react";

export type PromoStatusUI = {
  status: PromotionStatus;
  count: number;
};
export type PromotionStatusLineChartProps = {
  statuses: Array<PromoStatusUI>;
  tooltipPrefix: string;
};

const PromotionStatusLineChart: FC<PromotionStatusLineChartProps> = ({
  statuses,
  tooltipPrefix
}) => {
  const theme = useTheme();

  const promoStatusUI = useMemo(() => {
    return statuses
      .filter(status => status.count !== 0)
      .map(status => {
        return {
          ...promotionStatusToUILabelColor(status.status, theme),
          count: status.count
        };
      });
  }, [statuses, theme]);

  const total = useMemo(() => {
    return statuses.reduce((total, curr) => {
      return (total += curr.count);
    }, 0);
  }, [statuses]);

  return (
    <Stack direction={"row"} width={"100%"}>
      {promoStatusUI.map(status => {
        return (
          <PromotionStatusLineChartItem
            key={status.label}
            {...status}
            tooltipPrefix={tooltipPrefix}
            total={total}
          />
        );
      })}
    </Stack>
  );
};

interface PromotionStatusLineChartItemProps extends UILabelColor {
  count: number;
  total: number;
  tooltipPrefix: string;
}

const PromotionStatusLineChartItem: FC<PromotionStatusLineChartItemProps> = ({
  color,
  label,
  count,
  total,
  tooltipPrefix
}) => {
  const percent = useMemo(() => {
    return (count / total) * 100;
  }, [count, total]);

  return (
    <Tooltip
      title={`${tooltipPrefix} ${label}: ${count}`}
      placement="top"
      arrow
      followCursor
    >
      <Box
        width={`${percent}%`}
        height={16}
        sx={{
          backgroundColor: color,
          "&:not(:first-child):before": {
            content: '""',
            position: "relative",
            display: "block",
            zIndex: 1,
            height: "100%",
            width: 2,
            backgroundColor: "gray.lighter"
          },
          "&:first-child": {
            borderTopLeftRadius: 2,
            borderBottomLeftRadius: 2
          },
          "&:last-child": {
            borderTopRightRadius: 2,
            borderBottomRightRadius: 2
          }
        }}
      />
    </Tooltip>
  );
};

export default PromotionStatusLineChart;
