import { Components, Theme } from "@mui/material/styles";
import { NAVIGATION_SIDE_BAR_WIDTH } from "../../../layouts/navigation-side-bar/navigation-side-bar";

export default function AppBar(theme: Theme): Components {
  return {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: theme.palette.common.white,
        root: {
          left: `${NAVIGATION_SIDE_BAR_WIDTH}px`,
          width: "auto"
        }
      },
      defaultProps: {
        color: "default",
        position: "fixed"
      }
    }
  };
}
