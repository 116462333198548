import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import { DbKey } from "@pmp/adl/common/db";
import { PromotionDetailsSummary } from "@pmp/adl/petstock/merchantportal/api";
import { SupplierWorkspace } from "@pmp/adl/petstock/merchantportal/db";
import PieChart from "@pmp/components/PieChart/PieChart";
import PMPTabs from "@pmp/components/PMPTabs/PMPTabs";
import PromoStatusChart from "@pmp/components/PromotionStatus/PromoStatusChart";
import PromotionStatusLegend from "@pmp/components/PromotionStatus/PromotionStatusLegend";
import { PromoStatusUI } from "@pmp/components/PromotionStatus/PromotionStatusLineChart";
import { formatDate, formatDateWithTZ } from "@pmp/utils/dates";
import React, { FC, useMemo } from "react";
import { AppRoutes } from "../../../app/app";
import { useFeatures } from "../../../hooks/useFeatures";
import { AlertIcon, PlusIcon } from "../../common/icon/icons";
import { PromotionStatusCell } from "../promotion/promotion-overview/promotion-overview-view";

export interface DashboardStat {
  Icon: FC<IconProps>;
  label: string;
  stat: number;
  color?: "warning";
}

export interface DashboardViewProps {
  dashboardStats: Array<DashboardStat>;
  promotionByStatus: Array<PromoStatusUI>;
  // only needed for admins
  itemsStats?: {
    planned: number;
    current: number;
  };
  promotions: {
    current: Array<PromotionDetailsSummary>;
    upcoming: Array<PromotionDetailsSummary>;
  };
  actionsData: {
    actions: Array<PromotionDetailsSummary>;
    groupedCounts?: {
      suppliers: number;
      users: number;
    };
  };
  selectedWorkspaceId: DbKey<SupplierWorkspace> | null;
}
const DashboardView: FC<DashboardViewProps> = ({
  dashboardStats,
  promotionByStatus,
  itemsStats,
  promotions,
  selectedWorkspaceId,
  actionsData
}) => {
  const { isSupplier } = useFeatures();
  const theme = useTheme();

  const noPromotions = useMemo(() => {
    return promotions.current.length + promotions.upcoming.length === 0;
  }, [promotions]);

  const noActions = useMemo(() => {
    return actionsData === undefined || actionsData.actions.length === 0;
  }, [actionsData]);

  const noPromotionsStatus = useMemo(() => {
    const statusCount = promotionByStatus.reduce((prev, curr) => {
      return prev + curr.count;
    }, 0);
    return statusCount === 0;
  }, [promotionByStatus]);

  const noItems = useMemo(() => {
    return itemsStats === undefined;
  }, [itemsStats]);

  return (
    <Stack gap={3}>
      <Typography variant={"h1Bold"}>Supplier Workspace Overview</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Stack gap={2}>
            <Card>
              <Stack
                p={2}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                {dashboardStats.map(stat => (
                  <DashboardStat {...stat} key={stat.label} />
                ))}
              </Stack>
            </Card>
            <Card>
              <Stack p={2} gap={2} alignItems={"flex-start"}>
                <Typography variant={"h3Bold"}>Required Actions</Typography>
                {noActions ? (
                  <Stack gap={2} alignItems={"center"} width={"100%"} py={6}>
                    <AlertIcon sx={{ fontSize: 80 }} />
                    <Typography variant={"h4"}>
                      No required actions at the moment
                    </Typography>
                  </Stack>
                ) : isSupplier() ? (
                  <ActionsTable promotions={actionsData.actions ?? []} />
                ) : (
                  <PMPTabs
                    ariaLabel={"Actions"}
                    tabs={[
                      {
                        label: `by suppliers (${actionsData.groupedCounts
                          ?.suppliers ?? 0})`,
                        id: "actions-by-suppliers",
                        content: (
                          <ActionsTable
                            promotions={actionsData.actions ?? []}
                            displayType={"supplier"}
                          />
                        ),
                        disabled:
                          actionsData.groupedCounts === undefined ||
                          actionsData.groupedCounts.suppliers === 0
                      },
                      {
                        label: `by user (${actionsData.groupedCounts?.users ??
                          0})`,
                        id: "actions-by-user",
                        content: (
                          <ActionsTable
                            promotions={actionsData.actions ?? []}
                            displayType={"user"}
                          />
                        ),
                        disabled:
                          actionsData.groupedCounts === undefined ||
                          actionsData.groupedCounts.users === 0
                      }
                    ]}
                  />
                )}
              </Stack>
            </Card>
            <Card>
              <Stack p={2} gap={2} alignItems={"flex-start"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Typography variant={"h3Bold"}>
                    {isSupplier() ? "My" : "All"} Promotions
                  </Typography>
                  <Button href={AppRoutes.Promotions} disabled={noPromotions}>
                    view all promotions
                  </Button>
                </Stack>
                {noPromotions ? (
                  <Stack gap={2} alignItems={"center"} width={"100%"} py={6}>
                    <AlertIcon sx={{ fontSize: 80 }} />
                    <Typography variant={"h4"}>
                      No approved promotions at the moment
                    </Typography>
                    <Button
                      href={AppRoutes.PromotionsCreate}
                      disabled={!Boolean(selectedWorkspaceId)}
                      startIcon={<PlusIcon />}
                    >
                      create promotion
                    </Button>
                  </Stack>
                ) : (
                  <PMPTabs
                    ariaLabel={"Promotions"}
                    tabs={[
                      {
                        label: `current promotion (${promotions.current.length})`,
                        id: "current-promotions",
                        content: (
                          <PromotionsTable promotions={promotions.current} />
                        ),
                        disabled: promotions.current.length === 0
                      },
                      {
                        label: `upcoming promotion (${promotions.upcoming.length})`,
                        id: "upcoming-promotions",
                        content: (
                          <PromotionsTable promotions={promotions.upcoming} />
                        ),
                        disabled: promotions.upcoming.length === 0
                      }
                    ]}
                  />
                )}
              </Stack>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack gap={2}>
            <Card
              className={"autoHeight"}
              sx={{
                overflow: "visible"
              }}
            >
              <Stack p={2} alignItems={"flex-start"}>
                <Typography variant={"h3Bold"}>Promotion by Status</Typography>
                {noPromotionsStatus ? (
                  <Stack gap={2} alignItems={"center"} width={"100%"} py={6}>
                    <AlertIcon sx={{ fontSize: 80 }} />
                    <Typography variant={"h4"}>
                      No promotions at the moment
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Box height={250} width={"100%"}>
                      <PromoStatusChart statuses={promotionByStatus} />
                    </Box>
                    <PromotionStatusLegend hideDivider />
                  </>
                )}
              </Stack>
            </Card>
            {!isSupplier() && (
              <Card
                className={"autoHeight"}
                sx={{
                  overflow: "visible"
                }}
              >
                <Stack p={2} alignItems={"flex-start"}>
                  <Typography variant={"h3Bold"}>
                    Promotion Items Statistics
                  </Typography>
                  {noItems ? (
                    <Stack gap={2} alignItems={"center"} width={"100%"} py={6}>
                      <AlertIcon sx={{ fontSize: 80 }} />
                      <Typography variant={"h4"}>
                        No items at the moment
                      </Typography>
                    </Stack>
                  ) : (
                    <>
                      <Box height={250} width={"100%"}>
                        <PieChart
                          data={[
                            {
                              id: "current",
                              color: theme.palette.gray.light,
                              label: "Current Items",
                              value: itemsStats?.current ?? 0
                            },
                            {
                              id: "planned",
                              color: theme.palette.system.purple,
                              label: "Planned Items",
                              value: itemsStats?.planned ?? 0
                            }
                          ]}
                        />
                      </Box>
                      <Stack direction={"row"} gap={2}>
                        <Stack direction={"row"}>
                          <Box
                            borderRadius={"2px"}
                            width={16}
                            height={16}
                            mr={1}
                            sx={{
                              backgroundColor: theme => theme.palette.gray.light
                            }}
                          />
                          <Typography variant={"h5"}>Current Items</Typography>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box
                            borderRadius={"2px"}
                            width={16}
                            height={16}
                            mr={1}
                            sx={{
                              backgroundColor: theme =>
                                theme.palette.system.purple
                            }}
                          />
                          <Typography variant={"h5"}>Planned Items</Typography>
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Card>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

const DashboardStat: FC<DashboardStat> = ({ stat, Icon, label, color }) => {
  return (
    <Stack gap={1}>
      <Avatar
        sx={{
          bgcolor: `${color ?? "info"}.lighter`,
          color: `${color ?? "primary"}.main`
        }}
      >
        <Icon />
      </Avatar>
      <Typography
        variant={"h5"}
        color={"gray.main"}
        sx={{ textTransform: "capitalize" }}
      >
        {label}
      </Typography>
      <Typography variant={"h1Bold"} color={"primary.darker"}>
        {stat}
      </Typography>
    </Stack>
  );
};

interface PromotionsTableProps {
  promotions: Array<PromotionDetailsSummary>;
}

const PromotionsTable = ({ promotions }: PromotionsTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>promotion</TableCell>
              <TableCell>start date</TableCell>
              <TableCell>end date</TableCell>
              <TableCell>date created</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(promotion => (
                <TableRow key={promotion.id}>
                  <TableCell>{promotion.name}</TableCell>
                  <TableCell>
                    {formatDateWithTZ(
                      promotion.promotionEventSummary.startDate
                    )}
                  </TableCell>
                  <TableCell>
                    {formatDateWithTZ(promotion.promotionEventSummary.endDate)}
                  </TableCell>
                  <TableCell>{formatDate(promotion.createdAt)}</TableCell>
                  <TableCell align={"right"}>
                    <Button
                      href={`${AppRoutes.Promotions}/${promotion.id}`}
                      variant={"outlined"}
                    >
                      view
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={promotions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

interface ActionsTableProps {
  promotions: Array<PromotionDetailsSummary>;
  displayType?: "supplier" | "user";
}

const ActionsTable = ({ promotions, displayType }: ActionsTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {displayType !== undefined && (
                <TableCell>{displayType}</TableCell>
              )}
              <TableCell>promotion</TableCell>
              <TableCell>action type</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(promotion => (
                <TableRow key={promotion.id}>
                  {displayType !== undefined && (
                    <TableCell>
                      {displayType === "supplier"
                        ? promotion.supplierName
                        : promotion.createdByUser}
                    </TableCell>
                  )}
                  <TableCell>{promotion.name}</TableCell>
                  <PromotionStatusCell status={promotion.status} />
                  <TableCell align={"right"}>
                    <Button
                      href={`${AppRoutes.Promotions}/${promotion.id}`}
                      variant={"outlined"}
                    >
                      view
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={promotions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default DashboardView;
