import { assertNotUndefined } from "@hx/util/types";
import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppService } from "@pmp/adl/app-service";
import { CreateWorkspaceReq } from "@pmp/adl/petstock/merchantportal/api";
import { AppRoutes, LoggedInContext } from "../../../app/app";
import { useAlert } from "../../../hooks/useAlertContext";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import {
  CreateNewWorkspace,
  CreateNewWorkspaceProps
} from "./create-new-workspace";
import { WorkspaceOverviewPageView } from "./workspace-overview-page-view";
import { DbKey } from "@pmp/adl/common/db";
import { SupplierWorkspace } from "@pmp/adl/petstock/merchantportal/db";

export const WorkspaceOverviewPage = () => {
  const service: AppService = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );
  const history = useHistory();
  const [showAlert] = useAlert();

  const loadWorkspaces = useCallback(
    async (searchTerm: string | null) => {
      return await service.queryWorkspaces({
        workspaceNameLike: searchTerm,
        supplierWorkspaceId: null
      });
    },
    [service]
  );

  const loadManufacturers = useCallback(async () => {
    return await service.queryManufacturers({});
  }, [service]);
  const [loadingManufacturers] = useLoadingDataState(loadManufacturers);

  const handleCreateWorkspace = useCallback(
    async (createWorkspaceReq: CreateWorkspaceReq) => {
      const resp = await service.createWorkspace(createWorkspaceReq);

      if (resp.kind === "success") {
        history.push(`${AppRoutes.Workspaces}/${resp.value}`);
      } else if (resp.kind === "failNameExists") {
        showAlert({
          title: "Error Creating Workspace",
          body: "A supplier workspace with that name already exists"
        });
      } else if (resp.kind === "failCodeExists") {
        showAlert({
          title: "Error Creating Workspace",
          body: "A supplier workspace with that supplier code already exists"
        });
      }
    },
    [service, history, showAlert]
  );

  const CreateNewWorkspaceModal: React.FC<Omit<
    CreateNewWorkspaceProps,
    "onCreateWorkspace"
  >> = useCallback(
    ({ open, onClose }) => {
      return (
        <CreateNewWorkspace
          open={open}
          onClose={onClose}
          onCreateWorkspace={handleCreateWorkspace}
          manufacturers={
            loadingManufacturers.state === "success"
              ? loadingManufacturers.value
              : []
          }
        />
      );
    },
    [handleCreateWorkspace, loadingManufacturers]
  );

  const loadWorkspaceActivities = useCallback(
    async (workspaceId: DbKey<SupplierWorkspace>) => {
      return await service.queryActivityLogs({
        supplierWorkspaceId: workspaceId,
        promotionId: null
      });
    },
    [service]
  );

  return (
    <WorkspaceOverviewPageView
      loadWorkspaces={loadWorkspaces}
      loadWorkspaceActivities={loadWorkspaceActivities}
      CreateWorkspaceModal={CreateNewWorkspaceModal}
      manufacturers={
        loadingManufacturers.state === "success"
          ? loadingManufacturers.value
          : []
      }
    />
  );
};
