import { AlertColor } from "@mui/material";
import React, { useContext } from "react";
import { AlertManagerProps } from "../app/alert-manager";

export const DEFAULT_ALERT_MANAGER_VALUES: AlertManagerProps = {
  title: "",
  body: "",
  isShown: false,
  type: "error"
};

export interface OpenAlertProps {
  title: string;
  body: string;
  type?: AlertColor;
}

type AlertContextCallbacks = () => [
  (props: OpenAlertProps) => Promise<void>,
  () => Promise<void>
];
export interface AlertContextProps extends AlertManagerProps {
  openAlert: (props: OpenAlertProps) => Promise<void>;
  closeAlert: () => Promise<void>;
}

/* TODO: re-implement this */

export const AlertContext = React.createContext<AlertContextProps>({
  ...DEFAULT_ALERT_MANAGER_VALUES,
  openAlert: ({}) => Promise.resolve(),
  closeAlert: () => Promise.resolve()
});

export const useAlert: AlertContextCallbacks = () => {
  const { openAlert, closeAlert } = useContext(AlertContext);
  return [openAlert, closeAlert];
};
