import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

interface Props {
  open: boolean;
}

export const LoadingBackdrop = ({ open }: Props) => {
  return (
    <Backdrop
      sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: "color.main" }}
      open={open}
      onClick={() => {
        // empty
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
