import { Button, Stack, Tab, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MarkNotificationsReq,
  MarkNotificationsResp,
  QueryNotificationsReq,
  QueryNotificationsResp,
  valuesNotificationGroup
} from "@pmp/adl/petstock/merchantportal/api";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation } from "react-router-dom";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { assertNever } from "@hx/util/types";
import { NotificationTable } from "./notification-table";
import { Loader } from "@pmp/common/loader/loader";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { useAlert } from "../../../hooks/useAlertContext";
import { CheckmarkOutlineIcon } from "src/ui/common/icon/icons";

const tabs = [
  {
    label: "Promotion",
    filterNotifications: (notifications: QueryNotificationsResp) =>
      notifications.filter(n => n.promotionId !== null)
  },
  {
    label: "General",
    filterNotifications: (notifications: QueryNotificationsResp) =>
      notifications.filter(n => n.promotionId === null)
  }
];

interface LoadingTab {
  label: string;
  notifications: QueryNotificationsResp;
}

export interface NotificationPageViewProps {
  queryNotifications: (
    req: QueryNotificationsReq
  ) => Promise<QueryNotificationsResp>;
  markNotifications: (
    req: MarkNotificationsReq
  ) => Promise<MarkNotificationsResp>;
}

export const NotificationPageView = ({
  queryNotifications,
  markNotifications
}: NotificationPageViewProps) => {
  const [showAlert] = useAlert();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(location.hash || "#promotion");

  useEffect(() => {
    setSelectedTab(location.hash);
  }, [location.hash]);

  const loadNotifications = useCallback(async () => {
    return await queryNotifications({
      group: null,
      unRead: false
    });
  }, [queryNotifications]);
  const [loadingNotifications, refreshNotifications] = useLoadingDataState(
    loadNotifications
  );

  const handleTabChange = (_, tab: string) => {
    if (window.location.hash !== tab) {
      window.location.hash = tab;
    }
  };

  const loadingTabs = useMemo<LoadingTab[]>(() => {
    return tabs.map(t => {
      if (loadingNotifications.state === "loading") {
        return { label: t.label, notifications: [] };
      } else if (loadingNotifications.state === "success") {
        return {
          label: t.label,
          notifications: t.filterNotifications(loadingNotifications.value)
        };
      } else if (loadingNotifications.state === "error") {
        return { label: t.label, notifications: [] };
      } else {
        assertNever(loadingNotifications);
      }
    });
  }, [loadingNotifications]);

  const handleMarkNotificationsAsRead = useCallback(async () => {
    const selectedTabValue = selectedTab.startsWith("#")
      ? selectedTab.substring(1)
      : selectedTab;
    const notificationGroup = valuesNotificationGroup.find(
      val => val.toString().toLowerCase() === selectedTabValue
    );
    const resp = await markNotifications({
      // Leave as empty to mark all user's unread notification by the specified group
      userNotificationIds: [],
      notificationGroup: notificationGroup ? notificationGroup : null
    });

    if (resp === "success") {
      refreshNotifications();
    } else {
      await showAlert({
        title: "Error updating notification(s)",
        body:
          "An error occurred while updating the notification(s), please try again."
      });
    }
  }, [markNotifications, refreshNotifications, selectedTab, showAlert]);

  return (
    <Stack>
      <Typography variant="h1Bold">All Notifications</Typography>
      <TabContext value={selectedTab}>
        <Stack
          sx={{ borderBottom: 1, borderColor: "divider" }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <TabList onChange={handleTabChange}>
            {loadingTabs.map(tab => (
              <Tab
                key={tab.label}
                label={`${tab.label}(${
                  tab.notifications.filter(n => n.readAt === null).length
                })`}
                value={`#${tab.label.toLowerCase()}`}
              />
            ))}
          </TabList>
          <Button
            variant="text"
            endIcon={<CheckmarkOutlineIcon />}
            onClick={handleMarkNotificationsAsRead}
          >
            Mark all as read
          </Button>
        </Stack>

        {loadingTabs.map(tab => (
          <TabPanel
            key={tab.label}
            value={`#${tab.label.toLowerCase()}`}
            sx={{ padding: 0, paddingTop: 2 }}
          >
            <Loader loadingStates={[loadingNotifications]}>
              {isLoaded(loadingNotifications) && (
                <NotificationTable
                  notifications={tab.notifications}
                  markNotifications={markNotifications}
                  isNormalTableView={true}
                />
              )}
            </Loader>
          </TabPanel>
        ))}
      </TabContext>
    </Stack>
  );
};
