import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import PasswordInput from "@pmp/common/inputs/password-input/password-input";
import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { object, ref, string } from "yup";
import {
  VerifyUserReq,
  VerifyUserResp
} from "../../../adl-gen/petstock/merchantportal/api";
import { AppRoutes } from "../../../app/app";
import GetHelp from "../../components/GetHelp";

export interface VerifyAccountPageProps {
  /** Error message to show upon verification failure */
  verifyAccountError?: string;
  /** Callback to verify account */
  onVerify(req: VerifyUserReq): Promise<VerifyUserResp>;
}

interface VerifyAccountFormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = object().shape({
  password: string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/\W/, "Password requires a symbol")
    .required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password"), null], 'Must match "password" field value')
    .required("Password is required")
});

export const VerifyAccountPage = (props: VerifyAccountPageProps) => {
  const history = useHistory();
  const location = useLocation();

  /** Callback to verify account*/
  const onVerify = async (values: VerifyAccountFormValues) => {
    const { password } = values;
    if (secret.current) {
      const resp = await props.onVerify({
        password,
        secret: secret.current
      });
      if (resp.kind === "accessToken") {
        location.pathname = AppRoutes.Index;
        history.push(location);
      }
    }
  };

  // On initial mount, get the JWT token
  const secret = useRef<string>();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("code")) {
      secret.current = params.get("code") || "";
      params.delete("code");
    } else {
      // TODO redirect the user to the login page
    }
    // Dependency array deliberately kept empty so effect only runs once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Render the page */
  return (
    <Container maxWidth={"sm"}>
      <Paper elevation={4}>
        <Stack spacing={4} py={7} px={10}>
          <Box>
            <Typography variant="h1Bold">
              Set password to verify your account
            </Typography>
            {props.verifyAccountError && (
              <Alert severity="error">{props.verifyAccountError}</Alert>
            )}
          </Box>
          <Formik<VerifyAccountFormValues>
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
            validationSchema={validationSchema}
            onSubmit={onVerify}
          >
            {({ isValid }) => (
              <Form>
                <Stack spacing={4}>
                  {props.verifyAccountError && (
                    <Alert severity="error">{props.verifyAccountError}</Alert>
                  )}
                  <PasswordInput
                    name="password"
                    label="New Password"
                    placeholder="New Password"
                  />
                  <PasswordInput
                    name="confirmPassword"
                    label="Confirm New Password"
                    placeholder="Confirm Password"
                  />
                  <Button fullWidth type="submit" disabled={!isValid}>
                    Change Password
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Paper>
      <Box position={"absolute"} left={"50%"} bottom={0}>
        <GetHelp />
      </Box>
    </Container>
  );
};
