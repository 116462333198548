import React, { useCallback } from "react";
import { InviteWorkspaceUserReq } from "@pmp/adl/petstock/merchantportal/api";

import { useAlert } from "../../../hooks/useAlertContext";
import { useAppService } from "../../../hooks/useAppService";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { Loader } from "@pmp/common/loader/loader";
import {
  WorkspaceInviteNewUserPageView,
  WorkspaceInviteUserPageProps
} from "../workspace-invite-new-user/workspace-invite-new-user-page-view";
import { UserManagementPageView } from "./user-management-page-view";

export const UserManagementPage = () => {
  const service = useAppService();

  const loadUsers = useCallback(
    async (searchTerm: string | null) => {
      return await service.queryUsers({
        userId: null,
        supplierWorkspaceId: null,
        searchQuery: searchTerm
      });
    },
    [service]
  );

  const loadWorkspaces = useCallback(async () => {
    return await service.queryWorkspaces({
      workspaceNameLike: null,
      supplierWorkspaceId: null
    });
  }, [service]);
  const [loadingWorkspaces] = useLoadingDataState(loadWorkspaces);

  const loadRoles = useCallback(async () => {
    return await service.queryRoles({});
  }, [service]);
  const [loadingRoles] = useLoadingDataState(loadRoles);

  const [showAlert] = useAlert();
  const handleInviteNewUser = useCallback(
    async (inviteWorkspaceUserReq: InviteWorkspaceUserReq) => {
      return await service.inviteWorkspaceUser(inviteWorkspaceUserReq);
    },
    [service]
  );

  const InviteWorkspaceUsersModal: React.FC<Omit<
    WorkspaceInviteUserPageProps & { refreshUsers: () => void },
    "onInvite"
  >> = useCallback(
    ({ open, workspaces, roles, onClose, refreshUsers }) => {
      return (
        <WorkspaceInviteNewUserPageView
          open={open}
          onClose={onClose}
          roles={roles}
          workspaces={workspaces}
          onInvite={async (inviteWorkspaceUserReq: InviteWorkspaceUserReq) => {
            const resp = await handleInviteNewUser(inviteWorkspaceUserReq);
            if (resp === "success") {
              refreshUsers();
              onClose();
              showAlert({
                title: "Invite successful",
                body: "Invite has been sent to the new user",
                type: "success"
              });
            } else {
              showAlert({
                title: "Error inviting user",
                body:
                  "The email address you entered is already being used by another user in the system"
              });
            }

            return resp;
          }}
        />
      );
    },
    [handleInviteNewUser, showAlert]
  );

  return (
    <Loader loadingStates={[loadingWorkspaces, loadingRoles]}>
      {isLoaded(loadingWorkspaces) && isLoaded(loadingRoles) && (
        <UserManagementPageView
          workspaces={loadingWorkspaces.value}
          loadUsers={loadUsers}
          roles={loadingRoles.value}
          InviteWorkspaceUsersModal={InviteWorkspaceUsersModal}
        />
      )}
    </Loader>
  );
};
