import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { array, object, string } from "yup";

import {
  InviteExistingUserResp,
  QueryUsersResp
} from "../../../adl-gen/petstock/merchantportal/api";
import { DbKey } from "../../../adl-gen/common/db";
import { AppUser } from "../../../adl-gen/petstock/merchantportal/db";
import { LoadingButton } from "@mui/lab";
import { CrossIcon } from "src/ui/common/icon/icons";
import { SelectOption } from "@pmp/common/inputs/select-input/select-input";

export interface InviteExistingUserDialogProps {
  open: boolean;
  users: QueryUsersResp;
  workspaceName: string;
  onClose: () => void;
  onSendInvite: (
    invitedUser: DbKey<AppUser>
  ) => Promise<InviteExistingUserResp>;
}

interface InviteExistingUserForm {
  userId: DbKey<AppUser>[];
}

export const InviteExistingUserDialog = (
  props: InviteExistingUserDialogProps
) => {
  const { open, users, workspaceName, onClose, onSendInvite } = props;
  const [submitting, setSubmitting] = useState(false);

  const userOptions: SelectOption[] = useMemo(() => {
    return users
      .filter(user => user.inactiveAt === null)
      .map(user => {
        const userName = `${user.name.firstName} ${user.name.lastName}`;
        return {
          item: (
            <Stack>
              <Typography>{userName}</Typography>
              <Typography variant="body1">{user.email}</Typography>
            </Stack>
          ),
          title: userName,
          value: user.id
        };
      });
  }, [users]);

  const handleSubmit = useCallback(
    async (values: InviteExistingUserForm) => {
      if (values.userId.length > 0) {
        setSubmitting(true);
        await onSendInvite(values.userId[0]);
        setSubmitting(false);
      }
    },
    [onSendInvite]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      PaperProps={{
        sx: {
          padding: 4
        }
      }}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        variant="h2Bold"
      >
        Invite More Users
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText variant="h5">
            Invite new users to collaborate in{" "}
            <Box component="span" fontWeight={600}>
              {workspaceName}
            </Box>
          </DialogContentText>
          <Formik<InviteExistingUserForm>
            initialValues={{
              userId: []
            }}
            validationSchema={object().shape({
              userId: array()
                .of(string().required("Email address is required"))
                .min(1, "Email address is required")
            })}
            onSubmit={handleSubmit}
          >
            <Form id="invite-existing-user-form">
              <AutocompleteSelectInput
                name="userId"
                label="Email Address"
                options={userOptions}
                noOptionsText="User does not exist or cannot be invited. Please try again or contact your Super Admin."
                required
              />
            </Form>
          </Formik>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <LoadingButton
          loading={submitting}
          type="submit"
          form="invite-existing-user-form"
          fullWidth
          variant="contained"
        >
          Send Invite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
