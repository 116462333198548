import { useFormikContext } from "formik";
import _ from "lodash";
import { FC, useCallback, useEffect, useState } from "react";

interface SubmitListenerProps {
  debounceWait?: number;
}

/**
 * Listener for auto submitting formik forms whenever a form value updates. This is particularly
 * useful for performing searches and filtering.
 */
export const SubmitListener: FC<SubmitListenerProps> = ({
  debounceWait = 500
}) => {
  const formik = useFormikContext();
  const [lastValues, updateState] = useState(formik.values);

  // Note: The dependecies are intentionally left empty to allow the debounce to work. Otherwise,
  // each update to the form will cause the submitForm function to run multiple times.
  // E.g. A user typing in a search field will trigger a form submission for each character pressed.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitForm = useCallback(
    _.debounce(formik.submitForm, debounceWait),
    []
  );

  useEffect(() => {
    const valuesEqualLastValues = _.isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    // Only submit the form if the values are different and valid
    if (!valuesEqualLastValues && formik.isValid) {
      submitForm();
    }
  }, [
    formik.initialValues,
    formik.values,
    formik.isValid,
    lastValues,
    submitForm
  ]);

  return null;
};
