import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { userTypeToText } from "@pmp/utils/adl";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router-dom";

import { assertNever } from "@hx/util/types";
import { DbKey } from "@pmp/adl/common/db";
import { QueryUsersResp } from "@pmp/adl/petstock/merchantportal/api";
import { AppUser } from "@pmp/adl/petstock/merchantportal/db";
import { AppRoutes } from "../../../app/app";
import { useAlert } from "../../../hooks/useAlertContext";
import { useAppService } from "../../../hooks/useAppService";
import { useFeatures } from "../../../hooks/useFeatures";
import { isLoaded, LoadingValue } from "@pmp/utils/UtilityTypes";
import { Loader } from "@pmp/common/loader/loader";
import { MoreVerticalIcon } from "src/ui/common/icon/icons";

interface LoadingTab {
  label: ReactNode;
  users: QueryUsersResp;
}

interface TabContent {
  label: ReactNode;
  filterUsers: (users: QueryUsersResp) => QueryUsersResp;
}

export interface ManageUsersTableProps {
  tabs: TabContent[];
  loadingUsers: LoadingValue<QueryUsersResp>;
  refreshUsers: () => void;
  allowArchiving: boolean;
}

export const ManageUsersTable = ({
  tabs,
  loadingUsers,
  refreshUsers,
  allowArchiving
}: ManageUsersTableProps) => {
  const [openArchiveUser, setOpenArchiveUser] = useState(false);
  const [openUnarchiveUser, setOpenUnarchiveUser] = useState(false);
  const [openResendVerification, setResendVerification] = useState(false);

  const [userToArchive, setUserToArchive] = useState<
    DbKey<AppUser> | undefined
  >(undefined);
  const [userToUnarchive, setUserToUnarchive] = useState<
    DbKey<AppUser> | undefined
  >(undefined);
  const [userToResendVerification, setUserToResendVerification] = useState<
    DbKey<AppUser> | undefined
  >(undefined);
  const service = useAppService();

  const [selectedTab, setSelectedTab] = useState("0");
  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, tabIndex: string) => {
      setSelectedTab(tabIndex);
    },
    [setSelectedTab]
  );

  const loadingTabs = useMemo<LoadingTab[]>(() => {
    return tabs.map(t => {
      if (loadingUsers.state === "loading") {
        return { label: t.label, users: [] };
      } else if (loadingUsers.state === "success") {
        return { label: t.label, users: t.filterUsers(loadingUsers.value) };
      } else if (loadingUsers.state === "error") {
        // TODO: (eric) handle error loading users
        return { label: t.label, users: [] };
      } else {
        assertNever(loadingUsers);
      }
    });
  }, [tabs, loadingUsers]);

  const openArchiveUserDialog = (userId: DbKey<AppUser>) => {
    setUserToArchive(userId);
    setOpenArchiveUser(true);
  };

  const openUnarchiveUserDialog = (userId: DbKey<AppUser>) => {
    setUserToUnarchive(userId);
    setOpenUnarchiveUser(true);
  };

  const openResendVerificationDialog = (userId: DbKey<AppUser>) => {
    setUserToResendVerification(userId);
    setResendVerification(true);
  };

  const [showAlert] = useAlert();
  const handleArchiveUser = useCallback(async () => {
    if (userToArchive) {
      const resp = await service.archiveUser(userToArchive);

      if (resp !== "success") {
        showAlert({
          title: "Error archiving user",
          body: "Error archiving user"
        });
      } else {
        setOpenArchiveUser(false);
        refreshUsers();
      }
    }
  }, [service, refreshUsers, showAlert, userToArchive]);

  const handleUnarchiveUser = useCallback(async () => {
    if (userToUnarchive) {
      const resp = await service.unarchiveUser(userToUnarchive);

      if (resp !== "success") {
        showAlert({
          title: "Error unarchiving user",
          body: "Error unarchiving user"
        });
      } else {
        setOpenUnarchiveUser(false);
        refreshUsers();
      }
    }
  }, [service, refreshUsers, showAlert, userToUnarchive]);

  const handleResendUserVerification = useCallback(async () => {
    if (userToResendVerification) {
      const resp = await service.resendVerification({
        userId: userToResendVerification
      });

      if (resp !== "success") {
        showAlert({
          title: "Error resending user verification",
          body: ""
        });
      } else {
        showAlert({
          title: "Successfuly re-sent verification email",
          body: "",
          type: "success"
        });
        setResendVerification(false);
        refreshUsers();
      }
    }
  }, [refreshUsers, userToResendVerification, service, showAlert]);

  const history = useHistory();
  const handleEditUser = (userId: DbKey<AppUser>) => {
    if (userId) {
      history.push(`${AppRoutes.Users}/${userId}/edit`);
    }
  };

  return (
    <TabContext value={selectedTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleTabChange}>
          {loadingTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} value={index.toString()} />
          ))}
        </TabList>
      </Box>
      {loadingTabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={index.toString()}
          sx={{ padding: 0, paddingTop: 2 }}
        >
          <Loader loadingStates={[loadingUsers]}>
            {isLoaded(loadingUsers) && (
              <UserTable
                users={tab.users}
                onArchiveUser={openArchiveUserDialog}
                onUnarchiveUser={openUnarchiveUserDialog}
                onEditUser={handleEditUser}
                onResendVerification={openResendVerificationDialog}
                allowArchiving={allowArchiving}
              />
            )}
          </Loader>
        </TabPanel>
      ))}
      <ArchiveUsersDialog
        open={openArchiveUser}
        onClose={() => setOpenArchiveUser(false)}
        onArchive={handleArchiveUser}
      />
      <UnarchiveUsersDialog
        open={openUnarchiveUser}
        onClose={() => setOpenUnarchiveUser(false)}
        onUnarchive={handleUnarchiveUser}
      />
      <ResendVerificationDialog
        open={openResendVerification}
        onClose={() => setResendVerification(false)}
        onResendVerification={handleResendUserVerification}
      />
    </TabContext>
  );
};

interface UserTableProps {
  users: QueryUsersResp;
  onArchiveUser: (userId: DbKey<AppUser>) => void;
  onUnarchiveUser: (userId: DbKey<AppUser>) => void;
  onEditUser: (userId: DbKey<AppUser>) => void;
  onResendVerification: (userId: DbKey<AppUser>) => void;
  allowArchiving: boolean;
}
const UserTable = ({
  users,
  onArchiveUser,
  onUnarchiveUser,
  onEditUser,
  onResendVerification,
  allowArchiving
}: UserTableProps) => {
  const { hasEditorAccess } = useFeatures();
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>USERS</TableCell>
            <TableCell>EMAIL ADDRESS</TableCell>
            <TableCell>USER TYPE</TableCell>
            <TableCell>ORGANISATION</TableCell>
            <TableCell>USER ROLE</TableCell>
            {hasEditorAccess("userInformation") && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => {
            return (
              <TableRow key={user.id}>
                <TableCell>
                  <Link
                    onClick={() => onEditUser(user.id)}
                  >{`${user.name.firstName} ${user.name.lastName}`}</Link>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{userTypeToText(user.userType)}</TableCell>
                <TableCell>{user.organisation}</TableCell>
                <TableCell>{user.role?.value.name ?? ""}</TableCell>
                {hasEditorAccess("userInformation") && (
                  <UserActionsCell
                    userId={user.id}
                    inactiveAt={user.inactiveAt}
                    verifiedAt={user.verifiedAt}
                    onArchiveUser={onArchiveUser}
                    onUnarchiveUser={onUnarchiveUser}
                    onEditUser={() => onEditUser(user.id)}
                    onResendVerification={onResendVerification}
                    allowArchiving={allowArchiving}
                  />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface UserActionsCellProps {
  userId: DbKey<AppUser>;
  inactiveAt: number | null;
  verifiedAt: number | null;
  onArchiveUser: (userId: DbKey<AppUser>) => void;
  onUnarchiveUser: (userId: DbKey<AppUser>) => void;
  onEditUser: (userId: DbKey<AppUser>) => void;
  onResendVerification: (userId: DbKey<AppUser>) => void;
  allowArchiving: boolean;
}
const UserActionsCell = ({
  userId,
  inactiveAt,
  verifiedAt,
  onArchiveUser,
  onUnarchiveUser,
  onEditUser,
  onResendVerification,
  allowArchiving
}: UserActionsCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { hasEditorAccess } = useFeatures();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderActionItems = () => {
    const actionItems: JSX.Element[] = [];
    if (inactiveAt === null) {
      actionItems.push(
        <MenuItem
          key="edit-user"
          onClick={() => {
            onEditUser(userId);
            handleClose();
          }}
        >
          Edit User
        </MenuItem>
      );

      if (hasEditorAccess("archiveUser") && allowArchiving) {
        actionItems.push(
          <MenuItem
            key="archive-user"
            onClick={() => {
              onArchiveUser(userId);
              handleClose();
            }}
          >
            Archive User
          </MenuItem>
        );
      }
    } else {
      if (hasEditorAccess("archiveUser") && allowArchiving) {
        actionItems.push(
          <MenuItem
            key="unarchive-user"
            onClick={() => {
              onUnarchiveUser(userId);
              handleClose();
            }}
          >
            Unarchive User
          </MenuItem>
        );
      }
    }

    if (verifiedAt === null && hasEditorAccess("userCreation")) {
      actionItems.push(
        <MenuItem
          key="resend-verification"
          onClick={() => {
            onResendVerification(userId);
            handleClose();
          }}
        >
          Resend Verification Email
        </MenuItem>
      );
    }

    if (actionItems.length > 0) {
      return (
        <>
          <IconButton onClick={handleClick}>
            <MoreVerticalIcon />
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {actionItems}
          </Menu>
        </>
      );
    }
    return undefined;
  };

  return <TableCell>{renderActionItems()}</TableCell>;
};

interface ArchiveUsersDialogProps {
  open: boolean;
  onClose: () => void;
  onArchive: () => Promise<void>;
}

const ArchiveUsersDialog = ({
  open,
  onClose,
  onArchive
}: ArchiveUsersDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  // Ensure the submitting state is set to false when the dialog is unmounted
  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, [open]);

  const handleArchive = async () => {
    setSubmitting(true);
    await onArchive();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Archive User?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          User will be archived from all workspaces they are currently in
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={submitting}
          onClick={handleArchive}
        >
          Archive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface UnarchiveUsersDialogProps {
  open: boolean;
  onClose: () => void;
  onUnarchive: () => Promise<void>;
}

const UnarchiveUsersDialog = ({
  open,
  onClose,
  onUnarchive
}: UnarchiveUsersDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  // Ensure the submitting state is set to false when the dialog is unmounted
  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, [open]);

  const handleUnarchive = async () => {
    setSubmitting(true);
    await onUnarchive();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Unarchive User?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          User will be unarchived and made active again
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={submitting}
          onClick={handleUnarchive}
        >
          Unarchive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface ResendVerificationDialogProps {
  open: boolean;
  onClose: () => void;
  onResendVerification: () => Promise<void>;
}

const ResendVerificationDialog = ({
  open,
  onClose,
  onResendVerification
}: ResendVerificationDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  // Ensure the submitting state is set to false when the dialog is unmounted
  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, [open]);

  const handleAction = async () => {
    setSubmitting(true);
    await onResendVerification();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Re-send user verification email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          User will be re-sent a verification email
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={submitting}
          onClick={handleAction}
        >
          Resend
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
