import { Components, Theme } from "@mui/material/styles";

export default function List(theme: Theme): Components {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textTransform: "capitalize"
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.info.main,
            borderRadius: theme.shape.borderRadius,
            border: "1px solid",
            borderColor: theme.palette.gray.lighter,
            fontWeight: 600
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          ...theme.typography.body1,
          textTransform: "uppercase",
          backgroundColor: theme.palette.gray.lighter,
          paddingTop: 6,
          paddingBottom: 6
        }
      }
    }
  };
}
