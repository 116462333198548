import { Components, Theme } from "@mui/material/styles";

export default function Chip(theme: Theme): Components {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          ...theme.typography.h6,
          fontWeight: "bold"
        }
      }
    }
  };
}
