import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Theme,
  Typography
} from "@mui/material";
import { DbKey, WithDbId } from "@pmp/adl/common/db";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  ArchivePromotionNoteResp,
  CreatePromotionNoteReq,
  CreatePromotionNoteResp,
  GetDownloadLinkReq,
  PromotionDetailsSummary,
  PromotionNoteSummary,
  QueryActivityLogResp,
  QueryPromotionNotesReq,
  QueryPromotionNotesResp,
  UpdateAdminOnlyFieldsReq,
  UpdatePromotionOwnerReq,
  UpdatePromotionReq
} from "@pmp/adl/petstock/merchantportal/api";
import {
  AppUser,
  FundingType,
  Promotion,
  PromotionEvent,
  PromotionPriority,
  valuesFundingType,
  valuesPromotionPriority
} from "@pmp/adl/petstock/merchantportal/db";
import {
  PromotionDurationType,
  PromotionNoteTag,
  PromotionStatus,
  valuesPromotionNoteTag
} from "@pmp/adl/petstock/merchantportal/types";
import SelectInput, {
  SelectOption,
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import TextareaInput from "@pmp/common/inputs/textarea-input/textarea-input";
import { Loader } from "@pmp/common/loader/loader";
import {
  formatAppUserName,
  fundingTypeToText,
  promotionEventDurationTypeToText,
  promotionPriorityToText,
  promotionTypeToText
} from "@pmp/utils/adl";
import {
  datesDiffWithTZ,
  formatDateMonthDayYearWithTZ,
  formatDateMonthWithTZ,
  formatDateMonthYearShortWithTZ,
  formatDateMonthYearWithTZ,
  formatDateTime,
  getTimeZonedTime
} from "@pmp/utils/dates";
import { isLoaded, LoadingValue } from "@pmp/utils/UtilityTypes";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppService } from "src/hooks/useAppService";
import { useFeatures } from "src/hooks/useFeatures";
import { object, string } from "yup";
import { useAlert } from "../../../hooks/useAlertContext";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import DateRange from "../../components/DateRange";
import PromoStatusChip from "../../components/PromotionStatus/PromoStatusChip";
import { ActivityLogTable } from "../../widgets/activity-log-table/activity-log-table";
import { SubmitListener } from "../../widgets/form/helpers";
import PromotionItemsTable from "../../widgets/tables/promotion-items-table";
import { PromotionApprovalsTable } from "../../widgets/tables/promotion-approvals-table";
import {
  EditIcon,
  MoreVerticalIcon,
  PlusIcon,
  TrashIcon,
  RestoreFromTrashIcon
} from "src/ui/common/icon/icons";
import AddNoteDialog from "src/ui/widgets/forms/add-note-dialog";
import { editablePromoStatusBySuperAdmin } from "@pmp/utils/constants";

type PromotionEventData = WithDbId<
  Pick<PromotionEvent, "durationType" | "startDate" | "endDate" | "name">
>;

export interface PromotionPageViewProps {
  promotion: PromotionDetailsSummary;
  promotionEvents: PromotionEventData[];
  updatePromotion: (req: UpdatePromotionReq) => Promise<void>;
  updatePromotionOwner: (req: UpdatePromotionOwnerReq) => Promise<void>;
  updateAdminOnlyFields: (req: UpdateAdminOnlyFieldsReq) => Promise<void>;
  loadActivityLogs: (
    promotionId: DbKey<Promotion>
  ) => Promise<QueryActivityLogResp>;
  queryPromotionNotes: (
    req: QueryPromotionNotesReq
  ) => Promise<QueryPromotionNotesResp>;
  archiveNote: (
    note: PromotionNoteSummary
  ) => Promise<ArchivePromotionNoteResp>;
  createNote: (req: CreatePromotionNoteReq) => Promise<CreatePromotionNoteResp>;
  submitPromotion: () => Promise<void>;
  unsubmitPromotion: () => Promise<void>;
  attachFile: (file: File) => Promise<string>;
  downloadFile: (req: GetDownloadLinkReq) => Promise<string | null>;
  refreshPromotion: () => void;
  brands: string[];
  classes: string[];
  manufacturers: string[];
  groups: string[];
  workspaceId: string;
}

export const PromotionPageView: FC<PromotionPageViewProps> = ({
  promotion,
  promotionEvents,
  updatePromotion,
  updatePromotionOwner,
  updateAdminOnlyFields,
  loadActivityLogs,
  queryPromotionNotes,
  archiveNote,
  createNote,
  submitPromotion,
  unsubmitPromotion,
  attachFile,
  downloadFile,
  refreshPromotion,
  brands,
  classes,
  manufacturers,
  groups,
  workspaceId
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationDialogState, setConfirmationDialogState] = useState({
    message: "",
    title: "",
    onClose: () => {
      return;
    },
    onConfirm: () => Promise.resolve()
  });
  const { hasEditorAccess, isApprover, userType, isSuperAdmin } = useFeatures();

  const { itemCount, awardItemCount, qualifyingItemCount, status } = promotion;

  const a11yProps = (index: number) => {
    return {
      id: `promotion-tab-${index}`,
      "aria-controls": `promotion-tabpanel-${index}`
    };
  };

  const queryActivityLogs = useCallback(async () => {
    if (promotion) {
      return await loadActivityLogs(promotion.id);
    }
    return [];
  }, [loadActivityLogs, promotion]);
  const [loadingActivityLogs] = useLoadingDataState(queryActivityLogs);

  const dateSortingOptions: SelectOptions = [
    {
      title: "Newest",
      value: "newest"
    },
    {
      title: "Oldest",
      value: "oldest"
    }
  ];

  const tagOptions: SelectOptions = valuesPromotionNoteTag.map(tag => {
    return {
      title: `${tag[0].toUpperCase()}${tag.slice(1).toLowerCase()}`,
      value: tag.valueOf()
    };
  });

  const showArchivedNotesOptions: SelectOptions = [
    {
      title: "Hide Archived Notes",
      value: "false"
    },
    {
      title: "Show Archived Notes",
      value: "true"
    }
  ];

  const [showAlert] = useAlert();
  const [
    selectedShowArchivedNotesOption,
    setSelectedShowArchivedNotesOption
  ] = useState("true");
  const [selectedTagOption, setSelectedTagOption] = useState("all");
  const [selectedSortByOption, setSelectedSortByOption] = useState("newest");

  const queryNotes = useCallback(async () => {
    if (promotion) {
      let tags: PromotionNoteTag[];
      if (selectedTagOption === "all") {
        tags = valuesPromotionNoteTag;
      } else {
        tags = valuesPromotionNoteTag.filter(
          value => value.valueOf() === selectedTagOption
        );
      }

      const resp = await queryPromotionNotes({
        promotionId: promotion.id,
        tags: tags,
        showArchivedNotes: selectedShowArchivedNotesOption === "true"
      });

      if (selectedSortByOption === "newest") {
        resp.sort((a, b) => b.createdAt - a.createdAt);
      } else {
        resp.sort((a, b) => a.createdAt - b.createdAt);
      }

      return resp;
    }
    return [];
  }, [
    promotion,
    queryPromotionNotes,
    selectedShowArchivedNotesOption,
    selectedSortByOption,
    selectedTagOption
  ]);
  const [loadingPromotionNotes, refreshNotes] = useLoadingDataState(queryNotes);

  const handleShowArchivedNotesChange = useCallback(
    async (event: SelectChangeEvent) => {
      setSelectedShowArchivedNotesOption(event.target.value);
      refreshNotes();
    },
    [refreshNotes]
  );

  const handleTagChange = useCallback(
    async (event: SelectChangeEvent) => {
      setSelectedTagOption(event.target.value);
      refreshNotes();
    },
    [refreshNotes]
  );

  const handleSortByChange = useCallback(
    async (event: SelectChangeEvent) => {
      setSelectedSortByOption(event.target.value);
      refreshNotes();
    },
    [refreshNotes]
  );

  const handleArchiveNote = useCallback(
    async (note: PromotionNoteSummary) => {
      const resp = await archiveNote(note);
      if (resp === "success") {
        refreshNotes();
      } else {
        await showAlert({
          title: "Error updating note",
          body: "An error occurred while updating the note, please try again."
        });
      }
    },
    [archiveNote, refreshNotes, showAlert]
  );

  const handleDownloadAttachment = useCallback(
    async (promotionNoteAttachmentId: string) => {
      // Get pre-signed URL for download
      const downloadFileLink = await downloadFile({
        promotionNoteAttachmentId: promotionNoteAttachmentId
      });

      if (downloadFileLink) {
        // Download the file
        const link = document.createElement("a");
        link.download = `fileName`;
        link.href = downloadFileLink;
        link.click();
      }
    },
    [downloadFile]
  );

  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const handleAddNote = useCallback(
    async (createNoteReq: Omit<CreatePromotionNoteReq, "promotionId">) => {
      const resp = await createNote({
        promotionId: promotion.id,
        note: createNoteReq.note,
        tag: createNoteReq.tag,
        promotionNoteAttachments: createNoteReq.promotionNoteAttachments
      });
      if (resp.kind === "success") {
        setOpenNoteDialog(false);
        refreshNotes();
      }
    },
    [createNote, promotion.id, refreshNotes]
  );

  const AddNewNoteDialog = () => {
    return (
      <AddNoteDialog
        handleAddNote={handleAddNote}
        showTagsSelect={true}
        showAttachmentsField={true}
        handleAttachFile={attachFile}
        isOpen={openNoteDialog}
        onClose={() => setOpenNoteDialog(false)}
      />
    );
  };

  const handleUnsubmit = useCallback(() => {
    setOpenConfirmationDialog(true);
    setConfirmationDialogState({
      message: `Are you sure you want to unsubmit “${promotion.name}"? It won't be reviewed by Petspiration staff until you resubmit it.`,
      title: "Unsubmit Promotion",
      onClose: () => setOpenConfirmationDialog(false),
      onConfirm: unsubmitPromotion
    });
  }, [promotion.name, unsubmitPromotion]);

  /**
   * This handles the click action of the primary action button on the promotion page. This button can either be the
   * Submit, Unsubmit or Approval Actions button depending on the status of the promotion and the role/permissions of
   * the user.
   */
  const handlePromotionActionClick = useCallback(() => {
    const canSubmitPromotion = hasEditorAccess("promotionCreation");

    switch (promotion.status) {
      case "draft":
      case "renegotiation":
        if (canSubmitPromotion) {
          setOpenConfirmationDialog(true);
          setConfirmationDialogState({
            message: `Are you sure you want to submit “${promotion.name}"? `,
            title: "Submit Promotion",
            onClose: () => setOpenConfirmationDialog(false),
            onConfirm: submitPromotion
          });
        }
        break;
      case "submitted":
        if (isApprover()) {
          setActiveTab(1);
        } else if (canSubmitPromotion) {
          handleUnsubmit();
        }
        break;
      case "underReview":
      case "scheduled":
      case "completed":
        setActiveTab(1);
        break;
    }
  }, [
    handleUnsubmit,
    hasEditorAccess,
    isApprover,
    promotion.name,
    promotion.status,
    submitPromotion
  ]);

  const hasRequiredItemCount = useCallback(() => {
    const isFixedPriceMultiBuyAward =
      promotion.promotionType.kind === "fixedPriceDiscount" &&
      promotion.promotionType.value.kind === "multiBuyAward";
    const isSalePriceBuyBreakAward =
      promotion.promotionType.kind === "salePriceDiscount" &&
      (promotion.promotionType.value.kind === "buybreakAward" ||
        promotion.promotionType.value.kind === "buybreakAwardQty");

    if (isFixedPriceMultiBuyAward || isSalePriceBuyBreakAward) {
      return promotion.qualifyingItemCount > 0 && promotion.awardItemCount > 0;
    } else {
      return promotion.itemCount > 0;
    }
  }, [promotion]);

  /**
   * This handles the label and disabled state of the primary action button on the promotion page. Depending on
   * the status of the promotion and the role/permission of the user, the label and disabled state of the button
   * changes.
   */
  const renderPromotionActionButton = useCallback(() => {
    let buttonLabel = "Submit";
    let isEnabled = true;
    let showSuperAdminUnsubmit = false;
    let superAdminUnsubmitEnabled = true;

    switch (promotion.status) {
      case "draft":
      case "renegotiation":
        if (
          hasEditorAccess("promotionCreation") &&
          promotion.owner !== null &&
          hasRequiredItemCount()
        ) {
          buttonLabel = "Submit";
          isEnabled = true;
        } else {
          isEnabled = false;
        }
        break;
      case "rejected":
        isEnabled = false;
        superAdminUnsubmitEnabled = false;
        if (isApprover()) {
          buttonLabel = "Approval Actions";
          if (isSuperAdmin()) {
            showSuperAdminUnsubmit = true;
          }
        } else {
          buttonLabel = "Submit";
        }
        break;
      case "submitted":
        if (isApprover()) {
          buttonLabel = "Approval Actions";
          isEnabled = true;
          if (isSuperAdmin()) {
            showSuperAdminUnsubmit = true;
          }
        } else {
          buttonLabel = "Unsubmit";
          isEnabled = userType === "supplierUser";
        }
        break;
      case "underReview":
      case "scheduled":
        superAdminUnsubmitEnabled = false;
        if (isApprover()) {
          buttonLabel = "Approval Actions";
          isEnabled = true;
          if (isSuperAdmin()) {
            showSuperAdminUnsubmit = true;
          }
        } else {
          buttonLabel = "Unsubmit";
          isEnabled = false;
        }
        break;
      case "completed":
        isEnabled = false;
        superAdminUnsubmitEnabled = false;
        if (isApprover()) {
          buttonLabel = "Approval Actions";
          if (isSuperAdmin()) {
            showSuperAdminUnsubmit = true;
          }
        } else {
          buttonLabel = "Unsubmit";
        }
        break;
    }

    return (
      <>
        {showSuperAdminUnsubmit && (
          <Button
            disabled={!superAdminUnsubmitEnabled}
            onClick={handleUnsubmit}
          >
            Unsubmit
          </Button>
        )}
        <Button disabled={!isEnabled} onClick={handlePromotionActionClick}>
          {buttonLabel}
        </Button>
      </>
    );
  }, [
    promotion.status,
    promotion.owner,
    handleUnsubmit,
    handlePromotionActionClick,
    hasEditorAccess,
    hasRequiredItemCount,
    isApprover,
    isSuperAdmin,
    userType
  ]);

  return (
    <Stack spacing={2}>
      <AddNewNoteDialog />
      <PromotionConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationDialogState.message}
        title={confirmationDialogState.title}
        onClose={confirmationDialogState.onClose}
        onConfirm={confirmationDialogState.onConfirm}
      />
      <PromotionAlert
        promotion={promotion}
        hasRequiredItemCount={hasRequiredItemCount}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack alignItems={"flex-start"}>
          <PromoStatusChip status={status} />
          <PromotionName
            promotion={promotion}
            updatePromotion={updatePromotion}
          />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <PromotionDateRange
            promotion={promotion}
            promotionEvents={promotionEvents}
            updatePromotion={updatePromotion}
          />
          {renderPromotionActionButton()}
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12} md={4}>
          <PromotionGeneralInformation
            promotion={promotion}
            updatePromotionOwner={updatePromotionOwner}
          />
        </Grid>
        <Grid item xs={12} md={8} pl={{ sx: 0, md: 2 }}>
          <PromotionSummary
            promotion={promotion}
            promotionEvents={promotionEvents}
            updatePromotion={updatePromotion}
            updateAdminOnlyFields={updateAdminOnlyFields}
          />
        </Grid>
      </Grid>
      <Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(e, v) => setActiveTab(v)}
            aria-label="Promotion Tabs"
          >
            <Tab
              value={0}
              label={`Promotion Items(${itemCount})`}
              {...a11yProps(0)}
            />
            <Tab value={1} label={`Approvals`} {...a11yProps(1)} />
            <Tab value={2} label={`Notes & Documents`} {...a11yProps(2)} />
            <Tab value={3} label={`Activity Log`} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <Box sx={{ padding: 0, paddingTop: 2 }}>
          <TabPanel value={activeTab} index={0} sx={{ p: 0 }}>
            {promotion.promotionType.value.kind.includes("Award") ? (
              <>
                <Tabs
                  value={activeSubTab}
                  onChange={(e, v) => setActiveSubTab(v)}
                  aria-label="Promotion Tabs"
                >
                  <Tab
                    value={0}
                    label={`Qualified Products (${qualifyingItemCount})`}
                    {...a11yProps(0)}
                  />
                  <Tab
                    value={1}
                    label={`Award Products (${awardItemCount})`}
                    {...a11yProps(1)}
                  />
                </Tabs>

                <TabPanel
                  value={activeSubTab}
                  index={0}
                  sx={{ padding: 0, paddingTop: 2 }}
                >
                  <PromotionItemsTable
                    promotionId={promotion.id}
                    promotionStatus={promotion.status}
                    promotionType={promotion.promotionType}
                    productType={"qualifying"}
                    fundingType={promotion.funding.fundingType}
                    brands={brands}
                    classes={classes}
                    manufacturers={manufacturers}
                    groups={groups}
                    selectedWorkspaceId={workspaceId}
                    refreshPromotion={refreshPromotion}
                  />
                </TabPanel>
                <TabPanel
                  value={activeSubTab}
                  index={1}
                  sx={{ padding: 0, paddingTop: 2 }}
                >
                  <PromotionItemsTable
                    promotionId={promotion.id}
                    promotionStatus={promotion.status}
                    promotionType={promotion.promotionType}
                    productType={"award"}
                    fundingType={promotion.funding.fundingType}
                    brands={brands}
                    classes={classes}
                    manufacturers={manufacturers}
                    groups={groups}
                    selectedWorkspaceId={workspaceId}
                    refreshPromotion={refreshPromotion}
                  />
                </TabPanel>
              </>
            ) : (
              <PromotionItemsTable
                promotionId={promotion.id}
                promotionStatus={promotion.status}
                promotionType={promotion.promotionType}
                productType={null}
                fundingType={promotion.funding.fundingType}
                brands={brands}
                classes={classes}
                manufacturers={manufacturers}
                groups={groups}
                selectedWorkspaceId={workspaceId}
                refreshPromotion={refreshPromotion}
              />
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <PromotionApprovalsTable
              promotionId={promotion.id}
              approvalSummary={promotion.approvals}
              isPromotionSubmitted={
                promotion.status === "submitted" ||
                promotion.status === "underReview"
              }
              refreshPromotion={refreshPromotion}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <InputLabel id="select-sortBy" sx={{ display: "inline-block" }}>
                  Sort By:
                </InputLabel>
                <Select
                  variant="standard"
                  id="sortBy"
                  labelId="select-sortBy"
                  defaultValue="newest"
                  onChange={handleSortByChange}
                  sx={{ m: 1, minWidth: 85 }}
                  size="small"
                >
                  {dateSortingOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel id="select-tag">Tag</InputLabel>
                <Select
                  variant="standard"
                  id="tag"
                  labelId="select-tag"
                  defaultValue="all"
                  onChange={handleTagChange}
                  sx={{ m: 1, minWidth: 125 }}
                  size="small"
                >
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                  {tagOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  variant="standard"
                  id="showArchivedNotes"
                  labelId="showArchivedNotes"
                  defaultValue="true"
                  onChange={handleShowArchivedNotesChange}
                  sx={{ m: 1, minWidth: 125 }}
                  size="small"
                >
                  {showArchivedNotesOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Button
                  startIcon={<PlusIcon />}
                  onClick={() => setOpenNoteDialog(true)}
                  sx={{ m: 1 }}
                >
                  Add New Note
                </Button>
              </Stack>
            </Stack>
            <Loader loadingStates={[loadingPromotionNotes]}>
              {isLoaded(loadingPromotionNotes) && (
                <PromotionNoteTable
                  loadingPromotionNotes={loadingPromotionNotes}
                  handleArchiveNote={handleArchiveNote}
                  handleDownloadAttachment={handleDownloadAttachment}
                />
              )}
            </Loader>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <Loader loadingStates={[loadingActivityLogs]}>
              {isLoaded(loadingActivityLogs) && (
                <ActivityLogTable loadingActivities={loadingActivityLogs} />
              )}
            </Loader>
          </TabPanel>
        </Box>
      </Stack>
    </Stack>
  );
};

const isPromotionEditable = (
  status: PromotionStatus,
  isSuperAdmin: boolean
) => {
  return (
    ["draft", "renegotiation"].includes(status) ||
    (isSuperAdmin && editablePromoStatusBySuperAdmin.includes(status))
  );
};

const isPromotionSummaryEditable = (
  status: PromotionStatus,
  isSuperAdmin: boolean,
  hasEditorAccessToPromoSummary: boolean | undefined
) => {
  if (isSuperAdmin) {
    return isPromotionEditable(status, isSuperAdmin);
  } else {
    return (
      isPromotionEditable(status, isSuperAdmin) &&
      !!hasEditorAccessToPromoSummary
    );
  }
};

const isPromotionPriorityorHeaderEditable = (
  status: PromotionStatus,
  isSuperAdmin: boolean,
  hasEditorAccessToPriority: boolean | undefined
) => {
  if (isSuperAdmin) {
    return isPromotionEditable(status, isSuperAdmin);
  } else {
    return (
      !!hasEditorAccessToPriority &&
      ["submitted", "underReview"].includes(status)
    );
  }
};

interface PromotionNameForm {
  name: string;
}
interface PromotionNameProps {
  promotion: PromotionDetailsSummary;
  updatePromotion: (req: UpdatePromotionReq) => Promise<void>;
}
const PromotionName: FC<PromotionNameProps> = ({
  promotion,
  updatePromotion
}) => {
  const [isEditable, setEditable] = useState(false);
  const { hasEditorAccess, isSuperAdmin } = useFeatures();

  const handleSubmit = async (values: PromotionNameForm) => {
    await updatePromotion({
      promotionId: promotion.id,
      name: values.name,
      description: promotion.description,
      promotionEventId: promotion.promotionEventSummary.id,
      fundingType: promotion.funding.fundingType
    });
  };
  return (
    <Formik<PromotionNameForm>
      initialValues={{
        name: promotion.name
      }}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        name: string()
          .trim()
          .max(150)
          .required()
      })}
    >
      {({ isSubmitting, resetForm }) => (
        <Form
          style={{
            // So each Grid item has the same height
            height: "100%"
          }}
        >
          {isEditable ? (
            <Stack direction="row" spacing={1}>
              <TextareaInput
                name="name"
                max={150}
                rows={1}
                fullWidth
                sx={{ width: "25vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  resetForm();
                  setEditable(false);
                }}
              >
                Cancel
              </Button>
              <Button size="small" type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Typography variant={"h2Bold"}>{promotion.name}</Typography>
              {isPromotionEditable(promotion.status, isSuperAdmin()) &&
                hasEditorAccess("promotionSummary") && (
                  <IconButton onClick={() => setEditable(true)} size="small">
                    <EditIcon />
                  </IconButton>
                )}
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  );
};

interface PromotionSummaryForm {
  header: string | null;
  description: string;
  durationType: PromotionDurationType;
  month: string;
  scanDiscountType: FundingType;
  timeslot: DbKey<PromotionEvent>;
  priority: PromotionPriority | null;
}

const promotionSummaryFormValidationSchema = object().shape({
  header: string()
    .trim()
    .max(30, "Promotion header cannot exceed 30 characters"),
  description: string()
    .trim()
    .required(),
  month: string().required(),
  scanDiscountType: string()
    .oneOf(["dollarFormat", "percentageFormat"])
    .required(),
  durationType: string().required()
});

interface PromotionSummaryProps {
  promotion: PromotionDetailsSummary;
  promotionEvents: PromotionEventData[];
  updatePromotion: (req: UpdatePromotionReq) => Promise<void>;
  updateAdminOnlyFields: (req: UpdateAdminOnlyFieldsReq) => Promise<void>;
}
const PromotionSummary: FC<PromotionSummaryProps> = ({
  promotion,
  promotionEvents,
  updatePromotion,
  updateAdminOnlyFields
}) => {
  const {
    name,
    header,
    description,
    promotionType,
    promotionEventSummary,
    funding,
    priority
  } = promotion;
  const {
    id: promotionEventId,
    durationType,
    startDate,
    endDate
  } = promotionEventSummary;

  const [isEditable, setEditable] = useState(false);
  const [openEditTimeslotDialog, setOpenEditTimeslotDialog] = useState(false);
  const [
    openChangeScanValueFormatDialog,
    setOpenChangeScanValueFormatDialog
  ] = useState(false);
  const [
    scanValueFormatChangeConfirmed,
    setScanValueFormatChangeConfirmed
  ] = useState(false);
  const [timeslotOptions, setTimeslotOptions] = useState(promotionEvents);
  const { hasReadAccess, hasEditorAccess, isSuperAdmin } = useFeatures();

  const monthOptions: SelectOption[] = useMemo(() => {
    return _.uniqWith(
      promotionEvents.map(pe => {
        return {
          title: formatDateMonthYearShortWithTZ(pe.value.startDate),
          value: formatDateMonthYearShortWithTZ(pe.value.startDate)
        };
      }),
      _.isEqual
    );
  }, [promotionEvents]);

  const scanValueOptions: SelectOptions = valuesFundingType.map(ft => {
    return {
      title: fundingTypeToText(ft),
      value: ft
    };
  });

  const durationTypeOptions = useCallback(
    (month: string): SelectOption[] => {
      let activityOptions: SelectOption[];

      if (month !== null) {
        activityOptions = [];

        const presetActivities = _.uniqWith(
          promotionEvents
            .filter(pe => {
              const promotionMonth = formatDateMonthYearShortWithTZ(
                pe.value.startDate
              );
              return (
                promotionMonth === month && pe.value.durationType !== "Custom"
              );
            })
            .map(pe => ({
              title: promotionEventDurationTypeToText(
                pe.value.durationType,
                pe.value.name
              ),
              value: pe.value.durationType.toString()
            })),
          _.isEqual
        );

        if (presetActivities.length > 0) {
          activityOptions.push(
            {
              title: "PRESET DURATION",
              value: "PRESET DURATION",
              subheading: true
            },
            ...presetActivities
          );
        }

        const customActivities = _.uniqWith(
          promotionEvents
            .filter(pe => {
              const promotionMonth = formatDateMonthYearShortWithTZ(
                pe.value.startDate
              );
              return (
                promotionMonth === month && pe.value.durationType === "Custom"
              );
            })
            .map(pe => {
              return {
                title: pe.value.name,
                value: pe.value.name
              };
            }),
          _.isEqual
        );

        if (customActivities.length > 0) {
          activityOptions.push(
            {
              title: "CUSTOM EVENTS",
              value: "CUSTOM EVENTS",
              subheading: true
            },
            ...customActivities
          );
        }
      } else {
        activityOptions = [];
      }

      return activityOptions;
    },
    [promotionEvents]
  );

  const promotionPriorityOptions: SelectOptions = valuesPromotionPriority.map(
    priority => {
      return {
        title: promotionPriorityToText(priority),
        value: priority
      };
    }
  );

  const handleSubmit = useCallback(
    async (values: PromotionSummaryForm) => {
      // Display edit timeslot dialog if either the month or duration type has changed
      // and the timeslot is empty
      if (
        (values.month !== formatDateMonthYearShortWithTZ(startDate) ||
          values.durationType !== durationType) &&
        values.timeslot === ""
      ) {
        const options = promotionEvents.filter(pe => {
          const promotionMonth = formatDateMonthYearShortWithTZ(
            pe.value.startDate
          );

          return (
            promotionMonth === values.month &&
            (pe.value.durationType === values.durationType ||
              pe.value.name === values.durationType)
          );
        });
        setTimeslotOptions(options);
        setOpenEditTimeslotDialog(true);

        return;
      }

      if (
        values.scanDiscountType !== funding.fundingType &&
        scanValueFormatChangeConfirmed === false
      ) {
        setOpenChangeScanValueFormatDialog(true);

        return;
      }

      if (
        isPromotionSummaryEditable(
          promotion.status,
          isSuperAdmin(),
          hasEditorAccess("promotionSummary")
        )
      ) {
        await updatePromotion({
          promotionId: promotion.id,
          name,
          description: values.description,
          promotionEventId: values.timeslot ?? promotionEventId,
          fundingType: values.scanDiscountType
        });
      }
      if (
        (values.header || values.priority) &&
        isPromotionPriorityorHeaderEditable(
          promotion.status,
          isSuperAdmin(),
          hasEditorAccess("promotionPriority")
        )
      ) {
        await updateAdminOnlyFields({
          promotionId: promotion.id,
          header: header !== values.header ? values.header : null,
          priority: priority !== values.priority ? values.priority : null,
          thresholdAmount: null
        });
      }
    },
    [
      startDate,
      durationType,
      funding.fundingType,
      scanValueFormatChangeConfirmed,
      promotion.status,
      promotion.id,
      isSuperAdmin,
      hasEditorAccess,
      promotionEvents,
      updatePromotion,
      name,
      promotionEventId,
      updateAdminOnlyFields,
      header,
      priority
    ]
  );

  return (
    <Formik<PromotionSummaryForm>
      initialValues={{
        header,
        description,
        durationType,
        scanDiscountType: funding.fundingType,
        month: formatDateMonthYearShortWithTZ(startDate),
        timeslot: promotionEventId,
        priority: priority
      }}
      validationSchema={promotionSummaryFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        resetForm,
        setFieldValue,
        submitForm,
        isSubmitting,
        values
      }) => {
        return (
          <Form
            style={{
              // So each Grid item has the same height
              height: "100%"
            }}
          >
            <Card>
              <CardHeader
                title={"promotion summary"}
                action={
                  isEditable ? (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          resetForm();
                          setEditable(false);
                        }}
                      >
                        Cancel
                      </Button>

                      <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        type="submit"
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  ) : (
                    <>
                      {(isPromotionSummaryEditable(
                        promotion.status,
                        isSuperAdmin(),
                        hasEditorAccess("promotionSummary")
                      ) ||
                        isPromotionPriorityorHeaderEditable(
                          promotion.status,
                          isSuperAdmin(),
                          hasEditorAccess("promotionPriority")
                        )) && (
                        <IconButton
                          onClick={() => setEditable(true)}
                          size="small"
                          disabled={
                            !isPromotionSummaryEditable(
                              promotion.status,
                              isSuperAdmin(),
                              hasEditorAccess("promotionSummary")
                            ) &&
                            !isPromotionPriorityorHeaderEditable(
                              promotion.status,
                              isSuperAdmin(),
                              hasEditorAccess("promotionPriority")
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </>
                  )
                }
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <Stack spacing={2}>
                      <InfoView
                        title={"Promo Month"}
                        value={formatDateMonthYearWithTZ(startDate)}
                        editableInput={
                          <SelectInput
                            name="month"
                            options={monthOptions}
                            onChange={e => {
                              handleChange(e);

                              setFieldValue("durationType", "");
                              setFieldValue("timeslot", "");
                            }}
                          />
                        }
                        editable={
                          isEditable &&
                          isPromotionSummaryEditable(
                            promotion.status,
                            isSuperAdmin(),
                            hasEditorAccess("promotionSummary")
                          )
                        }
                      />
                      <InfoView
                        title={"Promo Duration"}
                        value={datesDiffWithTZ(startDate, endDate)}
                        editableInput={
                          <SelectInput
                            name="durationType"
                            options={durationTypeOptions(values.month)}
                            onChange={e => {
                              handleChange(e);
                              setFieldValue("timeslot", "");
                            }}
                          />
                        }
                        editable={
                          isEditable &&
                          isPromotionSummaryEditable(
                            promotion.status,
                            isSuperAdmin(),
                            hasEditorAccess("promotionSummary")
                          )
                        }
                      />
                      <InfoView
                        title={"Promo Type"}
                        value={promotionTypeToText(promotionType)}
                      />
                      {hasReadAccess("promotionPriority") && (
                        <InfoView
                          title={"Promo Priority"}
                          value={promotionPriorityToText(priority)}
                          editableInput={
                            <SelectInput
                              name="priority"
                              options={promotionPriorityOptions}
                            />
                          }
                          editable={
                            isEditable &&
                            isPromotionPriorityorHeaderEditable(
                              promotion.status,
                              isSuperAdmin(),
                              hasEditorAccess("promotionPriority")
                            )
                          }
                        />
                      )}
                      <InfoView
                        title={"Scan Value"}
                        value={fundingTypeToText(values.scanDiscountType)}
                        editableInput={
                          <SelectInput
                            name="scanDiscountType"
                            options={scanValueOptions}
                          />
                        }
                        editable={
                          isEditable &&
                          isPromotionSummaryEditable(
                            promotion.status,
                            isSuperAdmin(),
                            hasEditorAccess("promotionSummary")
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={7} pl={{ sx: 0, md: 5 }}>
                    <Stack spacing={2}>
                      {hasReadAccess("promotionPriority") && (
                        <InfoView
                          title={"Promo Header"}
                          value={header}
                          editableInput={
                            <TextareaInput name="header" rows={2} max={30} />
                          }
                          editable={
                            isEditable &&
                            isPromotionPriorityorHeaderEditable(
                              promotion.status,
                              isSuperAdmin(),
                              hasEditorAccess("promotionPriority")
                            )
                          }
                        />
                      )}
                      <InfoView
                        title={"Promo Description"}
                        value={description}
                        editableInput={<TextareaInput name="description" />}
                        editable={
                          isEditable &&
                          isPromotionSummaryEditable(
                            promotion.status,
                            isSuperAdmin(),
                            hasEditorAccess("promotionSummary")
                          )
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
              <EditTimeslotDialog
                promotionEvents={timeslotOptions}
                open={openEditTimeslotDialog}
                title="Changing Promo Month or Duration?"
                subtitle="Please re-select promotion slot"
                onClose={() => setOpenEditTimeslotDialog(false)}
                onSubmit={submitForm}
              />
              <ChangeScanValueFormatDialog
                open={openChangeScanValueFormatDialog}
                title="Changing Scan Value ($/%) Format?"
                subtitle="Any existing data under Scan $/% column will be reset to 0. Please review and re-enter your data in this column."
                onClose={() => setOpenChangeScanValueFormatDialog(false)}
                onSubmit={submitForm}
                setScanValueFormatChangeConfirmed={
                  setScanValueFormatChangeConfirmed
                }
              />
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

interface PromotionGeneralInformationForm {
  owner: DbKey<AppUser>;
}
interface PromotionGeneralInformationProps {
  promotion: PromotionDetailsSummary;
  updatePromotionOwner: (req: UpdatePromotionOwnerReq) => Promise<void>;
}
const PromotionGeneralInformation: FC<PromotionGeneralInformationProps> = ({
  promotion,
  updatePromotionOwner
}) => {
  const { id, createdByUser, owner, createdAt, supplierCode } = promotion;
  const [isCurrentlyEditing, setIsCurrentlyEditing] = useState(false);
  const [openOwnershipDialog, setOpenOwnershipDialog] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState<DbKey<AppUser>>("");
  const {
    hasEditorAccess,
    isSupplierUser,
    getUserProfile,
    isSuperAdmin
  } = useFeatures();

  const service = useAppService();
  const loadSupplierUsers = useCallback(async () => {
    const users = await service.queryUsers({
      supplierWorkspaceId: promotion.supplierWorkspaceId,
      userId: null,
      searchQuery: null
    });

    return users.filter(u => {
      return u.userType === "supplierUser" && u.inactiveAt === null;
    });
  }, [promotion.supplierWorkspaceId, service]);
  const [loadingSupplierUsers] = useLoadingDataState(loadSupplierUsers);

  const closeConfirmDialog = useCallback(() => {
    setOpenOwnershipDialog(false);
    setSelectedOwner("");
  }, [setOpenOwnershipDialog, setSelectedOwner]);

  const formSubmit = useCallback(
    async (values: PromotionGeneralInformationForm) => {
      if (selectedOwner === "") {
        setOpenOwnershipDialog(true);
        setSelectedOwner(values.owner);
        return;
      }
      await updatePromotionOwner({
        promotionId: promotion.id,
        userId: selectedOwner
      });
    },
    [promotion.id, selectedOwner, updatePromotionOwner]
  );

  const handleSubmit = useCallback(async () => {
    await updatePromotionOwner({
      promotionId: promotion.id,
      userId: selectedOwner
    });
  }, [promotion.id, selectedOwner, updatePromotionOwner]);

  const ownerOptions: SelectOption[] = useMemo(() => {
    if (loadingSupplierUsers.state !== "success") {
      return [];
    }

    return loadingSupplierUsers.value.map(su => {
      return {
        title: formatAppUserName(su.name),
        value: su.id
      };
    });
  }, [loadingSupplierUsers]);

  const canEditGeneralInformation = useMemo(() => {
    return (
      isPromotionEditable(promotion.status, isSuperAdmin()) &&
      hasEditorAccess("promotionGeneralInformation") &&
      // Should cover the case of:
      // The current user is an admin of some kind or
      (!isSupplierUser() ||
        // The current promotion has no owner
        !promotion.owner ||
        // The current user is the owner of this promotion and is able to assign this promotion to someone else
        promotion.owner.id === getUserProfile().id)
    );
  }, [
    promotion.status,
    promotion.owner,
    isSuperAdmin,
    hasEditorAccess,
    isSupplierUser,
    getUserProfile
  ]);

  return (
    <Formik<PromotionGeneralInformationForm>
      // TODO: Update this with the current owner of the promotion (requires update to query promotion)
      initialValues={{
        owner: ""
      }}
      onSubmit={formSubmit}
      validationSchema={object().shape({
        owner: string()
          .trim()
          .required()
      })}
    >
      {({ resetForm }) => {
        return (
          <Form
            style={{
              // So each Grid item has the same height
              height: "100%"
            }}
          >
            <Card>
              <CardHeader
                title={"general information"}
                action={
                  isCurrentlyEditing ? (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          resetForm();
                          setSelectedOwner("");
                          setIsCurrentlyEditing(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  ) : (
                    <>
                      {canEditGeneralInformation && (
                        <IconButton
                          onClick={() => setIsCurrentlyEditing(true)}
                          size="small"
                          disabled={
                            !isPromotionEditable(
                              promotion.status,
                              isSuperAdmin()
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </>
                  )
                }
              />
              <CardContent>
                <Stack spacing={1}>
                  <InfoView title={"Promo ID"} value={id} />
                  <InfoView title={"Supplier Code"} value={supplierCode} />
                  <InfoView title={"Created by"} value={createdByUser} />
                  <InfoView
                    title={"Owner"}
                    value={owner?.fullname ?? null}
                    editableInput={
                      <SelectInput
                        name="owner"
                        value={owner?.id}
                        options={ownerOptions}
                      />
                    }
                    editable={isCurrentlyEditing}
                  />
                  <InfoView
                    title={"Created Date"}
                    value={formatDateTime(createdAt)}
                  />
                  <InfoView title={"Forecast"} value={"--"} />
                </Stack>
              </CardContent>
              <PromotionConfirmationDialog
                open={openOwnershipDialog}
                message="This action cannot be undone without an Admin or the new owner of this promotion block."
                title="Re-assign Ownership?"
                onClose={closeConfirmDialog}
                onConfirm={handleSubmit}
              />
              <SubmitListener />
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

interface InfoViewProps {
  title: string;
  value: string | null;
  editableInput?: React.ReactNode;
  editable?: boolean;
}

const InfoView: FC<InfoViewProps> = ({
  title,
  value,
  editableInput,
  editable
}) => {
  return (
    <Grid container>
      <Grid item xs={5}>
        <Typography variant={"h5Bold"}>{title}</Typography>
      </Grid>
      <Grid item xs={7} pl={2}>
        {editable && editableInput ? (
          <>{editableInput}</>
        ) : (
          <Typography
            variant={"h5"}
            noWrap
            textAlign={"left"}
            title={value ?? "--"}
          >
            {value ?? "--"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

interface EditTimeslotDialogProps {
  open: boolean;
  promotionEvents: PromotionEventData[];
  title: string;
  subtitle?: string;
  onClose: () => void;
  onSubmit: () => void;
}

/**
 * Displays a dialog that allows selecting a new timeslot. This dialog
 * assumes it is inside Formik with "timeslot" as the name of the field.
 */
const EditTimeslotDialog: FC<EditTimeslotDialogProps> = ({
  open,
  promotionEvents,
  title,
  subtitle,
  onClose,
  onSubmit
}) => {
  const [submitting, setSubmitting] = useState(false);
  const timeslotOptions: SelectOption[] = useMemo(() => {
    return promotionEvents.map(pe => {
      return {
        title: `${formatDateMonthDayYearWithTZ(
          pe.value.startDate
        )} - ${formatDateMonthDayYearWithTZ(pe.value.endDate)}`,
        value: pe.id
      };
    });
  }, [promotionEvents]);

  const handleConfirm = () => {
    setSubmitting(true);
    onSubmit();
  };

  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      {subtitle && (
        <DialogContent>
          <Typography variant="subtitle2">
            Please re-select promotion slot
          </Typography>
        </DialogContent>
      )}
      <DialogContent>
        <SelectInput
          name="timeslot"
          options={timeslotOptions}
          label="Promotion Slot"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={submitting}
          onClick={handleConfirm}
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface ChangeScanValueFormatDialogProps {
  open: boolean;
  title: string;
  subtitle?: string;
  onClose: () => void;
  onSubmit: () => void;
  setScanValueFormatChangeConfirmed: (boolean) => void;
}

/**
 * Displays a dialog that allows selecting a new timeslot. This dialog
 * assumes it is inside Formik with "timeslot" as the name of the field.
 */
const ChangeScanValueFormatDialog: FC<ChangeScanValueFormatDialogProps> = ({
  open,
  title,
  subtitle,
  onClose,
  onSubmit,
  setScanValueFormatChangeConfirmed
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleConfirm = () => {
    setSubmitting(true);
    setScanValueFormatChangeConfirmed(true);
    onSubmit();
  };

  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={submitting}
          onClick={handleConfirm}
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
interface PromotionDateRangeForm {
  timeslot: DbKey<PromotionEvent>;
}
interface PromotionDateRangeProps {
  promotion: PromotionDetailsSummary;
  promotionEvents: PromotionEventData[];
  updatePromotion: (req: UpdatePromotionReq) => Promise<void>;
}
const PromotionDateRange: FC<PromotionDateRangeProps> = ({
  promotion,
  promotionEvents,
  updatePromotion
}) => {
  const [openEditTimeslotDialog, setOpenEditTimeslotDialog] = useState(false);
  const { hasEditorAccess, isSuperAdmin } = useFeatures();

  const handleSubmit = useCallback(
    async (values: PromotionDateRangeForm) => {
      await updatePromotion({
        promotionId: promotion.id,
        name: promotion.name,
        description: promotion.description,
        promotionEventId: values.timeslot,
        fundingType: promotion.funding.fundingType
      });
    },
    [
      promotion.description,
      promotion.funding,
      promotion.id,
      promotion.name,
      updatePromotion
    ]
  );

  const timeslotOptions = useMemo(() => {
    return promotionEvents.filter(pe => {
      const promoMonth = formatDateMonthWithTZ(pe.value.startDate);

      return (
        formatDateMonthWithTZ(pe.value.startDate) === promoMonth &&
        pe.value.durationType === promotion.promotionEventSummary.durationType
      );
    });
  }, [promotion.promotionEventSummary.durationType, promotionEvents]);

  return (
    <Formik<PromotionDateRangeForm>
      initialValues={{
        timeslot: promotion.promotionEventSummary.id
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form
          style={{
            // So each Grid item has the same height
            height: "100%"
          }}
        >
          <DateRange
            startDate={getTimeZonedTime(
              promotion.promotionEventSummary.startDate
            )}
            endDate={getTimeZonedTime(promotion.promotionEventSummary.endDate)}
            onClick={() => setOpenEditTimeslotDialog(true)}
            disabled={
              !isPromotionEditable(promotion.status, isSuperAdmin()) ||
              !hasEditorAccess("promotionSummary")
            }
          />
          <EditTimeslotDialog
            title="Edit Timeslot"
            promotionEvents={timeslotOptions}
            open={openEditTimeslotDialog}
            onClose={() => setOpenEditTimeslotDialog(false)}
            onSubmit={submitForm}
          />
        </Form>
      )}
    </Formik>
  );
};

interface PromotionConfirmationDialogProps {
  open: boolean;
  message: string;
  title: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
}
/**
 * Dialog for confirming an action on the promotion.
 */
const PromotionConfirmationDialog: FC<PromotionConfirmationDialogProps> = ({
  open,
  message,
  title,
  onConfirm,
  onClose
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await onConfirm();
  };

  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, []);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2Bold">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5">{message}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface TabPanelProps {
  value: number;
  index: number;
  sx?: SxProps<Theme>;
}

const TabPanel: FC<TabPanelProps> = ({ index, value, children, sx }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={sx || { p: 3 }}>{children}</Box>}
    </Box>
  );
};

interface PromotionNoteTableProps {
  loadingPromotionNotes: LoadingValue<QueryPromotionNotesResp>;
  handleArchiveNote: (note: PromotionNoteSummary) => Promise<void>;
  handleDownloadAttachment: (
    promotionNoteAttachmentId: string
  ) => Promise<void>;
}

const PromotionNoteTable = (props: PromotionNoteTableProps) => {
  const [openArchivePromotionNote, setOpenArchivePromotionNote] = useState(
    false
  );
  const [openUnarchivePromotionNote, setOpenUnarchivePromotionNote] = useState(
    false
  );

  const [noteToArchive, setNoteToArchive] = useState<
    PromotionNoteSummary | undefined
  >(undefined);

  const openArchivePromotionNoteDialog = (note: PromotionNoteSummary) => {
    setNoteToArchive(note);
    setOpenArchivePromotionNote(true);
  };

  const openUnarchivePromotionNoteDialog = (note: PromotionNoteSummary) => {
    setNoteToArchive(note);
    setOpenUnarchivePromotionNote(true);
  };

  return (
    <Loader loadingStates={[props.loadingPromotionNotes]}>
      {isLoaded(props.loadingPromotionNotes) && (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>NOTE</TableCell>
                <TableCell>ATTACHMENTS</TableCell>
                <TableCell>TAG</TableCell>
                <TableCell>ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.loadingPromotionNotes.value.map(note => (
                <TableRow key={note.id}>
                  <TableCell>
                    <Typography fontWeight={"bold"} display={"inline"}>
                      {`${note.createdBy.firstName.toUpperCase()} ${note.createdBy.lastName.toUpperCase()}`}
                    </Typography>
                    {" - "}
                    {format(note.createdAt, "yyyy-MM-dd")}
                    <Typography display={"block"}>{note.note}</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack>
                      {note.promotionNoteAttachments.map(attachment => (
                        <Link
                          key={attachment.promotionNoteAttachmentId}
                          onClick={() =>
                            props.handleDownloadAttachment(
                              attachment.promotionNoteAttachmentId
                            )
                          }
                        >
                          {attachment.fileName}
                        </Link>
                      ))}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {note.tag
                      .valueOf()
                      .charAt(0)
                      .toUpperCase() + note.tag.valueOf().slice(1)}
                  </TableCell>
                  {note.tag !== "approval" && (
                    <NoteActionCell
                      note={note}
                      onArchiveNote={openArchivePromotionNoteDialog}
                      onUnarchiveNote={openUnarchivePromotionNoteDialog}
                    />
                  )}
                </TableRow>
              ))}
              {noteToArchive !== undefined && (
                <>
                  <ArchivePromotionNoteDialog
                    open={openArchivePromotionNote}
                    onClose={() => setOpenArchivePromotionNote(false)}
                    onArchive={() => props.handleArchiveNote(noteToArchive)}
                  />
                  <UnarchivePromotionNoteDialog
                    open={openUnarchivePromotionNote}
                    onClose={() => setOpenUnarchivePromotionNote(false)}
                    onUnarchive={() => props.handleArchiveNote(noteToArchive)}
                  />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Loader>
  );
};

interface NoteActionCellProps {
  note: PromotionNoteSummary;
  onArchiveNote: (note: PromotionNoteSummary) => void;
  onUnarchiveNote: (note: PromotionNoteSummary) => void;
}
const NoteActionCell = ({
  note,
  onArchiveNote,
  onUnarchiveNote
}: NoteActionCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell>
      <IconButton onClick={handleClick}>
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        id="note-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {note.isArchived ? (
          <MenuItem
            key={note.id}
            onClick={() => {
              onUnarchiveNote(note);
              handleClose();
            }}
          >
            <RestoreFromTrashIcon /> Unarchive
          </MenuItem>
        ) : (
          <MenuItem
            key={note.id}
            onClick={() => {
              onArchiveNote(note);
              handleClose();
            }}
          >
            <TrashIcon /> Archive
          </MenuItem>
        )}
      </Menu>
    </TableCell>
  );
};

interface ArchivePromotionNoteDialogProps {
  open: boolean;
  onClose: () => void;
  onArchive: () => Promise<void>;
}

const ArchivePromotionNoteDialog = ({
  open,
  onClose,
  onArchive
}: ArchivePromotionNoteDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  // Ensure the submitting state is set to false when the dialog is unmounted
  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, [open]);

  const handleArchive = async () => {
    setSubmitting(true);
    await onArchive();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Archive this note?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this note? It will remain in the
          system and you can still find it by “Showing archive notes” in the
          filter.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={submitting}
          onClick={handleArchive}
        >
          Archive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface UnarchivePromotionNoteDialogProps {
  open: boolean;
  onClose: () => void;
  onUnarchive: () => Promise<void>;
}

const UnarchivePromotionNoteDialog = ({
  open,
  onClose,
  onUnarchive
}: UnarchivePromotionNoteDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  // Ensure the submitting state is set to false when the dialog is unmounted
  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, [open]);

  const handleUnarchive = async () => {
    setSubmitting(true);
    await onUnarchive();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Unarchive this note?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to unaarchive this note? It will become active
          again.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          loading={submitting}
          onClick={handleUnarchive}
        >
          Unarchive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface PromotionAlertProps {
  promotion: PromotionDetailsSummary;
  hasRequiredItemCount: () => boolean;
}

const PromotionAlert = ({
  promotion,
  hasRequiredItemCount
}: PromotionAlertProps) => {
  const [showAlert, setShowAlert] = useState(true);
  let alert: JSX.Element | null = null;

  if (promotion.status === "draft" || promotion.status === "renegotiation") {
    if (!promotion.owner) {
      alert = (
        <Alert severity="error" onClose={() => setShowAlert(false)}>
          This promotion is missing a Promotion Owner and can&lsquo;t be
          submitted. Please add a Promotion Owner or contact an Petpsiration
          Admin to solve this issue.
        </Alert>
      );
    }

    if (!hasRequiredItemCount()) {
      alert = (
        <Alert severity="error" onClose={() => setShowAlert(false)}>
          This promotion is missing promotion items and can&lsquo;t be
          submitted. Please finish adding products to this promotion.
        </Alert>
      );
    }
  }

  return showAlert ? alert : null;
};
