import {
  Alert,
  Card,
  Collapse,
  Divider,
  FormHelperText,
  Stack,
  Typography
} from "@mui/material";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import {
  Accept,
  DropzoneOptions,
  FileRejection,
  useDropzone
} from "react-dropzone";
import { TransitionGroup } from "react-transition-group";
import {
  PaperclipIcon,
  TrashIcon,
  UploadIcon,
  WarningIcon
} from "src/ui/common/icon/icons";

// In case new types are needed pick from here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const FILE_UPLOAD_DEFAULT_ACCEPTED_FILE_TYPES: Accept = {
  "application/pdf": [".pdf"],
  "text/csv": [".csv"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx"
  ],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx"
  ],
  "image/png": [".png"]
};

const ONE_MB = 1e6;
const FILE_UPLOAD_DEFAULT_ACCEPTED_FILE_SIZE_MB = 2 * ONE_MB;

interface Props
  extends Pick<DropzoneOptions, "multiple" | "maxFiles" | "disabled"> {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The input label.
   */
  label?: string;
  /**
   * Determines the required ON/OFF state.
   */
  required?: boolean;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
}

const FileUploadInput: FC<Props> = ({
  name,
  label,
  required,
  helperText,
  ...options
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [failUploadedFiles, setFailUploadedFiles] = useState<
    Array<FileRejection>
  >([]);

  // we do not need to use the _field
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_field, meta, helper] = useField(name);
  const { setValue, setTouched } = helper;
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
      // clear failed on new uploads
      setFailUploadedFiles([]);
      setFailUploadedFiles(fileRejections);
      setValue([...uploadedFiles, ...acceptedFiles]);
      setTouched(true);
    },
    [setTouched, setValue, uploadedFiles]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: FILE_UPLOAD_DEFAULT_ACCEPTED_FILE_TYPES,
    maxSize: FILE_UPLOAD_DEFAULT_ACCEPTED_FILE_SIZE_MB,
    ...options
  });

  const removeFile = useCallback(
    (index: number) => {
      const copyFiles = [...uploadedFiles];
      copyFiles.splice(index, 1);
      setUploadedFiles(copyFiles);
      setValue([...copyFiles]);

      // clear failed on removed file
      setFailUploadedFiles([]);
    },
    [setValue, uploadedFiles]
  );

  const getAllowedTypes = (acceptedTypes: Accept) => {
    const types = Object.entries(acceptedTypes)
      .flatMap(([_key, value]) => {
        return value.flatMap(val => `${val.slice(1)}`);
      })
      .join(", ");

    return (
      <Typography component={"span"} sx={{ textTransform: "uppercase" }}>
        {types}
      </Typography>
    );
  };

  return (
    <Stack spacing={1}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
          onClick={open}
        />
      )}
      <Card
        {...getRootProps()}
        variant={"outlined"}
        sx={{
          padding: 3,
          width: "100%",
          backgroundColor: "action.selected"
        }}
      >
        <input {...getInputProps()} name={name} />
        <Stack spacing={1} alignItems={"center"} justifyContent={"center"}>
          <UploadIcon fontSize={"large"} />
          <Typography variant={"h5"}>
            Drag and Drop Files or Browse here
          </Typography>
          <Typography>
            Allowed type files:{" "}
            {getAllowedTypes(FILE_UPLOAD_DEFAULT_ACCEPTED_FILE_TYPES)}.
          </Typography>
          <Typography>
            Maximum upload file(s) is{" "}
            {FILE_UPLOAD_DEFAULT_ACCEPTED_FILE_SIZE_MB / ONE_MB}MB
          </Typography>
        </Stack>
      </Card>
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
      <Stack spacing={1}>
        <TransitionGroup>
          {uploadedFiles.map((file, index) => (
            <Collapse key={file.name}>
              <FileItem
                name={file.name}
                size={file.size}
                onDelete={removeFile}
                index={index}
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </Stack>
      {failUploadedFiles.length > 0 && uploadedFiles.length > 0 && <Divider />}
      <Stack spacing={1}>
        <TransitionGroup>
          {failUploadedFiles.map(fileRejection => (
            <Collapse
              // @ts-ignore : it does have that property
              key={fileRejection.file.path}
            >
              <FileRejectionItem {...fileRejection} />
            </Collapse>
          ))}
        </TransitionGroup>
      </Stack>
    </Stack>
  );
};

interface FileItemProps {
  name: string;
  size: number;
  index: number;

  onDelete: (index: number) => void;
}

const FileItem: FC<FileItemProps> = ({ size, name, onDelete, index }) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      mt={1}
      justifyContent={"space-between"}
      sx={{
        "&:hover": {
          "& .MuiTypography-root.highlight": {
            textDecoration: "underline"
          }
        }
      }}
    >
      <Stack direction={"row"} spacing={1}>
        <PaperclipIcon />
        <Typography className={"highlight"}>{name}</Typography>
        <Typography display={"inline"}>
          ({(size / ONE_MB).toFixed(3)}MB)
        </Typography>
      </Stack>
      <TrashIcon onClick={() => onDelete(index)} />
    </Stack>
  );
};

const FileRejectionItem: FC<FileRejection> = ({ file, errors }) => {
  const { name } = file;

  const getErrors = useMemo(() => {
    return errors
      .map(error => {
        switch (error.code) {
          case "file-invalid-type":
            return "Incorrect File Type.";
          case "file-too-large":
            return "File Limit Exceeded.";
          case "file-too-small":
            return "Too Small";
          case "too-many-files":
            return "Max Files Exceded";
        }
      })
      .join(", ");
  }, [errors]);

  return (
    <Stack
      direction={"row"}
      spacing={1}
      mt={1}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        "&:hover": {
          "& .MuiTypography-root.highlight": {
            textDecoration: "underline"
          }
        }
      }}
    >
      <Alert
        severity={"error"}
        icon={<WarningIcon fontSize="inherit" />}
        sx={{ width: "100%" }}
      >
        Failed to upload{" "}
        <Typography display={"inline"} variant={"inherit"} fontWeight={"bold"}>
          {name}
        </Typography>
        <Typography
          display={"inline"}
          variant={"inherit"}
          fontFamily={"monospace"}
          ml={2}
        >
          Reasons: {getErrors}
        </Typography>
      </Alert>
    </Stack>
  );
};

export default FileUploadInput;
