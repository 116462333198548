import {
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  TextField
} from "@mui/material";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { FC, ReactNode, useMemo, useState } from "react";
import { ChevronDownIcon } from "src/ui/common/icon/icons";

export type SelectOption = {
  /**
   * The value passed to the form.
   */
  value: string | number;
  /**
   * The item rendered in list.
   */
  item?: ReactNode;
  /**
   * The shown when option is selected.
   */
  title: string;
};

export type SelectOptions = Array<SelectOption>;

type Props = SelectProps & {
  /**
   * Name to link to form submission.
   */
  textfieldName: string;
  /**
   * Name to link to form submission for the format field.
   */
  selectName: string;
  /**
   * The list of options.
   */
  options: SelectOptions;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Optional helper to create an action.
   */
  createOption?: {
    /**
     * The item shown as the last option with click action.
     */
    item: ReactNode;
    /**
     * The callback when creating a new item
     */
    onCreateOption(): void;
  };
};

/**
 * Component used as basic select input
 * @param props: Props
 */
export const NumberWithSelectInput: FC<Props> = props => {
  const {
    helperText,
    textfieldName,
    selectName,
    label,
    required,
    options,
    placeholder,
    createOption,
    ...rest
  } = props;
  const [field, meta] = useField(textfieldName);
  const [selectField, selectMeta] = useField(selectName);
  const hasError = useMemo(
    () =>
      (meta.touched && Boolean(meta.error)) ||
      (selectMeta.touched && Boolean(selectMeta.error)),
    [selectMeta.error, selectMeta.touched, meta.error, meta.touched]
  );
  // mui Label + Select do not hook with htmlFor from label, so we handle the open/close with this state
  const [open, setOpen] = useState(false);

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      {label && (
        <LabelInput
          label={label}
          name={textfieldName}
          required={required}
          error={hasError}
          helperText={helperText}
        />
      )}
      <TextField
        type={"number"}
        id={textfieldName}
        hiddenLabel
        error={hasError}
        helperText={hasError ? meta.error : null}
        sx={{}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Select
                id={selectName}
                labelId={selectName}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                displayEmpty
                defaultValue={selectField.value ?? options[0].value}
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
                renderValue={selected => {
                  const selectedOption = options.find(
                    option => option.value === selected
                  );
                  if (selectedOption) {
                    return selectedOption.item || selectedOption.title;
                  }
                  return placeholder || "--";
                }}
                error={hasError}
                IconComponent={ChevronDownIcon}
                {...selectField}
                {...rest}
              >
                {options.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.item || option.title}
                  </MenuItem>
                ))}
                {createOption && (
                  <>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        createOption.onCreateOption();
                      }}
                    >
                      {createOption.item}
                    </MenuItem>
                  </>
                )}
              </Select>
            </InputAdornment>
          ),
          sx: { paddingRight: 0 }
        }}
        {...field}
      />
    </Stack>
  );
};

export default NumberWithSelectInput;
