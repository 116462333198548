type Loading = { state: "loading" };
type Success<T> = { state: "success"; value: T };
type Errored = { state: "error"; error: Error };
export type LoadingValue<T> = Success<T> | Loading | Errored;

export interface Selectable<T> {
  selected: boolean;
  value: T;
}

export function assertValueLoaded<T>(val: LoadingValue<T>): T {
  if (val.state !== "success") {
    throw new Error("Value has not loaded");
  }

  return val.value;
}

export function isLoaded<T>(val: LoadingValue<T>): val is Success<T> {
  return val.state === "success";
}

export function isLoading<T>(val: LoadingValue<T>): val is Loading {
  return val.state === "loading";
}

export function isErrored<T>(val: LoadingValue<T>): val is Errored {
  return val.state === "error";
}
