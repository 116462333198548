/**
 * 🎨PetStock Merchant Portal Theme Palette
 * Figma: https://www.figma.com/file/hoiWN138B6hOCvFNGase6p/Merchant-Portal?node-id=1332%3A15891
 * Docs: https://mui.com/material-ui/customization/theming/
 * TODO: check with Designers better names for Secondaries/Foo
 */

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor {
    lighter?: string;
  }
  interface Palette {
    system: {
      purple: string;
    };
    gray: {
      // Grey from Figma
      lightest: string;
      lighter: string;
      light: string;
      main: string;
      darker: string;
    };
  }
}

const palette = {
  primary: {
    lighter: "#366AA5",
    light: "#24466D",
    main: "#002855",
    dark: "#011A37"
  },
  info: {
    lighter: "rgba(0, 174, 239, 0.2)",
    main: "#00AEEF"
  },
  success: {
    main: "#42B02A"
  },
  error: {
    main: "#E4002B"
  },
  warning: {
    lighter: "rgba(247, 158, 81, 0.2)",
    main: "#FF8200"
  },
  gray: {
    lightest: "#F7F8F8",
    lighter: "#E3E7EB",
    light: "#A4B0BC",
    main: "#617283",
    darker: "#3D4B57"
  },
  system: {
    purple: "#9063CD"
  },
  text: {
    primary: "#002855" // 👆primary.main
  }
} as const;

export default palette;
