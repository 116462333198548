import { Instant } from "@pmp/adl/common";
import { Loader } from "@pmp/common/loader/loader";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import React, { useCallback } from "react";
import { useLoadingDataState } from "src/hooks/useLoadingData";

import { useAppService } from "../../../hooks/useAppService";
import { PromotionEventListPageView } from "./promotion-event-list-page-view";

export const PromotionEventListPage = () => {
  const service = useAppService();

  const loadPromotionEvents = useCallback(
    async (queryByMonth: Instant) => {
      return await service.queryPromotionEvents({
        promotionEventId: null,
        queryAllAfter: null,
        queryByMonth
      });
    },
    [service]
  );

  const loadLocations = useCallback(async () => {
    return await service.queryLocations([]);
  }, [service]);
  const [loadingLocations] = useLoadingDataState(loadLocations);

  return (
    <Loader loadingStates={[loadingLocations]}>
      {isLoaded(loadingLocations) && (
        <PromotionEventListPageView
          loadPromotionEvents={loadPromotionEvents}
          locations={loadingLocations.value}
        />
      )}
    </Loader>
  );
};
