import { NotificationPageView } from "./notification-page-view";
import React, { useCallback } from "react";
import { useAppService } from "../../../hooks/useAppService";
import { MarkNotificationsReq } from "@pmp/adl/petstock/merchantportal/api";

export const NotificationPage = () => {
  const service = useAppService();

  const queryNotifications = useCallback(async () => {
    return await service.queryNotifications({
      group: null,
      unRead: false
    });
  }, [service]);

  const markNotifications = useCallback(
    async (req: MarkNotificationsReq) => {
      return await service.markNotifications(req);
    },
    [service]
  );

  return (
    <NotificationPageView
      queryNotifications={queryNotifications}
      markNotifications={markNotifications}
    />
  );
};
