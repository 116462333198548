/* @generated from adl */
import { declResolver, ScopedDecl } from "./runtime/adl";
import { _AST_MAP as common } from "./common";
import { _AST_MAP as common_adminui_api } from "./common/adminui/api";
import { _AST_MAP as common_adminui_db } from "./common/adminui/db";
import { _AST_MAP as common_config_aws } from "./common/config/aws";
import { _AST_MAP as common_config_log } from "./common/config/log";
import { _AST_MAP as common_db } from "./common/db";
import { _AST_MAP as common_flyway_api } from "./common/flyway/api";
import { _AST_MAP as common_flyway_internals } from "./common/flyway/internals";
import { _AST_MAP as common_http } from "./common/http";
import { _AST_MAP as common_strings } from "./common/strings";
import { _AST_MAP as common_tabular } from "./common/tabular";
import { _AST_MAP as common_ui } from "./common/ui";
import { _AST_MAP as petstock_merchantportal_api } from "./petstock/merchantportal/api";
import { _AST_MAP as petstock_merchantportal_db } from "./petstock/merchantportal/db";
import { _AST_MAP as petstock_merchantportal_prontoapi } from "./petstock/merchantportal/prontoapi";
import { _AST_MAP as petstock_merchantportal_types } from "./petstock/merchantportal/types";
import { _AST_MAP as petstock_merchantportal_uiconfig } from "./petstock/merchantportal/uiconfig";
import { _AST_MAP as sys_adlast } from "./sys/adlast";
import { _AST_MAP as sys_annotations } from "./sys/annotations";
import { _AST_MAP as sys_types } from "./sys/types";

export const ADL: { [key: string]: ScopedDecl } = {
  ...common,
  ...common_adminui_api,
  ...common_adminui_db,
  ...common_config_aws,
  ...common_config_log,
  ...common_db,
  ...common_flyway_api,
  ...common_flyway_internals,
  ...common_http,
  ...common_strings,
  ...common_tabular,
  ...common_ui,
  ...petstock_merchantportal_api,
  ...petstock_merchantportal_db,
  ...petstock_merchantportal_prontoapi,
  ...petstock_merchantportal_types,
  ...petstock_merchantportal_uiconfig,
  ...sys_adlast,
  ...sys_annotations,
  ...sys_types,
};

export const RESOLVER = declResolver(ADL);
