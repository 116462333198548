import { Components, Theme } from "@mui/material/styles";

export default function Tabs(theme: Theme): Components {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid",
          borderColor: theme.palette.gray.lighter,
          width: "100%"
        }
      }
    },
    MuiTabPanel: {
      defaultProps: {
        sx: {
          padding: 0,
          pt: 2,
          width: "100%"
        }
      }
    }
  };
}
