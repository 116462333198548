import { Avatar } from "@mui/material";
import { desaturate } from "polished";
import React, { FC, useMemo } from "react";

type AvatarNameProps = { name: string; size?: "smaller" | "small" | "large" };
const AvatarName: FC<AvatarNameProps> = ({ name, size }) => {
  const avatarSize = useMemo(() => {
    switch (size) {
      case "smaller":
        return {
          width: 24,
          height: 24
        };
      case "small":
        return {
          width: 32,
          height: 32
        };
      case "large":
        return {
          width: 56,
          height: 56
        };
    }
  }, [size]);

  const internalPropsWithChildren = stringAvatar(name);

  return (
    <Avatar
      sx={{
        ...internalPropsWithChildren.sx,
        ...avatarSize
      }}
    >
      {internalPropsWithChildren.children}
    </Avatar>
  );
};

export default AvatarName;

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return desaturate(0.25, color);
};

const stringAvatar = (name: string) => {
  const genColor = stringToColor(name);
  const names = name.split(" ");
  const firstName = names[0];
  const lastName = names[1];
  const firstInitial = firstName[0];
  const secondInitial = lastName ? lastName[0] : firstName[1];
  return {
    sx: {
      bgcolor: genColor,
      color: theme => theme.palette.getContrastText(genColor)
    },
    children: `${firstInitial.toUpperCase()}${secondInitial.toUpperCase()}`
  };
};
