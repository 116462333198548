import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton
} from "@mui/material";
import { Instant } from "@pmp/adl/common";
import { DbKey } from "@pmp/adl/common/db";
import {
  CreatePromotionEventResp,
  UpdatePromotionEventResp
} from "@pmp/adl/petstock/merchantportal/api";
import { Location, PromotionEvent } from "@pmp/adl/petstock/merchantportal/db";
import { PromotionDurationType } from "@pmp/adl/petstock/merchantportal/types";
import DatePickerInput from "@pmp/common/inputs/date-picker-input/date-picker-input";
import LocationsInput from "@pmp/common/inputs/locations-input/locations-input";
import SelectInput, {
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import TextInput from "@pmp/common/inputs/text-input/text-input";
import { promotionEventDurationTypeToOptions } from "@pmp/utils/adl";
import { formatDate, getTimeZonedTime } from "@pmp/utils/dates";
import { isLoaded, isLoading } from "@pmp/utils/UtilityTypes";
import {
  addDays,
  endOfDay,
  endOfMonth,
  isThisMonth,
  isWithinInterval,
  startOfDay
} from "date-fns";
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers,
  setNestedObjectValues
} from "formik";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from "react";
import { TrashIcon } from "src/ui/common/icon/icons";
import { array, number, object, ref, string } from "yup";
import { DeletePromotionEventDialog } from "../../page/promotion-event/promotion-event-list-page-view";
import { useAppService } from "../../../hooks/useAppService";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { LoadingButton } from "@mui/lab";

interface PromotionEventsValue {
  id?: DbKey<PromotionEvent>;
  durationType: PromotionDurationType;
  startDate: Instant;
  locationIds: Array<DbKey<Location>>;
  endDate: Instant;
  name: string | null;
}

interface PromotionEventsValues {
  promotionEvents: Array<PromotionEventsValue>;
}

type PromotionEventErrors =
  | UpdatePromotionEventResp
  | keyof CreatePromotionEventResp;

const defaultPresetPromoEvent: PromotionEventsValue = {
  durationType: "OnlineTwoDayTactical",
  startDate: getTimeZonedTime(Date.now()),
  locationIds: [],
  endDate: endOfDay(addDays(getTimeZonedTime(Date.now()), 1)).getTime(),
  name: null
};
const defaultCustomPromoEvent: PromotionEventsValue = {
  durationType: "Custom",
  startDate: getTimeZonedTime(Date.now()),
  locationIds: [],
  endDate: endOfDay(addDays(getTimeZonedTime(Date.now()), 1)).getTime(),
  name: ""
};

export const PROMOTION_EVENT_FORM_ID = "promotion-event-form-id";

type PromotionEventType = "preset" | "custom";

interface PromotionEventsProps {
  existingPromotionEvents?: Array<PromotionEventsValue>;
  promotionEventType: PromotionEventType;
  setFormSubmitting: Dispatch<SetStateAction<boolean>>;
  onSuccess: (eventCount: number) => void;
  onDeleteSuccess?: () => void;
}

const PromotionEventsForm: FC<PromotionEventsProps> = ({
  existingPromotionEvents,
  promotionEventType,
  setFormSubmitting,
  onSuccess,
  onDeleteSuccess
}) => {
  const [submitErrors, setSubmitErrors] = useState<
    Map<number, PromotionEventErrors>
  >();
  const [selectedPromotionEventId, setSelectedPromotionEventId] = useState("");
  const [
    openDeletePromotionEventDialog,
    setOpenDeletePromotionEventDialog
  ] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const service = useAppService();

  const isUpdating = useMemo(() => {
    return !!existingPromotionEvents && existingPromotionEvents.length > 0;
  }, [existingPromotionEvents]);

  const handleSubmitEvents = useCallback(
    async (
      values: PromotionEventsValues,
      formikHelpers: FormikHelpers<PromotionEventsValues>
    ) => {
      formikHelpers.setSubmitting(true);
      setFormSubmitting(true);
      const { promotionEvents } = values;
      if (promotionEvents) {
        const emptyMap = new Map<number, PromotionEventErrors>();
        if (isUpdating) {
          if (!hasConfirmed) {
            setOpenConfirmationDialog(true);
            return;
          }
          for (let i = 0; i < promotionEvents.length; i++) {
            const promotionEvent = promotionEvents[i];
            if (promotionEvent.id) {
              const resp = await service.updatePromotionEvent({
                promotionEventId: promotionEvent.id,
                startDate: promotionEvent.startDate,
                locationIds: promotionEvent.locationIds,
                endDate: promotionEvent.endDate,
                name: promotionEvent.name
              });
              if (resp !== "success") {
                emptyMap.set(i, resp);
              }
            }
          }
          setOpenConfirmationDialog(false);
        } else {
          const resp = await service.createPromotionEvents({
            events: promotionEvents
          });
          resp.map((r, index) => {
            if (r.kind !== "success") {
              emptyMap.set(index, r.kind);
            }
          });
        }

        setSubmitErrors(emptyMap);
        if (emptyMap.size === 0) {
          onSuccess(promotionEvents.length);
        }
      }
      formikHelpers.setSubmitting(false);
      setFormSubmitting(false);
    },
    [hasConfirmed, isUpdating, onSuccess, service, setFormSubmitting]
  );

  const handleDeletePromotionEventRow = useCallback(
    (
      promotionEvent: PromotionEventsValue,
      arrayHelper: FieldArrayRenderProps,
      pos: number
    ) => {
      if (isUpdating && promotionEvent.id) {
        setSelectedPromotionEventId(promotionEvent.id);
        setOpenDeletePromotionEventDialog(true);
      } else {
        arrayHelper.remove(pos);
      }
    },
    [isUpdating]
  );

  const handleDeletePromotionEvent = useCallback(
    async (promotionEventId: DbKey<PromotionEvent>) => {
      if (isUpdating) {
        try {
          await service.deletePromotionEvent(promotionEventId);

          if (onDeleteSuccess) {
            onDeleteSuccess();
          }
        } catch (e) {
          if (existingPromotionEvents) {
            const index = existingPromotionEvents.findIndex(
              pe => pe.id === promotionEventId
            );
            const emptyMap = new Map<number, PromotionEventErrors>();
            emptyMap.set(index, "failPromotionEventUnknown");
            setSubmitErrors(emptyMap);
          }
        }
      }
    },
    [existingPromotionEvents, isUpdating, onDeleteSuccess, service]
  );

  const getEndDate = useCallback(
    (
      durationType: Exclude<PromotionDurationType, "Custom">,
      startDate: number
    ): number => {
      const eventStartDate: Date = startOfDay(startDate);
      let eventEndDate: Date;
      switch (durationType) {
        case "OnlineTwoDayTactical":
          eventEndDate = endOfDay(addDays(eventStartDate, 1));
          return eventEndDate.getTime();
        case "FourDayTactical":
          eventEndDate = endOfDay(addDays(eventStartDate, 3));
          return eventEndDate.getTime();
        case "SevenDayTactical":
          eventEndDate = endOfDay(addDays(eventStartDate, 6));
          return eventEndDate.getTime();
        case "ElevenDayTactical":
          eventEndDate = endOfDay(addDays(eventStartDate, 10));
          return eventEndDate.getTime();
        case "Month":
          eventEndDate = endOfMonth(eventStartDate);
          return eventEndDate.getTime();
      }
    },
    []
  );

  const getErrorMessage = useCallback(
    (pos: number) => {
      if (submitErrors?.has(pos) && submitErrors.get(pos) !== undefined) {
        switch (submitErrors.get(pos)) {
          case "failPromotionEventConflictingTypeDates":
            return "Overlapped time slot with the same type of promotion duration is not allowed. Please re-select different dates.";
          case "success":
            break;
          case "failPromotionEventNotInSameMonth":
            return "Selected date must be in the same month.";
          case "failPromotionEventDatesInvalid":
            return "Selected date cannot be in the past.";
          case "failPromotionEventUnknown":
            return "Unknown Error please contact support if this persist.";
          case "failNoLocations":
            return "At least 1 location must be selected.";
        }
      }
    },
    [submitErrors]
  );

  /**
   * Check if the given date should be disabled
   * @param date: Calendar date
   * @param type: PromotionDurationType for the current promo event
   * @param values: all values on the form
   * @param pos: position of the current promo event
   */
  const checkDateForDurationType = useCallback(
    (
      date: number,
      type: PromotionDurationType,
      values: PromotionEventsValues,
      pos: number
    ): boolean => {
      if (
        values.promotionEvents === undefined ||
        values.promotionEvents.length === 0 ||
        type === "Custom"
      ) {
        // if no events or Custom event all dates should be enabled
        return false;
      }

      // adding an index so we can track the current event after the filter
      const eventsOfTypeWithIndex: Array<PromotionEventsValue & {
        index;
      }> = values.promotionEvents.map((event, index) => {
        return {
          ...event,
          index
        };
      });

      return eventsOfTypeWithIndex
        .filter(event => event.durationType === type)
        .some(event => {
          const interval = {
            start: startOfDay(event.startDate),
            end: getEndDate(type, event.startDate)
          };
          const endTime = getEndDate(type, date);
          // if on e of the below is true disable the date
          return (
            (type === "Month" && isThisMonth(date)) ||
            (event.index !== pos &&
              (isWithinInterval(date, interval) ||
                isWithinInterval(endTime, interval)))
          );
        });
    },
    [getEndDate]
  );

  interface LocationData {
    locationId: DbKey<Location>;
    name: string;
  }

  const locationsOptions = useCallback(async () => {
    const _options: SelectOptions = (await service.queryLocations([])).map(
      (location: LocationData) => {
        return {
          title: location.name,
          value: location.locationId
        };
      }
    );
    return _options;
  }, [service]);

  const [loadingLocations] = useLoadingDataState(locationsOptions);

  return (
    <>
      <Formik<PromotionEventsValues>
        initialValues={{
          promotionEvents:
            existingPromotionEvents && isUpdating
              ? existingPromotionEvents
              : [
                  promotionEventType === "preset"
                    ? defaultPresetPromoEvent
                    : defaultCustomPromoEvent
                ]
        }}
        validationSchema={object().shape({
          promotionEvents: array().of(
            object().shape({
              locationIds: array()
                .min(1)
                .required(),
              name: string().when("durationType", {
                is: "Custom",
                then: schema => schema.required(),
                otherwise: schema => schema.nullable()
              }),
              endDate: number().when("durationType", {
                is: "Custom",
                then: schema =>
                  schema.moreThan(
                    ref("startDate"),
                    "End date must be greater than start date"
                  )
              })
            })
          )
        })}
        onSubmit={(values, formikHelpers) =>
          handleSubmitEvents(values, formikHelpers)
        }
      >
        {({ values, submitForm, isSubmitting, setTouched, validateForm }) => {
          return (
            <Form id={PROMOTION_EVENT_FORM_ID}>
              <FieldArray
                name={"promotionEvents"}
                render={arrayHelpers => (
                  <>
                    <Grid container spacing={2} mb={2}>
                      {values.promotionEvents &&
                        values.promotionEvents.map((event, pos) => (
                          <Grid
                            item
                            container
                            spacing={2}
                            alignItems={"flex-start"}
                            key={pos}
                          >
                            <Grid item xs={12} sm={3}>
                              {promotionEventType === "preset" && (
                                <SelectInput
                                  label={"Promotion duration"}
                                  required
                                  disabled={isUpdating}
                                  options={promotionEventDurationTypeToOptions([
                                    "Custom"
                                  ])}
                                  name={`promotionEvents[${pos}].durationType`}
                                  hidePlaceholder={true}
                                />
                              )}
                              {promotionEventType === "custom" && (
                                <TextInput
                                  name={`promotionEvents[${pos}].name`}
                                  label={"Event Name"}
                                  required
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <DatePickerInput
                                name={`promotionEvents[${pos}].startDate`}
                                label={"Start Date"}
                                shouldDisableDate={date =>
                                  checkDateForDurationType(
                                    date.getTime(),
                                    values.promotionEvents[pos].durationType,
                                    values,
                                    pos
                                  )
                                }
                                disablePast
                              />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              {promotionEventType === "preset" &&
                                event.durationType !== "Custom" && (
                                  <TextInput
                                    name={"_endDate${pos}"}
                                    label={"End Date"}
                                    disabled
                                    value={formatDate(
                                      getEndDate(
                                        event.durationType,
                                        values.promotionEvents[pos].startDate
                                      )
                                    )}
                                  />
                                )}
                              {promotionEventType === "custom" && (
                                <DatePickerInput
                                  name={`promotionEvents[${pos}].endDate`}
                                  label={"End Date"}
                                  shouldDisableDate={date =>
                                    startOfDay(
                                      values.promotionEvents[pos].startDate
                                    ).getTime() > endOfDay(date).getTime()
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <LocationsInput
                                label={"Location"}
                                required
                                name={`promotionEvents[${pos}].locationIds`}
                                locations={
                                  isLoaded(loadingLocations)
                                    ? loadingLocations.value
                                    : []
                                }
                                loading={isLoading(loadingLocations)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1} alignSelf={"center"}>
                              <IconButton
                                color={"error"}
                                onClick={() =>
                                  handleDeletePromotionEventRow(
                                    values.promotionEvents[pos],
                                    arrayHelpers,
                                    pos
                                  )
                                }
                                disabled={
                                  !isUpdating &&
                                  values.promotionEvents.length === 1
                                }
                              >
                                <TrashIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                              {submitErrors && submitErrors.has(pos) && (
                                <Alert
                                  severity={"error"}
                                  sx={{
                                    backgroundColor: "transparent",
                                    color: "error.main",
                                    p: 0,
                                    mt: -1
                                  }}
                                >
                                  {getErrorMessage(pos)}
                                </Alert>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                    {!isUpdating && (
                      <Button
                        variant={"text"}
                        onClick={async () => {
                          const errors = await validateForm();

                          if (Object.keys(errors).length === 0) {
                            arrayHelpers.push(
                              promotionEventType === "preset"
                                ? defaultPresetPromoEvent
                                : defaultCustomPromoEvent
                            );
                          } else {
                            setTouched(setNestedObjectValues(errors, true));
                          }
                        }}
                      >
                        + Add Row
                      </Button>
                    )}
                  </>
                )}
              />
              <EditPromotionEventConfirmationDialog
                open={openConfirmationDialog}
                submitting={isSubmitting}
                onClose={() => {
                  setFormSubmitting(false);
                  setOpenConfirmationDialog(false);
                }}
                onConfirm={() => {
                  setHasConfirmed(true);
                  submitForm();
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <DeletePromotionEventDialog
        promotionEventId={selectedPromotionEventId}
        open={openDeletePromotionEventDialog}
        onClose={() => setOpenDeletePromotionEventDialog(false)}
        onDeletePromotionEvent={handleDeletePromotionEvent}
      />
    </>
  );
};

interface EditPromotionEventConfirmationDialogProps {
  open: boolean;
  submitting: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
const EditPromotionEventConfirmationDialog = ({
  open,
  submitting,
  onClose,
  onConfirm
}: EditPromotionEventConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit a Time Slot</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to make change to this existing time slot? The
          change will be reflected on promotions that haven&lsquo;t been
          approved in this timeslot, while approved promotion will need to be
          manually adjusted in Pronto.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton onClick={onConfirm} loading={submitting}>
          Save changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromotionEventsForm;
