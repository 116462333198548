import { Stack, Toolbar } from "@mui/material";

import { UserProfile } from "@pmp/adl/petstock/merchantportal/api";
import React from "react";
import { UserSupplierWorkspaces } from "../../../app/identity-state";
import { Header } from "../header/header";
import {
  NAVIGATION_SIDE_BAR_WIDTH,
  NavigationSideBar
} from "../navigation-side-bar/navigation-side-bar";

interface LoggedInPageLayoutProps {
  children: React.ReactNode;
  userProfile: UserProfile | undefined;
  onLogout: () => void;
  hasUnreadNotifications: boolean;
  handleSelectGlobalWorkspace: (workspaceId: string) => void;
  userWorkspaces: UserSupplierWorkspaces;
  selectedSupplierWorkspaceId: string | null;
}

export const LoggedInPageLayout = ({
  children,
  userProfile,
  onLogout,
  hasUnreadNotifications,
  userWorkspaces,
  handleSelectGlobalWorkspace,
  selectedSupplierWorkspaceId
}: LoggedInPageLayoutProps) => {
  return (
    <Stack sx={{ height: "100vh" }}>
      <Header
        onLogout={onLogout}
        userProfile={userProfile}
        hasUnreadNotifications={hasUnreadNotifications}
      />
      {userProfile && (
        <NavigationSideBar
          userProfile={userProfile}
          handleSelectGlobalWorkspace={handleSelectGlobalWorkspace}
          availableWorkspaces={userWorkspaces}
          selectedSupplierWorkspaceId={selectedSupplierWorkspaceId}
        />
      )}
      <Toolbar />
      <Stack
        marginLeft={`${NAVIGATION_SIDE_BAR_WIDTH}px`}
        padding={theme => theme.spacing(3)}
        height={theme => `calc(100vh - ${theme.spacing(8)})`}
      >
        {children}
      </Stack>
    </Stack>
  );
};
