import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import { AppRoutes } from "../../app/app";

/**
 * Component that restricts rendering of children based on login status.
 */
export const LoginRedirect = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  // If the user is not logged in and has entered a path greater than 1
  // (we ignore the first character of the pathname which is the forward slash
  // character), then set the referrer to the path the user has attempted to go to
  // that requires login prior to access.
  if (location.pathname.length > 1) {
    params.set("referrer", location.pathname);
    location.search = params.toString();
  }
  location.pathname = AppRoutes.Login;

  return <Redirect to={location} />;
};
