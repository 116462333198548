import { assertNever } from "@hx/util/types";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Button,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from "@mui/material";

import { DbKey } from "@pmp/adl/common/db";
import {
  DuplicatePromotionResp,
  PromotionDetailsSummary,
  PromotionsGroupBy,
  QueryActivityLogResp,
  QueryPromotionsByGroupResp,
  QueryPromotionsFilter,
  QueryPromotionsResp,
  valuesPromotionsGroupBy
} from "@pmp/adl/petstock/merchantportal/api";
import {
  Promotion,
  SupplierWorkspace
} from "@pmp/adl/petstock/merchantportal/db";
import {
  PromotionDurationType,
  PromotionStatus,
  valuesPromotionStatus
} from "@pmp/adl/petstock/merchantportal/types";
import AutocompleteSelectInput from "@pmp/common/inputs/autocomplete-input/autocomplete-select-input";
import DatePickerInput from "@pmp/common/inputs/date-picker-input/date-picker-input";
import SearchInput from "@pmp/common/inputs/search-input/search-input";
import SelectInput from "@pmp/common/inputs/select-input/select-input";
import { Loader } from "@pmp/common/loader/loader";
import PromotionsTimeline, {
  TimelineGroup,
  TimelineItem
} from "@pmp/components/PromotionsTimeline/PromotionsTimeline";
import {
  promotionEventDurationTypeToText,
  promotionsGroupByToText,
  promotionStatusToText
} from "@pmp/utils/adl";
import {
  formatDate,
  formatDateMonthWithTZ,
  formatDateWithTZ
} from "@pmp/utils/dates";
import { isLoaded, LoadingValue } from "@pmp/utils/UtilityTypes";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { useFeatures } from "src/hooks/useFeatures";
import { SubmitListener } from "src/ui/widgets/form/helpers";
import { useAlert } from "../../../../hooks/useAlertContext";
import { useAppService } from "../../../../hooks/useAppService";
import { useLoadingDataState } from "../../../../hooks/useLoadingData";
import {
  AlertIcon,
  CaretDownIcon,
  CaretRightIcon,
  ColumnsIcon,
  CopyIcon,
  ListIcon,
  MoreVerticalIcon,
  PlusIcon,
  SlidersIcon,
  TrashIcon,
  UploadIcon,
  UsersIcon
} from "../../../common/icon/icons";
import PromoStatusChip from "../../../components/PromotionStatus/PromoStatusChip";
import { PromotionActivityLogPageView } from "./promotion-activity-log-page-view";

export interface PromotionOverviewViewProps {
  loadPromotions: (
    filterOptions: QueryPromotionsFilter | null
  ) => Promise<QueryPromotionsResp>;
  loadPromotionsByGroup: (
    filterOptions: QueryPromotionsFilter | null,
    groupBy: PromotionsGroupBy
  ) => Promise<QueryPromotionsByGroupResp>;
  brands: string[];
  classes: string[];
  manufacturers: string[];
  duplicatePromotion: (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>
  ) => Promise<DuplicatePromotionResp | null>;
  loadActivityLogs: (
    promotionId: DbKey<PromotionDetailsSummary>
  ) => Promise<QueryActivityLogResp>;
  selectedWorkspaceId: DbKey<SupplierWorkspace> | null;
}

type PromoView = "" | "#timeline";
export const PromotionOverviewView = ({
  loadPromotions,
  loadPromotionsByGroup,
  brands,
  classes,
  manufacturers,
  duplicatePromotion,
  loadActivityLogs,
  selectedWorkspaceId
}: PromotionOverviewViewProps) => {
  const [
    promotionFilter,
    setPromotionFilter
  ] = useState<QueryPromotionsFilter | null>({
    promotionNameLike: "",
    supplierWorkspaceIds: null,
    filterByStatus: null,
    endDateAfter: null,
    filterByBrands: null,
    filterByClasses: null,
    filterByManufacturer: null
  });
  const [selectedGroupOption, setSelectedGroupOption] = useState<
    PromotionsGroupBy | ""
  >("");

  const history = useHistory();
  const { location } = history;
  const [selectedView, setSelectedView] = useState<PromoView>(
    (location.hash as PromoView) ?? ""
  );
  if (location.hash !== selectedView) {
    if (location.hash === "#timeline") {
      setSelectedView("#timeline");
    } else {
      setSelectedView("");
    }
  }

  const { url } = useRouteMatch();
  const { hasEditorAccess, isSupplier } = useFeatures();

  const handlePromotionSearch = useCallback((values: PromotionTableFilter) => {
    setPromotionFilter({
      promotionNameLike: values.promotionNameLike,
      supplierWorkspaceIds: null,
      filterByStatus: values.filterByStatus as Array<PromotionStatus>,
      endDateAfter: values.endDateAfter,
      filterByBrands: values.filterByBrands,
      filterByClasses: values.filterByClasses,
      filterByManufacturer: values.filterByManufacturer
    });
    setSelectedGroupOption(values.groupBy);
  }, []);

  const queryPromotions = useCallback(async () => {
    return await loadPromotions(promotionFilter);
  }, [promotionFilter, loadPromotions]);
  const [loadingPromotions, refreshPromotions] = useLoadingDataState(
    queryPromotions
  );
  const queryPromotionsByGroup = useCallback(async () => {
    if (selectedGroupOption !== "") {
      return await loadPromotionsByGroup(promotionFilter, selectedGroupOption);
    }
  }, [selectedGroupOption, loadPromotionsByGroup, promotionFilter]);
  const [loadingPromotionsByGroup] = useLoadingDataState(
    queryPromotionsByGroup
  );

  const brandOptions = brands.map(b => ({
    value: b,
    title: b
  }));

  const classOptions = classes.map(b => ({
    value: b,
    title: b
  }));

  const manufacturerOptions = manufacturers.map(b => ({
    value: b,
    title: b
  }));

  const groupByOptions = valuesPromotionsGroupBy.map(pgBy => ({
    value: pgBy,
    title: promotionsGroupByToText(pgBy)
  }));

  const statusOptions = valuesPromotionStatus.map(ps => ({
    value: ps,
    title: promotionStatusToText(ps)
  }));
  const onDuplicatePromotion = useCallback(
    async (
      promotionId: DbKey<Promotion>,
      promotionWorkspaceId: DbKey<SupplierWorkspace>
    ) => {
      await duplicatePromotion(promotionId, promotionWorkspaceId);
      refreshPromotions();
    },
    [duplicatePromotion, refreshPromotions]
  );

  const [openMoreSearch, setOpenMoreSearch] = React.useState(false);

  const getGroupsFromGroupedPromotions = useCallback(
    (promotionsByGroup: QueryPromotionsByGroupResp): Array<TimelineGroup> => {
      const groups: Array<TimelineGroup> = [];
      const promos = promotionsByGroup.promotions;
      let foundCustomEvent = false;
      Object.keys(promos)
        // ensure Custom events are shown last
        .sort((a, b) => {
          if (a === "Custom" || b === "Custom") {
            return -1;
          }

          return 0;
        })
        .map((key, index) => {
          if (promotionsByGroup.groupBy === "durationType") {
            if (index === 0) {
              groups.push({
                kind: "header",
                title: "preset duration",
                id: "preset-duration"
              });
            }
            if (key === "Custom") {
              if (!foundCustomEvent) {
                groups.push({
                  kind: "header",
                  title: "custom duration",
                  id: "custom-duration"
                });
                foundCustomEvent = true;
              }
              promos[key].map(promo => {
                groups.push({
                  kind: "item",
                  id: promo.id,
                  title: promo.name,
                  pmpData: promo
                });
              });
            } else {
              groups.push({
                kind: "parent",
                id: key,
                title: promotionEventDurationTypeToText(
                  key as PromotionDurationType,
                  key
                )
              });
            }
          } else {
            groups.push({
              kind: "parent",
              id: key,
              title: key
            });
          }
          promos[key].map(promo => {
            groups.push({
              kind: "item",
              id: promo.id,
              title: promo.name,
              pmpData: promo,
              parentId: key
            });
          });
        });

      return groups;
    },
    []
  );

  const groups = useMemo(() => {
    const _groups: Array<TimelineGroup> = [];
    if (selectedView === "#timeline") {
      if (
        selectedGroupOption !== "" &&
        loadingPromotionsByGroup.state === "success" &&
        loadingPromotionsByGroup.value !== undefined
      ) {
        return getGroupsFromGroupedPromotions(loadingPromotionsByGroup.value);
      }
      if (loadingPromotions.state === "success") {
        const promotions = loadingPromotions.value;
        promotions.map(promo => {
          _groups.push({
            kind: "item",
            id: promo.id,
            title: promo.name,
            pmpData: promo
          });
        });
      }
    }
    return _groups;
  }, [
    getGroupsFromGroupedPromotions,
    loadingPromotions,
    loadingPromotionsByGroup,
    selectedGroupOption,
    selectedView
  ]);

  const getItemsFromGroupedPromotions = useCallback(
    (promotionsByGroup: QueryPromotionsByGroupResp): Array<TimelineItem> => {
      const items: Array<TimelineItem> = [];
      const promos = promotionsByGroup.promotions;
      Object.keys(promos).map(key => {
        promos[key].map(promo => {
          items.push({
            id: promo.id,
            title: promo.name,
            group: promo.id,
            end_time: new Date(promo.promotionEventSummary.endDate),
            start_time: new Date(promo.promotionEventSummary.startDate),
            pmpData: promo
          });
        });
      });

      return items;
    },
    []
  );

  const items = useMemo(() => {
    const _items: Array<TimelineItem> = [];
    if (selectedView === "#timeline") {
      if (
        selectedGroupOption !== "" &&
        loadingPromotionsByGroup.state === "success" &&
        loadingPromotionsByGroup.value !== undefined
      ) {
        return getItemsFromGroupedPromotions(loadingPromotionsByGroup.value);
      }
      if (loadingPromotions.state === "success") {
        const promotions = loadingPromotions.value;

        promotions.map(promo => {
          _items.push({
            id: promo.id,
            title: promo.name,
            group: promo.id,
            start_time: new Date(promo.promotionEventSummary.startDate),
            end_time: new Date(promo.promotionEventSummary.endDate),
            pmpData: promo
          });
        });
      }
    }
    return _items;
  }, [
    getItemsFromGroupedPromotions,
    loadingPromotions,
    loadingPromotionsByGroup,
    selectedGroupOption,
    selectedView
  ]);

  return (
    <>
      <Stack spacing={2} flexGrow={1} borderColor={"green"} height={"100%"}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h1Bold">All Promotions</Typography>
          <Stack spacing={2} direction={"row"}>
            {hasEditorAccess("promotionCreation") && (
              <>
                {!Boolean(selectedWorkspaceId) && (
                  <Tooltip
                    describeChild
                    title={"Select a workspace to create a promotion"}
                  >
                    <IconButton color={"warning"}>
                      <AlertIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Button
                  startIcon={<PlusIcon />}
                  disabled={!Boolean(selectedWorkspaceId)}
                  href={`${url}/create`}
                >
                  Create New Promotion
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <TabContext value={selectedView}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Container
              maxWidth={"lg"}
              disableGutters
              sx={{
                // so does not affect Stack space-between
                margin: 0
              }}
            >
              <Formik<PromotionTableFilter>
                initialValues={{
                  promotionNameLike: "",
                  supplierWorkspaceIds: [],
                  filterByStatus: [],
                  endDateAfter: null,
                  filterByBrands: [],
                  filterByClasses: [],
                  filterByManufacturer: [],
                  groupBy: ""
                }}
                onSubmit={handlePromotionSearch}
              >
                <Form>
                  <SubmitListener />
                  {isSupplier() ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                          <SearchInput
                            name="promotionNameLike"
                            placeholder="Search Promotion Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <AutocompleteSelectInput
                            name="filterByBrands"
                            options={brandOptions}
                            limitTags={1}
                            multiple
                            placeholder="Show by Brand"
                          />
                        </Grid>
                        {selectedView === "#timeline" && (
                          <Grid item xs={12} md={6} lg={3}>
                            <Button
                              startIcon={<SlidersIcon />}
                              variant={
                                openMoreSearch ? "outlined" : "contained"
                              }
                              onClick={() => setOpenMoreSearch(!openMoreSearch)}
                              size={"large"}
                            >
                              Filter
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      {selectedView === "#timeline" && (
                        <Collapse
                          in={openMoreSearch}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid
                            container
                            spacing={2}
                            mt={1}
                            alignItems={"flex-start"}
                          >
                            <Grid item xs={12} md={6} lg={3} ml={0}>
                              <SelectInput
                                name={"groupBy"}
                                placeholder={"Group by"}
                                options={groupByOptions}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                              <AutocompleteSelectInput
                                name="filterByStatus"
                                options={statusOptions}
                                limitTags={1}
                                multiple
                                placeholder="Show by Status"
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                              <DatePickerInput
                                name="endDateAfter"
                                startDescription={"Show Promotion End After"}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2} alignItems={"flex-start"}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Stack>
                            <SearchInput
                              name="promotionNameLike"
                              placeholder="Search Promotion Name"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Button
                            startIcon={<SlidersIcon />}
                            variant={openMoreSearch ? "outlined" : "contained"}
                            onClick={() => setOpenMoreSearch(!openMoreSearch)}
                            size={"large"}
                          >
                            Filter
                          </Button>
                        </Grid>
                      </Grid>
                      <Collapse
                        in={openMoreSearch}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Grid container spacing={2} mt={1}>
                          {selectedView === "#timeline" ? (
                            <>
                              <Grid item xs={12} md={6} lg={3}>
                                <SelectInput
                                  name={"groupBy"}
                                  placeholder={"Group by"}
                                  options={groupByOptions}
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={3}>
                                <AutocompleteSelectInput
                                  name="filterByStatus"
                                  options={statusOptions}
                                  limitTags={1}
                                  multiple
                                  placeholder="Show by Status"
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <DatePickerInput
                                  name="endDateAfter"
                                  startDescription={"Show Promotion End After"}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} md={6} lg={3}>
                                <AutocompleteSelectInput
                                  name="filterByClasses"
                                  options={classOptions}
                                  limitTags={1}
                                  multiple
                                  placeholder="Show by Class"
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={3}>
                                <AutocompleteSelectInput
                                  name="filterByManufacturer"
                                  options={manufacturerOptions}
                                  limitTags={1}
                                  multiple
                                  placeholder="Show by Manufacturer"
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={3}>
                                <AutocompleteSelectInput
                                  name="filterByBrands"
                                  options={brandOptions}
                                  limitTags={1}
                                  multiple
                                  placeholder="Show by Brand"
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Collapse>
                    </>
                  )}
                </Form>
              </Formik>
            </Container>
            <ToggleButtonGroup
              value={selectedView}
              exclusive
              onChange={(_e, selection) => setSelectedView(selection)}
              aria-label="Promotions view"
              color={"primary"}
            >
              <ToggleButton value="" aria-label="list view">
                <ListIcon
                  sx={{
                    mr: 1
                  }}
                />{" "}
                list
              </ToggleButton>
              <ToggleButton value="#timeline" aria-label="timeline view">
                <ColumnsIcon
                  sx={{
                    mr: 1
                  }}
                />{" "}
                timeline
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <TabPanel
            value=""
            sx={{
              height: "100%",
              minHeight: 0
            }}
          >
            <ManagePromotionsTable
              tabs={[
                {
                  label: "All Promotions",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions
                },
                {
                  label: "Draft",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "draft")
                },
                {
                  label: "Submitted",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "submitted")
                },
                {
                  label: "Under Review",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "underReview")
                },
                {
                  label: "Amendment Required",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "renegotiation")
                },
                {
                  label: "Accepted",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "scheduled")
                },
                {
                  label: "Rejected",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "rejected")
                },
                {
                  label: "Archived",
                  filterPromotions: (promotions: QueryPromotionsResp) =>
                    promotions.filter(p => p.status === "archived")
                }
              ]}
              loadingPromotions={loadingPromotions}
              refreshPromotions={refreshPromotions}
              onDuplicatePromotion={onDuplicatePromotion}
              loadActivityLogs={loadActivityLogs}
            />
          </TabPanel>
          <TabPanel value="#timeline">
            <Loader
              loadingStates={[loadingPromotions, loadingPromotionsByGroup]}
            >
              {isLoaded(loadingPromotions) &&
                isLoaded(loadingPromotionsByGroup) && (
                  <PromotionsTimeline groups={groups} items={items} />
                )}
            </Loader>
          </TabPanel>
        </TabContext>
      </Stack>
    </>
  );
};

interface LoadingTab {
  label: string;
  promotions: QueryPromotionsResp;
}

interface TabContent {
  label: string;
  filterPromotions: (promotions: QueryPromotionsResp) => QueryPromotionsResp;
}

export interface ManagePromotionsTableProps {
  tabs: TabContent[];
  loadingPromotions: LoadingValue<QueryPromotionsResp>;
  refreshPromotions: () => void;
  onDuplicatePromotion: (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>
  ) => Promise<void>;
  loadActivityLogs: (
    workspaceId: DbKey<Promotion>
  ) => Promise<QueryActivityLogResp>;
}

const ManagePromotionsTable = ({
  tabs,
  loadingPromotions,
  onDuplicatePromotion,
  loadActivityLogs,
  refreshPromotions
}: ManagePromotionsTableProps) => {
  const [selectedTab, setSelectedTab] = useState("0");
  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, tabIndex: string) => {
      setSelectedTab(tabIndex);
    },
    [setSelectedTab]
  );

  const loadingTabs = useMemo<LoadingTab[]>(() => {
    return tabs.map(t => {
      if (loadingPromotions.state === "loading") {
        return { label: t.label, promotions: [] };
      } else if (loadingPromotions.state === "success") {
        return {
          label: t.label,
          promotions: t.filterPromotions(loadingPromotions.value)
        };
      } else if (loadingPromotions.state === "error") {
        return { label: t.label, promotions: [] };
      } else {
        assertNever(loadingPromotions);
      }
    });
  }, [tabs, loadingPromotions]);

  const [promotion, setPromotion] = useState<
    PromotionDetailsSummary | undefined
  >(undefined);
  const handleViewActivityLog = (
    selectedPromotion: PromotionDetailsSummary
  ) => {
    setPromotion(selectedPromotion);
  };
  const queryActivities = useCallback(async () => {
    if (promotion) {
      return await loadActivityLogs(promotion.id);
    }
    return [];
  }, [loadActivityLogs, promotion]);
  const [loadingActivityLogs] = useLoadingDataState(queryActivities);

  return (
    <TabContext value={selectedTab}>
      <TabList onChange={handleTabChange}>
        {loadingTabs.map((tab, index) => (
          <Tab key={tab.label} label={tab.label} value={index.toString()} />
        ))}
      </TabList>
      {loadingTabs.map((tab, index) => (
        <TabPanel
          key={tab.label}
          value={index.toString()}
          sx={{ height: "100%", minHeight: 0 }}
        >
          <Loader loadingStates={[loadingPromotions]}>
            {isLoaded(loadingPromotions) && (
              <PromotionTable
                promotions={tab.promotions}
                onDuplicatePromotion={onDuplicatePromotion}
                handleViewActivityLog={handleViewActivityLog}
                refreshPromotions={refreshPromotions}
              />
            )}
          </Loader>
        </TabPanel>
      ))}
      <PromotionActivityLogPageView
        open={promotion !== undefined}
        onClose={() => setPromotion(undefined)}
        promotion={promotion}
        loadingActivities={loadingActivityLogs}
      />
    </TabContext>
  );
};

interface PromotionTableFilter {
  promotionNameLike: string;
  supplierWorkspaceIds: string[];
  filterByStatus: string[];
  endDateAfter: null;
  filterByBrands: string[];
  filterByClasses: string[];
  filterByManufacturer: string[];
  groupBy: PromotionsGroupBy | "";
}

interface PromotionTableProps {
  promotions: QueryPromotionsResp;
  onDuplicatePromotion: (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>
  ) => Promise<void>;
  handleViewActivityLog: (promotion: PromotionDetailsSummary) => void;
  refreshPromotions: () => void;
}

const PromotionTable = ({
  promotions,
  onDuplicatePromotion,
  handleViewActivityLog,
  refreshPromotions
}: PromotionTableProps) => {
  const [openDuplicatePromotion, setOpenDuplicatePromotion] = useState(false);
  const [openArchivePromotion, setOpenArchivePromotion] = useState(false);

  const [promotionId, setPromotionId] = useState<DbKey<Promotion>>("");
  const [promotionWorkspaceId, setPromotionWorkspaceId] = useState<
    DbKey<SupplierWorkspace>
  >("");
  const [promotionName, setPromotionName] = useState<string>("");

  const service = useAppService();
  const [showAlert] = useAlert();

  const handleArchivePromotion = useCallback(async () => {
    const resp = await service.archivePromotion({
      promotionId
    });

    switch (resp) {
      case "success":
        showAlert({
          title: "Successfully archived promotion",
          body: "",
          type: "success"
        });
        refreshPromotions();
        break;
      case "failInvalidPromotionStatus":
        showAlert({
          title: "Unable to archive promotion",
          body: "Unable to archive a promotion that is not Draft"
        });
        break;
      default:
        showAlert({
          title: "Unable to archive promotion",
          body:
            "An error occurred while archiving the promotion, please try again"
        });
        break;
    }
  }, [promotionId, refreshPromotions, service, showAlert]);

  const openDuplicatePromotionDialog = (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>,
    promotionName: string
  ) => {
    setPromotionId(promotionId);
    setPromotionName(promotionName);
    setPromotionWorkspaceId(promotionWorkspaceId);
    setOpenDuplicatePromotion(true);
  };

  const openArchivePromotionDialog = (promotionId: DbKey<Promotion>) => {
    setPromotionId(promotionId);
    setOpenArchivePromotion(true);
  };

  return (
    <TableContainer
      sx={{
        height: "100%",
        minHeight: 0
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>SUPPLIER</TableCell>
            <TableCell>PROMOTION NAME</TableCell>
            <TableCell>MONTH</TableCell>
            <TableCell>START DATE</TableCell>
            <TableCell>END DATE</TableCell>
            <TableCell>CLASS</TableCell>
            <TableCell>BRAND</TableCell>
            <TableCell>MANUFACTURER</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions.map(promotion => (
            <PromotionRow
              key={promotion.id}
              promotion={promotion}
              onDuplicatePromotion={openDuplicatePromotionDialog}
              handleViewActivityLog={handleViewActivityLog}
              onArchivePromotion={openArchivePromotionDialog}
            />
          ))}
        </TableBody>
      </Table>
      <DuplicatePromotionDialog
        open={openDuplicatePromotion}
        onClose={() => setOpenDuplicatePromotion(false)}
        promotionId={promotionId}
        promotionName={promotionName}
        promotionWorkspaceId={promotionWorkspaceId}
        onDuplicatePromotion={onDuplicatePromotion}
      />
      <ArchivePromotionDialog
        open={openArchivePromotion}
        promotionId={promotionId}
        onClose={() => setOpenArchivePromotion(false)}
        onArchivePromotion={handleArchivePromotion}
      />
    </TableContainer>
  );
};

interface PromotionRowProps {
  promotion: PromotionDetailsSummary;
  onDuplicatePromotion: (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>,
    promotionName: string
  ) => void;
  handleViewActivityLog: (promotion: PromotionDetailsSummary) => void;
  onArchivePromotion: (promotionId: DbKey<Promotion>) => void;
}

const PromotionRow = ({
  promotion,
  onDuplicatePromotion,
  handleViewActivityLog,
  onArchivePromotion
}: PromotionRowProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover selected={open} onClick={() => setOpen(!open)}>
        <TableCell>
          <Stack direction={"row"} alignItems="center">
            {open ? <CaretDownIcon /> : <CaretRightIcon />}
            <Typography>{promotion.supplierName}</Typography>
          </Stack>
        </TableCell>
        <TableCell>{promotion.name}</TableCell>
        <TableCell>
          {formatDateMonthWithTZ(promotion.promotionEventSummary.startDate)}
        </TableCell>
        <TableCell>
          {formatDateWithTZ(promotion.promotionEventSummary.startDate)}
        </TableCell>
        <TableCell>
          {formatDateWithTZ(promotion.promotionEventSummary.endDate)}
        </TableCell>
        <TableCell
          className={"PmpCellNoWrap"}
          title={promotion.classes.join(", ")}
        >
          {promotion.classes.join(", ")}
        </TableCell>
        <TableCell
          className={"PmpCellNoWrap"}
          title={promotion.brands.join(", ")}
        >
          {promotion.brands.join(", ")}
        </TableCell>
        <TableCell
          className={"PmpCellNoWrap"}
          title={promotion.manufacturers.join(", ")}
        >
          {promotion.manufacturers.join(", ")}
        </TableCell>
        <PromotionStatusCell status={promotion.status} />
        <PromotionActionsCell
          promotion={promotion}
          onDuplicatePromotion={onDuplicatePromotion}
          onViewActivityLog={handleViewActivityLog}
          onArchivePromotion={onArchivePromotion}
        />
      </TableRow>
      <CollapsiblePromotionRow open={open} promotion={promotion} />
    </>
  );
};

interface PromotionStatusCellProp {
  status: PromotionStatus;
}

export const PromotionStatusCell = ({ status }: PromotionStatusCellProp) => {
  return (
    <TableCell>
      <PromoStatusChip status={status} />
    </TableCell>
  );
};

interface PromotionActionsCellProp {
  promotion: PromotionDetailsSummary;
  onDuplicatePromotion: (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>,
    promotionName: string
  ) => void;
  onViewActivityLog: (promotion: PromotionDetailsSummary) => void;
  onArchivePromotion: (promotionId: DbKey<Promotion>) => void;
}

const PromotionActionsCell = ({
  promotion,
  onDuplicatePromotion,
  onViewActivityLog,
  onArchivePromotion
}: PromotionActionsCellProp) => {
  const { hasEditorAccess, isSuperAdmin } = useFeatures();
  const { url } = useRouteMatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewActivityLog = () => {
    onViewActivityLog(promotion);
    handleClose();
  };

  const handleDuplicatePromotion = () => {
    onDuplicatePromotion(
      promotion.id,
      promotion.supplierWorkspaceId,
      promotion.name
    );
  };

  const handleArchivePromotion = () => {
    onArchivePromotion(promotion.id);
  };

  const canArchivePromotion = useMemo(
    () =>
      (isSuperAdmin() && promotion.status !== "archived") ||
      (promotion.status === "draft" && hasEditorAccess("archivePromotion")),
    [hasEditorAccess, isSuperAdmin, promotion.status]
  );

  return (
    <TableCell>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Button href={`${url}/${promotion.id}`}>View</Button>
        <IconButton sx={{ ml: 1 }} onClick={handleClick}>
          <MoreVerticalIcon />
        </IconButton>
      </Stack>
      <Menu
        id="promotion-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          event.stopPropagation()
        }
      >
        <MenuItem onClick={handleViewActivityLog}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant={"h5"}>Activity Log</Typography>
          </ListItemText>
        </MenuItem>
        {hasEditorAccess("duplicatePromotion") && (
          <MenuItem onClick={handleDuplicatePromotion}>
            <ListItemIcon>
              <CopyIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant={"h5"}>Duplicate Promotion</Typography>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem disabled>
          <ListItemIcon>
            <UploadIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant={"h5"}>Export Promo Data</Typography>
          </ListItemText>
        </MenuItem>
        {canArchivePromotion && (
          <MenuItem onClick={handleArchivePromotion}>
            <ListItemIcon>
              <TrashIcon color="error" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="error" variant={"h5"}>
                Archive Promotion
              </Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </TableCell>
  );
};

interface CollapisblePromotionRowProps {
  open: boolean;
  promotion: PromotionDetailsSummary;
}

const CollapsiblePromotionRow = ({
  open,
  promotion
}: CollapisblePromotionRowProps) => {
  const duration = promotionEventDurationTypeToText(
    promotion.promotionEventSummary.durationType,
    promotion.promotionEventSummary.name
  );
  const promotionType = `${_.startCase(
    promotion.promotionType.kind
  )} / ${_.startCase(promotion.promotionType.value.kind)}`;

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Grid container rowSpacing={1}>
                <Grid item xs={12} md={6}>
                  <PromoDetail label="Promotion ID:" value="--" />
                  <PromoDetail
                    label="Promotion Items:"
                    value={promotion.itemCount}
                  />
                  <PromoDetail label="Promotion Type:" value={promotionType} />
                </Grid>
                <Grid item xs={12} md={6} pl={2}>
                  <PromoDetail label="Promotion Duration:" value={duration} />
                  <PromoDetail label="Forecast" value="--" />
                </Grid>
              </Grid>
            </Stack>

            <Stack direction="row">
              <Divider orientation="vertical" flexItem />
              <Stack marginLeft={2}>
                <PromoDetail
                  label="Created By"
                  value={promotion.createdByUser}
                  icon={<UsersIcon />}
                />
                <PromoDetail
                  label="Created Date"
                  value={formatDate(promotion.createdAt)}
                  icon={<UsersIcon />}
                />
                <PromoDetail
                  label="Owner"
                  value={promotion.owner?.fullname ?? null}
                  icon={<UsersIcon />}
                />
              </Stack>
            </Stack>
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

interface PromoDetailProps {
  label: string;
  value: string | number | null;
  icon?: React.ReactNode;
}

const PromoDetail: FC<PromoDetailProps> = ({ label, value, icon }) => {
  return (
    <Grid container>
      {icon && (
        <Grid item xs={2}>
          {icon}
        </Grid>
      )}
      <Grid item xs={icon ? 4 : 6}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={6} pl={1}>
        <Typography variant="subtitle2">
          {value !== null ? value : "--"}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface DuplicatePromotionDialogProps {
  open: boolean;
  promotionId: string;
  promotionWorkspaceId: DbKey<SupplierWorkspace>;
  promotionName: string;
  onClose: () => void;
  onDuplicatePromotion: (
    promotionId: DbKey<Promotion>,
    promotionWorkspaceId: DbKey<SupplierWorkspace>
  ) => Promise<void>;
}

const DuplicatePromotionDialog = ({
  open,
  onClose,
  promotionId,
  promotionWorkspaceId,
  promotionName,
  onDuplicatePromotion
}: DuplicatePromotionDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  const handleDuplicatePromotion = async () => {
    setSubmitting(true);
    await onDuplicatePromotion(promotionId, promotionWorkspaceId);
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">
        Duplicate &quot;{promotionName}&quot;?
      </DialogTitle>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={submitting} onClick={handleDuplicatePromotion}>
          Duplicate as a Draft
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface ArchivePromotionDialogProps {
  open: boolean;
  promotionId: DbKey<Promotion>;
  onClose: () => void;
  onArchivePromotion: (promotionId: DbKey<Promotion>) => void;
}

const ArchivePromotionDialog = ({
  open,
  promotionId,
  onClose,
  onArchivePromotion
}: ArchivePromotionDialogProps) => {
  const [submitting, setSubmitting] = useState(false);

  const handleArchivePromotion = useCallback(() => {
    setSubmitting(true);
    onArchivePromotion(promotionId);
    setSubmitting(false);
  }, [onArchivePromotion, promotionId]);

  useEffect(() => {
    return () => {
      setSubmitting(false);
    };
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5">Archive this Promotion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive this promotion? You won&apos;t be
          able to make changes to it and it will remain in the system under
          Archived tab.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton loading={submitting} onClick={handleArchivePromotion}>
          Archive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
