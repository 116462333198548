import { Instant } from "@adltools/adl-gen/common";
import { add, differenceInDays, format, intervalToDuration } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { PROMO_EVENTS_TZ } from "./constants";

export const getTimeZonedTime = (date: Instant): Instant => {
  return utcToZonedTime(date, PROMO_EVENTS_TZ).getTime();
};

export const formatDate = (date: Instant): string => {
  return format(date, "dd/MM/yyyy");
};

export const formatDateWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "dd/MM/yyyy");
};

export const formatDateMonthYear = (date: Instant): string => {
  return format(date, "MMMM yyyy");
};

export const formatDateMonthYearWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "MMMM yyyy");
};

export const formatDateMonthYearShort = (date: Instant): string => {
  return format(date, "MMM yyyy");
};

export const formatDateMonthYearShortWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "MMM yyyy");
};

export const formatDateMonth = (date: Instant): string => {
  return format(date, "MMMM").toUpperCase();
};

export const formatDateMonthWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "MMMM").toUpperCase();
};

export const formatDateTime = (date: Instant): string => {
  return format(date, "yyyy-MM-dd HH:mm");
};

export const formatDateTimeWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "yyyy-MM-dd HH:mm");
};

export const formatDateMonthDayYear = (date: Instant): string => {
  return format(date, "MMM dd, yyyy");
};

export const formatDateMonthDayYearWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "MMM dd, yyyy");
};

export const formatDateMonthDayTime = (date: Instant): string => {
  return format(date, "MMM dd HH:mm aa");
};

export const formatDateMonthDayTimeWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "MMM dd HH:mm aa");
};

export const formatDateYearMonthShort = (date: Instant): string => {
  return format(date, "yyMM");
};

export const formatDateYearMonthShortWithTZ = (date: Instant): string => {
  return format(utcToZonedTime(date, PROMO_EVENTS_TZ), "yyMM");
};

export const dateDiffDays = (startDate: Instant, endDate: Instant): number => {
  // Add +1 to be inclusive of the end date
  return differenceInDays(endDate, startDate) + 1;
};

export const dateDiffDaysWithTZ = (
  startDate: Instant,
  endDate: Instant
): number => {
  // Add +1 to be inclusive of the end date
  return (
    differenceInDays(
      utcToZonedTime(startDate, PROMO_EVENTS_TZ),
      utcToZonedTime(endDate, PROMO_EVENTS_TZ)
    ) + 1
  );
};

export const datesDiff = (startDate: Instant, endDate: Instant): string => {
  const duration = intervalToDuration({ start: startDate, end: endDate });

  return datesDiffFromDuration(duration);
};

export const datesDiffWithTZ = (
  startDate: Instant,
  endDate: Instant
): string => {
  const duration = intervalToDuration({
    start: utcToZonedTime(startDate, PROMO_EVENTS_TZ),
    end: utcToZonedTime(endDate, PROMO_EVENTS_TZ)
  });

  return datesDiffFromDuration(duration);
};

export const addDaysToDate = (date: Instant, days) => {
  return add(date, { days });
};

export const addDaysToDateWithTZ = (date: Instant, days) => {
  return add(utcToZonedTime(date, PROMO_EVENTS_TZ), { days });
};

const datesDiffFromDuration = (duration: Duration): string => {
  const days = duration.days ?? 0;
  const months = duration.months ?? 0;
  const years = duration.years ?? 0;

  if (years > 0) {
    return `${years} Year${years >= 1 && "s"}`;
  }

  if (months > 0) {
    return `${months} Month${months >= 1 && "s"}`;
  }

  return `${days + 1} Day${days >= 1 && "s"}`;
};
