import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  FormHelperText,
  Stack,
  TextField
} from "@mui/material";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import {
  SelectOption,
  SelectOptions
} from "@pmp/common/inputs/select-input/select-input";
import { useField } from "formik";
import React, { FC, ReactNode, useMemo, useState } from "react";
import {
  CheckmarkIcon,
  CheckmarkOutlineIcon,
  CrossIcon
} from "src/ui/common/icon/icons";

type Props = {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The input label.
   */
  label?: string;
  /**
   * Determines the required ON/OFF state.
   */
  required?: boolean;
  /**
   * Content to display in `InputLabel > InputTooltip`.
   */
  helperText?: ReactNode;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * The available locations
   */
  locations: SelectOptions;
  /**
   * Determines if locations are loading
   */
  loading: boolean;
};

const icon = <CheckmarkOutlineIcon fontSize="small" />;
const checkedIcon = <CheckmarkIcon fontSize="small" />;
const SELECT_ALL_VALUE = "SELECT_ALL_VALUE";

export const LocationsInput: FC<Props> = props => {
  const {
    helperText,
    name,
    label,
    required,
    placeholder,
    locations,
    loading
  } = props;
  const [field, meta, helper] = useField(name);
  const { value } = field;
  // mui Label + Select do not hook with htmlFor from label, so we handle the open/close with this state
  const [open, setOpen] = useState(false);

  const { setValue, setTouched } = helper;
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);
  // used for autocomplete value as it needs the whole option not just the value
  const selectedOptions = useMemo(() => {
    return locations.filter(option => value.some(val => option.value === val));
  }, [locations, value]);

  const allSelected = useMemo(() => {
    return locations.length === selectedOptions.length;
  }, [locations.length, selectedOptions.length]);

  const _onChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<SelectOptions, undefined, undefined, undefined>,
    reason: AutocompleteChangeReason,
    _details: AutocompleteChangeDetails<SelectOption> | undefined
  ) => {
    if (value && (reason === "selectOption" || reason === "removeOption")) {
      if (value.find(o => o.value === SELECT_ALL_VALUE)) {
        setValue(
          allSelected
            ? []
            : locations
                .filter(o => o.value !== SELECT_ALL_VALUE)
                .map(o => o.value)
        );
      } else {
        setValue(
          value.filter(o => o.value !== SELECT_ALL_VALUE).map(o => o.value)
        );
      }
    } else if (reason === "clear") {
      setValue([]);
    }
  };

  const _onBlur = () => {
    setTouched(true);
  };

  const filter = createFilterOptions<SelectOption>();

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
          onClick={() => setOpen(true)}
        />
      )}
      <Autocomplete<SelectOption, true>
        multiple
        fullWidth
        disableCloseOnSelect
        clearOnEscape
        limitTags={1}
        id={name}
        options={locations}
        loading={loading}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={_onChange}
        onBlur={_onBlur}
        value={selectedOptions}
        getOptionLabel={(option: SelectOption) => option.title}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option: SelectOption, { selected }) => (
          <li {...props} key={option.value}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                option.value === SELECT_ALL_VALUE ? allSelected : selected
              }
            />
            {option.item || option.title}
          </li>
        )}
        filterOptions={(options, params) => {
          if (locations.length === 0) {
            const filtered = filter(options, params);
            return [...filtered];
          }
          const filtered = filter(options, params);
          return [
            { title: "Select All", value: SELECT_ALL_VALUE },
            ...filtered
          ];
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              )
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index, array) => {
            // due to the large number of locations we will only show 1st selectedLocation with +N in a new chip
            if (array.length > 1 && index === 0) {
              return (
                <>
                  <Chip
                    label={option.title}
                    {...getTagProps({ index })}
                    deleteIcon={<CrossIcon />}
                    sx={{
                      borderRadius: 1,
                      fontWeight: "bold"
                    }}
                  />
                  {open && (
                    <Chip
                      label={`+ ${array.length}`}
                      deleteIcon={<CrossIcon />}
                      sx={{
                        borderRadius: 1,
                        fontWeight: "bold"
                      }}
                    />
                  )}
                </>
              );
            }
            if (array.length === 1) {
              return (
                <Chip
                  label={option.title}
                  {...getTagProps({ index })}
                  deleteIcon={<CrossIcon />}
                  sx={{
                    borderRadius: 1,
                    fontWeight: "bold"
                  }}
                />
              );
            }
          })
        }
        getLimitTagsText={more => (
          <Chip
            label={`+ ${more}`}
            deleteIcon={<CrossIcon />}
            sx={{
              borderRadius: 1,
              fontWeight: "bold"
            }}
          />
        )}
      />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </Stack>
  );
};

export default LocationsInput;
