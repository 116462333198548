import { LoggedInContext } from "../../../app/app";
import { useCallback, useContext } from "react";
import { AppService } from "@pmp/adl/app-service";
import { assertNotUndefined } from "@hx/util/types";
import { UserDetails } from "@pmp/adl/petstock/merchantportal/api";
import { useHistory, useParams } from "react-router-dom";
import { DbKey } from "@pmp/adl/common/db";
import { AppUser } from "@pmp/adl/petstock/merchantportal/db";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import { isLoaded } from "@pmp/utils/UtilityTypes";
import { EditUserPageView } from "./edit-user-page-view";
import { Loader } from "@pmp/common/loader/loader";
import React from "react";
import { useAlert } from "../../../hooks/useAlertContext";

export const EditUserPage = () => {
  const service: AppService = assertNotUndefined(
    useContext(LoggedInContext).loginState?.user?.apis.app
  );
  const history = useHistory();

  const { userId } = useParams<{
    userId: DbKey<AppUser>;
  }>();

  const loadUser = useCallback(async () => {
    return await service.queryUsers({
      supplierWorkspaceId: null,
      userId: userId,
      searchQuery: null
    });
  }, [service, userId]);

  const [loadingUsers, refreshUser] = useLoadingDataState(loadUser);

  const loadAllWorkspaces = useCallback(async () => {
    return await service.queryWorkspaces({
      workspaceNameLike: null,
      supplierWorkspaceId: null
    });
  }, [service]);
  const [loadingAllWorkspaces] = useLoadingDataState(loadAllWorkspaces);

  const loadRoles = useCallback(async () => {
    return await service.queryRoles({});
  }, [service]);
  const [loadingRoles] = useLoadingDataState(loadRoles);

  const [showAlert] = useAlert();
  const handleApplyChanges = useCallback(
    async (userDetails: UserDetails) => {
      try {
        const resp = await service.updateWorkspaceUser(userDetails);

        switch (resp) {
          case "failAppUserDoesNotExist":
            await showAlert({
              title: "Error updating user",
              body: "User not found."
            });
            refreshUser();
            return;
          case "failUpdateNotPermitted":
            await showAlert({
              title: "Error updating user",
              body: "You do not have permission to update this user."
            });
            refreshUser();
            return;
          case "failMultipleWorkspacesError":
            await showAlert({
              title: "Error updating user",
              body: "Multiple workspaces are not allowed for supplier users."
            });
            refreshUser();
            return;
          case "failUserUpdateError":
            await showAlert({
              title: "Error updating user",
              body:
                "An error occurred while updating the user, please try again."
            });
            refreshUser();
            return;
          case "failEmailUsedByAnotherUser":
            await showAlert({
              title: "Error updating user",
              body:
                "The email address you entered is already being used by another user in the system."
            });
            refreshUser();
            return;
          case "success":
            refreshUser();
            await showAlert({
              title: "User update",
              body: "User details successfully updated.",
              type: "success"
            });
            return;
        }
      } catch (e) {
        showAlert({
          title: "Error updating user",
          body: "An error occurred while updating the user, please try again."
        });
        return;
      }
    },
    [refreshUser, service, showAlert]
  );

  const handleArchiveUser = useCallback(async () => {
    const resp = await service.archiveUser(userId);
    switch (resp) {
      case "failAppUserDoesNotExist":
        await showAlert({
          title: "Error archiving user",
          body: "User not found."
        });
        return;
      case "failArchiveNotPermitted":
        await showAlert({
          title: "Error archiving user",
          body: "You do not have permission to archive this user."
        });
        return;
      case "success":
        refreshUser();
        await showAlert({
          title: "User archive",
          body: "User successfully archived.",
          type: "success"
        });
    }
  }, [service, showAlert, userId, refreshUser]);

  const handleUnarchiveUser = useCallback(async () => {
    const resp = await service.unarchiveUser(userId);
    switch (resp) {
      case "failAppUserDoesNotExist":
        await showAlert({
          title: "Error unarchiving user",
          body: "User not found."
        });
        return;
      case "failUnarchiveNotPermitted":
        await showAlert({
          title: "Error unarchiving user",
          body: "You do not have permission to unarchive this user."
        });
        return;
      case "success":
        refreshUser();
        await showAlert({
          title: "User unarchive",
          body: "User successfully unarchived.",
          type: "success"
        });
    }
  }, [service, showAlert, userId, refreshUser]);

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <Loader loadingStates={[loadingUsers, loadingAllWorkspaces, loadingRoles]}>
      {isLoaded(loadingUsers) &&
        isLoaded(loadingAllWorkspaces) &&
        isLoaded(loadingRoles) && (
          <EditUserPageView
            user={loadingUsers.value[0]}
            cancelFn={handleCancel}
            roles={loadingRoles.value}
            workspaces={loadingAllWorkspaces.value}
            applyChangesFn={handleApplyChanges}
            archiveUserFn={handleArchiveUser}
            unarchiveUserFn={handleUnarchiveUser}
          />
        )}
    </Loader>
  );
};
