import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  GridColTypeDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams
} from "@mui/x-data-grid-pro";
import { PromotionDiscount } from "@pmp/adl/petstock/merchantportal/types";
import { formatCurrency, formatPercent } from "@pmp/utils/numbers";
import React, { FC } from "react";
import {
  EditIcon,
  HiddenIcon,
  PlusIcon,
  SlidersIcon
} from "../../common/icon/icons";

export const baseNumericCell: GridColTypeDef = {
  type: "number",
  cellClassName: "font-tabular-nums"
};
export const cellPriceDef: GridColTypeDef = {
  ...baseNumericCell,
  valueFormatter: ({ value }) => formatCurrency(value)
};

export const cellPercentDef: GridColTypeDef = {
  ...baseNumericCell,
  valueFormatter: ({ value }) => formatPercent(value)
};

export const salePriceDiscountValue = (
  cellParams: GridValueFormatterParams<PromotionDiscount>
): string => {
  switch (cellParams.value.kind) {
    case "amountOff":
      return formatCurrency(Number(cellParams.value.value));
    case "percentOff":
      return formatPercent(Number(cellParams.value.value));
  }
};

// DataGrid Component is not exposed on the Theme(theme.ts) so styles have to be done with CSS classes
// Docs: https://mui.com/x/react-data-grid/style/#custom-theme
export const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaderTitle": {
    textTransform: "uppercase",
    fontWeight: 600,
    color: theme.palette.primary.dark
  },
  "& .MuiDataGrid-toolbarContainer": {
    gap: 16,
    justifyContent: "flex-end",
    borderBottom: "1px solid",
    borderColor: theme.palette.action.disabled,
    paddingBottom: 4
  }
}));

interface Props {
  handleEdit: () => void;
  handleAddItems: () => void;
  handleBarcodeUpload: () => void;
  handleCancel: () => void;
  handleSave: () => Promise<void>;
  editMode: boolean;
  hasChanges: boolean;
  showEditActions: boolean;
  showAddActions: boolean;
  tableEmpty: boolean;
}

/**
 * A helper toolbar containing: Column Toggle | Multi Filter | BasicExport
 */
export const BaseToolbar: FC<Props> = ({
  handleEdit,
  handleAddItems,
  handleBarcodeUpload,
  handleSave,
  handleCancel,
  editMode,
  hasChanges,
  showEditActions,
  showAddActions,
  tableEmpty
}) => {
  return (
    <GridToolbarContainer>
      {editMode ? (
        <>
          <Button variant={"outlined"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={!hasChanges}>
            Save Changes
          </Button>
        </>
      ) : (
        <>
          {showEditActions && !tableEmpty && (
            <Button
              variant={"outlined"}
              startIcon={<EditIcon />}
              onClick={handleEdit}
            >
              Edit Table
            </Button>
          )}
          {showAddActions && (
            <>
              <Button
                variant={"outlined"}
                startIcon={<PlusIcon />}
                onClick={handleAddItems}
              >
                Add Items
              </Button>
              <Button
                variant={"outlined"}
                startIcon={<PlusIcon />}
                onClick={handleBarcodeUpload}
              >
                Barcode Upload
              </Button>
            </>
          )}
        </>
      )}
      <Box flexGrow={1} />
      <GridToolbarColumnsButton
        variant={"outlined"}
        startIcon={<HiddenIcon />}
      />
      <GridToolbarFilterButton
        componentsProps={{
          button: {
            variant: "outlined",
            startIcon: <SlidersIcon />
          }
        }}
      />
      <GridToolbarExport variant={"outlined"} />
    </GridToolbarContainer>
  );
};
