import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PromotionStatus } from "@pmp/adl/petstock/merchantportal/types";
import { promotionStatusToUILabelColor } from "@pmp/utils/adl";
import React, { FC } from "react";

const PromoStatusChip: FC<{
  status: PromotionStatus;
  size?: "small";
  uppercased?: boolean;
}> = ({ status, size, uppercased }) => {
  const theme = useTheme();
  const { label, color } = promotionStatusToUILabelColor(status, theme);

  return (
    <Chip
      label={label}
      size={size}
      sx={{
        color: theme.palette.getContrastText(color),
        backgroundColor: color,
        textTransform: uppercased ? "uppercase" : "capitalize"
      }}
    />
  );
};

export default PromoStatusChip;
