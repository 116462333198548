import { Components, Theme } from "@mui/material/styles";
import LinkBehavior from "../helpers/LinkBehaviour";

export default function Button(theme: Theme): Components {
  return {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        LinkComponent: LinkBehavior
      },
      styleOverrides: {
        outlined: {
          backgroundColor: theme.palette.common.white
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius
        }
      }
    }
  };
}
