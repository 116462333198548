import { createTheme, ThemeOptions } from "@mui/material/styles";
import componentsOverrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const themeOptions: ThemeOptions = {
  palette,
  typography,
  shape: {
    borderRadius: 8
  }
};

const theme = createTheme(themeOptions);
theme.components = componentsOverrides(theme);

export const AppTheme = theme;
