import { useTheme } from "@mui/material";
import PieChart from "@pmp/components/PieChart/PieChart";
import { promotionStatusToUILabelColor } from "@pmp/utils/adl";
import React, { FC, useMemo } from "react";
import { PromoStatusUI } from "./PromotionStatusLineChart";

interface PromoStatusChartProps {
  statuses: Array<PromoStatusUI>;
}
const PromoStatusChart: FC<PromoStatusChartProps> = ({ statuses }) => {
  const theme = useTheme();

  const data = useMemo(() => {
    return statuses.map(status => {
      return {
        ...promotionStatusToUILabelColor(status.status, theme),
        value: status.count,
        id: status.status
      };
    });
  }, [statuses, theme]);

  return <PieChart data={data} tooltipLabelValueJoiner={"Promotions in"} />;
};

export default PromoStatusChart;
