import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Portal,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridValueFormatterParams
} from "@mui/x-data-grid-pro";
import { BigDecimal } from "@pmp/adl/common";
import {
  BulkUpdatePromotionItemsValue,
  FindProductsReq,
  PromotionItemSummary,
  QueryProductsFilter
} from "@pmp/adl/petstock/merchantportal/api";
import { CostUsed, FundingType } from "@pmp/adl/petstock/merchantportal/db";
import {
  PromotionDiscount,
  PromotionItemType,
  PromotionStatus,
  PromotionType
} from "@pmp/adl/petstock/merchantportal/types";
import { editablePromoStatusBySuperAdmin } from "@pmp/utils/constants";
import {
  formatCurrency,
  formatLeadingZero,
  formatPercent
} from "@pmp/utils/numbers";
import { formatPlural } from "@pmp/utils/strings";
import _ from "lodash";
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { useAppService } from "../../../hooks/useAppService";
import { useFeatures } from "../../../hooks/useFeatures";
import { useLoadingDataState } from "../../../hooks/useLoadingData";
import ActionsDrawer from "../../components/ActionsDrawer";
import { AddProductsDialog } from "../add-products-dialog/add-products-dialog";
import { BarcodeUploadDialog } from "../barcode-upload-dialog/barcode-upload-dialog";
import {
  baseNumericCell,
  BaseToolbar,
  cellPercentDef,
  cellPriceDef,
  salePriceDiscountValue,
  StyledDataGridPro
} from "./helpers";

interface Props {
  promotionId: string;
  promotionStatus: PromotionStatus;
  promotionType: PromotionType;
  productType: PromotionItemType | null;
  fundingType: FundingType;
  brands: string[];
  classes: string[];
  manufacturers: string[];
  groups: string[];
  selectedWorkspaceId: string;
  refreshPromotion: () => void;
}

const EDIT_ROW_ID = "EDIT_ROW_ID";
const PromotionItemsTable: FC<Props> = ({
  promotionId,
  promotionStatus,
  promotionType,
  productType,
  fundingType,
  brands,
  classes,
  manufacturers,
  groups,
  selectedWorkspaceId,
  refreshPromotion
}) => {
  const { hasEditorAccess, isSupplier, isSuperAdmin } = useFeatures();
  const service = useAppService();
  const [hiddenFields, setHiddenFields] = useState(0);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [removingPromoItems, setRemovingPromoItems] = useState(false);
  const [errorAddingPromoItems, setErrorAddingPromoItems] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openBarcodeUploadDialog, setOpenBarcodeUploadDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [bulkUpdateSTATUS, setBulkUpdateSTATUS] = useState<
    "SUCCESS" | "ERROR" | null
  >(null);

  const bulkUploadEmptyValue = {
    promoQuantity: null,
    psaDono: null,
    scanDollar: null,
    scanPercentage: null,
    salePriceDiscount: null,
    fixedPriceDiscount: null,
    qualifyAmount: null,
    costUsed: null
  };

  const [bulkEditValues, setBulkEditValues] = useState<
    BulkUpdatePromotionItemsValue
  >(bulkUploadEmptyValue);

  const [
    thresholdAmountEditValue,
    setThresholdAmountEditValue
  ] = useState<BigDecimal | null>(null);

  const promotionsItems = useCallback(async () => {
    return await service.queryPromotionItems({
      promotionId
    });
  }, [promotionId, service]);

  const [loadingPromotionItems, reloadPromotionItems] = useLoadingDataState(
    promotionsItems
  );

  const removePromotionItems = useCallback(async () => {
    setRemovingPromoItems(true);
    await service.deletePromotionItems({
      promotionId,
      promotionItemIds: selectedIds
    });

    setRemovingPromoItems(false);
    setOpenRemoveDialog(false);
    reloadPromotionItems();
    refreshPromotion();
  }, [
    promotionId,
    refreshPromotion,
    reloadPromotionItems,
    selectedIds,
    service
  ]);

  const handleAddPromoItems = useCallback(
    async (productIds, productType) => {
      const resp = await service.addPromotionItemsToPromotion({
        promotionId,
        productIds,
        promotionItemType: productType
      });

      if (resp === "unknownError") {
        setErrorAddingPromoItems(true);
        return;
      }
      reloadPromotionItems();
      refreshPromotion();
      setOpenAddDialog(false);
      setOpenBarcodeUploadDialog(false);
    },
    [promotionId, refreshPromotion, reloadPromotionItems, service]
  );

  const rows: Array<PromotionItemSummary> = useMemo(() => {
    if (loadingPromotionItems.state !== "success") {
      return [];
    }

    const _rows = loadingPromotionItems.value.promotionItemSummaries.filter(
      pi => pi.promotionItemType === productType
    );

    if (_rows === undefined) {
      return [];
    }
    if (editMode) {
      return [
        {
          ..._rows[_rows.length - 1],
          id: EDIT_ROW_ID
        },
        ..._rows
      ];
    }

    return _rows;
  }, [loadingPromotionItems, editMode, productType]);

  const otherRows: Array<PromotionItemSummary> = useMemo(() => {
    if (loadingPromotionItems.state !== "success") {
      return [];
    }

    const _rows = loadingPromotionItems.value.promotionItemSummaries.filter(
      pi => pi.promotionItemType !== productType
    );

    if (_rows === undefined) {
      return [];
    }
    if (editMode) {
      return [
        {
          ..._rows[_rows.length - 1],
          id: EDIT_ROW_ID
        },
        ..._rows
      ];
    }

    return _rows;
  }, [loadingPromotionItems, editMode, productType]);

  const productsAlreadyInPromotion = useMemo(() => {
    if (loadingPromotionItems.state !== "success") {
      return [];
    }

    const promoItems = loadingPromotionItems.value.promotionItemSummaries;
    return promoItems
      .filter(item => item.promotionItemType === null)
      .map(item => item.productId);
  }, [loadingPromotionItems]);

  const qualifiedProducts = useMemo(() => {
    if (loadingPromotionItems.state !== "success") {
      return [];
    }

    const promoItems = loadingPromotionItems.value.promotionItemSummaries;
    return promoItems
      .filter(item => item.promotionItemType === "qualifying")
      .map(item => item.productId);
  }, [loadingPromotionItems]);

  const awardProducts = useMemo(() => {
    if (loadingPromotionItems.state !== "success") {
      return [];
    }

    const promoItems = loadingPromotionItems.value.promotionItemSummaries;
    return promoItems
      .filter(item => item.promotionItemType === "award")
      .map(item => item.productId);
  }, [loadingPromotionItems]);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<
    GridColumnVisibilityModel
  >({
    name: true,
    rrp: true,
    qualifyAmount: true,
    promoQuantity: true,
    promoRrp: true,
    psaDono: true,
    savingPercentage: true,
    savingAmount: true,
    thresholdAmount: true,
    stockCode: true,
    manufacturer: true,
    brandCash: true,
    brand: true,
    class: true,
    groupName: true,
    storesSOHSOO: true,
    storesWithStock: true,
    planogramStores: true,
    salePriceDiscount: true,
    fixedPriceDiscount: true,
    costUsed: true,
    brandCode: true,
    upc: true,
    planagramedStores: true,
    isActive: true,
    dcsWithStock: true,
    dcSOH: true,
    storesWAC: true,
    isOnline: true,
    isEndlessAisle: true,
    dcAvgCost: true,
    storeBuyCost: true
  });

  const toolbarColumnLabel = useMemo(() => {
    return hiddenFields > 0
      ? `${hiddenFields} Hidden ${formatPlural("Field", hiddenFields) + " "}`
      : "Hide Fields";
  }, [hiddenFields]);

  const itemsString = useMemo(() => {
    return formatPlural("Item", selectedIds.length) + " ";
  }, [selectedIds.length]);

  const loadProducts = useCallback(
    async (filterOptions: QueryProductsFilter) => {
      return await service.queryProducts({
        supplierWorkspaceId: selectedWorkspaceId,
        productFilter:
          filterOptions !== null
            ? {
                ...filterOptions,
                excludeIds: rows
                  ? rows
                      .map(row => row.productId)
                      .concat(otherRows.map(row => row.productId))
                  : otherRows.map(row => row.productId)
              }
            : {
                nameOrBarcodeLike: null,
                filterByBrands: null,
                filterByClasses: null,
                filterByGroup: null,
                filterByManufactuer: null,
                excludeIds: rows
                  ? rows
                      .map(row => row.productId)
                      .concat(otherRows.map(row => row.productId))
                  : otherRows.map(row => row.productId)
              }
      });
    },
    [service, selectedWorkspaceId, rows, otherRows]
  );

  const findProducts = useCallback(
    async (req: FindProductsReq) => {
      return await service.findProducts(req);
    },
    [service]
  );

  const handleUpdateBulkUploadValues = (value: unknown, field: string) => {
    const _bulkEditValues = bulkEditValues;
    let _value = value;
    if (field === "qualifyAmount" || field === "promoQuantity") {
      _value = Number(_value);
    }
    _bulkEditValues[field] = _value;
    setBulkEditValues({ ..._bulkEditValues });
  };

  const handleUpdateThresholdAmountValue = (value: string | null) => {
    setThresholdAmountEditValue(value);
  };

  const canEditThreshold =
    (["submitted", "underReview"].includes(promotionStatus) &&
      !!hasEditorAccess("promotionPriority")) ||
    (editablePromoStatusBySuperAdmin.includes(promotionStatus) &&
      isSuperAdmin());

  const canEditNormalFields =
    (["draft", "renegotiation"].includes(promotionStatus) &&
      !!hasEditorAccess("promotionItems")) ||
    (editablePromoStatusBySuperAdmin.includes(promotionStatus) &&
      isSuperAdmin());

  const canEditPromotions = canEditNormalFields || canEditThreshold;

  const columns: Array<GridColDef> = [];
  columns.push(
    {
      field: "upc",
      headerAlign: "center",
      headerName: "Barcode",
      width: 140,
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "Bulk Update" : null)
    },
    {
      field: "stockCode",
      width: 140,
      headerAlign: "center",
      headerName: "Stock Code",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "name",
      headerAlign: "center",
      headerName: "Product",
      width: 300,
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "category",
      width: 140,
      headerAlign: "center",
      headerName: "Category",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "class",
      width: 140,
      headerAlign: "center",
      headerName: "Class",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "groupName",
      width: 140,
      headerAlign: "center",
      headerName: "Group",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    }
  );
  if (!isSupplier()) {
    columns.push({
      field: "manufacturer",
      width: 140,
      headerAlign: "center",
      headerName: "Manufacturer",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    });
  }
  columns.push({
    field: "brand",
    width: 140,
    headerAlign: "center",
    headerName: "Brand",
    align: "center",
    renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
  });
  if (!isSupplier()) {
    columns.push(
      {
        field: "storesSOHSOO",
        width: 140,
        headerAlign: "center",
        headerName: "SOH+SOO",
        align: "center",
        renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
      },
      {
        field: "dcSOH",
        width: 140,
        headerAlign: "center",
        headerName: "DC SOH",
        align: "center",
        renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
      }
    );
  }
  columns.push(
    {
      field: "storesWithStock",
      width: 160,
      headerAlign: "center",
      headerName: "Stores With Stock",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "planogramStores",
      width: 160,
      headerAlign: "center",
      headerName: "PLANOGRAMMED STORES",
      align: "center",
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    }
  );
  if (!isSupplier()) {
    columns.push({
      field: "hasEndlessAisle",
      width: 160,
      headerAlign: "center",
      headerName: "Has Endless Aisle",
      align: "center",
      type: "boolean",
      valueFormatter: ({ value }) => (value === true ? "Yes" : "No"),
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    });
  }
  columns.push({
    field: "isOnline",
    width: 140,
    headerAlign: "center",
    headerName: "Online Status",
    align: "center",
    renderCell: ({ id, value }) =>
      id === EDIT_ROW_ID ? (
        ""
      ) : (
        <Chip
          label={value ? "Available" : "Unavailable"}
          variant={value ? "filled" : "outlined"}
        />
      )
  });
  if (
    promotionType.kind === "salePriceDiscount" &&
    productType !== "qualifying"
  ) {
    columns.push({
      field: "salePriceDiscount",
      headerAlign: "center",
      headerName: "sale price discount",
      align: "center",
      valueFormatter: (params: GridValueFormatterParams<PromotionDiscount>) =>
        salePriceDiscountValue(params),
      ...baseNumericCell,
      width: 200,
      renderCell: ({ value, field, id }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields ? (
            <BulkUpdatePriceDiscountEditCell
              value={value}
              onChange={(val: PromotionDiscount) => {
                handleUpdateBulkUploadValues(
                  _.isEqual(value, val) ? val : null,
                  field
                );
              }}
            />
          ) : (
            ""
          )
        ) : null
    });
  }
  if (
    promotionType.kind === "fixedPriceDiscount" &&
    productType !== "qualifying"
  ) {
    columns.push({
      field: "fixedPriceDiscount",
      width: 200,
      headerAlign: "center",
      headerName:
        promotionType.value.kind === "multiBuySet"
          ? "Promo Price per Set"
          : "fixed price discount",
      align: "center",
      ...cellPriceDef,
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields ? (
            <BaseEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    });
  }
  columns.push(
    {
      field: "rrp",
      width: 140,
      headerAlign: "center",
      headerName: "Retail Price",
      align: "center",
      ...cellPriceDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "promoRrp",
      width: 140,
      headerAlign: "center",
      headerName: "Promo Price",
      align: "center",
      ...cellPriceDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "savingAmount",
      width: 140,
      headerAlign: "center",
      headerName: "Saving $'s",
      align: "center",
      ...cellPriceDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "savingPercentage",
      width: 140,
      headerAlign: "center",
      headerName: "Saving %",
      align: "center",
      ...cellPercentDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    }
  );
  if (!isSupplier()) {
    columns.push({
      field: "brandCash",
      width: 140,
      headerAlign: "center",
      headerName: "Brand Cash",
      align: "center",
      ...cellPriceDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    });
  }
  columns.push({
    field: "dcAvgCost",
    width: 140,
    headerAlign: "center",
    headerName: "DC Cost",
    align: "center",
    ...cellPriceDef,
    renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
  });
  if (!isSupplier()) {
    columns.push(
      {
        field: "storeBuyCost",
        width: 140,
        headerAlign: "center",
        headerName: "Store Cost",
        align: "center",
        ...cellPriceDef,
        renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
      },
      {
        field: "wac",
        width: 140,
        headerAlign: "center",
        headerName: "wac",
        align: "center",
        ...cellPriceDef,
        renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
      }
    );
  }
  columns.push(
    {
      field: "costUsed",
      width: 140,
      headerAlign: "center",
      headerName: "Cost Used",
      align: "center",
      type: "string",
      valueFormatter: ({ value }) => value.toUpperCase(),
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields ? (
            <CostUsedEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    },
    {
      field: "scanPercentage",
      width: 140,
      headerAlign: "center",
      headerName: "Scan %",
      align: "center",
      type: "number",
      cellClassName: "font-tabular-nums",
      valueFormatter: ({ value }) => formatPercent(value),
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields && fundingType === "percentageFormat" ? (
            <BaseEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    },
    {
      field: "scanDollar",
      width: 140,
      headerAlign: "center",
      headerName: "Scan $'s",
      align: "center",
      type: "number",
      cellClassName: "font-tabular-nums",
      valueFormatter: ({ value }) => formatCurrency(value),
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields && fundingType === "dollarFormat" ? (
            <BaseEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    },
    {
      field: "actualScanPercentageOffCostUsed",
      width: 300,
      headerAlign: "center",
      headerName: "Actual Scan % Funding OFF COST USED",
      align: "center",
      ...cellPercentDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      field: "actualScanPercentageOffWAC",
      width: 280,
      headerAlign: "center",
      headerName: "Actual Scan % Funding OFF WAC",
      align: "center",
      ...cellPercentDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    },
    {
      ...baseNumericCell,
      field: "psaDono",
      width: 140,
      headerAlign: "center",
      headerName: "PSF Dono",
      align: "center",
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields ? (
            <BaseEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    },
    {
      field: "notionalCost",
      width: 140,
      headerAlign: "center",
      headerName: "Notional Cost",
      align: "center",
      ...cellPriceDef,
      renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
    }
  );
  if (!isSupplier()) {
    columns.push(
      {
        field: "gpPercentPetUnit",
        width: 240,
        headerAlign: "center",
        headerName: "NGP% (Exl Scan) per Unit",
        align: "center",
        ...cellPercentDef,
        renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
      },
      {
        field: "ngpPercentPerUnit",
        width: 240,
        headerAlign: "center",
        headerName: "NGP% (Inc Scan) per Unit",
        align: "center",
        ...cellPercentDef,
        renderCell: ({ id }) => (id === EDIT_ROW_ID ? "" : null)
      },
      {
        field: "thresholdAmount",
        width: 180,
        headerAlign: "center",
        headerName: "Threshold Amount",
        align: "center",
        ...cellPriceDef,
        renderCell: ({ id, value }) =>
          id === EDIT_ROW_ID ? (
            canEditThreshold ? (
              <BaseEditCell
                value={value ?? ""}
                onChange={val => {
                  handleUpdateThresholdAmountValue(value !== val ? val : null);
                }}
              />
            ) : (
              ""
            )
          ) : null
      }
    );
  }
  if (promotionType.value.kind !== "basic" && productType !== "award") {
    columns.push({
      ...baseNumericCell,
      field: "qualifyAmount",
      width: 140,
      headerAlign: "center",
      headerName: "Qualify Qty",
      align: "center",
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields ? (
            <BaseEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    });
  }
  if (
    promotionType.value.kind.includes("Award") &&
    productType !== "qualifying"
  ) {
    columns.push({
      ...baseNumericCell,
      field: "promoQuantity",
      width: 140,
      headerAlign: "center",
      headerName: "Promo Qty",
      align: "center",
      renderCell: ({ id, field, value }) =>
        id === EDIT_ROW_ID ? (
          canEditNormalFields ? (
            <BaseEditCell
              value={value ?? ""}
              onChange={val => {
                handleUpdateBulkUploadValues(value !== val ? val : null, field);
              }}
            />
          ) : (
            ""
          )
        ) : null
    });
  }

  const handleBulkUpload = async () => {
    try {
      if (!_.isEqual(bulkEditValues, bulkUploadEmptyValue)) {
        await service.bulkUpdatePromotionItems({
          promotionId,
          value: bulkEditValues,
          promotionItemType: productType
        });
      }
      if (thresholdAmountEditValue !== null) {
        await service.updateAdminOnlyFields({
          promotionId,
          header: null,
          priority: null,
          thresholdAmount: thresholdAmountEditValue
        });
      }

      setBulkUpdateSTATUS("SUCCESS");
      reloadPromotionItems();
      setEditMode(false);
    } catch (e) {
      setBulkUpdateSTATUS("ERROR");
    }
  };

  const handleCancelBulkUpload = () => {
    setBulkEditValues(bulkUploadEmptyValue);
    setEditMode(false);
  };

  return (
    <>
      {/* TODO: Fix layout Box sx in #162  */}
      <Box
        sx={{
          height: "1px",
          minHeight: "55vh",
          width: "100%"
        }}
      >
        <StyledDataGridPro
          rows={rows}
          columns={columns}
          components={{
            Toolbar: () => (
              <BaseToolbar
                handleEdit={() => setEditMode(true)}
                handleAddItems={() => setOpenAddDialog(true)}
                handleBarcodeUpload={() => setOpenBarcodeUploadDialog(true)}
                handleSave={handleBulkUpload}
                handleCancel={handleCancelBulkUpload}
                editMode={editMode}
                hasChanges={
                  !_.isEqual(bulkEditValues, bulkUploadEmptyValue) ||
                  thresholdAmountEditValue !== null
                }
                showEditActions={canEditPromotions}
                showAddActions={canEditNormalFields}
                tableEmpty={rows.length === 0}
              />
            )
          }}
          checkboxSelection={canEditNormalFields && !editMode}
          isCellEditable={params => editMode && params.id === EDIT_ROW_ID}
          localeText={{ toolbarColumns: toolbarColumnLabel }}
          loading={loadingPromotionItems.state === "loading"}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={model => {
            setColumnVisibilityModel(model);
            const hiddenCount = Object.values(model).filter(val => !val).length;
            setHiddenFields(hiddenCount);
          }}
          onSelectionModelChange={ids =>
            setSelectedIds(ids.map(id => id.toString()))
          }
          disableSelectionOnClick
          disableDensitySelector
          disableMultipleSelection={editMode}
        />
      </Box>

      <Portal>
        <Snackbar
          open={errorAddingPromoItems}
          onClose={() => setErrorAddingPromoItems(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="error" variant={"filled"}>
            Fail adding promotion items.
          </Alert>
        </Snackbar>
        <Snackbar
          open={bulkUpdateSTATUS === "SUCCESS"}
          onClose={() => setBulkUpdateSTATUS(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success" variant={"filled"}>
            Updated Promotions.
          </Alert>
        </Snackbar>
        <Snackbar
          open={bulkUpdateSTATUS === "ERROR"}
          onClose={() => setBulkUpdateSTATUS(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="error" variant={"filled"}>
            Fail updating promotion items.
          </Alert>
        </Snackbar>
        <AddProductsDialog
          open={openAddDialog}
          loadProducts={loadProducts}
          productType={productType}
          onAddProducts={handleAddPromoItems}
          onClose={() => setOpenAddDialog(false)}
          brands={brands}
          classes={classes}
          manufacturers={manufacturers}
          groups={groups}
          handleSwitchToBarcodeUpload={() => {
            setOpenAddDialog(false);
            setOpenBarcodeUploadDialog(true);
          }}
          initialProducts={[]}
        />
        <BarcodeUploadDialog
          open={openBarcodeUploadDialog}
          onClose={() => setOpenBarcodeUploadDialog(false)}
          findProducts={findProducts}
          productType={productType}
          onAddProducts={handleAddPromoItems}
          supplierWorkspaceId={selectedWorkspaceId}
          handleSwitchToSelectItems={() => {
            setOpenBarcodeUploadDialog(false);
            setOpenAddDialog(true);
          }}
          qualifiedProducts={qualifiedProducts}
          awardProducts={awardProducts}
          productsAlreadyInPromotion={productsAlreadyInPromotion}
        />

        <ActionsDrawer
          isOpen={selectedIds.length > 0}
          primaryAction={
            <Button color={"error"} onClick={() => setOpenRemoveDialog(true)}>
              Remove {itemsString}
            </Button>
          }
          secondaryAction={
            <Typography>
              {formatLeadingZero(selectedIds.length)} {itemsString} Selected
            </Typography>
          }
        />
        <Dialog
          open={openRemoveDialog}
          onClose={() => setOpenRemoveDialog(false)}
        >
          <DialogTitle>
            Remove {formatLeadingZero(selectedIds.length)} {itemsString} from
            this Promotion?
          </DialogTitle>
          <DialogActions>
            <Button
              fullWidth
              variant={"outlined"}
              onClick={() => setOpenRemoveDialog(false)}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={removingPromoItems}
              fullWidth
              variant={"contained"}
              color={"error"}
              onClick={() => removePromotionItems()}
            >
              Remove
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Portal>
    </>
  );
};

interface BaseCellEditProps {
  value: string;
  onChange: (val: string) => void;
}

const BaseEditCell: FC<BaseCellEditProps> = ({
  value: valueProp,
  onChange
}) => {
  const [value, setValue] = React.useState(valueProp);

  return (
    <TextField
      type={"number"}
      value={value ?? ""}
      onChange={e => {
        const val = e.target.value;
        setValue(val);
        onChange(val);
      }}
      size={"small"}
    />
  );
};

interface BulkUpdatePriceDiscountProps {
  value: PromotionDiscount;
  onChange: (val: PromotionDiscount) => void;
}

const BulkUpdatePriceDiscountEditCell: FC<BulkUpdatePriceDiscountProps> = ({
  value: valueProp,
  onChange
}) => {
  const [value, setValue] = React.useState<PromotionDiscount>(valueProp);

  const _handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (value === undefined) {
      return;
    }
    const val = e.target.value;

    const _value = value;
    switch (_value.kind) {
      case "amountOff":
        _value.value = val;
        break;
      case "percentOff":
        _value.value = Number(val);
        break;
    }
    setValue(_value);
    onChange(_value);
  };

  const _handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (value === undefined) {
      return;
    }
    const val = e.target.value;
    const _value = value;
    _value.kind = val as PromotionDiscount["kind"];
    switch (_value.kind) {
      case "amountOff":
        _value.value = _value.value + "";
        break;
      case "percentOff":
        _value.value = Number(_value.value);
        break;
    }
    setValue(_value);
    onChange(_value);
  };

  return (
    <TextField
      value={value?.value ?? ""}
      onChange={_handleInputChange}
      size={"small"}
      type={"number"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Select
              fullWidth
              value={value?.kind ?? ""}
              onChange={_handleSelectChange}
              size={"small"}
            >
              <MenuItem value="percentOff">% Discount</MenuItem>
              <MenuItem value="amountOff">$ Discount</MenuItem>
            </Select>
          </InputAdornment>
        ),
        sx: { paddingRight: 0 }
      }}
    />
  );
};

interface CostUsedEditCellProps {
  value: CostUsed;
  onChange: (val: CostUsed) => void;
}

const CostUsedEditCell: FC<CostUsedEditCellProps> = ({
  value: valueProp,
  onChange
}) => {
  const [value, setValue] = React.useState<CostUsed>(valueProp);

  return (
    <Select
      fullWidth
      value={value}
      size={"small"}
      onChange={e => {
        const val = e.target.value as CostUsed;
        setValue(val);
        onChange(val);
      }}
    >
      <MenuItem value="wac">WAC</MenuItem>
      <MenuItem value="dc">DC</MenuItem>
    </Select>
  );
};

export default PromotionItemsTable;
