import { Stack, TextField } from "@mui/material";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import LabelInput from "@pmp/common/inputs/helpers/input-label";
import { useField } from "formik";
import React, { FC, useMemo } from "react";

interface Props extends BaseTextFieldProps {
  /**
   * Name to link to form submission.
   */
  name: string;
  /**
   * The min value allowed.
   */
  min?: number;
  /**
   * The max value allowed.
   */
  max?: number;
}

/**
 * Component used as basic number input
 * @param props: Props
 */
export const NumberInput: FC<Props> = props => {
  const { helperText, name, label, required, min, max, ...rest } = props;
  const [field, meta, helper] = useField(name);
  const { value } = field;
  const { setValue, setTouched } = helper;
  const hasError = useMemo(() => meta.touched && Boolean(meta.error), [
    meta.error,
    meta.touched
  ]);

  const _onBlur = () => {
    setTouched(true);
    if (min !== undefined && field.value < min) {
      setValue(min);
    }
    if (max !== undefined && field.value > max) {
      setValue(max);
    }
  };

  return (
    <Stack spacing={1}>
      {label && (
        <LabelInput
          label={label}
          name={name}
          required={required}
          error={hasError}
          helperText={helperText}
        />
      )}
      <TextField
        type="number"
        id={name}
        hiddenLabel
        error={hasError}
        helperText={hasError ? meta.error : null}
        sx={{}}
        {...field}
        {...rest}
        value={value ?? ""}
        onBlur={_onBlur}
      />
    </Stack>
  );
};

export default NumberInput;
